let coloums = [
    {
        children: [
            {
                title: 'Dwarf type',
                dataIndex: 'code'
            },
            {
                title: 'Date of Planting',
                dataIndex: 'dt_planted',
            },
            {
                title: 'Tree No.',
                dataIndex: 'tree_no_full',
            }
        ]
    }
]

let coloums2 = [
    {
        children: [
            {
                title: 'Tall type',
                dataIndex: 'code'
            },
            {
                title: 'Date of Planting',
                dataIndex: 'dt_planted',
            },
            {
                title: 'Tree No.',
                dataIndex: 'tree_no_full',
            }
        ]
    }
]

export const dwartfColoums = (dateArr) => coloums.concat(dateArr.map((i, index) => ({
    title: `EMASCULATION IN ${i - 1} AND HARVEST IN ${i}`,
    children: [
        {
            title: 'No. of spathes',
            dataIndex: 'no_of_spathes_' + index,
            render: it => (it ? it : '-')
        },
        {
            title: 'No. of Buttons',
            dataIndex: 'no_of_buttons_' + index,
            render: it => (it ? it : '-')
        },
        {
            title: '120 Days Count',
            dataIndex: 'count_120_days_' + index,
            render: it => (it ? it : '-')
        },
        {
            title: 'Harvested Nuts',
            dataIndex: 'harvested_nuts_' + index,
            render: it => (it ? it : '-')
        },
        {
            title: 'Nuts per Spathe',
            dataIndex: 'nuts_per_spathe_' + index,
            render: it => (it ? it : '-')
        }
    ]
})))

export const tallColoums = (dateArr) => coloums2.concat(dateArr.map((i, index) => ({
    title: `HARVEST IN ${i}`,
    children: [
        {
            title: 'Bunches',
            dataIndex: 'bunches_' + index,
            render: it => (it ? it : '-')
        },
        {
            title: 'Harvested Nuts',
            dataIndex: 'harvested_nuts_' + index,
            render: it => (it ? it : '-')
        },
        {
            title: 'Nuts per Bunches',
            dataIndex: 'nuts_per_bunch_' + index,
            render: it => (it ? it : '-')
        }
    ]
})))
