import React, { Component, Fragment } from "react";

import { message, Spin } from "antd";

//Utility
import { fetchApi, fetchExcelApi } from "../../../services/api";
import { parseData, parseData2 } from "./parseData";
import { cloneDeep, filter } from "lodash";
import moment from "moment";
import { sortFunction } from "../../../utility/sorter";
import { formatDate } from "../../../utility/date";
import { COOKIE, getCookie } from "../../../services/cookie";

//Component import
import ReportLayout from "../../pageLayouts/Report";
import MultiTable from "./MultiTable4";
import ScollBarView from "react-double-scrollbar";
import Empty from "../../components/Custom/Empty";

class SeedlingGrowthDataForTallTrees extends Component {
  componentRef = React.createRef();
  state = {
    data: [],
    dynamicData: [],
    loading: false,
    blockNumber: null,
    blockNoList: [],
    block_uuid: null,
    areaNoList: [],
    areaNumber: null,
    area_uuid: null,
    plantedDate: null
  };

  componentDidMount() {
    this.fetchBlockNo();
  }

  fetchData = () => {
    let { plantedDate, data, block_uuid, area_uuid } = this.state;
    if (plantedDate) {
      this.setState({ loading: true });
      let url = `/input-format/seedling-growth?type=2&month=${plantedDate}`;
      if (block_uuid) url += `&block.uuid=${block_uuid}`;
      if (area_uuid) url += `&area.uuid=${area_uuid}`;
      fetchApi(url, "get").then(res => {
        if (res) {
          this.setState({
            data: res,
            dynamicData: parseData2(res.blocks)
          });
        } else message.error("Internal Server Error!");
        this.setState({ loading: false });
      });
    }
  };

  fetchBlockNo = () =>
    fetchApi("/blocksuuid", "get").then(data =>
      this.setState({ blockNoList: sortFunction(data, "blockNoFull") })
    );

  onBlockChangeHandler = it => {
    let { blockNoList } = this.state;
    if (it) {
      fetchApi(
        `/areasuuid?block.uuid=${
        cloneDeep(blockNoList).filter(item => item.blockNoFull == it)[0].uuid
        }`,
        "get"
      ).then(res => this.setState({ areaNoList: res }));
    }
    this.setState(
      {
        blockNumber: it ? it : null,
        block_uuid: it
          ? cloneDeep(blockNoList).filter(each => each.blockNoFull === it)[0]
            .uuid
          : null,
        areaNumber: null,
        areaNoList: []
      },
      () => this.fetchData()
    );
  };

  onAreaChangeHandler = it => {
    let { areaNoList } = this.state;
    this.setState(
      {
        areaNumber: it ? it : null,
        area_uuid: it
          ? cloneDeep(areaNoList).filter(each => each.areaNo === it)[0].uuid
          : null
      },
      () => this.fetchData()
    );
  };

  plantedHandlerDate = (date, dateString) =>
    date
      ? this.setState({
        plantedDate: date.format("YYYY-MM"),
        dynamicData: [],
        data: []
      }, () =>
        this.fetchData()
      )
      : this.setState({
        plantedDate: null,
        dynamicData: [],
        data: []
      });

  /*tableFilterHandler = () => {
    let { plantedDate, data, areaNumber, blockNumber } = this.state,
      filterJson = {};
    if (blockNumber) filterJson.blockNo = blockNumber;
    if (areaNumber) filterJson.areaNo = areaNumber;
    if (plantedDate) filterJson.dtPlanted = plantedDate.format("DD/MM/YYYY");

    this.setState({
      dynamicData: parseData(filter(cloneDeep(data), filterJson))
    });
  };*/

  // generateTables = tableData =>
  //   tableData.map(
  //     ({ block_no, area_no, trees }, index) => (
  //       console.log(trees),
  //       (
  //         <ScollBarView>
  //           <MultiTable
  //             key={index}
  //             blockNo={block_no}
  //             areaNo={area_no}
  //             columns={[
  //               {
  //                 title: "Seedling Details",
  //                 children: [
  //                   {
  //                     title: "Tree No.",
  //                     dataIndex: "tree_no_full",
  //                     key: "tree_no_full"
  //                   },
  //                   {
  //                     title: "Dwarf Type",
  //                     dataIndex: "code",
  //                     key: "code"
  //                   },
  //                   ,
  //                   {
  //                     title: "Planted Date",
  //                     dataIndex: "dt_planted",
  //                     key: "dt_planted",
  //                     render: it => (it ? formatDate(it) : "-")
  //                   }
  //                 ]
  //               },
  //               {
  //                 title: "1st Reading Due Date:",
  //                 children: [
  //                   {
  //                     title: "Date of reading",
  //                     dataIndex: "dt_reading_1",
  //                     key: "dt_reading_1",
  //                     render: it => (it ? formatDate(it) : "-")
  //                   },
  //                   {
  //                     title: "Due date",
  //                     dataIndex: "due_dt_1",
  //                     key: "due_dt_1",
  //                     render: it => (it ? formatDate(it) : "-")
  //                   },
  //                   {
  //                     title: "Leaves",
  //                     dataIndex: "leaves_1",
  //                     key: "leaves_1",
  //                     render: it => (it ? it : "-")
  //                   },
  //                   {
  //                     title: "Girth (cms)",
  //                     dataIndex: "girth_1",
  //                     key: "girth_1",
  //                     render: it => (it ? it : "-")
  //                   },
  //                   {
  //                     title: "Height (cms)",
  //                     dataIndex: "height_1",
  //                     key: "height_1",
  //                     render: it => (it ? it : "-")
  //                   }
  //                 ]
  //               },
  //               {
  //                 title: "2nd Reading Due Date:",
  //                 children: [
  //                   {
  //                     title: "Date of reading",
  //                     dataIndex: "dt_reading_2",
  //                     key: "dt_reading_2",
  //                     render: it => (it ? formatDate(it) : "-")
  //                   },
  //                   {
  //                     title: "Due date",
  //                     dataIndex: "due_dt_2",
  //                     key: "due_dt_2",
  //                     render: it => (it ? formatDate(it) : "-")
  //                   },
  //                   {
  //                     title: "Leaves",
  //                     dataIndex: "leaves_2",
  //                     key: "leaves_2",
  //                     render: it => (it ? it : "-")
  //                   },
  //                   {
  //                     title: "Girth (cms)",
  //                     dataIndex: "girth_2",
  //                     key: "girth_2",
  //                     render: it => (it ? it : "-")
  //                   },
  //                   {
  //                     title: "Height (cms)",
  //                     dataIndex: "height_2",
  //                     key: "height_2",
  //                     render: it => (it ? it : "-")
  //                   }
  //                 ]
  //               },
  //               {
  //                 title: "3rd Reading Due Date:",
  //                 children: [
  //                   {
  //                     title: "Date of reading",
  //                     dataIndex: "dt_reading_3",
  //                     key: "dt_reading_3",
  //                     render: it => (it ? formatDate(it) : "-")
  //                   },
  //                   {
  //                     title: "Due date",
  //                     dataIndex: "due_dt_3",
  //                     key: "due_dt_3",
  //                     render: it => (it ? formatDate(it) : "-")
  //                   },
  //                   {
  //                     title: "Leaves",
  //                     dataIndex: "leaves_3",
  //                     key: "leaves_3",
  //                     render: it => (it ? it : "-")
  //                   },
  //                   {
  //                     title: "Girth (cms)",
  //                     dataIndex: "girth_3",
  //                     key: "girt_3",
  //                     render: it => (it ? it : "-")
  //                   },
  //                   {
  //                     title: "Height (cms)",
  //                     dataIndex: "height_3",
  //                     key: "height_3",
  //                     render: it => (it ? it : "-")
  //                   }
  //                 ]
  //               }
  //             ]}
  //             dataSource={trees}
  //           />
  //         </ScollBarView>
  //       )
  //     )
  //   );

  generateTables = tableData => (
    <MultiTable
      tableData={tableData}
      columns={[
        {
          title: "Seedling Details",
          className: "reports-right-border",
          children: [
            {
              title: "Tree No.",
              dataIndex: "tree_no_full",
              key: "tree_no_full"
            },
            {
              title: "Dwarf Type",
              dataIndex: "code",
              key: "code"
            },
            ,
            {
              title: "Planted Date",
              dataIndex: "dt_planted",
              className: "reports-right-border",
              key: "dt_planted",
              render: it => (it ? formatDate(it) : "-")
            }
          ]
        },
        {
          title: "1st Reading Due Date:",
          className: "reports-right-border",
          children: [
            {
              title: "Due date",
              dataIndex: "due_dt_1",
              key: "due_dt_1",
              render: it => (it ? formatDate(it) : "-")
            },
            {
              title: "Date of reading",
              dataIndex: "dt_reading_1",
              key: "dt_reading_1",
              render: it => (it ? formatDate(it) : "-")
            },
            {
              title: "Leaves",
              dataIndex: "leaves_1",
              key: "leaves_1",
              render: it => (it ? it : "-")
            },
            {
              title: "Girth (cms)",
              dataIndex: "girth_1",
              key: "girth_1",
              render: it => (it ? it : "-")
            },
            {
              title: "Height (cms)",
              dataIndex: "height_1",
              className: "reports-right-border",
              key: "height_1",
              render: it => (it ? it : "-")
            }
          ]
        },
        {
          title: "2nd Reading Due Date:",
          className: "reports-right-border",
          children: [
            {
              title: "Due date",
              dataIndex: "due_dt_2",
              key: "due_dt_2",
              render: it => (it ? formatDate(it) : "-")
            },
            {
              title: "Date of reading",
              dataIndex: "dt_reading_2",
              key: "dt_reading_2",
              render: it => (it ? formatDate(it) : "-")
            },
            {
              title: "Leaves",
              dataIndex: "leaves_2",
              key: "leaves_2",
              render: it => (it ? it : "-")
            },
            {
              title: "Girth (cms)",
              dataIndex: "girth_2",
              key: "girth_2",
              render: it => (it ? it : "-")
            },
            {
              title: "Height (cms)",
              dataIndex: "height_2",
              className: "reports-right-border",
              key: "height_2",
              render: it => (it ? it : "-")
            }
          ]
        },
        {
          title: "3rd Reading Due Date:",
          children: [
            {
              title: "Due date",
              dataIndex: "due_dt_3",
              key: "due_dt_3",
              render: it => (it ? formatDate(it) : "-")
            },
            {
              title: "Date of reading",
              dataIndex: "dt_reading_3",
              key: "dt_reading_3",
              render: it => (it ? formatDate(it) : "-")
            },
            {
              title: "Leaves",
              dataIndex: "leaves_3",
              key: "leaves_3",
              render: it => (it ? it : "-")
            },
            {
              title: "Girth (cms)",
              dataIndex: "girth_3",
              key: "girt_3",
              render: it => (it ? it : "-")
            },
            {
              title: "Height (cms)",
              dataIndex: "height_3",
              key: "height_3",
              render: it => (it ? it : "-")
            }
          ]
        }
      ]}
    />
  );
  generateExcel = () => {
    let { plantedDate, data, block_uuid, area_uuid } = this.state;
    if (plantedDate) {
      let url = `/input-format/seedling-growth?type=2&month=${plantedDate}`;
      if (block_uuid) url += `&block.uuid=${block_uuid}`;
      if (area_uuid) url += `&area.uuid=${area_uuid}`;
      fetchExcelApi(url, "get").then();
    }
  };

  render() {
    let {
      plantedDate,
      dynamicData,
      loading,
      blockNoList,
      areaNoList,
      blockNumber,
      areaNumber
    } = this.state;
    let JSX =
      dynamicData.length > 0 ? (
        <Fragment>
          <h3>
            Seedling Growth Data For Tall Trees Input Format
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            Farm : {getCookie(COOKIE.FARM).charAt(0).toUpperCase() + getCookie(COOKIE.FARM).slice(1)}{" "} </h3>
          <h3>Planted During : {" " + moment(this.state.plantedDate).format("MMMM YYYY")}</h3>
          {this.generateTables(dynamicData)}
        </Fragment>
      ) : plantedDate ? (
        <Empty description={"No Data Found"} />
      ) : (
            <Empty description={"Select Planted Date"} />
          );
    const buttonList =
      dynamicData.length > 0
        ? [
          {
            label: "Excel",
            icon: "file-text",
            onClickHandler: () => this.generateExcel()
          },
          {
            label: "Print",
            icon: "diff",
            toolTip: "Suggestion: Print in Landscape",
            reference: this.componentRef
          }
        ]
        : null;
    return (
      <ReportLayout
        title="Seedling Growth Data For Tall Trees"
        buttonList={buttonList}
        dropDownList={[
          {
            label: "Block",
            listItems: cloneDeep(blockNoList).map(it => it.blockNoFull),
            changeHandler: it => this.onBlockChangeHandler(it)
          },
          {
            label: "Area",
            listItems: cloneDeep(areaNoList).map(it => it.areaNo),
            disabled: blockNumber ? false : true,
            value: areaNumber ? areaNumber : undefined,
            changeHandler: it => this.onAreaChangeHandler(it)
          },
          {
            label: "Planted Date",
            type: "month",
            changeHandler: this.plantedHandlerDate
          }
        ]}
      >
        <div ref={this.componentRef}>
          <Spin spinning={this.state.loading} size="large">
            {JSX}
          </Spin>
        </div>
      </ReportLayout>
    );
  }
}

export default SeedlingGrowthDataForTallTrees;
