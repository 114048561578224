import { sortFunction } from "../../../utility/sorter";
import { months } from "moment";

export const parseData = arr => {
  // let resultArray = [];

  // var Areas = [];
  var Area = [];
  var Block = [];
  var treeData = [];
  var Data = [];
  var months = [];
  var treeData = [];
  //Months
  var MM = [
    "Jan",
    "Feb",
    "March",
    "April",
    "May",
    "June",
    "July",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  ];
  console.log("Arraa", arr);

  arr.map(({ block_no, trees }, index) => {
    if (trees) {
      trees = sortFunction(trees, "tree_no");
      trees.map(({ area_no, code, dt_planted, tree_no, row_no, years }, j) => {
        years &&
          years.map(({ buttons, count120_days, nuts, spathes, year }, k) => {
            if (nuts) {
              for (var i = 0; i < 12; i++) {
                months.push({
                  buttons: buttons ? buttons[i].buttonsqty : "0",
                  count120_days: count120_days ? count120_days[i].daysqty : "0",
                  nuts: nuts[i].harqty,
                  spathes: spathes[i].spathe_count,
                  MM: MM[i]
                });
              }
              months.push({
                buttons: buttons ? buttons[12].total : "0",
                count120_days: count120_days ? count120_days[12].total : "0",
                nuts: nuts[12].total,
                spathes: spathes[12].total,
                MM: "Total"
              });
            } else {
              for (var i = 0; i < 12; i++) {
                months.push({
                  buttons: buttons ? buttons[i].buttonsqty : "0",
                  count120_days: count120_days ? count120_days[i].daysqty : "0",

                  spathes: spathes[i].spathe_count,
                  MM: MM[i]
                });
              }
              months.push({
                buttons: buttons ? buttons[12].total : "0",
                count120_days: count120_days ? count120_days[12].total : "0",

                spathes: spathes[12].total,
                MM: "Total"
              });
            }

            treeData.push({
              // buttons: buttons,
              // count120_days: count120_days,
              // nuts: nuts,
              // spathes: spathes,
              months: months,
              year: year
            });
            months = [];
          });
        Data.push({
          treeIndex: j,
          block_no: block_no,
          tree_no: tree_no,
          area_no: area_no,
          code: code,
          dt_planted: dt_planted,
          row_no: row_no,
          treeData: treeData
        });
        treeData = [];
      });
    }
  });
  // arr.map(({ block_no, areas, block_codes, block_total }, i) => {
  //   Area = [];
  //   areas &&
  //     areas.map(({ area_no, tree_nos, area_codes }, j) => {
  //       // area_codes.map(({},k)=>{

  //       // })

  //       Area.push({
  //         block_no: block_no,
  //         area_no: area_no,
  //         tree_nos: tree_nos,
  //         area_codes: area_codes
  //       });
  //     });
  //   if (block_total > 0) {
  //     Block.push({
  //       block_no: block_no,
  //       Area: Area,
  //       block_codes: block_codes,
  //       block_total: block_total
  //     });
  //   }
  // });

  // console.log("hiii", Block);

  // return sortFunction(Block, "block_no");
  console.log("Data", Data);
  return Data;
};
