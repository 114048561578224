import React from "react";
import { Table, Col, Statistic, Row } from "antd";
import { isEmpty } from "lodash";

const layout = {
  xs: {
    span: 6
  },
  sm: {
    span: 6
  },
  lg: {
    span: 4
  },
  md: {
    span: 4
  }
};

export default ({ dataSource = null, columns, type=null }) =>
  dataSource && (
    <div>
      <h3>Daily Data Entry Status Report {type} for {dataSource.month}</h3>
      <br />
      <br />
      {dataSource.blocks.map(({ data, block_no }, i) => (
        <div key={i} >
          <Row gutter={5}>
            <Col key={i} {...layout}>
              <Statistic title={"Block No."} value={block_no} />
            </Col>
          </Row>
          <Table
            //className="block-wise-summary-dwarf-table"
            //className="bordered-table-head"
            className="table-left-align"
            bordered
            columns={columns}
            dataSource={data}
            size="medium"
            pagination={false}
          />
          <br />
          <br />
          <br />
          {
              dataSource.blocks.length > i+1 ? (
              <div className="pagebreak" />
            ) : null
          }
        </div>
      ))}
    </div>
  );
