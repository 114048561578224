const renderContent = it =>
  it
    ? it
    : it == 0
    ? it
    : {
        props: {
          colSpan: 0
        }
      };

let coloums = blockArr => [
  {
    title: "GROUP TYPE",
    dataIndex: "group_type"
  },
  {
    title: "DESCRIPTION",
    render: it =>
      it.description
        ? {
            children: it.description,
            props: {
              colSpan: 1
            }
          }
        : {
            props: {
              colSpan: blockArr.length + 1
            }
          }
  }
];

export const Coloums = blockArr =>
  coloums(blockArr).concat(
    blockArr.map((i, index) => ({
      title: `${i.toUpperCase()}`,
      dataIndex: `${i}`,
      render: renderContent
    })),
    {
      title: "Total",
      dataIndex: "total"
    }
  );
