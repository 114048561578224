import React, { Component } from "react";
import { Input } from "antd";

import "./index.less";

class CustomInput extends Component {
  static getDerivedStateFromProps(nextProps, prevSate) {
    if ("value" in nextProps) {
      return Object.assign(
        {},
        {
          value: nextProps.value || null
        },
        nextProps.value
          ? {
              fieldActive: nextProps.value ? true : false
            }
          : {}
      );
    }
    return null;
  }

  constructor(props) {
    super(props);
    this.state = {
      value: props.value || null,
      test: "",
      fieldActive: false
    };
  }

  triggerChange = changedValue => {
    const { onChange } = this.props;
    if (onChange) {
      onChange(changedValue);
    }
  };

  //Styling handler
  activateField = () => {
    this.setState({
      fieldActive: true
    });
  };
  // to deactivate input only if it's empty
  disableField = e => {
    if (e.target.value == "" || !e.target.value) {
      this.setState({
        fieldActive: false
      });
    }
  };

  render() {
    const { label, required = false, value, readonly, ...rest } = this.props;
    const { state } = this;
    return (
      <div className="custom-input">
        <label
          className={
            readonly
              ? "custom-input-label require-field-active"
              : this.state.fieldActive
              ? required
                ? "field-active custom-input-label require-field-active"
                : "field-active custom-input-label"
              : required
              ? "custom-input-label require-field-active"
              : "custom-input-label"
          }
        >
          {label}
        </label>
        <Input
          value={state.value}
          onChange={this.handleValueChange}
          onFocus={this.activateField}
          onBlur={this.disableField}
          readOnly={readonly}
          className={readonly ? "disabledField" : undefined}
          {...rest}
        />
      </div>
    );
  }
}

export default CustomInput;
