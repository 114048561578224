import React, { Component } from "react";

//Component
import { message, Spin } from "antd";
import ReportLayout from "../../pageLayouts/Report";
import MultiTable from "./MultiTable";
import FarmTotal from "./FarmTotal";
import Empty from "../../components/Custom/Empty";

//Utility
import { fetchApi, fetchExcelApi } from "../../../services/api";
import { cloneDeep } from "lodash";
import moment from "moment";
import { sortFunction } from "../../../utility/sorter";
import { COOKIE, getCookie } from "../../../services/cookie";

class DwarfTreesPlantsWithNutsCountOneTwentyDays extends Component {
  componentRef = React.createRef();
  state = {
    data: [],
    dynamicData: [],
    loading: false,
    blockNoList: [],
    blockNumber: null,
    treeTypeList: [],
    treeType: null,
    emasculationFrom: null,
    emasculationTo: null,
    noOfSpathes: null,
    farm_total: [],
    isopen: false
  };

  componentDidMount() {
    this.fetchBlockNo();
    this.fetchTreeType();
  }

  fetchDTPWithNutsCountOneTwentyDays = () => {
    let {
      blockNumber,
      treeType,
      noOfSpathes,
      emasculationFrom,
      emasculationTo
    } = this.state;
    this.setState({data: []})
    if (noOfSpathes && emasculationFrom && emasculationTo) {
      let url = `/query-module/nuts-count-120days?fromDate=${moment(emasculationFrom + "-" + "01").format(
        "YYYY-MM-DD")}&toDate=${moment(emasculationTo)
          .endOf("month")
          .format("YYYY-MM-DD")}&noOfSpathes=${noOfSpathes}`;
      if (blockNumber) url += `&block.uuid=${blockNumber}`;
      if (treeType) url += `&tree_type.uuid=${treeType}`;
      this.setState({ loading: true });
      fetchApi(url, "get").then(res => {
        if (res) {
          let tmp = res.blocks;
          res.blocks =
            tmp &&
            tmp.map(({ list, ...rest }) => ({
              list: list.map(
                ({ dt_created_120, dt_emasculation, ...rest }) => ({

                  dt_created_120: 
                  moment(dt_created_120).format("DD/MM/YYYY"),
                  dt_emasculation: 
                  moment(dt_emasculation).format("DD/MM/YYYY"),
                  ...rest
                })
              ),
              ...rest
            }));
          let { blocks, farm_total } = res;
          this.setState({
            data: blocks ? blocks : [],
            farm_total: farm_total ? farm_total : []
          });
        } else message.error("Internal Server Error!");
        this.setState({ loading: false });
      });
    }
  };

  fetchBlockNo = () =>
    fetchApi("/blocksuuid", "get").then(data =>
      this.setState({ blockNoList: sortFunction(data, "blockNoFull") })
    );

  fetchTreeType = () =>
    fetchApi("/tree_types?type=1", "get").then(data =>
      this.setState({ treeTypeList: data })
    );

  //year functions
  handleOpenChange = status => {
    if (status) {
      this.setState({ isopen: true });
    } else {
      this.setState({ isopen: false });
    }
  };
  
  onDropDownChangeHandler = (type, item) => {
    switch (type) {
      case "block":
        this.setState(
          {
            blockNumber: item
              ? cloneDeep(this.state.blockNoList).filter(
                  each => each.blockNoFull === item
                )[0].uuid
              : null
          },
          () => this.fetchDTPWithNutsCountOneTwentyDays()
        );
        break;
      case "tree":
        this.setState(
          {
            treeType: item
              ? cloneDeep(this.state.treeTypeList).filter(
                  each => each.code === item
                )[0].uuid
              : null
          },
          () => this.fetchDTPWithNutsCountOneTwentyDays()
        );
        break;

      case "no-of-spathes":
        this.setState({ noOfSpathes: item ? item : null }, () =>
          this.fetchDTPWithNutsCountOneTwentyDays()
        );
        break;

      default:
        this.setState(
          {
            value: item,
            emasculationFrom:
              item.length > 0 ? item[0].format("YYYY-MM") : null,
            emasculationTo:
              item.length > 0 ? item[1].format("YYYY-MM") : null,
              isopen: false
          },
          () => this.fetchDTPWithNutsCountOneTwentyDays()
        );
    }
  };

  generateTables = tableData =>
  <div>
  <h3>Dwarf Trees/Plants with Nuts Count 120 Days {" <= "+this.state.noOfSpathes} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Farm
  : {getCookie(COOKIE.FARM).charAt(0).toUpperCase() + getCookie(COOKIE.FARM).slice(1)}{" "} </h3>
  <h3>120 days Nuts Count from {moment(this.state.emasculationFrom).format("MM/YYYY")} to {moment(this.state.emasculationTo).format("MM/YYYY")}</h3>
  <h3>{"(Emasculation in "+moment(this.state.emasculationFrom).subtract(4, 'months').format("MMM YYYY") +" to "+moment(this.state.emasculationTo).subtract(4, 'months').format("MMM YYYY") +")"}</h3>
  {tableData.map(({ block_no, list, summary }, index) => (
      <MultiTable
        key={index}
        blockNo={block_no}
        list={sortFunction(list,"tree_no_full") }
        summary={summary}
        listColumns={[
          {
            title: "Plant/Tree No.",
            dataIndex: "tree_no_full",
            key: "tree_no_full"
          },
          {
            title: "Dwarf Type",
            dataIndex: "code",
            key: "code"
          },
          {
            title: "Spathe No.",
            dataIndex: "spathe_no",
            key: "spathe_no"
          },
          {
            title: "Date Of Emasculation",
            dataIndex: "dt_emasculation",
            key: "dt_emasculation"
          },
          {
            title: "No. of Buttons",
            dataIndex: "buttons",
            key: "buttons"
          },
          {
            title: "120 Days Count",
            dataIndex: "actual_nuts",
            key: "actual_nuts"
          }
        ]}
        summaryColumns={[
          {
            title: "Dwarf Type",
            dataIndex: "code",
            render: it => (it ? it : <b>Block Total</b>)
          },
          { title: "No. Of Plants/Trees", dataIndex: "no_of_dwarfs" },
          { title: "No. Of Spathes", dataIndex: "no_of_spathes" }
        ]}
      />
    ))}
    </div>

  generateFarmTotal = data => (
    <FarmTotal
      data={data}
      columns={[
        {
          title: "Dwarf Type",
          dataIndex: "code",
          render: it => (it ? it : <b>Farm Total</b>)
        },
        { title: "No. Of Dwarfs", dataIndex: "no_of_dwarfs" },
        { title: "No. Of Spathes", dataIndex: "no_of_spathes" }
      ]}
    />
  );

  generateExcel = () => {
    let {
      blockNumber,
      treeType,
      noOfSpathes,
      emasculationFrom,
      emasculationTo
    } = this.state;
    if (noOfSpathes && emasculationFrom && emasculationTo) {
      let url = `/query-module/nuts-count-120days?fromDate=${moment(emasculationFrom + "-" + "01").format(
        "YYYY-MM-DD")}&toDate=${moment(emasculationTo)
          .endOf("month")
          .format("YYYY-MM-DD")}
      &noOfSpathes=${noOfSpathes}`;
      if (blockNumber) url += `&block.uuid=${blockNumber}`;
      if (treeType) url += `&tree_type.uuid=${treeType}`;
      fetchExcelApi(url, "get").then();
    }
  };

  render() {
    let {
      data,
      treeTypeList,
      blockNoList,
      farm_total,
      loading,
      noOfSpathes,
      emasculationFrom,
      emasculationTo,
      isopen,
      value
    } = this.state;
    let JSX =
        data.length > 0 ? (
          this.generateTables(data)
        ) : emasculationTo && emasculationFrom && noOfSpathes ? (
          <Empty />
        ) : (
          <Empty
            description={"Select No. of Spathes and 120 days Nuts Count During"}
          />
        ),
      farmTotalJSX =
        farm_total.length > 0 && data.length > 0
          ? this.generateFarmTotal(farm_total)
          : null;

    const buttonList =
      data.length > 0
        ? [
            {
              label: "Excel",
              icon: "file-text",
              onClickHandler: () => this.generateExcel()
            },
            {
              label: "Print",
              icon: "diff",
              reference: this.componentRef
            }
          ]
        : null;
    return (
      <ReportLayout
        title="Dwarf Trees/Plants with Nuts Count 120 Days"
        buttonList={buttonList}
        dropDownList={[
          {
            label: "Block",
            listItems: cloneDeep(blockNoList).map(it => it.blockNoFull),
            changeHandler: it => this.onDropDownChangeHandler("block", it)
          },
          {
            label: "Dwarf Type",
            listItems: cloneDeep(treeTypeList).map(it => it.code),
            changeHandler: it => this.onDropDownChangeHandler("tree", it)
          },
          {
            label: "120 Days Count <=",
            type: "number",
            changeHandler: it =>
              this.onDropDownChangeHandler("no-of-spathes", it)
          },
          // {
          //   label: "120 days Nuts Count During :",
          //   type: "range",
          //   mode: ["date", "date"],
          //   format: "DD/MM/YYYY",
          //   changeHandler: (date, string) =>
          //     this.onDropDownChangeHandler("emasDate", date)
          // }
          {
            label: "120 days Nuts Count During :",
            mode: ["month", "month"],
            value: value,
            type: "range",
            format: "MM/YYYY",
            open: isopen,
            showTime: true,
            onOk: date => this.onDropDownChangeHandler("emasDate", date),
            onOpenChange: this.handleOpenChange,
            onPanelChange: val => this.setState({ value: val }),
            changeHandler: () => this.onDropDownChangeHandler("emasDate", [])
          }
        ]}
      >
        <div ref={this.componentRef}>
          <Spin spinning={loading} size="large">
            {JSX}
            {farmTotalJSX}
          </Spin>
        </div>
      </ReportLayout>
    );
  }
}

export default DwarfTreesPlantsWithNutsCountOneTwentyDays;
