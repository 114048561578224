import React, { Component, Fragment } from "react";
import ReactDOM from "react-dom";

import { Table, message, Spin, Statistic, Col, Row } from "antd";

//Component import
import ReportLayout from "../../../pageLayouts/Report";
import { fetchApi, fetchExcelApi } from "../../../../services/api";
import Empty from "../../../components/Custom/Empty";

//Utility
import { mapKeys, isEmpty, cloneDeep } from "lodash";
import { sortFunction } from "../../../../utility/sorter";
import moment from "moment";

import columns from "./columns";

const layout = {
  xs: {
    span: 6
  },
  sm: {
    span: 6
  },
  lg: {
    span: 4
  },
  md: {
    span: 4
  }
};

class ValuationOfNewAdditions extends Component {
  componentRef = React.createRef();
  state = {
    data: [],
    loading: false,
    tempData: [],
    blockNoList: [],
    block_uuid: null,
    type: 1,
    treetype: "Dwarf",
    period: [],
    DateAsOn: null,
    category: null,
    isopen: false
  };

  componentDidMount() {
    //this.fetchBlockNo();
  }
  fetchValueOfNewAddition = () => {
    console.log("here");
    let { type, period } = this.state;
    if (period.length > 0 && type) {
      this.setState({ loading: true });
      fetchApi(
        `/regular-reports/valuation-of_new-addition?fromDate=${period[0]}&toDate=${period[1]}&type=${type}`,
        "get"
      ).then(res => {
        if (res) {
          this.setState({
            data: res.blocks
          });
          console.log("res", res);
        } else message.error("Internal Server Error!");
        this.setState({ loading: false });
      });
    } else {
      this.setState({
        data: []
      });
    }
  };

  handleOpenChange = status => {
    if (status) {
      this.setState({ isopen: true });
    } else {
      this.setState({ isopen: false });
    }
  };

  onTreeTypeChangeHandler = it => {
    this.setState(
      {
        type: it == "Dwarf" ? 1 : it == "Tall" ? 2 : null,
        treetype: it
      },
      () => this.fetchValueOfNewAddition()
    );
  };

  onPeriodChangeHandler = item => {
    //let { } = this.state;
    if (item.length > 0) {
      this.setState(
        {
          value: item,
          period: [
            moment(item[0]).format("YYYY-MM"),
            moment(item[1]).format("YYYY-MM")
          ],
          isopen: false
        },
        () => this.fetchValueOfNewAddition()
      );
    } else {
      this.setState(
        {
          value: null,
          period: [],
          isopen: false,
          data: []
        },
        () => this.fetchValueOfNewAddition()
      );
    }
  };

  generateExcel = () => {
    let { type, period } = this.state;
    if (type && period.length > 0) {
      let url = `/regular-reports/valuation-of_new-addition?fromDate=${period[0]}&toDate=${period[1]}&type=${type}`;
      fetchExcelApi(url, "get").then();
    }
  };

  render() {
    let { data, loading, isopen, value, type, treetype, period } = this.state;
    const buttonList =
      data && data.length > 0
        ? [
            {
              label: "Excel",
              icon: "file-text",
              onClickHandler: () => this.generateExcel()
            },
            {
              label: "Print",
              icon: "diff",
              reference: this.componentRef
            }
          ]
        : null;
    const JSX =
      data && data.length > 0 ? (
        data.map(({ block_no_full, seedlings, plants, trees }, index) => (
          <div className={index == 0 ? "" : "pagebreak"} key={index}>
            {
              <Fragment>
                <div className="multi-table-header">
                  <Statistic title="Block No." value={block_no_full} />
                  <Statistic
                    title="Type"
                    value={type == 1 ? "Dwarf" : "Tall"}
                  />
                </div>
                <Table
                  //className="bordered-table-head"
                  bordered
                  rowKey={trees.tree_no_full}
                  columns={columns}
                  dataSource={trees}
                  bordered
                  size="medium"
                  pagination={false}
                />
                <br />
              </Fragment>
            }
            <br />
          </div>
        ))
      ) : period.length > 0 ? (
        <Empty description="No Data Found" />
      ) : (
        <Empty description="Select Period" />
      );

    return (
      <ReportLayout
        title="Valuation Of New Additions"
        buttonList={buttonList}
        dropDownList={[
          {
            label: "Tree Type",
            listItems: ["Dwarf", "Tall"],
            hasInitial: true,
            changeHandler: it => this.onTreeTypeChangeHandler(it)
          },
          {
            label: "Period",
            mode: ["month", "month"],
            value: value,
            type: "range",
            format: "MM/YYYY",
            open: isopen,
            showTime: true,
            onOk: date => this.onPeriodChangeHandler(date),
            onOpenChange: this.handleOpenChange,
            onPanelChange: val => this.setState({ value: val }),
            changeHandler: () => this.onPeriodChangeHandler([])
          }
        ]}
      >
        <div ref={this.componentRef}>
          <Spin spinning={loading} size="large">
            {data && data.length > 0 ? (
              <Fragment>
                <h3>
                  {treetype +
                    " Type Valuation Of New Additions for the Period of  " +
                    moment(period[0]).format("MMM YYYY") +
                    " to " +
                    moment(period[1]).format("MMM YYYY")}
                </h3>
                {JSX}
              </Fragment>
            ) : period.length > 0 ? (
              <Empty description="No Data Found" />
            ) : (
              <Empty description="Select Period" />
            )}
          </Spin>
        </div>
      </ReportLayout>
    );
  }
}
export default ValuationOfNewAdditions;
