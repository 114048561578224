import React, { Component, Fragment } from "react";
import ReactDOM from "react-dom";
import { Table, message, notification, Icon, Tooltip, Spin } from "antd";

//Component import
import LocationPageBuilder from "../PageBuilder";
import Empty from "../../../components/Custom/Empty";

//Utility
import { sortFunction, sortFunction2 } from "../../../../utility/sorter";
import { COOKIE, getCookie } from "../../../../services/cookie";
import { fetchApi, fetchExcelApi } from "../../../../services/api";
import { cloneDeep, filter } from "lodash";
import { formatDate } from "../../../../utility/date";
import moment from "moment";
import DeathReasonList from "../deathReasonList";

class Plants extends Component {
  componentRef = React.createRef();
  state = {
    addModal: false,
    data: [],
    dynamicTableData: [],
    btnLoad: false,
    pagination: true,

    blockNumber: null,
    blockNoList: [],
    block_uuid: null,

    areaNumber: null,
    areaNoList: [],
    area_uuid: null,

    rowNumber: null,
    rowNoList: [],
    row_uuid: null,

    plantsNumber: null,
    plantsNoList: [],
    plants_uuid: null,

    treeTypeCode: null,
    treeTypeCodeList: [],
    treeType_uuid: null,

    editData: null,
    isEdit: false,
    isDelete: false,
    isTransfer: false,
    addPopulateData: null,
    tableLoader: false,

    blockUuid: null,
    areaUuid: null,
    rowUuid: null,
    treeTypeUuid: null,
    plantUuid: null,
    treeNum: null,
    treeSuffix: null,
    showDeathReasonInput: false,
  };

  componentDidMount() {
    this.setState({ tableLoader: true });
    this.fetchPlants();
    this.fetchBlockNo();
    this.fetchTreeTypeCode();
    const tableCon = ReactDOM.findDOMNode(this.refs["table"]),
      table = tableCon.querySelector("table"),
      header = table.createTHead(),
      row = header.insertRow(0);
    //cell = row.insertCell(0);
    row.innerHTML = `<h1> Dwarf Plant Master - List &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Farm
    : ${getCookie(COOKIE.FARM)}${" "} </h1>`;
    table.setAttribute("id", "table-to-xls");
    table.setAttribute("class", "Seedling-Plant-Tree");
  }

  fetchPlants = () => {
    this.setState({
      tableLoader: true,
      dynamicTableData: [],
      pagination: false
    });
    let { block_uuid, area_uuid, row_uuid } = this.state,
      url = "/dwarves?stageCode=2";
    if (block_uuid) url += `&block.uuid=${block_uuid}`;
    if (area_uuid) url += `&area.uuid=${area_uuid}`;
    if (row_uuid) url += `&row.uuid=${row_uuid}`;
    fetchApi(url, "get")
      .then(data =>
        this.setState(
          {
            tableLoader: false,
            data: data.map(({ dtPlanted, ...it }) => {
              return {
                dtPlanted: formatDate(dtPlanted),
                date: dtPlanted,
                ...it
              };
            })
          },
          () =>
            this.setState(
              {
                dynamicTableData: sortFunction2(this.state.data, [
                  "treeNoFull",
                  "rowNo",
                  "areaNo",
                  "blockNo"
                ]),
                pagination: true
              },
              () => this.tableFilterHandler()
            )
        )
      )
      .catch(err => {
        this.setState({ tableLoader: false });
        message.error("Internal Server Error");
      });
  };

  fetchBlockNo = () => {
    let { blockNumber } = this.state,
      url = "/blocksuuid";
    if (blockNumber) url += `?blockNo=${blockNumber}`;
    fetchApi(url, "get").then(data =>
      this.setState({ blockNoList: sortFunction(data, "blockNoFull") })
    );
  };

  fetchTreeTypeCode = () =>
    fetchApi(
      "/tree_types?type=1&properties[]=uuid&properties[]=code",
      "get"
    ).then(data => this.setState({ treeTypeCodeList: data }));

  addModalToggle = () => {
    let {
      addModal,
      blockNumber,
      block_uuid,
      areaNumber,
      area_uuid,
      rowNumber,
      row_uuid,
      plantsNumber,
      plants_uuid,

      isEdit,
      isDelete,
      isTransfer
    } = this.state;

    if (addModal) {
      this.setState({
        addModal: false,
        editData: null,

        blockNumber: isTransfer || isEdit || isDelete ? blockNumber : null,
        block_uuid: isTransfer || isEdit || isDelete ? block_uuid : null,
        areaNumber: isTransfer || isEdit || isDelete ? areaNumber : null,
        area_uuid: isTransfer || isEdit || isDelete ? area_uuid : null,
        rowNumber: isTransfer || isEdit || isDelete ? rowNumber : null,
        row_uuid: isTransfer || isEdit || isDelete ? row_uuid : null,
        plantsNumber: isTransfer || isEdit || isDelete ? plantsNumber : null,
        plants_uuid: isTransfer || isEdit || isDelete ? plants_uuid : null,
        showDeathReasonInput: false,
        isEdit: false,
        isDelete: false,
        isTransfer: false
      });
    } else {
      this.setState({ addModal: !this.state.addModal });
    }
  };

  addFormHandler = ({
    treeNo,
    treeNoSuffix,
    treeType,
    dtPlanted,
    dtTransfered,
    dtRemoved,
    reason,
    otherreason,
    tagNumber,
    ...val
  }) => {
    notification.destroy();
    let {
      treeType_uuid,
      isEdit,
      isTransfer,
      isDelete,
      blockUuid,
      areaUuid,
      rowUuid,
      treeTypeUuid,
      plantUuid,
      treeNum,
      treeSuffix
    } = this.state;
    this.setState({ btnLoad: true });
    if (isEdit) {
      fetchApi(`/dwarves/${plantUuid}`, "put", {
        treeNo: parseInt(treeNum),
        suffix: treeSuffix == "--All--" || treeSuffix == "" ? null : treeSuffix,
        dtPlanted: dtPlanted.format("YYYY-MM-DD"),
        treeType: `/tree_types/${treeType_uuid ? treeType_uuid : treeTypeUuid}`,
        tagNumber: tagNumber ? tagNumber.toUpperCase() : "",
        stageCode: 2,
        growthCode: 1,
        block: "/blocks/" + blockUuid,
        area: "/areas/" + areaUuid,
        row: "/rows/" + rowUuid,
        ...val
      }).then(res => {
        if (res) {
          if (res.violations) {
            notification.error({
              message: "Failed",
              description: res.violations[0].message
            });
          } else {
            notification.success({
              message: "Success",
              description: "Successfully updated Dwarf Plant"
            });
            this.fetchPlants();
            this.addModalToggle();
          }
        } else {
          notification.error({
            message: "Failed",
            description: "Failed to update Dwarf Plant"
          });
        }
        this.setState(
          {
            btnLoad: false
          }
          //,
          //() => this.addModalToggle()
        );
      });
    } else if (isTransfer) {
      fetchApi(`/dwarves/${plantUuid}`, "put", {
        dtStage3: dtTransfered.format("YYYY-MM-DD"),
        stageCode: 3,
        isTransfer: true,
        tagNumber: tagNumber ? tagNumber.toUpperCase() : "",
      }).then(res => {
        if (res) {
          if (res.violations) {
            notification.error({
              message: "Failed",
              description: res.violations[0].message
            });
          } else {
            notification.success({
              message: "Success",
              description: "Successfully transferred Dwarf Plant to Tree"
            });
            this.fetchPlants();
            this.addModalToggle()
          }
        } else {
          notification.error({
            message: "Failed",
            description: "Failed to transfer Dwarf Plant to Tree"
          });
        }
        this.setState(
          {
            btnLoad: false
          }
          //,
          //() => this.addModalToggle()
        );
      });
    } else if (isDelete) {
      fetchApi(`/dwarves/remove/${plantUuid}`, "post", {
        dtDied: dtRemoved.format("YYYY-MM-DD"),
        deleteReason: reason === "OR - Other Reason" ? "OR - " + otherreason : reason
      }).then(res => {
        if (res) {
          if (res.violations) {
            notification.error({
              message: "Failed",
              description: res.violations[0].message
            });
          } else {
            notification.success({
              message: "Success",
              description: "Successfully deleted Dwarf Plant"
            });
            this.setState({ showDeathReasonInput: false });
            this.fetchPlants();
            this.addModalToggle()
          }
        } else {
          notification.error({
            message: "Failed",
            description: "Failed to delete Dwarf Plant"
          });
        }
        this.setState(
          {
            btnLoad: false
          }
          //,
          //() => this.addModalToggle()
        );
      });
    }
  };

  onBlockChangeHandler = it => {
    let { blockNoList } = this.state;

    if (it) {
      fetchApi(
        `/areasuuid?block.uuid=${cloneDeep(blockNoList).filter(item => item.blockNoFull == it)[0].uuid
        }`,
        "get"
      ).then(res => this.setState({ areaNoList: res }));
    }

    this.setState(
      {
        blockNumber: it ? it : null,
        block_uuid: it
          ? cloneDeep(blockNoList).filter(item => item.blockNoFull == it)[0]
            .uuid
          : null,
        areaNumber: null,
        areaNoList: [],
        area_uuid: null,
        rowNumber: null,
        rowNoList: [],
        row_uuid: null,
        plantsNumber: null,
        plantsNoList: []
      },
      () => this.fetchPlants()
    );
  };

  onAreaChangeHandler = it => {
    let { areaNoList, blockNoList, blockNumber } = this.state;

    if (it) {
      fetchApi(
        `/rowsuuid?area.uuid=${cloneDeep(areaNoList).filter(item => item.areaNo == it)[0].uuid
        }&block.uuid=${cloneDeep(blockNoList).filter(
          item => item.blockNoFull == blockNumber
        )[0].uuid
        }`,
        "get"
      ).then(res => {
        this.setState({ rowNoList: sortFunction(res, "rowNoFull") });
      });
    }

    this.setState(
      {
        areaNumber: it ? it : null,
        area_uuid: it
          ? cloneDeep(areaNoList).filter(item => item.areaNo == it)[0].uuid
          : null,
        rowNumber: null,
        rowNoList: [],
        row_uuid: null,
        plantsNumber: null,
        plantsNoList: []
      },
      () => this.fetchPlants()
    );
  };

  onRowChangeHandler = it => {
    let {
      rowNoList,
      areaNoList,
      blockNoList,
      blockNumber,
      areaNumber
    } = this.state;

    if (it) {
      fetchApi(
        `/dwarves?stageCode=2&row.uuid=${cloneDeep(rowNoList).filter(item => item.rowNoFull == it)[0].uuid
        }&area.uuid=${cloneDeep(areaNoList).filter(item => item.areaNo == areaNumber)[0]
          .uuid
        }&block.uuid=${cloneDeep(blockNoList).filter(
          item => item.blockNoFull == blockNumber
        )[0].uuid
        }&properties[]=uuid&properties[]=treeNoFull`,
        "get"
      ).then(res =>
        this.setState({ plantsNoList: sortFunction(res, "treeNoFull") })
      );
    }

    this.setState(
      {
        rowNumber: it ? it : null,
        row_uuid: it
          ? cloneDeep(rowNoList).filter(item => item.rowNoFull == it)[0].uuid
          : null,
        plantsNumber: null,
        plantsNoList: []
      },
      () => this.fetchPlants()
    );
  };

  onPlantsChangeHandler = it => {
    let { plantsNoList } = this.state;

    this.setState(
      {
        plantsNumber: it ? it : null,
        plants_uuid: it
          ? cloneDeep(plantsNoList).filter(item => item.treeNoFull == it)[0]
            .uuid
          : null
      },
      () => this.fetchPlants()
    );
  };

  onTreeTypeChangeHandler = it => {
    let { treeTypeCodeList } = this.state;

    this.setState(
      {
        treeTypeCode: it ? it : null,
        treeType_uuid: it
          ? cloneDeep(treeTypeCodeList).filter(item => item.code == it)[0].uuid
          : null
      },
      () => this.tableFilterHandler()
    );
  };

  onDeathReasonChange = it => {
    if (it === "OR - Other Reason") {
      this.setState({ showDeathReasonInput: true });
    }
    else {
      this.setState({ showDeathReasonInput: false });
    }
  };

  tableFilterHandler = () => {
    let { blockNumber, areaNumber, rowNumber, plantsNumber, data } = this.state,
      filterJson = {};
    if (blockNumber) filterJson.blockNo = blockNumber;
    if (areaNumber) filterJson.areaNo = areaNumber;
    if (rowNumber) filterJson.rowNo = rowNumber.toString();
    if (plantsNumber) filterJson.treeNoFull = plantsNumber;

    this.setState({
      dynamicTableData: filter(data, filterJson)
    });
  };

  onActionClickHandler = (item, action) => {
    let { treeTypeCodeList } = this.state;
    Promise.all([
      fetchApi(`/dwarf_growths?tree.uuid=${item.uuid}`, "get")
    ]).then(data => {
      this.setState(
        {
          isEdit: action === "edit" ? true : false,
          isDelete: action === "delete" ? true : false,
          isTransfer: action === "transfer" ? true : false
        },
        () => {
          this.setState({
            blockUuid: item.blockUuid,
            areaUuid: item.areaUuid,
            rowUuid: item.rowUuid,
            treeType_uuid: null,
            treeTypeUuid: cloneDeep(treeTypeCodeList).filter(
              it => it.code == item.treeType
            )[0].uuid,
            plantUuid: item.uuid,
            treeNum: item.treeNo,
            treeSuffix: item.suffix,
            addModal: true,
            editData: {
              blockNo: item.blockNo,
              areaNo: item.areaNo,
              rowNo: item.rowNo,
              treeNoFull: item.treeNoFull,
              treeType: item.treeType,
              tagNumber: item.tagNumber ? item.tagNumber.toUpperCase() : "",
              dtPlanted: moment(item.date, "YYYY-MM-DD"),
              noOfLeaves: data[0][0] ? data[0][0].leaves : null,
              girth: data[0][0] ? data[0][0].girth : null,
              height: data[0][0] ? data[0][0].height : null
            }
          });
        }
      );
    });
  };

  generateExcel = () => {
    let { block_uuid, area_uuid, row_uuid } = this.state,
      url = "/dwarves?stageCode=2";
    if (block_uuid) url += `&block.uuid=${block_uuid}`;
    if (area_uuid) url += `&area.uuid=${area_uuid}`;
    if (row_uuid) url += `&row.uuid=${row_uuid}`;
    fetchExcelApi(url, "get").then();
  };

  render() {
    let {
      addModal,
      dynamicTableData,
      btnLoad,
      blockNoList,
      blockNumber,
      areaNoList,
      areaNumber,
      rowNoList,
      rowNumber,
      plantsNoList,
      plantsNumber,
      treeTypeCodeList,
      editData,
      isEdit,
      isDelete,
      isTransfer,
      tableLoader,
      pagination,
      data,
      showDeathReasonInput,
    } = this.state;
    const columns = [
      {
        title: "Tree No.",
        dataIndex: "treeNoFull"
      },
      {
        title: "Tag No.",
        dataIndex: "tagNumber",
        render: it => (it ? it.toUpperCase() : "-")
      },
      {
        title: "Tree Type",
        dataIndex: "treeType"
      },
      {
        title: "Block No.",
        dataIndex: "blockNo"
      },
      {
        title: "Area No.",
        dataIndex: "areaNo"
      },
      {
        title: "Row No.",
        dataIndex: "rowNo"
      },
      {
        title: "Date of Planting",
        dataIndex: "dtPlanted"
      },
      {
        title: "Action",
        className: "action",
        render: it => (
          <Fragment>
            <Tooltip placement="topRight" title={"Edit"}>
              <Icon
                type="edit"
                className="table-action-btn"
                onClick={() => this.onActionClickHandler(it, "edit")}
              />
            </Tooltip>
            <Tooltip placement="topRight" title={"Delete"}>
              <Icon
                type="delete"
                className="table-action-btn"
                onClick={() => this.onActionClickHandler(it, "delete")}
              />
            </Tooltip>
            <Tooltip placement="topRight" title={"Transfer"}>
              <Icon
                type="to-top"
                className="table-action-btn"
                onClick={() => this.onActionClickHandler(it, "transfer")}
              />
            </Tooltip>
          </Fragment>
        )
      }
    ],
      formItems = [
        {
          name: "blockNo",
          placeholder: "Block No.",
          disabled: true
        },
        {
          name: "areaNo",
          placeholder: "Area No.",
          type: "number",
          disabled: true
        },
        {
          name: "rowNo",
          placeholder: "Row No.",
          disabled: true
        },
        {
          name: "treeNoFull",
          placeholder: "Tree No.",
          disabled: true
        }
      ];
    const _formItems = isEdit
      ? formItems.concat([
        {
          name: "treeType",
          placeholder: "Tree Type",
          listData: cloneDeep(treeTypeCodeList).map(it => it.code),
          changeHandler: it => this.onTreeTypeChangeHandler(it)
        },
        {
          name: "tagNumber",
          placeholder: "Tag No.",
          type: "tagNum",
        },
        {
          name: "dtPlanted",
          placeholder: "Date of Planting",
          type: "date",
          disabled: "true"
        }
      ])
      : isDelete
        ? formItems.concat([
          {
            name: "treeType",
            placeholder: "Tree Type",
            disabled: true
          },
          {
            name: "tagNumber",
            placeholder: "Tag No.",
            disabled: true,
            required: false
          },
          {
            name: "dtPlanted",
            placeholder: "Date of Planting",
            type: "date",
            disabled: true
          },
          {
            name: "noOfLeaves",
            placeholder: "No. of Leaves",
            disabled: true,
            required: false
          },
          {
            name: "girth",
            placeholder: "Girth (in cm)",
            disabled: true,
            required: false
          },
          {
            name: "height",
            placeholder: "Height (in cm)",
            disabled: true,
            required: false
          },
          {
            name: "dtRemoved",
            placeholder: "Date of Removal",
            type: getCookie(COOKIE.ROLE) === "ROLE_ADMIN" ? "date" : "date_yesterday",
          },
          {
            name: "reason",
            placeholder: "Reason for Deletion",
            listData: DeathReasonList.deathReasonList,
            changeHandler: it => this.onDeathReasonChange(it),
            required: true,
          },
          {
            name: "otherreason",
            placeholder: "Other Reason for Deletion",
            required: showDeathReasonInput,
            type: showDeathReasonInput ? "text" : "hidden"
          }
        ])
        : isTransfer
          ? formItems.concat([
            {
              name: "treeType",
              placeholder: "Tree Type",
              disabled: true
            },
            {
              name: "tagNumber",
              placeholder: "Tag No.",
              type: "tagNum",
            },
            {
              name: "dtPlanted",
              placeholder: "Date of Planting",
              type: "date",
              disabled: true
            },
            {
              name: "noOfLeaves",
              placeholder: "No. of Leaves",
              disabled: true,
              required: false
            },
            {
              name: "girth",
              placeholder: "Girth (in cm)",
              disabled: true,
              required: false
            },
            {
              name: "height",
              placeholder: "Height (in cm)",
              disabled: true,
              required: false
            },
            {
              name: "dtTransfered",
              placeholder: "Transfer date",
              type: "date"
            }
          ])
          : null;
    const buttonList =
      data.length > 0
        ? [
          {
            label: "Excel",
            icon: "file-text",
            onClickHandler: () => this.generateExcel()
          },
          {
            label: "Print",
            icon: "diff",
            reference: this.componentRef
          },
          {
            label: this.state.pagination ? "View All" : "View Less",
            icon: "container",
            onClickHandler: () => this.setState({ pagination: !pagination })
          }
        ]
        : null;
    return (
      <LocationPageBuilder
        title="Dwarf Plant Master"
        buttonList={buttonList}
        dropDownList={[
          {
            label: "Block",
            listItems: cloneDeep(blockNoList).map(it => it.blockNoFull),
            value: blockNumber ? blockNumber : undefined,
            changeHandler: it => this.onBlockChangeHandler(it)
          },
          {
            label: "Area",
            listItems: cloneDeep(areaNoList).map(it => it.areaNo),
            disabled: blockNumber ? false : true,
            value: areaNumber ? areaNumber : undefined,
            changeHandler: it => this.onAreaChangeHandler(it)
          },
          {
            label: "Row",
            listItems: cloneDeep(rowNoList).map(it => it.rowNoFull),
            disabled: areaNumber ? false : true,
            value: rowNumber ? rowNumber : undefined,
            changeHandler: it => this.onRowChangeHandler(it)
          },
          {
            label: "Plant No.",
            listItems: cloneDeep(plantsNoList).map(it => it.treeNoFull),
            disabled: rowNumber ? false : true,
            value: plantsNumber ? plantsNumber : undefined,
            changeHandler: it => this.onPlantsChangeHandler(it)
          }
        ]}
        addModal={addModal}
        addModalToggle={this.addModalToggle}
        addFormHandler={this.addFormHandler}
        editData={editData ? editData : null}
        btnLoad={btnLoad}
        formItems={_formItems}
        isEdit={isEdit}
        isDelete={isDelete}
        isTransfer={isTransfer}
      >
        <div ref={this.componentRef}>
          {/* <h1>
            Dwarf Plant Master - List &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Farm
            : {getCookie(COOKIE.FARM)}{" "}
          </h1> */}
          <Spin spinning={tableLoader} size="large">
            <Table
              ref="table"
              columns={columns}
              dataSource={dynamicTableData}
              pagination={!pagination ? pagination : { pageSize: 15 }}
              //loading={tableLoader}
              locale={{
                emptyText: <Empty />
              }}
            />
          </Spin>
        </div>
      </LocationPageBuilder>
    );
  }
}

export default Plants;
