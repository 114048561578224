import React, { Component } from "react";

//Component
import { message, Spin } from "antd";
import ReportLayout from "../../pageLayouts/Report";
import MultiTable from "./MultiTable";
import FarmTotal from "./FarmTotal";
import Empty from "../../components/Custom/Empty";

//Utility
import { fetchApi, fetchExcelApi } from "../../../services/api";
import { cloneDeep } from "lodash";
import moment from "moment";
import { sortFunction } from "../../../utility/sorter";
import { COOKIE, getCookie } from "../../../services/cookie";

class TallTreesWithNoMalePollen extends Component {
  componentRef = React.createRef();
  state = {
    data: [],
    dynamicData: [],
    loading: false,
    blockNoList: [],
    blockNumber: null,
    treeTypeList: [],
    treeType: null,
    treeCode: null,
    dateOfTransfer: null,
    farm_total: [],
    type: null
  };

  componentDidMount() {
    this.fetchBlockNo();
  }

  fetchTallTreesWithNoMalePollen = () => {
    let { blockNumber, treeType, dateOfTransfer, type } = this.state;
    this.setState({data: []})

    if (dateOfTransfer && type) {
      console.log(type);
      let url = `/query-module/${
        type === "Dwarf" ? "dwarf" : "tall"
      }-nil-harvest?date=${dateOfTransfer}`;
      if (blockNumber) url += `&block.uuid=${blockNumber}`;
      if (treeType) url += `&tree_type.uuid=${treeType}`;
      this.setState({ loading: true });
      fetchApi(url, "get").then(res => {
        if (res) {
          let tmp = res.blocks;
          res.blocks =
            tmp &&
            tmp.map(({ list, ...rest }) => ({
              list: list.map(({ dt_planted, dt_stage3, ...rest }) => ({
                dt_planted: moment(dt_planted).format("DD/MM/YYYY"),
                dt_stage3: moment(dt_stage3).format("DD/MM/YYYY"),
                ...rest
              })),
              ...rest
            }));
          let { blocks, farm_total } = res;
          this.setState({
            data: blocks ? blocks : [],
            farm_total: farm_total ? farm_total : []
          });
        } else message.error("Internal Server Error!");
        this.setState({ loading: false });
      });
    } else
      this.setState({
        data: [],
        farm_total: []
      });
  };

  fetchBlockNo = () =>
    fetchApi("/blocksuuid", "get").then(data =>
      this.setState({ blockNoList: sortFunction(data, "blockNoFull") })
    );

  fetchType = it => {
    it
      ? fetchApi(`/tree_types?type=${it === "Dwarf" ? 1 : 2}`, "get").then(
          data => {
            this.setState(
              {
                treeTypeList: sortFunction(data, "code"),
                treeType: null,
                treeCode: null,
                type: it
              },
              () => this.fetchTallTreesWithNoMalePollen()
            );
          }
        )
      : this.setState(
          { treeTypeList: [], treeType: null, type: null, treeCode: null },
          () => this.fetchTallTreesWithNoMalePollen()
        );
  };

  onDropDownChangeHandler = (type, item) => {
    switch (type) {
      case "block":
        this.setState(
          {
            blockNumber: item
              ? cloneDeep(this.state.blockNoList).filter(
                  each => each.blockNoFull === item
                )[0].uuid
              : null
          },
          () => this.fetchTallTreesWithNoMalePollen()
        );
        break;
      case "tree":
        this.setState(
          {
            treeType: item
              ? cloneDeep(this.state.treeTypeList).filter(
                  each => each.code === item
                )[0].uuid
              : null,
            treeCode: item ? item : null
          },
          () => this.fetchTallTreesWithNoMalePollen()
        );
        break;
      default:
        this.setState(
          {
            dateOfTransfer: item ? item.format("YYYY-MM-DD") : null
          },
          () => this.fetchTallTreesWithNoMalePollen()
        );
    }
  };

  generateTables = tableData =>
  <div>
  <h3>{this.state.type + " "} Trees Nil Harvested Nuts &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Farm
  : {getCookie(COOKIE.FARM).charAt(0).toUpperCase() + getCookie(COOKIE.FARM).slice(1)}{" "} </h3>
  <h3>Nil Harvested Nuts From Date of Transfer Till {moment(this.state.dateOfTransfer).format("DD/MM/YYYY")}</h3>
  {tableData.map(({ block_no, list, summary }, index) => (
      <MultiTable
        key={index}
        blockNo={block_no}
        list={sortFunction(list,"tree_no_full") }
        summary={summary}
        listColumns={[
          {
            title: "Tree No.",
            dataIndex: "tree_no_full",
            key: "tree_no_full"
          },
          {
            title: "Type",
            dataIndex: "code",
            key: "code"
          },
          {
            title: "Date of Planting",
            dataIndex: "dt_planted",
            key: "dt_planted"
          },
          {
            title: "Date of Transfer",
            dataIndex: "dt_stage3",
            key: "dt_stage3"
          },
          {
            title: "No. of Months",
            dataIndex: "months_between",
            key: "months_between"
          }
        ]}
        summaryColumns={[
          {
            title: "Type",
            dataIndex: "code",
            render: it => (it ? it : <b>Farm Total</b>)
          },
          { title: "No. Of Trees", dataIndex: "no_of_trees" }
        ]}
      />
    ))}
    </div>

  generateFarmTotal = data => (
    <FarmTotal
      data={data}
      columns={[
        {
          title: "Type",
          dataIndex: "code",
          render: it => (it ? it : <b>Farm Total</b>)
        },
        { title: "No. Of Plants", dataIndex: "no_of_trees" }
      ]}
    />
  );

  generateExcel = () => {
    let { blockNumber, treeType, dateOfTransfer, type } = this.state;
    if (dateOfTransfer) {
      if (type) {
        let url = `/query-module/${
          type === "Dwarf" ? "dwarf" : "tall"
        }-nil-harvest?date=${dateOfTransfer}`;
        if (blockNumber) url += `&block.uuid=${blockNumber}`;
        if (treeType) url += `&tree_type.uuid=${treeType}`;
        fetchExcelApi(url, "get").then();
      }
    }
  };

  render() {
    let {
      data,
      treeTypeList,
      blockNoList,
      farm_total,
      loading,
      type,
      treeCode,
      dateOfTransfer
    } = this.state;
    let JSX =
        data.length > 0 ? (
          this.generateTables(data)
        ) : type && dateOfTransfer && dateOfTransfer ? (
          <Empty />
        ) : (
          <Empty description={"Select Date and Tree Type"} />
        ),
      farmTotalJSX =
      data.length > 0 && farm_total.length > 0 ? this.generateFarmTotal(farm_total) : null;
    const buttonList =
      data.length > 0
        ? [
            {
              label: "Excel",
              icon: "file-text",
              onClickHandler: () => this.generateExcel()
            },
            {
              label: "Print",
              icon: "diff",
              reference: this.componentRef
            }
          ]
        : null;
    return (
      <ReportLayout
        title="Dwarf/Tall Trees Nil Harvested Nuts"
        buttonList={buttonList}
        dropDownList={[
          {
            label: "Block",
            listItems: cloneDeep(blockNoList).map(it => it.blockNoFull),
            changeHandler: it => this.onDropDownChangeHandler("block", it)
          },
          {
            label: "Tree",
            listItems: ["Dwarf", "Tall"],
            changeHandler: it => this.fetchType(it)
          },
          {
            label: "Type",
            disabled: !treeTypeList.length > 0,
            listItems: cloneDeep(treeTypeList).map(it => it.code),
            value: treeCode ? treeCode : undefined,
            changeHandler: it => this.onDropDownChangeHandler("tree", it)
          },
          {
            label: "Date",
            type: "date",
            changeHandler: (date, string) =>
              this.onDropDownChangeHandler("dateOfTransfer", date),
            tooltip: "Nill Harvested Nuts from the Date of Transfer till"
          }
        ]}
      >
        <div ref={this.componentRef}>
          <Spin spinning={loading} size="large">
            {JSX}
            {farmTotalJSX}
          </Spin>
        </div>
      </ReportLayout>
    );
  }
}

export default TallTreesWithNoMalePollen;
