import React, { Component, Fragment } from "react";
import ReactDOM from "react-dom";

import { Table, message, Spin, Statistic } from "antd";

//Component import
import ReportLayout from "../../../pageLayouts/Report";
import { fetchApi, fetchExcelApi } from "../../../../services/api";
import ScollBarView from "react-double-scrollbar";
import Empty from "../../../components/Custom/Empty";

//Utility
import { mapKeys, isEmpty, cloneDeep } from "lodash";
import { sortFunction } from "../../../../utility/sorter";
import { parserFunction, parserFunctionFarm } from "./parserFunctions";
import moment from "moment";
import { columns } from "./columns";
import { farmColumns } from "./farmColumns";

class MonthlyFarmProductionActivitiesReportAllBlocks extends Component {
  componentRef = React.createRef();
  state = {
    data: [],
    loading: false,
    tempData: [],
    blockNoList: [],
    block_uuid: null,
    DateOfPeriod: null,

    farm: null,
    count_120_month: null,
    harvest_ems_month: null
  };

  componentDidMount() {
    this.fetchBlockNo();
  }

  fetchMonthlyFarmProductionActivities = () => {
    let { block_uuid, DateOfPeriod } = this.state,
      today = moment().format("YYYY-MM");
    if (DateOfPeriod) {
      this.setState({ loading: true });
      fetchApi(
        `/regular-reports/farm-production-activities-all-blocks?period=${DateOfPeriod}&block.uuid=${block_uuid}`,
        "get"
      ).then(res => {
        if (res)
          this.setState(
            {
              // tempData: res.farm ? res.blocks.concat(res.farm) : res.blocks
              tempData:res.blocks? sortFunction(res.blocks, "block_no"):[],
              farm: res.farm ? parserFunctionFarm(res.farm) : null,
              count_120_month: res.count_120_month,
              harvest_ems_month: res.harvest_ems_month
            },
            () => {
              console.log("result", res);
              this.setState({
                data: this.state.tempData
                  ? parserFunction(this.state.tempData).flat(1)
                  : []
              });
            }
          );
        else message.error("Internal Server Error!");
        this.setState({ loading: false });
      });
    }
  };

  fetchBlockNo = () =>
    fetchApi("/blocksuuid", "get").then(data =>
      this.setState({ blockNoList: sortFunction(data, "blockNoFull") })
    );

  onBlockChangeHandler = it => {
    if (it) {
      this.setState(
        {
          blockNumber: it ? it : null,
          block_uuid: it
            ? cloneDeep(this.state.blockNoList).filter(
                each => each.blockNoFull === it
              )[0].uuid
            : null
        },
        () => this.fetchMonthlyFarmProductionActivities()
      );
    } else {
      this.setState(
        {
          blockNumber: null,
          block_uuid: null
        },
        () => this.fetchMonthlyFarmProductionActivities()
      );
    }
  };

  dateOfPeriodHandler = date => {
    if (date) {
      //console.log("Date", date.format("YYYY-MM"));
      this.setState(
        {
          DateOfPeriod: date.format("YYYY-MM")
        },
        () => this.fetchMonthlyFarmProductionActivities()
      );
    } else {
      this.setState(
        {
          DateOfPeriod: null,
          data: [],
          harvest_ems_month: null
        },
        () => this.fetchMonthlyFarmProductionActivities()
      );
    }
  };

  generateExcel = () => {
    let { block_uuid, DateOfPeriod } = this.state,
      today = moment().format("YYYY-MM");
    if (DateOfPeriod) {
      fetchExcelApi(
        `/regular-reports/farm-production-activities-all-blocks?period=${DateOfPeriod}&block.uuid=${block_uuid}`,
        "get"
      ).then();
    }
  };

  render() {
    let {
      data,
      loading,
      blockNoList,
      blockNumber,
      DateOfPeriod,
      farm,
      count_120_month,
      harvest_ems_month
    } = this.state;
    let JSX =
      data.length > 0 ? (
        <Fragment>
          <h3>
            {"Monthly Farm Production Activities Report - All Blocks for " +
              moment(DateOfPeriod).format("MMM YYYY")}
          </h3>
          <ScollBarView>
            <Table
              className="regular-reports-tables bordered-table-head"
              bordered
              columns={columns(count_120_month, harvest_ems_month)}
              dataSource={data}
              bordered
              size="small"
              pagination={false}
            />
            {console.log("DataSource", data)}
          </ScollBarView>
          <br></br>
          <ScollBarView>
            {farm && (
              <Table
                className="regular-reports-tables bordered-table-head"
                bordered
                columns={farmColumns(count_120_month, harvest_ems_month)}
                dataSource={farm}
                bordered
                size="small"
                pagination={false}
              />
            )}
          </ScollBarView>
        </Fragment>
      ) : DateOfPeriod ? (
        <Empty description="No Data Found" />
      ) : (
        <Empty description={"Select Period"} />
      );
    const buttonList =
      data.length > 0
        ? [
            {
              label: "Excel",
              icon: "file-text",
              onClickHandler: () => this.generateExcel()
            },
            {
              label: "Print",
              icon: "diff",
              toolTip: "Suggestion: Print in Landscape",
              reference: this.componentRef
            }
          ]
        : null;
    return (
      <ReportLayout
        title="Monthly Farm Production Activities Report - All Blocks"
        buttonList={buttonList}
        dropDownList={[
          {
            label: "Block",
            listItems: cloneDeep(blockNoList).map(it => it.blockNoFull),
            value: blockNumber ? blockNumber : undefined,
            changeHandler: it => this.onBlockChangeHandler(it)
          },
          {
            label: "Period",
            type: "month",
            //value: DateOfPeriod ? DateOfPeriod : undefined,
            changeHandler: this.dateOfPeriodHandler
          }
        ]}
      >
        <div ref={this.componentRef}>
          <Spin
            spinning={loading}
            size="large"
            wrapperClassName="regular-reports-container"
          >
            {JSX}
          </Spin>
        </div>
      </ReportLayout>
    );
  }
}

export default MonthlyFarmProductionActivitiesReportAllBlocks;
