import React, { Component } from "react";

//Component
import { message, Spin, Table } from "antd";
import ReportLayout from "../../pageLayouts/Report";
import MultiTable from "./MultiTable";
import FarmTotal from "./FarmTotal";
import Empty from "../../components/Custom/Empty";

//Utility
import { fetchApi, fetchExcelApi } from "../../../services/api";
import { cloneDeep } from "lodash";
import moment from "moment";
import { sortFunction } from "../../../utility/sorter";
import { COOKIE, getCookie } from "../../../services/cookie";

class DwarfPlantswithNilSpatheEmasculation extends Component {
  componentRef = React.createRef();
  state = {
    data: [],
    dynamicData: [],
    loading: false,
    blockNoList: [],
    blockNumber: null,
    treeTypeList: [],
    treeType: null,
    dateOfEmasculation: null,
    farm_total: []
  };

  componentDidMount() {
    //this.fetchNilSpatheEmasculation();
    this.fetchBlockNo();
    this.fetchTreeType();
  }

  fetchNilSpatheEmasculation = () => {
    let { blockNumber, treeType, dateOfEmasculation } = this.state;
    this.setState({data: []})
    if (dateOfEmasculation) {
      let url =
        "/query-module/nil-spathe-emasculation" +
        `?date=${
          dateOfEmasculation
            ? dateOfEmasculation
            : moment().format("YYYY-MM-DD")
        }`;
      this.setState({ loading: true });

      if (blockNumber) url += `&block.uuid=${blockNumber}`;
      if (treeType) url += `&tree_type.uuid=${treeType}`;

      fetchApi(url, "get").then(res => {
        if (res) {
          let tmp = res.blocks;
          res.blocks =
            tmp &&
            tmp.map(({ list, ...rest }) => ({
              list: list.map(
                ({ dt_planted, dt_stage2, months_between, ...rest }) => ({
                  dt_planted: moment(dt_planted).format("DD/MM/YYYY"),
                  dt_stage2: dt_stage2
                    ? moment(dt_stage2).format("DD/MM/YYYY")
                    : "-",
                  months_between: months_between ? months_between : "-",
                  ...rest
                })
              ),
              ...rest
            }));
          let { blocks, farm_total } = res;
          this.setState({
            data: blocks ? blocks : [],
            farm_total: farm_total ? farm_total : []
          });
        } else message.error("Internal Server Error!");
        this.setState({ loading: false });
      });
    } else {
      this.setState({
        data: [],
        farm_total: []
      });
    }
  };

  fetchBlockNo = () =>
    fetchApi("/blocksuuid", "get").then(data =>
      this.setState({ blockNoList: sortFunction(data, "blockNoFull") })
    );

  fetchTreeType = () =>
    fetchApi("/tree_types?type=1", "get").then(data =>
      this.setState({ treeTypeList: data })
    );

  onDropDownChangeHandler = (type, item) => {
    switch (type) {
      case "block":
        this.setState(
          {
            blockNumber: item
              ? cloneDeep(this.state.blockNoList).filter(
                  each => each.blockNoFull === item
                )[0].uuid
              : null
          },
          () => this.fetchNilSpatheEmasculation()
        );
        break;
      case "tree":
        this.setState(
          {
            treeType: item
              ? cloneDeep(this.state.treeTypeList).filter(
                  each => each.code === item
                )[0].uuid
              : null
          },
          () => this.fetchNilSpatheEmasculation()
        );
        break;
      default:
        this.setState(
          {
            dateOfEmasculation: item ? item.format("YYYY-MM-DD") : null
          },
          () => this.fetchNilSpatheEmasculation()
        );
    }
  };

  generateTables = tableData =>
  <div>
    <h3>Dwarf Plants with Nil Spathe Emasculation from date of transfer till {moment(this.state.dateOfEmasculation).format("DD/MM/YYYY")} 
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Farm
    : {getCookie(COOKIE.FARM).charAt(0).toUpperCase() + getCookie(COOKIE.FARM).slice(1)}{" "} </h3>
    {tableData.map(({ block_no, list, summary }, index) => (
      <MultiTable
        pagebreak={index==0 ? "" : "pagebreak"}
        key={index}
        blockNo={block_no}
        list={list}
        summary={summary}
        listColumns={[
          {
            title: "Plant No.",
            dataIndex: "tree_no_full",
            key: "tree_no_full"
          },
          {
            title: "Date Of Planting",
            dataIndex: "dt_planted",
            key: "dt_planted"
          },
          {
            title: "Dwarf Type",
            dataIndex: "code",
            key: "code"
          },
          {
            title: "Date Of Transfer",
            dataIndex: "dt_stage2",
            key: "dt_stage2"
          },
          {
            title: "No.Of Months",
            dataIndex: "months_between",
            key: "months_between"
          },
          {
            title: "Last Date Of Emasculation",
            dataIndex: "dt_stage3",
            //key: "dt_stage3"
            render: it => "-"
          }
        ]}
        summaryColumns={[
          {
            title: "Dwarf Type",
            dataIndex: "code",
            render: it => (it ? it : <b>Block Total</b>)
          },
          { title: "No. Of Plants", dataIndex: "count" }
        ]}
      />
    ))}
    </div>;

  generateFarmTotal = data => (
    <FarmTotal
      data={data}
      columns={[
        {
          title: "Dwarf Type",
          dataIndex: "code",
          render: it => (it ? it : <b>Farm Total</b>)
        },
        { title: "No. Of Plants", dataIndex: "count" }
      ]}
    />
  );

  generateExcel = () => {
    let { blockNumber, treeType, dateOfEmasculation } = this.state,
      url =
        "/query-module/nil-spathe-emasculation" +
        `?date=${
          dateOfEmasculation
            ? dateOfEmasculation
            : moment().format("YYYY-MM-DD")
        }`;
    if (blockNumber) url += `&block.uuid=${blockNumber}`;
    if (treeType) url += `&tree_type.uuid=${treeType}`;
    fetchExcelApi(url, "get").then();
  };

  render() {
    let {
      data,
      treeTypeList,
      blockNoList,
      farm_total,
      dateOfEmasculation,
      loading
    } = this.state;
    let JSX =
        data.length > 0 ? (
          this.generateTables(data)
        ) : dateOfEmasculation ? (
          <Empty />
        ) : (
          <Empty description={"Select Date "} />
        ),
      farmTotalJSX =
        farm_total.length > 0 ? this.generateFarmTotal(farm_total) : null;
    const buttonList =
      data.length > 0
        ? [
            {
              label: "Excel",
              icon: "file-text",
              onClickHandler: () => this.generateExcel()
            },
            {
              label: "Print",
              icon: "diff",
              reference: this.componentRef
            }
          ]
        : null;
    return (
      <ReportLayout
        title="Dwarf Plants with Nil Spathe Emasculation from date of transfer"
        buttonList={buttonList}
        dropDownList={[
          {
            label: "Block",
            listItems: cloneDeep(blockNoList).map(it => it.blockNoFull),
            changeHandler: it => this.onDropDownChangeHandler("block", it)
          },
          {
            label: "Dwarf Type",
            listItems: cloneDeep(treeTypeList).map(it => it.code),
            changeHandler: it => this.onDropDownChangeHandler("tree", it)
          },
          {
            label: "Date",
            type: "date",
            //value: moment(this.state.dateOfEmasculation),
            changeHandler: (date, string) =>
              this.onDropDownChangeHandler("emasDate", date),
            tooltip: "Emasculated from the Date of Transfer till"
          }
        ]}
      >
        <div ref={this.componentRef}>
          <Spin spinning={loading} size="large">
            {JSX}
            {farmTotalJSX}
          </Spin>
        </div>
      </ReportLayout>
    );
  }
}

export default DwarfPlantswithNilSpatheEmasculation;
