//import { COOKIE, getCookie } from "../../../services/cookie";
import { userName, farmName } from "./getUserData";

export const adminRoutes = {
  headerRoute: [
    // {
    //   key: "1",
    //   iconType: "home",
    //   link: "/master-files",
    //   title: "Home",
    //   dropdown: [],
    //   theme: "filled",
    //   tooltip: "Home"
    // },
    {
      key: "2",
      iconType: "environment",
      link: "/location",
      title: "Location",
      dropdown: [],
      theme: "filled",
      tooltip: farmName
    },
    {
      key: "3",
      iconType: "user",
      link: "/profile",
      title: "Profile",
      dropdown: [
        // {
        //   label: "Profile",
        //   onClick: "profileClickHandler"
        // },
        {
          label: "Change Password",
          onClick: "changePasswordClickHandler"
        },
        {
          label: "Log out",
          onClick: "logoutClickHandler"
        }
      ],
      tooltip: userName
    },
    // {
    //   key: "4",
    //   iconType: "question-circle",
    //   link: "/help",
    //   title: "Help",
    //   dropdown: [],
    //   theme: "filled",
    //   tooltip: "Help"
    // }
  ],
  menuRoutes: [
    {
      key: "11",
      iconType: "layout",
      link: "/master-files",
      title: "Master Files Maintenance",
      theme: "filled"
    },
    {
      key: "22",
      iconType: "book",
      //link: "/transaction-files",
      link: "/transaction-files/seedling-growth/dwarf-seedling",
      title: "Transaction Files",
      theme: "filled"
    },
    {
      key: "33",
      iconType: "file-text",
      link: "/input-formats/120-days-count",
      title: "Input Formats",
      theme: "filled"
    },
    {
      key: "44",
      iconType: "pic-left",
      link: "/tree-agewise-summary/row-wise-chart",
      title: "Tree/Agewise Summary"
    },
    {
      key: "55",
      iconType: "message",
      link: "/query-module/dwarf-plants-with-nil-spathe-emasculation",
      title: "Query Module",
      theme: "filled"
    },
    {
      key: "66",
      iconType: "calendar",
      link:
        "/regular-reports/monthly-farm-production-activities-report-all-blocks",
      title: "Regular Reports",
      theme: "filled"
    },
    {
      key: "77",
      iconType: "database",
      link: "/special-reports/selected-list-low/high-yielding-dwarf-trees",
      title: "Special Reports",
      theme: "filled"
    },
    {
      key: "88",
      iconType: "calendar",
      link: "/other-reports/pits-and-gaps/pits-and-gaps-list",
      title: "Other Reports",
      theme: "filled"
    },
    {
      key: "99",
      iconType: "user",
      link: "/administration",
      title: "Administration"
    }
  ]
};

export const valuationRoutes = {
  headerRoute: [
    // {
    //   key: "1",
    //   iconType: "home",
    //   link: "/master-files",
    //   title: "Home",
    //   dropdown: [],
    //   theme: "filled",
    //   tooltip: "Home"
    // },
    {
      key: "2",
      iconType: "environment",
      link: "/location",
      title: "Location",
      dropdown: [],
      theme: "filled",
      tooltip: farmName
    },
    {
      key: "3",
      iconType: "user",
      link: "/profile",
      title: "Profile",
      dropdown: [
        // {
        //   label: "Profile",
        //   onClick: "profileClickHandler"
        // },
        {
          label: "Change Password",
          onClick: "changePasswordClickHandler"
        },
        {
          label: "Log out",
          onClick: "logoutClickHandler"
        }
      ],
      tooltip: userName
    },
    // {
    //   key: "4",
    //   iconType: "question-circle",
    //   link: "/help",
    //   title: "Help",
    //   dropdown: [],
    //   theme: "filled",
    //   tooltip: "Help"
    // }
  ],
  menuRoutes: [
    {
      key: "11",
      iconType: "layout",
      link: "/master-files",
      title: "Master Files Maintenance",
      theme: "filled"
    },
    {
      key: "22",
      iconType: "book",
      //link: "/transaction-files",
      link: "/transaction-files/seedling-growth/dwarf-seedling",
      title: "Transaction Files",
      theme: "filled"
    },
    {
      key: "33",
      iconType: "file-text",
      link: "/input-formats/120-days-count",
      title: "Input Formats",
      theme: "filled"
    },
    {
      key: "44",
      iconType: "pic-left",
      link: "/tree-agewise-summary/row-wise-chart",
      title: "Tree/Agewise Summary"
    },
    {
      key: "55",
      iconType: "message",
      link: "/query-module/dwarf-plants-with-nil-spathe-emasculation",
      title: "Query Module",
      theme: "filled"
    },
    {
      key: "66",
      iconType: "calendar",
      link:
        "/regular-reports/monthly-farm-production-activities-report-all-blocks",
      title: "Regular Reports",
      theme: "filled"
    },
    {
      key: "77",
      iconType: "database",
      link: "/special-reports/selected-list-low/high-yielding-dwarf-trees",
      title: "Special Reports",
      theme: "filled"
    },
    {
      key: "88",
      iconType: "calendar",
      link: "/other-reports/pits-and-gaps/pits-and-gaps-list",
      title: "Other Reports",
      theme: "filled"
    },
  ]
};

export const farmRoutes = {
  headerRoute: [
    // {
    //   key: "1",
    //   iconType: "home",
    //   link: "/master-files",
    //   title: "Home",
    //   dropdown: [],
    //   theme: "filled",
    //   tooltip: "Home"
    // },
    {
      key: "2",
      iconType: "environment",
      link: "/location",
      title: "Location",
      dropdown: [],
      theme: "filled",
      tooltip: farmName
    },
    {
      key: "3",
      iconType: "user",
      link: "/profile",
      title: "Profile",
      dropdown: [
        // {
        //   label: "Profile",
        //   onClick: "profileClickHandler"
        // },
        {
          label: "Change Password",
          onClick: "changePasswordClickHandler"
        },
        {
          label: "Log out",
          onClick: "logoutClickHandler"
        }
      ],
      tooltip: userName
    },
    // {
    //   key: "4",
    //   iconType: "question-circle",
    //   link: "/help",
    //   title: "Help",
    //   dropdown: [],
    //   theme: "filled",
    //   tooltip: "Help"
    // }
  ],
  menuRoutes: [
    {
      key: "11",
      iconType: "layout",
      link: "/master-files",
      title: "Master Files Maintenance",
      theme: "filled"
    },
    // {
    //   key: "22",
    //   iconType: "book",
    //   link: "/transaction-files",
    //   title: "Transaction Files",
    //   theme: "filled"
    // },
    {
      key: "22",
      iconType: "book",
      link: "/transaction-files/seedling-growth/dwarf-seedling",
      title: "Transaction Files",
      theme: "filled"
    },
    {
      key: "33",
      iconType: "file-text",
      link: "/input-formats/120-days-count",
      title: "Input Formats",
      theme: "filled"
    },
    {
      key: "44",
      iconType: "pic-left",
      link: "/tree-agewise-summary/row-wise-chart",
      title: "Tree/Agewise Summary"
    },
    {
      key: "55",
      iconType: "message",
      link: "/query-module/dwarf-plants-with-nil-spathe-emasculation",
      title: "Query Module",
      theme: "filled"
    },
    {
      key: "66",
      iconType: "calendar",
      link:
        "/regular-reports/monthly-farm-production-activities-report-all-blocks",
      title: "Regular Reports",
      theme: "filled"
    },
    {
      key: "77",
      iconType: "database",
      link: "/special-reports/selected-list-low/high-yielding-dwarf-trees",
      title: "Special Reports",
      theme: "filled"
    },
    {
      key: "88",
      iconType: "calendar",
      link: "/other-reports/pits-and-gaps/pits-and-gaps-list",
      title: "Other Reports",
      theme: "filled"
    }
  ]
};

export const reportsRoutes = {
  headerRoute: [
    // {
    //   key: "1",
    //   iconType: "home",
    //   link: "/input-formats/120-days-count",
    //   title: "Home",
    //   dropdown: [],
    //   theme: "filled",
    //   tooltip: "Home"
    // },
    {
      key: "2",
      iconType: "environment",
      link: "/location",
      title: "Location",
      dropdown: [
        {
          label: "locationDropdown",
          onClick: "locationChangeHandler"
        }
      ],
      theme: "filled",
      tooltip: farmName
    },
    {
      key: "3",
      iconType: "user",
      link: "/profile",
      title: "Profile",
      dropdown: [
        // {
        //   label: "Profile",
        //   onClick: "profileClickHandler"
        // },
        {
          label: "Change Password",
          onClick: "changePasswordClickHandler"
        },
        {
          label: "Log out",
          onClick: "logoutClickHandler"
        }
      ],
      tooltip: userName
    },
    // {
    //   key: "4",
    //   iconType: "question-circle",
    //   link: "/help",
    //   title: "Help",
    //   dropdown: [],
    //   theme: "filled",
    //   tooltip: "Help"
    // }
  ],
  menuRoutes: [
    {
      key: "33",
      iconType: "file-text",
      link: "/input-formats/120-days-count",
      title: "Input Formats",
      theme: "filled"
    },
    {
      key: "44",
      iconType: "pic-left",
      link: "/tree-agewise-summary/row-wise-chart",
      title: "Tree/Agewise Summary"
    },
    {
      key: "55",
      iconType: "message",
      link: "/query-module/dwarf-plants-with-nil-spathe-emasculation",
      title: "Query Module",
      theme: "filled"
    },
    {
      key: "66",
      iconType: "calendar",
      link:
        "/regular-reports/monthly-farm-production-activities-report-all-blocks",
      title: "Regular Reports",
      theme: "filled"
    },
    {
      key: "77",
      iconType: "database",
      link: "/special-reports/selected-list-low/high-yielding-dwarf-trees",
      title: "Special Reports",
      theme: "filled"
    },
    {
      key: "88",
      iconType: "calendar",
      link: "/other-reports/pits-and-gaps/pits-and-gaps-list",
      title: "Other Reports",
      theme: "filled"
    }
  ]
};

// let role = getCookie(COOKIE.ROLE)
// let route = [];

// if (role == "ROLE_ADMIN") {
//   route = {
//     headerRoute: [
//       {
//         key: "1",
//         iconType: "home",
//         link: "/master-files",
//         title: "Home",
//         dropdown: [],
//         theme: "filled",
//         tooltip: "Home"
//       },
//       {
//         key: "2",
//         iconType: "environment",
//         link: "/location",
//         title: "Location",
//         dropdown: [],
//         theme: "filled",
//         tooltip: getCookie(COOKIE.FARM)
//       },
//       {
//         key: "3",
//         iconType: "user",
//         link: "/profile",
//         title: "Profile",
//         dropdown: [
//           {
//             label: "Profile",
//             onClick: "profileClickHandler"
//           },
//           {
//             label: "Change Password",
//             onClick: "changePasswordClickHandler"
//           },
//           {
//             label: "Log out",
//             onClick: "logoutClickHandler"
//           }
//         ],
//         tooltip: unescape(getCookie(COOKIE.NAME))
//       },
//       {
//         key: "4",
//         iconType: "question-circle",
//         link: "/help",
//         title: "Help",
//         dropdown: [],
//         theme: "filled",
//         tooltip: "Help"
//       }
//     ],
//     menuRoutes: [
//       {
//         key: "11",
//         iconType: "layout",
//         link: "/master-files",
//         title: "Master Files Maintenance",
//         theme: "filled"
//       },
//       {
//         key: "22",
//         iconType: "book",
//         link: "/transaction-files",
//         title: "Transaction Files",
//         theme: "filled"
//       },
//       {
//         key: "33",
//         iconType: "file-text",
//         link: "/input-formats/120-days-count",
//         title: "Input Formats",
//         theme: "filled"
//       },
//       {
//         key: "44",
//         iconType: "pic-left",
//         link: "/tree-agewise-summary/row-wise-chart",
//         title: "Tree/Agewise Summary"
//       },
//       {
//         key: "55",
//         iconType: "message",
//         link:
//           "/query-module/120-days-count/dwarf-plants-with-nil-spathe-emasculation",
//         title: "Query Module",
//         theme: "filled"
//       },
//       {
//         key: "66",
//         iconType: "calendar",
//         link:
//           "/regular-reports/monthly-farm-production-activities-report-all-blocks",
//         title: "Regular Reports",
//         theme: "filled"
//       },
//       {
//         key: "77",
//         iconType: "database",
//         link: "/special-reports/selected-list-low/high-yielding-dwarf-trees",
//         title: "Special Reports",
//         theme: "filled"
//       },
//       {
//         key: "88",
//         iconType: "user",
//         link: "/administration",
//         title: "Administration"
//       }
//     ]
//   }
// } else if (role == "ROLE_FARM") {
//   route = {
//     headerRoute: [
//       {
//         key: "1",
//         iconType: "home",
//         link: "/master-files",
//         title: "Home",
//         dropdown: [],
//         theme: "filled",
//         tooltip: "Home"
//       },
//       {
//         key: "2",
//         iconType: "environment",
//         link: "/location",
//         title: "Location",
//         dropdown: [],
//         theme: "filled",
//         tooltip: getCookie(COOKIE.FARM)
//       },
//       {
//         key: "3",
//         iconType: "user",
//         link: "/profile",
//         title: "Profile",
//         dropdown: [
//           {
//             label: "Profile",
//             onClick: "profileClickHandler"
//           },
//           {
//             label: "Change Password",
//             onClick: "changePasswordClickHandler"
//           },
//           {
//             label: "Log out",
//             onClick: "logoutClickHandler"
//           }
//         ],
//         tooltip: unescape(getCookie(COOKIE.NAME))
//       },
//       {
//         key: "4",
//         iconType: "question-circle",
//         link: "/help",
//         title: "Help",
//         dropdown: [],
//         theme: "filled",
//         tooltip: "Help"
//       }
//     ],
//     menuRoutes: [
//       {
//         key: "11",
//         iconType: "layout",
//         link: "/master-files",
//         title: "Master Files Maintenance",
//         theme: "filled"
//       },
//       {
//         key: "22",
//         iconType: "book",
//         link: "/transaction-files",
//         title: "Transaction Files",
//         theme: "filled"
//       },
//       {
//         key: "33",
//         iconType: "file-text",
//         link: "/input-formats/120-days-count",
//         title: "Input Formats",
//         theme: "filled"
//       },
//       {
//         key: "44",
//         iconType: "pic-left",
//         link: "/tree-agewise-summary/row-wise-chart",
//         title: "Tree/Agewise Summary"
//       },
//       {
//         key: "55",
//         iconType: "message",
//         link:
//           "/query-module/120-days-count/dwarf-plants-with-nil-spathe-emasculation",
//         title: "Query Module",
//         theme: "filled"
//       },
//       {
//         key: "66",
//         iconType: "calendar",
//         link:
//           "/regular-reports/monthly-farm-production-activities-report-all-blocks",
//         title: "Regular Reports",
//         theme: "filled"
//       },
//       {
//         key: "77",
//         iconType: "database",
//         link: "/special-reports/selected-list-low/high-yielding-dwarf-trees",
//         title: "Special Reports",
//         theme: "filled"
//       }
//     ]
//   }
// } else if (getCookie(COOKIE.ROLE) == "ROLE_REPORTS") {
//   route = {
//     headerRoute: [
//       {
//         key: "1",
//         iconType: "home",
//         link: "/input-formats/120-days-count",
//         title: "Home",
//         dropdown: [],
//         theme: "filled",
//         tooltip: "Home"
//       },
//       {
//         key: "2",
//         iconType: "environment",
//         link: "/location",
//         title: "Location",
//         dropdown: [],
//         theme: "filled",
//         tooltip: getCookie(COOKIE.FARM)
//       },
//       {
//         key: "3",
//         iconType: "user",
//         link: "/profile",
//         title: "Profile",
//         dropdown: [
//           {
//             label: "Profile",
//             onClick: "profileClickHandler"
//           },
//           {
//             label: "Change Password",
//             onClick: "changePasswordClickHandler"
//           },
//           {
//             label: "Log out",
//             onClick: "logoutClickHandler"
//           }
//         ],
//         tooltip: unescape(getCookie(COOKIE.NAME))
//       },
//       {
//         key: "4",
//         iconType: "question-circle",
//         link: "/help",
//         title: "Help",
//         dropdown: [],
//         theme: "filled",
//         tooltip: "Help"
//       }
//     ],
//     menuRoutes: [
//       {
//         key: "33",
//         iconType: "file-text",
//         link: "/input-formats/120-days-count",
//         title: "Input Formats",
//         theme: "filled"
//       },
//       {
//         key: "44",
//         iconType: "pic-left",
//         link: "/tree-agewise-summary/row-wise-chart",
//         title: "Tree/Agewise Summary"
//       },
//       {
//         key: "55",
//         iconType: "message",
//         link:
//           "/query-module/120-days-count/dwarf-plants-with-nil-spathe-emasculation",
//         title: "Query Module",
//         theme: "filled"
//       },
//       {
//         key: "66",
//         iconType: "calendar",
//         link:
//           "/regular-reports/monthly-farm-production-activities-report-all-blocks",
//         title: "Regular Reports",
//         theme: "filled"
//       },
//       {
//         key: "77",
//         iconType: "database",
//         link: "/special-reports/selected-list-low/high-yielding-dwarf-trees",
//         title: "Special Reports",
//         theme: "filled"
//       }
//     ]
//   }
// }

// export const routes_links = route;
