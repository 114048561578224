import React from "react";
import { Route, Redirect } from "react-router-dom";

import { COOKIE, getCookie } from "../services/cookie";

import * as JWT from "jwt-decode";

/**
 *
 * @param {Object} Route parameters
 * @description Route parameters must contain layout, component and other props. Layout has to be the master oage.
 */
export default ({ layout, component, roles, ...rest }) => {
  let cookie = getCookie(COOKIE.ID_TOKEN);
  //role = getCookie(COOKIE.ROLE);
  var token = getCookie(COOKIE.ID_TOKEN);
  var decoded = token ? JWT(token) : "";
  var role = decoded ? decoded.roles[0] : "";
  if (cookie == null) {
    return <Redirect to="/login" />;
  } else if (roles && roles.indexOf(role) === -1) {
    // role not authorised so redirect to home page
    if (role == "ROLE_REPORTS") {
      return <Redirect to="/input-formats/120-days-count" />;
    } else {
      return <Redirect to="/" />;
    }
  } else {
    return (
      <Route
        {...rest}
        render={props =>
          React.createElement(
            layout,
            { ...props, ...rest },
            React.createElement(component, { ...props, ...rest })
          )
        }
      />
    );
  }
};
