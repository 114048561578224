export const parserFunction = arr =>
  arr.map(({ block_no, data, code_types, blockwise }) => [
    ...data.map((it, index) => ({
      block_no:
        index == 0
          ? block_no
          : it.tree_code
          ? checkIfNum(it.tree_code)
          : "BLOCK TOTAL",
      ...it
    }))
    // ...code_types.map(({ tree_code, ...ctItems }) => ({
    //   total: `${tree_code}-TOTAL`,
    //   ...ctItems
    // })),
    // ...blockwise.map(({ tree_code, ...bItems }) => ({
    //   total: block_no ? "BLOCK TOTAL" : "FARM TOTAL",
    //   ...bItems
    // }))
  ]);

export const parserFunctionFarm = arr => {
  console.log("arrrr", arr);
  return [
    ...arr.map((it, index) => ({
      ...it,
      tree_code: it.tree_code
        ? checkIfNum(it.tree_code, it.tree_code)
        : "FARM TOTAL"
    }))
  ];
};

const checkIfNum = (string, ifNot = " ") => {
  var c = string.charAt(string.length - 1);
  if (c >= "0" && c <= "9") {
    return string + "-Total";
  } else {
    return ifNot;
  }
};
