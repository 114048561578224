import React, { Component, Fragment } from "react";
import ReactDOM from "react-dom";

import {
  Table,
  message,
  Skeleton,
  Statistic,
  Spin,
  Col,
  Descriptions,
  Button,
  Icon
} from "antd";

//Component import
import ReportLayout from "../../../../pageLayouts/Report";
import { fetchApi, fetchExcelApi } from "../../../../../services/api";
import MultiTable from "../MultiTableDataEntryStatus";
import Empty from "../../../../components/Custom/Empty";

//Utility
import { COOKIE, getCookie } from "../../../../../services/cookie";
import { sortFunction } from "../../../../../utility/sorter";
import { mapKeys, isEmpty, cloneDeep } from "lodash";
import moment from "moment";
import { formatDate } from "../../../../../utility/date";

//mock
import mock from "./dummy-tall-performance.json";

import { parseData } from "./parserFunctionViewReport";

const ButtonGroup = Button.Group;

const layout = {
  xs: {
    span: 6
  },
  sm: {
    span: 6
  },
  lg: {
    span: 4
  },
  md: {
    span: 4
  }
};

class TallTreeView extends Component {
  componentRef = React.createRef();
  state = {
    data: null,
    loading: false,
    farmTotal: [],
    blockNoList: [],
    block_uuid: null,
    block_no: null,
    ActivityMonth: null,
    treeIndex: 0,
    treeNoList: [],
    treeNo: null
  };

  componentDidMount() {
    //this.fetchDataEntryTall();
    this.fetchBlockNo();
    //this.fetchTreeView();
  }

  fetchTreeView = () => {
    this.setState({ 
      loading: true,
      data: [] 
    });
    let { block_uuid, tree_uuid } = this.state;

    if (block_uuid && tree_uuid) {
      fetchApi(
        `/api/talls/get-tall-performance-view?stage_code=3&block.uuid=${block_uuid}&tree.uuid=${tree_uuid}`,
        "get"
      ).then(res => {
        if (res)
          this.setState(
            {
              data: parseData(res.data)
            },
            () => console.log("The Data is", this.state.data)
          );
        else message.error("Internal Server Error!");
        this.setState({ loading: false });
      });
    } else {
      this.setState({ loading: false, data: null });
    }
  };

  fetchBlockNo = () =>
    fetchApi("/blocksuuid", "get").then(data =>
      this.setState({ blockNoList: sortFunction(data, "blockNoFull") })
    );

  onBlockChangeHandler = it => {
    if (it) {
      fetchApi(
        `/talls?stageCode=3&block.uuid=${
          cloneDeep(this.state.blockNoList).filter(
            each => each.blockNoFull === it
          )[0].uuid
        }`,
        "get"
      ).then(res =>
        this.setState(
          {
            treeNoList: sortFunction(res, "treeNoFull"),
            treeIndex: 0,
            treeNo: null,
            tree_uuid: null
          },
          () => console.log("Tree No Lis", this.state.treeNoList)
        )
      );
    }

    this.setState(
      {
        blockNumber: it ? it : null,
        block_uuid: it
          ? cloneDeep(this.state.blockNoList).filter(
              each => each.blockNoFull === it
            )[0].uuid
          : null,
        block_no: it,
        treeNoList: [],
        treeNo: null,
        tree_uuid: null
      },
      () => this.fetchTreeView()
    );
  };

  onTreeChangeHandler = it => {
    if (it) {
      // var { data } = this.state;
      // if (Object.keys(data).length > 0) {
      //   this.setState({
      //     treeIndex: cloneDeep(data).filter(data => data.tree_no == it)[0]
      //       .treeIndex
      //   });
      // }
      // this.setState({ treeNo: it });
      var { data, treeNoList } = this.state;
      this.setState(
        {
          // treeIndex: cloneDeep(data).filter(data => data.tree_no == it)[0]
          //   .treeIndex,
          treeNo: it,
          tree_uuid: cloneDeep(treeNoList).filter(
            item => item.treeNoFull == it
          )[0].uuid
        },
        () => this.fetchTreeView()
      );
    } else {
      // this.setState({
      //   treeNo: null
      // });
      this.setState(
        {
          treeNo: null,
          tree_uuid: null
        },
        () => this.fetchTreeView()
      );
    }
  };

  activityMonthHandler = date => {
    if (date) {
      this.setState(
        {
          ActivityMonth: date.format("YYYY-MM")
        },
        () => this.fetchDataEntryTall()
      );
    } else {
      this.setState({
        ActivityMonth: null,
        blockNumber: null,
        block_uuid: null,
        TreeType: null,
        data: null
      });
    }
  };

  previousTreeIndex = () => {
    //console.log(this.state.treeIndex);
    var { treeIndex } = this.state;
    if (treeIndex > 0) {
      treeIndex--;
      this.setState({ treeIndex: treeIndex, treeNo: null });
    }
  };

  nextTreeIndex = () => {
    //console.log(this.state.treeIndex);
    var { treeIndex } = this.state;

    treeIndex++;
    this.setState({ treeIndex: treeIndex, treeNo: null });
  };

  generateTables = (tableData, columns, treeIndex) => {
    if (Object.keys(tableData).length == 0) {
      // this.setState({ treeNoList: [] });
      return <Empty description="No Data Found, Select Another Block No." />;
    } else {
      return (
        <div>
          <h3>
          Tall Tree Master - View Performance &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Farm
        : {getCookie(COOKIE.FARM).charAt(0).toUpperCase() + getCookie(COOKIE.FARM).slice(1)}{" "} </h3>
          <Col {...layout}>
            <Statistic title="Block No." value={tableData[treeIndex].block_no} />
          </Col>
          <Col {...layout}>
            <Statistic title="Area No." value={tableData[treeIndex].area_no} />
          </Col>
          <Col {...layout}>
            <Statistic title="Row No." value={tableData[treeIndex].row_no} />
          </Col>
          <br></br>
          <br></br>
          <br></br>
          <Col {...layout}>
            <Statistic title="Tree No." value={tableData[treeIndex].tree_no} />
          </Col>
          <Col {...layout}>
            <Statistic title="Type" value={tableData[treeIndex].code} />
          </Col>
          <Col {...layout}>
            <Statistic
              title="Date Of Planting"
              value={formatDate(tableData[treeIndex].dt_planted)}
            />
          </Col>
          <br></br>
          <br></br>

          <div>
            <br></br>
            <br></br>
            <br></br>
            <Table
              className="block-wise-summary-dwarf-table"
              columns={columns}
              dataSource={tableData[treeIndex].Nuts}
              size="small"
              pagination={false}
              title={() => (
                <center>
                  <h3>Nuts Harvested</h3>
                </center>
              )}
            />
          </div>
          {/* ))} */}
          <br></br>
          <br></br>
          <br></br>
          {/* <ButtonGroup>
            {this.state.treeIndex > 0 ? (
              <Button type="primary" onClick={() => this.previousTreeIndex()}>
                <Icon type="left" />
                Previous Tree
              </Button>
            ) : null}
            {this.state.treeIndex < tableData.length - 1 ? (
              <Button type="primary" onClick={() => this.nextTreeIndex()}>
                Next Tree
                <Icon type="right" />
              </Button>
            ) : null}
          </ButtonGroup> */}
        </div>
      );
    }
  };

  generateExcel = () => {
    let { block_uuid, tree_uuid } = this.state;

    if (block_uuid && tree_uuid) {
      fetchExcelApi(
        `/api/talls/get-tall-performance-view?stage_code=3&block.uuid=${block_uuid}&tree.uuid=${tree_uuid}`,
        "get"
      ).then();
    }
  };

  render() {
    let {
      data,
      loading,
      farmTotal,
      blockNoList,
      treeIndex,
      block_no,
      treeNo,
      treeNoList
    } = this.state;
    const buttonList = data? 
        [
          {
            label: "Excel",
            icon: "file-text",
            onClickHandler: () => this.generateExcel()
          },
          {
            label: "Print",
            icon: "diff",
            reference: this.componentRef
          }
        ]
      : null;
    const columns = [
      {
        title: "Year",
        dataIndex: "year"
      },
      {
        title: "Jan",
        dataIndex: "january"
      },
      {
        title: "Feb",
        dataIndex: "february"
      },
      {
        title: "March",
        dataIndex: "march"
      },
      {
        title: "April",
        dataIndex: "april"
      },
      {
        title: "May",
        dataIndex: "may"
      },
      {
        title: "June",
        dataIndex: "june"
      },
      {
        title: "July",
        dataIndex: "july"
      },
      {
        title: "Aug",
        dataIndex: "august"
      },
      {
        title: "Sep",
        dataIndex: "september"
      },
      {
        title: "Oct",
        dataIndex: "october"
      },
      {
        title: "Nov",
        dataIndex: "november"
      },
      {
        title: "Dec",
        dataIndex: "december"
      },
      {
        title: "Total",
        dataIndex: "total"
      }
    ];
    console.log("data",data)
    let JSX = data ? this.generateTables(data, columns, treeIndex) : null;
    return (
      <ReportLayout
        title="Tall Tree Master - View Performance"
        buttonList={buttonList}
        dropDownList={[
          {
            label: "Block",
            listItems: cloneDeep(blockNoList).map(it => it.blockNoFull),
            changeHandler: it => this.onBlockChangeHandler(it)
          },
          {
            label: "Tree No.",
            listItems: cloneDeep(treeNoList).map(it => it.treeNoFull),
            disabled: block_no ? false : true,
            value: treeNo ? treeNo : undefined,
            changeHandler: it => this.onTreeChangeHandler(it)
          }
        ]}
      >
        <div ref={this.componentRef}>
          <Spin spinning={loading} size="large">
            {block_no && treeNo ? (
              data && data.length >0 ?
              data[0].Nuts.length > 0 ? 
              <div>{JSX}</div>
              : <Empty/>:
                <Empty/>
            ) : (
              <Empty description={"Select Block No and Tree No. "} />
            )}
          </Spin>
        </div>
      </ReportLayout>
    );
  }
}

export default TallTreeView;
