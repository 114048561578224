import React, { Component } from "react";
import ReactDOM from "react-dom";
import { Table, message, notification, Icon, Tooltip, Spin } from "antd";

//Component import
import LocationPageBuilder from "../PageBuilder";
import Empty from "../../../components/Custom/Empty";

//Utility
import { COOKIE, getCookie } from "../../../../services/cookie";
import { fetchApi, fetchExcelApi } from "../../../../services/api";
import { cloneDeep, filter } from "lodash";

class TallMaster extends Component {
  componentRef = React.createRef();
  state = {
    addModal: false,
    data: [],
    btnLoad: false,
    editData: null,
    isEdit: false,
    tableLoader: false,
    pagination: true,
    addPopulateData: null
  };

  componentDidMount() {
    this.setState({ tableLoader: true });
    this.fetchTallMaster();
    const tableCon = ReactDOM.findDOMNode(this.refs["table"]),
      table = tableCon.querySelector("table"),
      header = table.createTHead(),
      row = header.insertRow(0);
    //cell = row.insertCell(0);
    row.innerHTML = `<h1>Research Tall Master - List &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Farm
    : ${getCookie(COOKIE.FARM)}${" "} </h1>`;
    table.setAttribute("id", "table-to-xls");
    table.setAttribute("class", "Research-Master");
  }

  fetchTallMaster = () =>
    fetchApi("/researches?type=2", "get").then(data =>
      this.setState({ tableLoader: false, data })
    );

  addModalToggle = () => {
    let { addModal } = this.state;
    if (addModal) {
      this.setState(
        {
          addModal: false,
          editData: null,
          isEdit: false,
          tallMasterUuid: null,
          addPopulateData: null
        }
        //() => this.fetchTallMaster()
      );
    } else {
      this.setState({ addModal: !this.state.addModal });
    }
  };

  clearFields = () => {
    this.setState({
      addPopulateData: {
        name: null,
        details: null,
        code: null
      }
    });
  };

  addFormHandler = val => {
    //to remove the previous instances of
    notification.destroy();

    let { isEdit, tallMasterUuid } = this.state;
    this.setState({ btnLoad: true });
    if (!isEdit) {
      fetchApi("/researches", "post", {
        type: 2,
        ...val
      }).then(res => {
        if (res) {
          if (res.violations) {
            notification.error({
              message: "Failed",
              description: res.violations[0].message
            });
          } else {
            notification.success({
              message: "Success",
              description: "Successfully added Research Tall Master"
            });
            this.clearFields();
            this.fetchTallMaster();
          }
        } else {
          notification.error({
            message: "Failed",
            description: "Failed to add Research Tall Master"
          });
        }
        this.setState({ btnLoad: false, addPopulateData: null });
      });
    } else {
      fetchApi(`/researches/${tallMasterUuid}`, "put", {
        type: 2,
        ...val
      }).then(res => {
        if (res) {
          notification.success({
            message: "Success",
            description: "Successfully updated the Research Tall Master"
          });
          this.fetchTallMaster();
          this.addModalToggle()
        } else {
          notification.error({
            message: "Failed",
            description: "Failed to update the Research Tall Master"
          });
        }
        this.setState({ btnLoad: false }
          //, () => this.addModalToggle()
        );
      });
    }
  };

  onEditClickHandler = item => {
    this.setState(
      {
        isEdit: true
      },
      () => {
        this.setState({
          tallMasterUuid: item.uuid,
          addModal: true,
          editData: {
            code: item.code,
            name: item.name,
            details: item.details
          }
        });
      }
    );
  };

  generateExcel = () => {
    fetchExcelApi("/researches?type=2", "get").then();
  };

  render() {
    let {
      addModal,
      data,
      btnLoad,
      editData,
      pagination,
      tableLoader,
      isEdit,
      addPopulateData
    } = this.state;
    const columns = [
      {
        title: "Research Code",
        dataIndex: "code"
      },
      {
        title: "Research Name",
        dataIndex: "name"
      },
      {
        title: "Details",
        dataIndex: "details"
      },
      {
        title: "Actions",
        className: "action",
        render: it => (
          <Tooltip placement="topRight" title={"Edit"}>
            <Icon onClick={() => this.onEditClickHandler(it)} type="edit" />
          </Tooltip>
        )
      }
    ],
      formItems = [
        {
          name: "name",
          placeholder: "Research Name",
          type: "text"
        },
        {
          name: "details",
          placeholder: "Details",
          type: "text"
        }
      ];

    const _formItems = isEdit
      ? [
        {
          name: "code",
          placeholder: "Research Code.",
          disabled: true
        }
      ].concat(formItems)
      : [
        {
          name: "code",
          placeholder: "Research Code.",
          type: "alphanumeric"
        }
      ].concat(formItems);

    const buttonList =
      data.length > 0
        ? [
          {
            label: "Add",
            icon: "folder-add",
            onClickHandler: this.addModalToggle
          },
          {
            label: "Excel",
            icon: "file-text",
            onClickHandler: () => this.generateExcel()
          },
          {
            label: "Print",
            icon: "diff",
            reference: this.componentRef
          },
          {
            label: this.state.pagination ? "View All" : "View Less",
            icon: "container",
            onClickHandler: () => this.setState({ pagination: !pagination })
          }
        ]
        : [
          {
            label: "Add",
            icon: "folder-add",
            onClickHandler: this.addModalToggle
          }
        ];
    return (
      <LocationPageBuilder
        title="Research Tall Master"
        buttonList={buttonList}
        addModal={addModal}
        addPopulateData={addPopulateData}
        addModalToggle={this.addModalToggle}
        addFormHandler={this.addFormHandler}
        editData={editData ? editData : null}
        btnLoad={btnLoad}
        formItems={_formItems}
        isEdit={isEdit}
      >
        <div ref={this.componentRef}>
          {/* <h1>
            Research Tall Master - List &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            Farm : {getCookie(COOKIE.FARM)}{" "}
          </h1> */}
          <Spin spinning={tableLoader} size="large">
            <Table
              ref="table"
              columns={columns}
              dataSource={data}
              pagination={!pagination ? pagination : { pageSize: 15 }}
            //loading={tableLoader}
            locale={{
              emptyText: <Empty />
            }}
            />
          </Spin>
        </div>
      </LocationPageBuilder>
    );
  }
}

export default TallMaster;
