import React, { Component } from "react";

//Component
import { message, Spin } from "antd";
import ReportLayout from "../../../pageLayouts/Report";
import MultiTable from "./MultiTable";
import Empty from "../../../components/Custom/Empty";
import { relatedReportsColoums } from "./coloumCreator";

//Utility
import { sortFunction } from "../../../../utility/sorter";
import { fetchApi, fetchExcelApi } from "../../../../services/api";
import { parserFunction } from "./parserFunction";
import { cloneDeep } from "lodash";
import moment from "moment";
import { rearchGroupData } from "./researchGroup";

class ResearchGroupRelatedPerformanceReport extends Component {
  componentRef = React.createRef();
  state = {
    data: [],
    coloums: [],
    loading: false,
    blockNoList: [],
    blockNumber: null,
    block_uuid: null,
    isopen: false,
    researchGroup: null,
    researchGroupList: [],
    researchGroup_uuid: null,
    period: [],
    value: null,
    title: null
  };

  componentDidMount() {
    this.fetchBlockNo();
    this.fetchResearchGroup();
  }

  fetchRelatedPerformanceReport = () => {
    this.setState({data:[]})
    let { block_uuid, researchGroup_uuid, period } = this.state;
    if (block_uuid && period.length > 0 && researchGroup_uuid) {
      this.setState({ loading: true });
      fetchApi(
        `/special-reports/get-research-group-related-report?from_month=${period[0]}&to_month=${period[1]}&block.uuid=${block_uuid}&research.uuid=${researchGroup_uuid}`,
        "get"
      ).then(res => {
        if (res)
          res.blocks
            ? this.setState(
                {
                  coloums: relatedReportsColoums([
                    moment(period[0]),
                    moment(period[1])
                  ]),
                  title: res.title
                },
                () => this.setState({ data: parserFunction(res.blocks) })
              )
            : this.setState(
                {
                  coloums: []
                },
                () => this.setState({ data: [], title: null })
              );
        else message.error("Internal Server Error!");
        this.setState({ loading: false });
      });
    } else {
      this.setState({
        data: [],
        title: null
      });
    }
  };

  fetchBlockNo = () =>
    fetchApi("/blocksuuid", "get").then(data =>
      this.setState({ blockNoList: sortFunction(data, "blockNoFull") })
    );

  fetchResearchGroup = () =>
    fetchApi(
      "/researches?properties[]=uuid&properties[]=code&type=1",
      "get"
    ).then(data => this.setState({ researchGroupList: data }));

  //year functions
  handleOpenChange = status => {
    if (status) {
      this.setState({ isopen: true });
    } else {
      this.setState({ isopen: false });
    }
  };

  onDropDownChangeHandler = (type, item) => {
    switch (type) {
      case "block":
        if (item) {
          this.setState(
            {
              blockNumber: item ? item : null,
              block_uuid: item
                ? cloneDeep(this.state.blockNoList).filter(
                    each => each.blockNoFull === item
                  )[0].uuid
                : null
            },
            () => this.fetchRelatedPerformanceReport()
          );
        } else {
          this.setState(
            {
              blockNumber: null,
              block_uuid: null
            },
            () => this.fetchRelatedPerformanceReport()
          );
        }
        break;

      case "researchGroup":
        if (item) {
          this.setState(
            {
              researchGroup: item ? item : null,
              researchGroup_uuid: item
                ? cloneDeep(this.state.researchGroupList).filter(
                    each => each.code === item
                  )[0].uuid
                : null
            },
            () => this.fetchRelatedPerformanceReport()
          );
        } else {
          this.setState(
            {
              researchGroup: null,
              researchGroup_uuid: null
            },
            () => this.fetchRelatedPerformanceReport()
          );
        }
        break;

      default:
        if (item.length > 0) {
          this.setState(
            {
              value: item,
              period: [
                moment(item[0]).format("YYYY-MM"),
                moment(item[1]).format("YYYY-MM")
              ],
              isopen: false
            },
            () => this.fetchRelatedPerformanceReport()
          );
        } else {
          this.setState(
            {
              value: null,
              period: [],
              isopen: false,
              data: [],
              title: null
            },
            () => this.fetchRelatedPerformanceReport()
          );
        }
    }
  };

  generateExcel = () => {
    let { block_uuid, researchGroup_uuid, period } = this.state;
    let url = `/special-reports/get-research-group-related-report?from_month=${period[0]}&to_month=${period[1]}&block.uuid=${block_uuid}&research.uuid=${researchGroup_uuid}`;

    fetchExcelApi(url, "get").then();
  };

  generateTables = (tableData,title )=> (
    <MultiTable dataSource={tableData} columns={this.state.coloums} title={title}/>
  );

  render() {
    let {
      data,
      blockNoList,
      blockNumber,
      loading,
      isopen,
      value,
      researchGroupList,
      researchGroup,
      period,
      block_uuid,
      title
    } = this.state;
    title = "Tree Performance "+ (title ? title : "");
    let JSX =
      data.length > 0 ? (
        this.generateTables(data,title)
      ) : block_uuid && researchGroup && period ? (
        <Empty />
      ) : (
        <Empty description={"Select Block No., Research Group and Period"} />
      );
    const buttonList =
      data.length > 0
        ? [
            {
              label: "Excel",
              icon: "file-text",
              onClickHandler: () => this.generateExcel()
            },
            {
              label: "Print",
              icon: "diff",
              //toolTip: "Suggestion: Print in Landscape",
              reference: this.componentRef
            }
          ]
        : null;
    return (
      <ReportLayout
        //title="Research Group Related Performance Report For A Selected Period & Selected Research Group"
        title={"Tree Performance " 
        //+ (title ? title : "")
      }
        buttonList={buttonList}
        dropDownList={[
          {
            label: "Block",
            listItems: cloneDeep(blockNoList).map(it => it.blockNoFull),
            value: blockNumber ? blockNumber : undefined,
            changeHandler: it => this.onDropDownChangeHandler("block", it)
          },
          {
            label: "Research Group",
            listItems: cloneDeep(researchGroupList).map(it => it.code),
            value: researchGroup ? researchGroup : undefined,
            changeHandler: it =>
              this.onDropDownChangeHandler("researchGroup", it)
          },
          {
            label: "Period",
            mode: ["month", "month"],
            value: value,
            type: "range",
            format: "MM/YYYY",
            open: isopen,
            showTime: true,
            onOk: date => this.onDropDownChangeHandler("period", date),
            onOpenChange: this.handleOpenChange,
            onPanelChange: val => this.setState({ value: val }),
            changeHandler: () => this.onDropDownChangeHandler("period", [])
          }
        ]}
      >
        <div ref={this.componentRef}>
          <Spin spinning={loading} size="large">
            {JSX}
          </Spin>
        </div>
      </ReportLayout>
    );
  }
}

export default ResearchGroupRelatedPerformanceReport;
