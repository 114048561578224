import React, { Component, Fragment } from "react";
import { Radio, Checkbox, Form, Col, Row, Button, Modal, Spin } from "antd";
import Input from "../../components/Custom/Input";
import { keys, isEqual, isArray } from "lodash";

import "./index.less";

class UserForm extends Component {
  //state = {}
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      confirmDirty: false
    };
  }

  componentDidMount() {
    let {
      form: { setFieldsValue },
      user
    } = this.props;
    this.setState({ user }, () => {
      setFieldsValue({
        ...user
      });
    });
  }

  componentWillReceiveProps(nextProps) {
    if (!isEqual(this.state.user, nextProps.user)) {
      this.setState({ user: nextProps.user }, () => {
        let {
          form: { setFieldsValue },
          user
        } = nextProps;
        setFieldsValue({
          ...user
        });
      });
    }
  }

  //componentDidMount() {
  //   let {
  //     form: { setFieldsValue },
  //     user
  //   } = this.props;
  //   if (user)
  //     setFieldsValue({
  //       username: user.username
  //     });
  // }

  // componentWillReceiveProps(nextProp) {
  //   let { user, form } = nextProp;
  //   if (user) {
  //     let temp = keys(user),
  //       checkObject = {};
  //     temp.forEach(it => {
  //       checkObject[it] = form.getFieldsValue()[it];
  //     });
  //     if (!isEqual(checkObject, user)) {
  //       form.setFieldsValue({
  //         ...user
  //       });
  //     }
  //   }
  // }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (this.props.onSumbitHandler && !err)
        this.props.onSumbitHandler(values);
    });
  };

  handleConfirmBlur = e => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue("password")) {
      callback("Passwords does not match.");
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(["confirm"], { force: true });
    }
    callback();
  };

  render() {
    const { getFieldDecorator } = this.props.form,
      {
        btnLoad,
        onClose,
        user,
        title,
        modal,
        isEdit,
        isRoleEdit,
        isChangePassword,
        isChangePass,
        isAdd
      } = this.props;

    return (
      <Modal
        visible={modal}
        footer={false}
        closable={false}
        className="admin-container-modal"
        destroyOnClose={true}
      >
        <Spin spinning={btnLoad}>
          <Form onSubmit={this.handleSubmit} className="user-form-container" autocomplete="off">
            <h2>{title}</h2>
            <Form.Item>
              {getFieldDecorator("username", {
                rules: [
                  { required: true, message: "This field is required" },
                  {
                    pattern: "^[a-z0-9]*$",
                    message: `Enter a Valid Username`
                  }]
              })(
                <Input
                  label="Username"
                  required={true}
                  disabled={
                    isChangePass == true ||
                      isChangePassword == true ||
                      isEdit == true
                      ? true
                      : false
                  }
                />
              )}
            </Form.Item>
            {(isEdit == true || isAdd == true) && (
              <Form.Item>
                {getFieldDecorator("fullName", {
                  rules: [{ required: true, message: "This field is required" },
                  {
                    pattern: /^[a-zA-Z ]{2,30}[']{0,1}[a-zA-Z ]{1,30}$/,
                    message: `Enter a Valid Full Name`
                  }]
                })(<Input label="Full Name" required={true} />)}
              </Form.Item>
            )}
            {isChangePass == true && (
              <Form.Item>
                {getFieldDecorator("oldpassword", {
                  rules: [{ required: true, message: "This field is required" }]
                })(
                  <Input type="password" label="Old Password" required={true} />
                )}
              </Form.Item>
            )}
            {(isChangePassword == true ||
              isAdd == true ||
              isChangePass == true) && (
                <Fragment>
                  <Form.Item>
                    {getFieldDecorator("password", {
                      rules: [
                        { required: true, message: "This field is required" },
                        {
                          validator: this.validateToNextPassword
                        },
                        {
                          min: 6,
                          message: "Password must be at least 6 characters long"
                        }
                      ]
                    })(
                      <Input
                        type="password"
                        label={
                          isChangePassword == true || isChangePass == true
                            ? "New Password"
                            : "Password"
                        }
                        required={true}
                      />
                    )}
                  </Form.Item>
                  <Form.Item>
                    {getFieldDecorator("confirmPassword", {
                      rules: [
                        { required: true, message: "This field is required" },
                        {
                          validator: this.compareToFirstPassword
                        }
                      ]
                    })(
                      <Input
                        type="password"
                        label={
                          isChangePassword == true || isChangePass == true
                            ? "Confirm New Password"
                            : "Confirm Password"
                        }
                        required={true}
                        onBlur={this.handleConfirmBlur}
                      />
                    )}
                  </Form.Item>
                </Fragment>
              )}
            {(isEdit == true || isAdd == true) && (
              <Fragment>
                <Form.Item label="Roles">
                  {getFieldDecorator("roles", {
                    rules: [
                      { required: true, message: "This field is required" }
                    ]
                  })(
                    <Radio.Group
                      className="admin-form-role"
                      disabled={isRoleEdit}
                      style={{ width: "100%" }}
                    >
                      <Row>
                        <Col span={8}>
                          <Radio value="ROLE_ADMIN">Admin User</Radio>
                        </Col>
                        <Col span={8}>
                          <Radio value="ROLE_VALUATION">Valuation User</Radio>
                        </Col>
                        <Col span={8}>
                          <Radio value="ROLE_FARM">Farm User</Radio>
                        </Col>
                        <Col span={8}>
                          <Radio value="ROLE_REPORTS">Report User</Radio>
                        </Col>
                      </Row>
                    </Radio.Group>
                  )}
                </Form.Item>

                <Form.Item>
                  {getFieldDecorator("canChangePassword", {
                    valuePropName: "checked",
                    initialValue: false
                  })(<Checkbox>User Can Change Password</Checkbox>)}
                </Form.Item>
              </Fragment>
            )}

            <Form.Item>
              <Row type="flex" justify="end" gutter={5}>
                <Col>
                  <Button
                    onClick={onClose} //loading={btnLoad}
                  >
                    CANCEL
                  </Button>
                </Col>
                <Col>
                  <Button
                    htmlType="submit" //loading={btnLoad}
                  >
                    {isAdd == true ? "ADD" : "UPDATE"}
                  </Button>
                </Col>
              </Row>
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    );
  }
}

export default Form.create({ name: "user_form" })(UserForm);
