export const parserFunction = arr => {
  let resultArray = [];
  arr &&
    arr.forEach(({ block_no, data, block_monthly_data, block_total }) => {
      data.forEach(
        ({
          area_code = null,
          tree_code = null,
          year_data,
          type_total = null,
          area_total = null
        }) => {
          //Push tree code or area code
          tree_code && resultArray.push({ tree_code: `TYPE - ${tree_code}` });

          area_code && resultArray.push({ area_code: `AREA - ${area_code}` });

          //year data
          year_data.forEach(({ year, ...rest }, index) => {
            resultArray.push({
              year:
                index == 0
                  ? year
                  : year_data[index - 1].year == year
                  ? "  "
                  : year,
              ...rest
            });
          });
          //type total
          type_total &&
            type_total.forEach((it, index) => {
              if (tree_code)
                resultArray.push({
                  total: "TYPE - TOTAL",
                  ...it
                });
              if (area_code)
                resultArray.push({
                  total: "AREA - TOTAL",
                  ...it
                });
            });
          //type total
          area_total &&
            area_total.forEach((it, index) => {
              // if (tree_code)
              //   resultArray.push({
              //     total: 'TYPE - TOTAL',
              //     ...it
              //   })
              if (area_code)
                resultArray.push({
                  total: "AREA - TOTAL",
                  ...it
                });
            });
        }
      );

      block_monthly_data &&
        resultArray.push({ tree_code: `BLOCK ${block_no}` });
      //block monthly data
      block_monthly_data &&
        block_monthly_data.forEach(({ year, month, ...rest }, index) => {
          resultArray.push({
            //year: index == 0 ? year : "  ",
            year: index == 0 || month == "Jan" ? year : "  ",
                month,
            ...rest
          });
        });
      //block total
      block_total &&
        block_total.forEach((it, index) => {
          resultArray.push({
            total: "BLOCK - TOTAL",
            ...it
          });
        });
    });
  return resultArray;
};
