/**
 * @name parseData
 * @description parses the array supplied for rendering in the table
 * @param {Array} arr
 */

export const parseData = arr => {
   console.log("arr",arr);
    let blankRow ={
        blank_row: "   "
    } 
    let totalFarm = {
        original_cost: 0,
        additions_fy: 0,
        deletion_on_fy: 0,
        cost_as_on: 0,
        opening_dep_res: 0,
        opening_wdv: 0,
        useful_life_expired_year: "-",
        useful_life_expired_month: "-",
        total_useful_life: "-",
        remaining_useful_life: "-",
        dep_fy: 0,
        dep_rev_on_del: 0,
        acc_dep_as_on: 0,
        closing_wdv_as_on: 0,
        farm_total: "Farm Total"
       }
   arr.map(({ trees, block_no_full }) =>{
    let totalData = {
        original_cost: 0,
        additions_fy: 0,
        deletion_on_fy: 0,
        cost_as_on: 0,
        opening_dep_res: 0,
        opening_wdv: 0,
        useful_life_expired_year: "-",
        useful_life_expired_month: "-",
        total_useful_life: "-",
        remaining_useful_life: "-",
        dep_fy: 0,
        dep_rev_on_del: 0,
        acc_dep_as_on: 0,
        closing_wdv_as_on: 0,
        block_total: "Block Total"
       }
        trees.map(({ original_cost , additions_fy, deletion_on_fy, cost_as_on, opening_dep_res, opening_wdv, useful_life_expired_year, useful_life_expired_month, total_useful_life, remaining_useful_life, dep_fy, dep_rev_on_del, acc_dep_as_on, closing_wdv_as_on }) =>{
            totalData["original_cost"]= totalData["original_cost"] + original_cost;
            totalData["additions_fy"]= totalData["additions_fy"] + additions_fy;
            totalData["deletion_on_fy"]= totalData["deletion_on_fy"] + deletion_on_fy;
            totalData["cost_as_on"]= totalData["cost_as_on"] + cost_as_on;
            totalData["opening_dep_res"]= totalData["opening_dep_res"] + opening_dep_res;
            totalData["opening_wdv"]= totalData["opening_wdv"] + opening_wdv;
            //totalData["useful_life_expired_year"]= totalData["useful_life_expired_year"] + useful_life_expired_year;
            //totalData["useful_life_expired_month"]= totalData["useful_life_expired_month"] + useful_life_expired_month;
            //totalData["total_useful_life"]= totalData["total_useful_life"] + total_useful_life;
            //totalData["remaining_useful_life"]= totalData["remaining_useful_life"] + remaining_useful_life;
            totalData["dep_fy"]= totalData["dep_fy"] + dep_fy;
            totalData["dep_rev_on_del"]= totalData["dep_rev_on_del"] + dep_rev_on_del;
            totalData["acc_dep_as_on"]= totalData["acc_dep_as_on"] + acc_dep_as_on;
            totalData["closing_wdv_as_on"]= totalData["closing_wdv_as_on"] + closing_wdv_as_on;
        })

        totalFarm["original_cost"]= totalFarm["original_cost"] + totalData["original_cost"];
        totalFarm["additions_fy"]= totalFarm["additions_fy"] + totalData["additions_fy"];
        totalFarm["deletion_on_fy"]= totalFarm["deletion_on_fy"] + totalData["deletion_on_fy"];
        totalFarm["cost_as_on"]= totalFarm["cost_as_on"] + totalData["cost_as_on"];
        totalFarm["opening_dep_res"]= totalFarm["opening_dep_res"] + totalData["opening_dep_res"];
        totalFarm["opening_wdv"]= totalFarm["opening_wdv"] + totalData["opening_wdv"];
        //totalFarm["useful_life_expired_year"]= totalFarm["useful_life_expired_year"] + totalData["useful_life_expired_year"];
        //totalFarm["useful_life_expired_month"]= totalFarm["useful_life_expired_month"] + totalData["useful_life_expired_month"];
        //totalFarm["total_useful_life"]= totalFarm["total_useful_life"] + totalData["total_useful_life"];
        //totalFarm["remaining_useful_life"]= totalFarm["remaining_useful_life"] + totalData["remaining_useful_life"];
        totalFarm["dep_fy"]= totalFarm["dep_fy"] + totalData["dep_fy"];
        totalFarm["dep_rev_on_del"]= totalFarm["dep_rev_on_del"] + totalData["dep_rev_on_del"];
        totalFarm["acc_dep_as_on"]= totalFarm["acc_dep_as_on"] + totalData["acc_dep_as_on"];
        totalFarm["closing_wdv_as_on"]= totalFarm["closing_wdv_as_on"] + totalData["closing_wdv_as_on"];

        totalData["original_cost"] = Math.round(totalData["original_cost"]);
        totalData["additions_fy"] = Math.round(totalData["additions_fy"]* 1000)/1000;
        totalData["deletion_on_fy"] = Math.round(totalData["deletion_on_fy"]);
        totalData["cost_as_on"] = Math.round(totalData["cost_as_on"]);
        totalData["opening_dep_res"] = Math.round(totalData["opening_dep_res"]);
        totalData["opening_wdv"] = Math.round(totalData["opening_wdv"]);
        //totalData["useful_life_expired_year"] = Math.round(totalData["useful_life_expired_year"]);
        //totalData["useful_life_expired_month"] = Math.round(totalData["useful_life_expired_month"]);
        //totalData["total_useful_life"] = Math.round(totalData["total_useful_life"]);
        //totalData["remaining_useful_life"] = Math.round(totalData["remaining_useful_life"]);
        totalData["dep_fy"] = Math.round(totalData["dep_fy"]);
        totalData["dep_rev_on_del"] = Math.round(totalData["dep_rev_on_del"]);
        totalData["acc_dep_as_on"] = Math.round(totalData["acc_dep_as_on"]);
        totalData["closing_wdv_as_on"] = Math.round(totalData["closing_wdv_as_on"]);
        trees.push(totalData);
    })
    if(arr.length>1){
        totalFarm["original_cost"] = Math.round(totalFarm["original_cost"]);
        totalFarm["additions_fy"] = Math.round(totalFarm["additions_fy"]);
        totalFarm["deletion_on_fy"] = Math.round(totalFarm["deletion_on_fy"]);
        totalFarm["cost_as_on"] = Math.round(totalFarm["cost_as_on"]);
        totalFarm["opening_dep_res"] = Math.round(totalFarm["opening_dep_res"]);
        totalFarm["opening_wdv"] = Math.round(totalFarm["opening_wdv"]);
        //totalFarm["useful_life_expired_year"] = Math.round(totalFarm["useful_life_expired_year"]);
        //totalFarm["useful_life_expired_month"] = Math.round(totalFarm["useful_life_expired_month"]);
        //totalFarm["total_useful_life"] = Math.round(totalFarm["total_useful_life"]);
        //totalFarm["remaining_useful_life"] = Math.round(totalFarm["remaining_useful_life"]);
        totalFarm["dep_fy"] = Math.round(totalFarm["dep_fy"]);
        totalFarm["dep_rev_on_del"] = Math.round(totalFarm["dep_rev_on_del"]);
        totalFarm["acc_dep_as_on"] = Math.round(totalFarm["acc_dep_as_on"]);
        totalFarm["closing_wdv_as_on"] = Math.round(totalFarm["closing_wdv_as_on"]);
        
        arr[arr.length-1].trees.push(blankRow);
        arr[arr.length-1].trees.push(totalFarm);
    }
    //console.log("new arr",arr)
    return arr;
  };

