import React, { Component, Fragment } from "react";
import {
  Table,
  Tag,
  Popconfirm,
  Modal,
  Icon,
  Tooltip,
  notification
} from "antd";
import AdvanceHeader from "../../components/AdvanceHeader";
import {
  COOKIE,
  createCookie,
  deleteCookie,
  getCookie
} from "../../../services/cookie";
import UserForm from "./UserForm";
import { fetchApi } from "../../../services/api";
import { cloneDeep, filter } from "lodash";
import { sortFunctionString } from "../../../utility/sorter";

import "./index.less";

class Admin extends Component {
  state = {
    title: null,
    user: null,
    data: [],
    modal: false,
    btnLoad: false,
    tableLoader: false,
    isAdd: false,
    isEdit: false,
    isRoleEdit: false,
    isChangePassword: false,
    isDelete: false,
    user_uuid: null
  };

  componentDidMount() {
    this.setState({ tableLoader: true });
    this.fetchUsers();
  }

  fetchUsers = () =>
    fetchApi("/users", "get").then(data =>
      this.setState({
        tableLoader: false,
        data: sortFunctionString(data, "fullName")
      })
    );

  //modaltoggle = () => this.setState({ modal: !this.state.modal, user: null });

  modaltoggle = () => {
    let { user, modal, isEdit, isChangePassword, isDelete } = this.state;
    if (isEdit == false && isChangePassword == false && isDelete == false) {
      this.setState({ isAdd: true, title: "Add User" });
    }

    if (modal) {
      this.setState({
        modal: false,
        user: null,
        isAdd: false,
        isEdit: false,
        isRoleEdit: false,
        isChangePassword: false,
        isDelete: false,
        user_uuid: null
      });
    } else {
      this.setState({ modal: !this.state.modal, user: null });
    }
  };

  editHandler = item => {
    let { uuid, isDisabled, roles, ...rest } = item;
    this.setState(
      {
        isEdit: true,
        isRoleEdit: atob(getCookie(COOKIE.UUID)) == item.uuid ? true : false,
        title: "Edit User Details",
        user: {
          roles: roles[0],
          ...rest
        }
      },
      this.setState({ modal: true })
    );
  };

  changePasswordHandler = item => {
    let { username, ...rest } = item;
    this.setState(
      {
        isChangePassword: true,
        title: "Change Password",
        user: {
          username: username
        }
      },
      this.setState({ modal: true })
    );
  };

  onDeleteHandler = it => {
    //to remove the previous instances of notification
    notification.destroy();

    console.log(it, "Delete");
    this.setState({ btnLoad: true });
    this.setState(
      {
        user_uuid: cloneDeep(this.state.data).filter(
          item => item.username == it.username
        )[0].uuid
      },
      () =>
        fetchApi(`/users/${this.state.user_uuid}`, "put", {
          isDisabled: true
        }).then(res => {
          if (res) {
            if (res.violations) {
              notification.error({
                message: "Failed",
                description: res.violations[0].message
              });
            } else {
              notification.success({
                message: "Success",
                description: "Successfully deleted a User"
              });
              this.fetchUsers();
            }
          } else {
            notification.error({
              message: "Failed",
              description: "Failed to delete a User"
            });
          }
          this.setState({
            btnLoad: false
          });
        })
    );
  };

  onSumbitHandler = ({ roles, ...data }) => {
    //to remove the previous instances of notification
    notification.destroy();

    let { isAdd, isEdit, isChangePassword, isDelete } = this.state;

    console.log(data);
    this.setState({ btnLoad: true });
    if (isEdit) {
      this.setState(
        {
          user_uuid: cloneDeep(this.state.data).filter(
            item => item.username == data.username
          )[0].uuid
        },
        () =>
          fetchApi(`/users/${this.state.user_uuid}`, "put", {
            fullName: data.fullName,
            roles: [roles],
            canChangePassword: data.canChangePassword
          }).then(res => {
            if (res) {
              if (res.violations) {
                notification.error({
                  message: "Failed",
                  description: res.violations[0].message
                });
              } else {
                notification.success({
                  message: "Success",
                  description: "Successfully updated User Details"
                });
                this.fetchUsers();
              }
            } else {
              notification.error({
                message: "Failed",
                description: "Failed to update User Details"
              });
            }
            this.setState(
              {
                btnLoad: false
              },
              () => this.modaltoggle()
            );
          })
      );
    } else if (isChangePassword) {
      this.setState(
        {
          user_uuid: cloneDeep(this.state.data).filter(
            item => item.username == data.username
          )[0].uuid
        },
        () =>
          fetchApi(`/users/change-password/${this.state.user_uuid}`, "put", {
            newPassword: data.password,
            newConfirmPassword: data.confirmPassword
          }).then(res => {
            if (res) {
              if (res.violations) {
                notification.error({
                  message: "Failed",
                  description: res.violations[0].message
                });
              } else {
                notification.success({
                  message: "Success",
                  description: "Successfully updated User Password"
                });
                this.fetchUsers();
              }
            } else {
              notification.error({
                message: "Failed",
                description: "Failed to update User Password"
              });
            }
            this.setState(
              {
                btnLoad: false
              },
              () => this.modaltoggle()
            );
          })
      );
    } else if (isAdd) {
      fetchApi("/users", "post", {
        roles: [roles],
        ...data
      }).then(res => {
        if (res) {
          if (res.violations) {
            notification.error({
              message: "Failed",
              description: res.violations[0].message
            });
          } else {
            notification.success({
              message: "Success",
              description: "Successfully added a User"
            });
            this.fetchUsers();
          }
        } else {
          notification.error({
            message: "Failed",
            description: "Failed to add a User"
          });
        }
        this.setState(
          {
            btnLoad: false
          },
          () => this.modaltoggle()
        );
      });
    }
  };

  render() {
    const columns = [
      {
        title: "User Name",
        dataIndex: "username"
      },
      {
        title: "Full Name",
        dataIndex: "fullName"
      },
      {
        title: "Roles",
        key: "roles",
        dataIndex: "roles",
        render: roles => (
          <span>
            {roles.map(role => {
              let color;
              if (role === "ROLE_ADMIN") {
                color = "red";
              } else if (role === "ROLE_VALUATION") {
                color = "orange";
              } else if (role === "ROLE_FARM") {
                color = "green";
              } else {
                color = "geekblue";
              }

              return (
                <Tag color={color} key={role}>
                  {role == "ROLE_ADMIN"
                    ? "Admin"
                    : role == "ROLE_FARM"
                      ? "Farm":
                      role == "ROLE_VALUATION"
                      ? "Valuation"
                      : "Report"}
                </Tag>
              );
            })}
          </span>
        )
      },
      {
        title: "Action",
        key: "action",
        render: it => (
          <Fragment>
            {
              it.fullName != getCookie(COOKIE.NAME) ?
                (<Fragment> < Tooltip
                  placement="top"
                  title="Edit User Details and Permissions"
                >
                  <Icon
                    className="table-action-btn"
                    onClick={() => this.editHandler(it)}
                    type="edit"
                  />
                </Tooltip>
                  <Tooltip placement="top" title="Change Password">
                    <Icon
                      className="table-action-btn"
                      onClick={() => this.changePasswordHandler(it)}
                      //type="key"
                      type="lock"
                    />
                  </Tooltip>

                  <Popconfirm
                    title="Are you sure？"
                    icon={
                      <Icon type="question-circle-o" style={{ color: "red" }} />
                    }
                    onConfirm={() => this.onDeleteHandler(it)}
                    okText="Yes"
                    cancelText="No"
                    className="table-action-btn"
                  >
                    <a href="#">
                      <Tooltip placement="top" title="Delete User">
                        <Icon type="delete" />
                      </Tooltip>
                    </a>
                  </Popconfirm></Fragment >) : null
            }</Fragment>
        )
      }
    ],
      {
        title,
        modal,
        btnLoad,
        user,
        data,
        isAdd,
        isEdit,
        isChangePassword,
        isDelete,
        tableLoader,
        isRoleEdit
      } = this.state;

    return (
      <div className="admin-container">
        <AdvanceHeader
          title="Admin"
          buttonList={[
            {
              label: "Add User",
              icon: "folder-add",
              onClickHandler: this.modaltoggle
            }
          ]}
        />
        <Table columns={columns} dataSource={data} loading={tableLoader} />
        <UserForm
          modal={modal}
          title={title}
          btnLoad={btnLoad}
          onClose={this.modaltoggle}
          user={user}
          isAdd={isAdd}
          isEdit={isEdit}
          isRoleEdit={isRoleEdit}
          isChangePassword={isChangePassword}
          isDelete={isDelete}
          onSumbitHandler={this.onSumbitHandler}
        />
      </div>
    );
  }
}

export default Admin;
