import React, { Component } from "react";

//Componenet Import
import { Row, Col, Button, Layout } from "antd";

import {buttonList, buttonListFarms} from "./ButtonRenderer";
import { xor } from "lodash";
import * as JWT from "jwt-decode";
import { COOKIE, getCookie } from "../../../services/cookie";

import "./index.less";

const { Content } = Layout;

const btnLayout = {
  xs: 8,
  sm: 16,
  md: 12
};

const userRole = getCookie(COOKIE.ID_TOKEN) ? JWT(getCookie(COOKIE.ID_TOKEN)) : null;
const roleUser = userRole? userRole.roles[0]:null;

class MasterPage extends Component {
  constructor(props) {
    console.log("roleUser",roleUser);
    super(props);
    let linkItem = null,
      temp = [];
    if(roleUser=="ROLE_FARM")
      {if (buttonListFarms) {
        buttonListFarms.forEach(item => {
          let filterItems = item.children.filter((each, index) => {
            if (each.link === this.props.location.pathname) {
              linkItem = index;
            }

            return each.link.includes(
              `/${this.props.location.pathname.split("/")[1]}/${
                this.props.location.pathname.split("/")[2]
              }`
            );
          });
          if (filterItems.length > 0) temp.push(...filterItems);
        });
      }
    }else{
      if (buttonList) {
        buttonList.forEach(item => {
          let filterItems = item.children.filter((each, index) => {
            if (each.link === this.props.location.pathname) {
              linkItem = index;
            }

            return each.link.includes(
              `/${this.props.location.pathname.split("/")[1]}/${
                this.props.location.pathname.split("/")[2]
              }`
            );
          });
          if (filterItems.length > 0) temp.push(...filterItems);
        });
      }
    }
    this.state = {
      activeKey: temp.length > 0 ? temp : [],
      selectKey: linkItem
    };
  }

  onButtonClick = children => {
    let { activeKey } = this.state;
    if (activeKey == children)
      this.setState({ selectKey: null }, () =>
        this.setState({ activeKey: [] })
      );
    else
      this.setState({ selectKey: null }, () =>
        this.setState({ activeKey: children })
      );
  };

  renderButtonList = buttonList =>
    buttonList.map(({ label, key, children }) => (
      <Col className="gutter-row" span={6} key={key}>
        {console.log(xor(this.state.activeKey, children).length)}
        <Button
          className={
            xor(this.state.activeKey, children).length === 0
              ? "master-file-btn master-file-btn-main master-file-btn-active"
              : "master-file-btn master-file-btn-main"
          }
          onClick={() => this.onButtonClick(children)}
        >
          {label}
        </Button>
      </Col>
    ));

  setContentRender = (key, link) => {
    this.props.history.push(link);
    this.setState({ selectKey: key });
  };

  renderSubButtons = btns => {
    let { selectKey, activeKey } = this.state;
    return btns.map(({ label, key, link, span }, i) => (
      <Col
        className="gutter-row"
        //span={btns.length > 4 ? 4 : 6}
        span={span}
        key={key}
      >
        <Button
          className={
            selectKey !== null && activeKey[selectKey]
              ? activeKey[selectKey].key == key
                ? "master-file-btn master-file-btn-active"
                : "master-file-btn"
              : "master-file-btn"
          }
          onClick={() => this.setContentRender(i, link)}
        >
          {label}
        </Button>
      </Col>
    ));
  };

  render() {
    let JSX = buttonList.length > 0 ? this.renderButtonList(roleUser=="ROLE_FARM" ?buttonListFarms: buttonList) : null,
      { activeKey, selectKey } = this.state;

    console.log(activeKey, selectKey);

    return (
      <div className="master-file">
        <Row gutter={btnLayout} className="master-file-btn-container">
          {JSX}
        </Row>
        <Row gutter={12} className="master-file-sub-btn-container">
          {this.renderSubButtons(activeKey)}
        </Row>
        {selectKey !== null && activeKey[selectKey] ? (
          <Content className="master-file-content-container">
            {activeKey[selectKey].render()}
          </Content>
        ) : null}
      </div>
    );
  }
}

export default MasterPage;
