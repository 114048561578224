import React, { Component } from 'react'
import AdvanceHeader from '../../components/AdvanceHeader'
import './index.less'

export default ({
    title = null,
    buttonList = [],
    dropDownList = [],
    children,
}) => {
    return (
        <div className='page-builder-container' >
            <AdvanceHeader
                title={title}
                buttonList={buttonList}
                dropDownList={dropDownList}
            />
            <div className='page-builder-table'>
                {children}
                <table id="table-to-xls" />
            </div>
        </div>
    )
}