import React from 'react'

const columns = [
    {
        title: "TYPE CODE",
        dataIndex: "code",
        render: it => (it ? it : 'TOTAL')
    },
    {
        title: "NIL NUTS",
        dataIndex: "null_nuts",
    },
    {
        title: "1-3",
        dataIndex: "1-3",
        render: it => (it ? it : 0)
    },
    {
        title: "4-6",
        dataIndex: "4-6",
        render: it => (it ? it : 0)
    },
    {
        title: "7-9",
        dataIndex: "7-9",
        render: it => (it ? it : 0)
    },
    {
        title: "10-12",
        dataIndex: "10-12",
        render: it => (it ? it : 0)
    },
    {
        title: "13-15",
        dataIndex: "13-15",
        render: it => (it ? it : 0)
    },
    {
        title: "16-18",
        dataIndex: "16-18",
        render: it => (it ? it : 0)
    },
    {
        title: "19-21",
        dataIndex: "19-21",
        render: it => (it ? it : 0)
    },
    {
        title: ">=22",
        dataIndex: ">=22",
        render: it => (it ? it : 0)
    },
    {
        title: "Total",
        dataIndex: "total",
        render: it => (it ? it : 0)
    }
];

export default columns;