import React, { Component } from "react";

//Component
import { message, Spin } from "antd";
import ReportLayout from "../../pageLayouts/Report";
import MultiTable from "./MultiTable";
import FarmTotal from "./FarmTotal";
import Empty from "../../components/Custom/Empty";

//Utility
import { fetchApi, fetchExcelApi } from "../../../services/api";
import { cloneDeep } from "lodash";
import moment from "moment";
import { sortFunction } from "../../../utility/sorter";
import { COOKIE, getCookie } from "../../../services/cookie";

class TallTreesWithNoMalePollen extends Component {
  componentRef = React.createRef();
  state = {
    data: [],
    dynamicData: [],
    loading: false,
    blockNoList: [],
    blockNumber: null,
    treeTypeList: [],
    treeType: null,
    pollenCollectionFrom: null,
    pollenCollectionTo: null,
    farm_total: []
  };

  componentDidMount() {
    this.fetchBlockNo();
    this.fetchTreeType();
  }

  fetchTallTreesWithNoMalePollen = () => {
    let {
      blockNumber,
      treeType,
      pollenCollectionFrom,
      pollenCollectionTo
    } = this.state;
    this.setState({data: []})
    if (pollenCollectionFrom && pollenCollectionTo) {
      let url = `/query-module/tall-male-pollen-not-collected?fromDate=${pollenCollectionFrom}&toDate=${pollenCollectionTo}`;
      if (blockNumber) url += `&block.uuid=${blockNumber}`;
      if (treeType) url += `&tree_type.uuid=${treeType}`;
      this.setState({ loading: true });
      fetchApi(url, "get").then(res => {
        if (res) {
          let tmp = res.blocks;
          res.blocks =
            tmp &&
            tmp.map(({ list, ...rest }) => ({
              list: list.map(({ last_date, ...rest }) => ({
                last_date: last_date
                  ? moment(last_date).format("DD/MM/YYYY")
                  : "-",
                ...rest
              })),
              ...rest
            }));
          let { blocks, farm_total } = res;
          this.setState({
            data: blocks ? blocks : [],
            farm_total: farm_total ? farm_total : []
          });
        } else message.error("Internal Server Error!");
        this.setState({ loading: false });
      });
    }
  };

  fetchBlockNo = () =>
    fetchApi("/blocksuuid", "get").then(data =>
      this.setState({ blockNoList: sortFunction(data, "blockNoFull") })
    );

  fetchTreeType = () =>
    fetchApi("/tree_types?type=2", "get").then(data =>
      this.setState({ treeTypeList: sortFunction(data, "code") })
    );

  onDropDownChangeHandler = (type, item) => {
    switch (type) {
      case "block":
        this.setState(
          {
            blockNumber: item
              ? cloneDeep(this.state.blockNoList).filter(
                  each => each.blockNoFull === item
                )[0].uuid
              : null
          },
          () => this.fetchTallTreesWithNoMalePollen()
        );
        break;
      case "tree":
        this.setState(
          {
            treeType: item
              ? cloneDeep(this.state.treeTypeList).filter(
                  each => each.code === item
                )[0].uuid
              : null
          },
          () => this.fetchTallTreesWithNoMalePollen()
        );
        break;
      default:
        this.setState(
          {
            pollenCollectionFrom:
              item.length > 0 ? item[0].format("YYYY-MM-DD") : null,
            pollenCollectionTo:
              item.length > 0 ? item[1].format("YYYY-MM-DD") : null
          },
          () => this.fetchTallTreesWithNoMalePollen()
        );
    }
  };

  generateTables = tableData =>
  <div>
  <h3>Tall Trees with NO MALE POLLEN Collection &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Farm
  : {getCookie(COOKIE.FARM).charAt(0).toUpperCase() + getCookie(COOKIE.FARM).slice(1)}{" "} </h3>
  <h3>Pollen Collection Data Entered from  {moment(this.state.pollenCollectionFrom).format("DD/MM/YYYY")} to {moment(this.state.pollenCollectionTo).format("DD/MM/YYYY")}</h3>
  {tableData.map(({ block_no, list, summary }, index) => (
      <MultiTable
        key={index}
        blockNo={block_no}
        list={list}
        summary={summary}
        listColumns={[
          {
            title: "Tall Tree No.",
            dataIndex: "tree_no_full",
            key: "tree_no_full"
          },
          {
            title: "Tall Type",
            dataIndex: "code",
            key: "code"
          },
          {
            title: "Last Date of Pollen Collection",
            dataIndex: "last_date",
            key: "last_date",
            render: it => (it ? it : "-")
          }
        ]}
        summaryColumns={[
          {
            title: "Tall Type",
            dataIndex: "code",
            render: it => (it ? it : <b>Block Total</b>)
          },
          { title: "No. Of Trees/Plants", dataIndex: "no_of_trees" }
        ]}
      />
    ))}
    </div>

  generateFarmTotal = data => (
    <FarmTotal
      data={data}
      columns={[
        {
          title: "Tall Type",
          dataIndex: "code",
          render: it => (it ? it : <b>Farm Total</b>)
        },
        { title: "No. Of Plants", dataIndex: "no_of_trees" }
      ]}
    />
  );

  generateExcel = () => {
    let {
      blockNumber,
      treeType,
      pollenCollectionFrom,
      pollenCollectionTo
    } = this.state;
    if (pollenCollectionFrom && pollenCollectionTo) {
      let url = `/query-module/tall-male-pollen-not-collected?fromDate=${pollenCollectionFrom}&toDate=${pollenCollectionTo}`;
      if (blockNumber) url += `&block.uuid=${blockNumber}`;
      if (treeType) url += `&tree_type.uuid=${treeType}`;
      fetchExcelApi(url, "get").then();
    }
  };

  render() {
    let {
      data,
      treeTypeList,
      blockNoList,
      farm_total,
      loading,
      pollenCollectionFrom,
      pollenCollectionTo
    } = this.state;
    let JSX =
        data.length > 0 ? (
          this.generateTables(data)
        ) : pollenCollectionFrom && pollenCollectionTo ? (
          <Empty />
        ) : (
          <Empty description={"Select the Pollen collection Period"} />
        ),
      farmTotalJSX =
        farm_total.length > 0 ? this.generateFarmTotal(farm_total) : null;

    const buttonList =
      data.length > 0
        ? [
            {
              label: "Excel",
              icon: "file-text",
              onClickHandler: () => this.generateExcel()
            },
            {
              label: "Print",
              icon: "diff",
              reference: this.componentRef
            }
          ]
        : null;
    return (
      <ReportLayout
        title="Tall Trees with NO MALE POLLEN"
        buttonList={buttonList}
        dropDownList={[
          {
            label: "Block",
            listItems: cloneDeep(blockNoList).map(it => it.blockNoFull),
            changeHandler: it => this.onDropDownChangeHandler("block", it)
          },
          {
            label: "Tall Type",
            listItems: cloneDeep(treeTypeList).map(it => it.code),
            changeHandler: it => this.onDropDownChangeHandler("tree", it)
          },
          {
            label: "Pollen Collection Date Entered From:",
            type: "range",
            //mode: ["date", "date"],
            format: "DD/MM/YYYY",
            changeHandler: (date, string) =>
              this.onDropDownChangeHandler("pollenCollectionPeriod", date)
          }
        ]}
      >
        <div ref={this.componentRef}>
          <Spin spinning={loading} size="large">
            {JSX}
            {farmTotalJSX}
          </Spin>
        </div>
      </ReportLayout>
    );
  }
}

export default TallTreesWithNoMalePollen;
