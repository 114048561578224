import React, { Component, Fragment } from "react";
import ReactDOM from "react-dom";

import {
  Table,
  Icon,
  notification,
  message,
  Popconfirm,
  Tooltip,
  Spin
} from "antd";

//Component import
import LocationPageBuilder from "../../MasterFiles/PageBuilder";
import Empty from "../../../components/Custom/Empty";

//Utility
import { sortFunction } from "../../../../utility/sorter";
import { cloneDeep, filter } from "lodash";
import { fetchApi } from "../../../../services/api";
import { formatDate } from "../../../../utility/date";
import moment from "moment";

class DwarfSeedling extends Component {
  componentRef = React.createRef();
  state = {
    loading: false,
    blockNoList: [],
    data: [],
    dynamicTableData: [],
    blockNumber: null,
    areaNoList: [],
    areaNumber: null,
    seedlingNoList: [],
    seedlingNumber: null,
    pagination: true,
    plantedDuring: null,
    dateOfReading: null,
    ddPlantedDuring: null,
    ddDateOfReading: null,
    addModal: false,
    btnLoad: false,
    editData: null,
    block_uuid: null,
    area_uuid: null,
    seedling_uuid: null,
    isDelete: false,
    addPopulateData: null
  };

  componentDidMount() {
    this.dwarfGrowths();
    this.fetchBlockNo();
    const tableCon = ReactDOM.findDOMNode(this.refs["table"]),
      table = tableCon.querySelector("table"),
      header = table.createTHead(),
      row = header.insertRow(0),
      cell = row.insertCell(0);
    row.innerHTML = "<h1>Dwarf Seedling Growth</h1>";
    table.setAttribute("id", "table-to-xls");
    table.setAttribute("class", "Seedling-Growth");
  }

  dwarfGrowths = (reload = true) => {
    if (reload) {
      this.setState({ loading: true, dynamicTableData: [], pagination: false });
      let { block_uuid, area_uuid, seedling_uuid } = this.state,
        url = "/dwarf_growths";
      if (block_uuid) url += `?tree.block.uuid=${block_uuid}`;
      if (area_uuid) url += `&tree.area.uuid=${area_uuid}`;
      if (seedling_uuid) url += `&tree.uuid=${seedling_uuid}`;
      fetchApi(url, "get")
        .then(data => {
          data
            ? this.setState(
              {
                data: data.map(({ dtPlanted, dtReading, ...it }) => {
                  return {
                    dtPlanted: formatDate(dtPlanted),
                    dtReading: formatDate(dtReading),
                    date: dtReading,
                    dtCreated: dtReading,
                    plantedDate: dtPlanted,
                    ...it
                  };
                }),
                pagination: true
              },
              () => this.tableFilterHandler()
            )
            : message.error("Internal Server Error!");
        })
        .then(() => this.setState({ loading: false }));
    }
  };

  fetchBlockNo = () => {
    let { blockNumber } = this.state;
    fetchApi("/blocksuuid", "get").then(data =>
      this.setState({ blockNoList: sortFunction(data, "blockNoFull") })
    );
  };

  fetchTreeNoList = () => {
    let { block_uuid, area_uuid, plantedDuring } = this.state;
    let url = `/dwarves?stageCode=1`;
    if (block_uuid) url += `&block.uuid=${block_uuid}`;
    if (area_uuid) url += `&area.uuid=${area_uuid}`;
    if (plantedDuring) {
      let endDate = moment(plantedDuring)
        .endOf("month")
        .format("YYYY-MM-DD");
      let startDate = moment(plantedDuring)
        .format("YYYY-MM")
        .concat("-01");

      url += `&dtPlanted%5Bbefore%5D=${endDate}&dtPlanted%5Bafter%5D=${startDate}`;
    }
    fetchApi(url, "get").then(res => {
      this.setState({
        seedlingNoList: sortFunction(res, "treeNoFull"),
        seedlingNumber: null,
        seedling_uuid: null
      });
    });
  };

  clear = () => {
    this.setState(
      {
        data: [],
        dynamicTableData: [],
        blockNumber: null,
        areaNoList: [],
        areaNumber: null,
        seedlingNoList: [],
        seedlingNumber: null,
        pagination: true,
        plantedDuring: null,
        dateOfReading: null,
        ddPlantedDuring: null,
        ddDateOfReading: null,
        addModal: false,
        btnLoad: false,
        editData: null,
        block_uuid: null,
        area_uuid: null,
        seedling_uuid: null,
        isDelete: false
        //addPopulateData: null
      },
      () => {
        this.dwarfGrowths();
      }
    );
  };
  addModalToggle = () => {
    let {
      isAdded,
      dateOfReading,
      plantedDuring,
      ddDateOfReading,
      ddPlantedDuring,
      addModal,
      blockNumber,
      block_uuid,
      areaNumber,
      area_uuid,
      seedlingNumber,
      seedlingNoList,
      seedling_uuid,
      isEdit,
      isDelete
    } = this.state;
    if (addModal) {
      this.setState(
        {
          addModal: false,
          editData: null,
          addPopulateData: null,
          blockNumber: isEdit || isAdded || isDelete ? blockNumber : null,
          block_uuid: isEdit || isAdded || isDelete ? block_uuid : null,
          areaNumber: isEdit || isDelete ? areaNumber : null,
          area_uuid: isEdit || isDelete ? area_uuid : null,
          seedlingNumber: isEdit || isDelete ? seedlingNumber : null,
          seedlingNoList: isEdit || isAdded || isDelete ? seedlingNoList : [],
          seedling_uuid: isEdit || isDelete ? seedling_uuid : null,
          dateOfReading: isEdit || isAdded || isDelete ? dateOfReading : null,
          plantedDuring: isEdit || isAdded || isDelete ? plantedDuring : null,
          ddPlantedDuring: isEdit || isDelete ? plantedDuring : null,
          ddDateOfReading: isEdit || isDelete ? ddDateOfReading : null,
          isEdit: false,
          isDelete: false,
          isAdded: false
        },
        () => {
          this.setState({ isAdded: false });
        }
        //() => this.dwarfGrowths()
      );
    } else {
      if (!blockNumber || !ddPlantedDuring || !ddDateOfReading) {
        message.info("Select Block No., Planted Date and Reading Date");
      } else if (seedlingNoList < 1 || !seedlingNoList) {
        message.info("No seedlings for the selected planted date");
      } else {
        // if (!this.state.isEdit) this.getTreeType();
        ddDateOfReading && blockNumber && ddPlantedDuring
          ? this.setState(
            {
              editData: Object.assign(
                {},
                cloneDeep(ddDateOfReading)
                  ? { dtReading: cloneDeep(ddDateOfReading) }
                  : {},
                //seedlingNumber ? { tree: seedlingNumber } : {},
                blockNumber ? { blockNo: blockNumber } : {},
                ddPlantedDuring ? { plantedDate: ddPlantedDuring } : {}
              )
            },
            () => {
              this.getTreeType();
              this.setState({ addModal: !this.state.addModal });
            }
          )
          : this.setState({ addModal: !this.state.addModal });
      }
    }
  };

  clearFields = () => {
    this.setState(
      {
        ddPlantedDuring: null,
        addPopulateData: {
          treeNo: null,
          treeType: null,
          leaves: null,
          girth: null,
          height: null
        }
      },
      () =>
        this.setState({
          addPopulateData: null
        })
    );
  };

  addFormHandler = ({
    girth,
    height,
    leaves,
    dtReading,
    plantedDate,
    ...values
  }) => {
    notification.destroy();
    let {
      isEdit,
      isDelete,
      editData,
      seedlingNumber,
      seedlingNoList
    } = this.state;
    this.setState({ btnLoad: true });
    if (isDelete) {
      fetchApi(`/dwarf_growths/${editData.uuid}`, "put", {
        dtDeleted: moment().format("YYYY-MM-DD")
      }).then(res => {
        if (res) {
          if (res.violations) {
            notification.error({
              message: "Failed",
              description: res.violations[0].message
            });
          } else {
            notification.success({
              message: "Success",
              description: "Successfully Deleted Dwarf Seedling Growth"
            });
            this.dwarfGrowths();
            this.addModalToggle()
          }

        } else {
          notification.error({
            message: "Failed",
            description: "Failed to Delete Dwarf Seedling Growth"
          });
        }
        this.setState({ btnLoad: false }, () => this.addModalToggle());
      });
    } else if (isEdit) {
      fetchApi(`/dwarf_growths/${editData.uuid}`, "put", {
        girth: parseFloat(girth),
        height: parseFloat(height),
        leaves: parseInt(leaves),
        dtCreated: moment(editData.dtCreated).format("YYYY-MM-DD"),
        tree: "/dwarves/" + editData.treeUuid,
        dtReading: moment(dtReading._d).format("YYYY-MM-DD")
      }).then(res => {
        if (res) {
          if (res.violations) {
            notification.error({
              message: "Failed",
              description: res.violations[0].message
            });
          } else {
            notification.success({
              message: "Success",
              description: "Successfully Updated Dwarf Seedling Growth"
            });
            this.dwarfGrowths();
            this.addModalToggle()
          }

        } else {
          notification.error({
            message: "Failed",
            description: "Failed to Update Dwarf Seedling Growth"
          });
        }
        this.setState({ btnLoad: false }
          //, () => this.addModalToggle()
        );
      });
    } else {
      fetchApi("/dwarf_growths", "post", {
        girth: parseFloat(girth),
        height: parseFloat(height),
        leaves: parseInt(leaves),
        tree:
          "/dwarves/" +
          cloneDeep(seedlingNoList).filter(
            it => it.treeNoFull == seedlingNumber
          )[0].uuid,
        dtReading: moment(dtReading).format("YYYY-MM-DD"),
        plantedDate: moment(plantedDate).format("YYYY-MM-DD"),
        ...values
      }).then(res => {
        if (res) {
          if (res.violations) {
            notification.error({
              message: "Failed",
              description: res.violations[0].message
            });
          } else {
            notification.success({
              message: "Success",
              description: "Successfully added Dwarf Seedling Growth"
            });
            this.setState(
              {
                isAdded: true,
                //dateOfReading: null, plantedDuring: null,
                ddDateOfReading: null,
                //ddPlantedDuring: null,
                seedlingNumber: null,
                seedling_uuid: null,
                areaNumber: null,
                area_uuid: null
              },
              () => this.dwarfGrowths()
            );
            this.clearFields();
          }
        } else {
          notification.error({
            message: "Failed",
            description: "Failed to add Dwarf Seedling Growth"
          });
        }
        this.setState({
          btnLoad: false,
          //addPopulateData: null
          addModalToggle: null
        });
      });
    }
  };

  onBlockChangeHandler = it => {
    let { blockNoList } = this.state;
    if (it) {
      fetchApi(
        `/areasuuid?block.uuid=${
        cloneDeep(blockNoList).filter(item => item.blockNoFull == it)[0].uuid
        }`,
        "get"
      ).then(res =>
        this.setState(
          {
            block_uuid: cloneDeep(blockNoList).filter(
              item => item.blockNoFull == it
            )[0].uuid,
            blockNumber: it,
            areaNoList: res,
            areaNumber: null,
            area_uuid: null,
            seedlingNoList: [],
            seedlingNumber: null,
            seedling_uuid: null
          },
          () => {
            this.dwarfGrowths();
            this.fetchTreeNoList();
          }
        )
      );
    } else {
      this.setState(
        {
          block_uuid: null,
          blockNumber: null,
          areaNoList: [],
          areaNumber: null,
          area_uuid: null,
          seedlingNoList: [],
          seedlingNumber: null,
          seedling_uuid: null
        },
        () => this.dwarfGrowths()
      );
    }
  };

  onAreaChangeHandler = (it, reload = true) => {
    let {
      areaNoList,
      blockNumber,
      blockNoList,
      area_uuid,
      block_uuid,
      plantedDuring
    } = this.state;

    // let url = `/dwarves?stageCode=1`;
    // if (block_uuid) url += `&block.uuid=${block_uuid}`;
    // if (it)
    //   url += `&area.uuid=${
    //     cloneDeep(areaNoList).filter(item => item.areaNo == it)[0].uuid
    //   }`;
    // if (plantedDuring) {
    //   let endDate = moment(plantedDuring)
    //     .endOf("month")
    //     .format("YYYY-MM-DD");
    //   let startDate = moment(plantedDuring)
    //     .format("YYYY-MM")
    //     .concat("-01");

    //   url += `&dtPlanted%5Bbefore%5D=${endDate}&dtPlanted%5Bafter%5D=${startDate}`;
    // }

    //if (it) {
    //   fetchApi(url, "get").then(res => {
    //     this.setState(
    //       {
    //         areaNumber: it,
    //         area_uuid: cloneDeep(areaNoList).filter(
    //           item => item.areaNo == it
    //         )[0].uuid,
    //         seedlingNoList: sortFunction(res, "treeNoFull"),
    //         seedlingNumber: null,
    //         seedling_uuid: null
    //         // addPopulateData: {
    //         //   areaNo: it,
    //         //   treeNo: null,
    //         //   treeType: null,
    //         //   plantedDate: null
    //         // }
    //       },
    //       () => this.dwarfGrowths(reload)
    //     );
    //   });
    // } else {
    //   fetchApi(url, "get").then(res => {
    // this.setState(
    //   {
    //     areaNumber: null,
    //     area_uuid: null,
    //seedlingNoList: sortFunction(res, "treeNoFull"),
    //seedlingNumber: null,
    //seedling_uuid: null
    // addPopulateData: {
    //   areaNo: null,
    //   treeNo: null,
    //   treeType: null,
    //   plantedDate: null
    // }
    //},
    //   this.setState({
    //     areaNumber: it,
    //     area_uuid: cloneDeep(areaNoList).filter(
    //               item => item.areaNo == it
    //             )[0].uuid,
    //   },
    //   () => {
    //     //this.dwarfGrowths(reload)
    //     this.dwarfGrowths();
    //     this.fetchTreeNoList();
    //   }
    // );
    //});
    //}
    this.setState(
      {
        areaNumber: it ? it : null,
        area_uuid: it
          ? cloneDeep(areaNoList).filter(item => item.areaNo == it)[0].uuid
          : null
      },
      () => {
        //this.dwarfGrowths(reload)
        this.dwarfGrowths();
        this.fetchTreeNoList();
      }
    );
  };

  onSeedlingChangeHandler = (it, reload = true) => {
    let { seedlingNoList } = this.state;
    if (it) {
      this.setState(
        {
          seedlingNumber: it,
          seedling_uuid: cloneDeep(seedlingNoList).filter(
            item => item.treeNoFull == it
          )[0].uuid,
          areaNumber: cloneDeep(seedlingNoList).filter(
            item => item.treeNoFull == it
          )[0].areaNo
        },
        () => {
          this.dwarfGrowths(reload);
          this.getTreeType();
        }
      );
    } else {
      this.setState(
        {
          seedlingNumber: null,
          seedling_uuid: null
        },
        () => {
          this.dwarfGrowths(reload);
          this.getTreeType();
        }
      );
    }
  };

  getTreeType = () => {
    let { block_uuid, area_uuid, seedlingNumber, areaNumber } = this.state,
      url = "/dwarves";
    if (block_uuid) url += `?block.uuid=${block_uuid}`;
    if (area_uuid) url += `&area.uuid=${area_uuid}`;
    if (seedlingNumber) url += `&treeNo=${seedlingNumber}`;
    fetchApi(url, "get", {}).then(res => {
      if (res) {
        this.setState(
          {
            addPopulateData: {
              treeType: res[0].treeType,
              //treeNo: res[0].treeNoFull,
              treeNo: seedlingNumber ? seedlingNumber : null,
              plantedDate: moment(res[0].dtPlanted)
              //areaNo: areaNumber ? areaNumber : null
            }
          },
          () => { }
        );
      }
    });
  };

  plantedDuringHandler = (date, dateString) => {
    let { blockNumber, dateOfReading } = this.state;
    if (date) {
      this.setState(
        {
          plantedDuring: date,
          ddPlantedDuring: date,
          seedlingNumber: null,
          seedling_uuid: null
        },
        () => {
          this.dwarfGrowths();
          this.fetchTreeNoList();
          //this.getTreeType();
        }
      );
    } else {
      this.setState(
        {
          plantedDuring: null,
          ddPlantedDuring: null,
          seedlingNumber: null,
          seedling_uuid: null
        },
        () => {
          this.dwarfGrowths();
          this.fetchTreeNoList();
        }
      );
    }
  };

  dateOfReadingHandler = (date, dateString) => {
    let { blockNumber } = this.state;
    if (date) {
      this.setState(
        {
          dateOfReading: date,
          ddDateOfReading: date
        },
        () => this.dwarfGrowths()
      );
    } else {
      this.setState(
        {
          dateOfReading: null,
          ddDateOfReading: null
        },
        () => this.dwarfGrowths()
      );
    }
  };

  editHandler = ({ dtReading, plantedDate, date, ...it }) => {
    this.setState({ addPopulateData: null });
    this.setState(
      {
        isEdit: true,
        editData: {
          dtReading: moment(cloneDeep(date), "YYYY-MM-DD"),
          plantedDate: moment(cloneDeep(plantedDate), "YYYY-MM-DD"),
          ...it
        }
      },
      () => this.setState({ addModal: true })
    );
  };

  tableFilterHandler = () => {
    let {
      //plantedDuring,
      //dateOfReading,
      data,
      ddDateOfReading,
      ddPlantedDuring
      // blockNumber,
      // areaNumber,
      // seedlingNumber
    } = this.state,
      filterJson = {};
    let temp = cloneDeep(data);
    // if (blockNumber) filterJson.blockNo = blockNumber;
    // if (areaNumber) filterJson.areaNo = areaNumber;
    // if (seedlingNumber) filterJson.treeNo = seedlingNumber;
    if (ddDateOfReading) filterJson.dtReading = formatDate(ddDateOfReading);
    if (ddPlantedDuring) {
      temp = filter(temp, o => ddPlantedDuring.isSame(o.plantedDate, "month"));
    }
    this.setState({
      dynamicTableData: sortFunction(filter(temp, filterJson), "treeNo")
    });
  };

  deleteHandler = ({ dtReading, plantedDate, date, ...it }) => {
    this.setState(
      {
        isDelete: true,
        editData: {
          dtReading: moment(cloneDeep(date), "YYYY-MM-DD"),
          plantedDate: moment(cloneDeep(plantedDate), "YYYY-MM-DD"),
          ...it
        }
      },
      () => this.setState({ addModal: true })
    );
  };

  render() {
    let {
      blockNoList,
      blockNumber,
      areaNoList,
      seedlingNoList,
      areaNumber,
      seedlingNumber,
      dateOfReading,
      plantedDuring,
      ddPlantedDuring,
      ddDateOfReading,
      dynamicTableData,
      pagination,
      addModal,
      editData,
      btnLoad,
      isEdit,
      isDelete,
      loading,
      data,
      addPopulateData
    } = this.state;
    const columns = [
      {
        title: "Tree No.",
        key: "treeNo",
        dataIndex: "treeNo"
      },
      {
        title: "Tree Type",
        key: "treeType",
        dataIndex: "treeType"
      },
      {
        title: "Date of Reading",
        key: "dtReading",
        dataIndex: "dtReading"
      },
      {
        title: "Date of Planting",
        key: "dtPlanted",
        dataIndex: "dtPlanted"
      },
      {
        title: "No. of Leaves",
        key: "leaves",
        dataIndex: "leaves"
      },
      {
        title: "Girth (cms)",
        key: "girth",
        dataIndex: "girth"
      },
      {
        title: "Height (cms)",
        key: "height",
        dataIndex: "height"
      },
      {
        title: "Actions",
        className: "action",
        //key: "isLatest",
        render: it =>
          it.isLatest ? (
            <Fragment>
              <Tooltip placement="top" title="Edit">
                <Icon
                  className="table-action-btn"
                  onClick={() => this.editHandler(it)}
                  type="edit"
                />
              </Tooltip>
              <Tooltip placement="top" title="Delete">
                <Icon
                  type="delete"
                  className="table-action-btn"
                  onClick={() => this.deleteHandler(it)}
                  type="delete"
                />
              </Tooltip>
            </Fragment>
          ) : null
      }
    ];
    const formItems =
      !isEdit && !isDelete
        ? [
          {
            name: "blockNo",
            placeholder: "Block",
            //listData: cloneDeep(blockNoList).map(it => it.blockNo),
            disabled: true
          },
          // {
          //   name: "treeNo",
          //   placeholder: "Tree No.",
          //   type: "text",
          //   disabled: true,
          //   required: false
          // },
          // {
          //   name: "areaNo",
          //   placeholder: "Area No.",
          //   listData: cloneDeep(areaNoList).map(it => it.areaNo),
          //   disabled: blockNumber ? false : true,
          //   value: areaNumber ? areaNumber : undefined,
          //   changeHandler: it => this.onAreaChangeHandler(it, false)
          // },
          {
            name: "treeNo",
            placeholder: "Seedling No",
            listData: cloneDeep(seedlingNoList).map(it => it.treeNoFull),
            //disabled: areaNumber ? false : true,
            //value: seedlingNumber ? seedlingNumber : undefined,
            changeHandler: it => this.onSeedlingChangeHandler(it, false)
          },
          {
            name: "treeType",
            placeholder: "Tree Type",
            disabled: true,
            type: "text",
            required: false
          },
          {
            name: "plantedDate",
            placeholder: "Date of Planting",
            type: "date",
            disabled: true,
            required: false
          },
          {
            name: "dtReading",
            placeholder: "Date of Reading",
            type: "date",
            disabled: isDelete ? true : false,
            message: "Input the Reading Date!"
          },
          {
            name: "leaves",
            placeholder: "No. of Leaves",
            type: "number",
            disabled: isDelete ? true : false,
            message: "Input the Leaves!"
          },
          {
            name: "girth",
            placeholder: "Girth (cm)",
            type: "number",
            disabled: isDelete ? true : false,
            message: "Input the Girth!"
          },
          {
            name: "height",
            placeholder: "Height (cm)",
            type: "number",
            disabled: isDelete ? true : false,
            message: "Input the Height!"
          }
        ]
        : [
          {
            name: "blockNo",
            placeholder: "Block",
            //listData: cloneDeep(blockNoList).map(it => it.blockNo),
            disabled: true
          },
          {
            name: "treeNo",
            placeholder: "Tree No.",
            type: "text",
            disabled: true,
            required: false
          },
          {
            name: "treeType",
            placeholder: "Tree Type",
            disabled: true,
            type: "text",
            required: false
          },
          {
            name: "plantedDate",
            placeholder: "Date of Planting",
            type: "date",
            disabled: true,
            required: false
          },
          {
            name: "dtReading",
            placeholder: "Date of Reading",
            type: "date",
            disabled: isDelete ? true : false,
            message: "Input the Reading Date!"
          },
          {
            name: "leaves",
            placeholder: "No. of Leaves",
            type: "number",
            disabled: isDelete ? true : false,
            message: "Input the Leaves!"
          },
          {
            name: "girth",
            placeholder: "Girth (cm)",
            type: "number",
            disabled: isDelete ? true : false,
            message: "Input the Girth!"
          },
          {
            name: "height",
            placeholder: "Height (cm)",
            type: "number",
            disabled: isDelete ? true : false,
            message: "Input the Height!"
          }
        ];

    const buttonList =
      data.length > 0
        ? [
          blockNumber ||
            areaNumber ||
            seedlingNumber ||
            dateOfReading ||
            plantedDuring
            ? {
              label: "Clear",
              icon: "delete",
              onClickHandler: this.clear
            }
            : "",
          {
            label: "Add",
            icon: "folder-add",
            onClickHandler: this.addModalToggle
          },
          // {
          //   label: "Excel",
          //   icon: "file-text"
          // },
          // {
          //   label: "Print",
          //   icon: "diff",
          //   reference: this.componentRef
          // },
          {
            label: this.state.pagination ? "View All" : "View Less",
            icon: "container",
            onClickHandler: () => this.setState({ pagination: !pagination })
          }
        ]
        : [
          blockNumber ||
            areaNumber ||
            seedlingNumber ||
            dateOfReading ||
            plantedDuring
            ? {
              label: "Clear",
              icon: "delete",
              onClickHandler: this.clear
            }
            : "",
          {
            label: "Add",
            icon: "folder-add",
            onClickHandler: this.addModalToggle
          }
        ];
    return (
      <LocationPageBuilder
        title="Dwarf Seedling Growth"
        buttonList={buttonList}
        dropDownList={[
          {
            label: "Block",
            listItems: blockNoList.map(it => it.blockNoFull),
            value: blockNumber ? blockNumber : undefined,
            changeHandler: it => this.onBlockChangeHandler(it)
          },
          {
            label: "Area",
            listItems: cloneDeep(areaNoList).map(it => it.areaNo),
            disabled: blockNumber ? false : true,
            value: areaNumber ? areaNumber : undefined,
            changeHandler: it => this.onAreaChangeHandler(it)
          },
          {
            label: "Seedling No.",
            listItems: cloneDeep(seedlingNoList).map(it => it.treeNoFull),
            disabled: areaNumber && ddPlantedDuring ? false : true,
            value: seedlingNumber ? seedlingNumber : undefined,
            changeHandler: it => this.onSeedlingChangeHandler(it)
          },
          {
            label: "Planted Date",
            type: "month",
            //value: plantedDuring ? plantedDuring : undefined,
            value: ddPlantedDuring ? ddPlantedDuring : undefined,
            changeHandler: this.plantedDuringHandler
          },
          {
            label: "Reading Date",
            type: "date",
            //value: dateOfReading ? dateOfReading : undefined,
            value: ddDateOfReading ? ddDateOfReading : undefined,
            changeHandler: this.dateOfReadingHandler
          }
        ]}
        addModalToggle={this.addModalToggle}
        addModal={addModal}
        addFormHandler={this.addFormHandler}
        editData={editData}
        addPopulateData={addPopulateData}
        btnLoad={btnLoad}
        isEdit={isEdit}
        isDelete={isDelete}
        formItems={formItems}
      >
        <div ref={this.componentRef}>
          <Spin spinning={loading} size="large">
            <Table
              ref="table"
              rowKey="uuid"
              columns={columns}
              dataSource={dynamicTableData}
              pagination={!pagination ? pagination : { pageSize: 15 }}
              //loading={loading}
              locale={{
                emptyText: <Empty />
              }}
            />
          </Spin>
        </div>
      </LocationPageBuilder>
    );
  }
}

export default DwarfSeedling;
