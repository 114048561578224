import React from "react";
import buttonList from "./ButtonRenderer";

import PageTabs from "../../components/PageTabs";

import "./index.less";

export default () => {
  return <PageTabs buttonList={buttonList} />;
};
