import React, { Component } from "react";
import ReactDOM from "react-dom";
import { Table, message, notification, Icon, Tooltip, Spin } from "antd";

//Component import
import LocationPageBuilder from "../PageBuilder";

//Utility
import { sortFunctionDesc } from "../../../../utility/sorter";
import moment from "moment";
import { COOKIE, getCookie } from "../../../../services/cookie";
import { fetchApi, fetchExcelApi } from "../../../../services/api";
import { cloneDeep, filter } from "lodash";

class ValuationChart extends Component {
  componentRef = React.createRef();
  state = {
    addModal: false,
    data: [],
    btnLoad: false,
    editData: null,
    isEdit: false,
    tableLoader: false,
    pagination: true,
    dwarfTreeTypeUuid: null,
    isopen: false,
    clearFields: true,
    addPopulateData: null
  };

  componentDidMount() {
    this.setState({ tableLoader: true });
    this.fetchDwarfValuationRate();
    const tableCon = ReactDOM.findDOMNode(this.refs["table"]),
      table = tableCon.querySelector("table"),
      header = table.createTHead(),
      row = header.insertRow(0);
    //cell = row.insertCell(0);
    row.innerHTML = `<h1>Dwarf Valuation Chart - List &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Farm
    : ${getCookie(COOKIE.FARM)}${" "} </h1>`;
    table.setAttribute("id", "table-to-xls");
    table.setAttribute("class", "Dwarf-Tree-Types");
  }

  fetchDwarfValuationRate = () =>
    fetchApi("/dwarf_valuation_rates", "get").then(data =>
      this.setState({
        tableLoader: false,
        data: sortFunctionDesc(data, "valuationYear")
      })
    );

  addModalToggle = () => {
    let { addModal } = this.state;
    if (addModal) {
      this.setState(
        {
          addModal: false,
          editData: null,
          isEdit: false,
          dwarfValuationUuid: null,
          addPopulateData: null
        }
        //() => this.fetchTreeTypes()
      );
    } else {
      this.setState({ addModal: !this.state.addModal });
    }
  };

  clearFields = () => {
    this.setState({
      addPopulateData: {
        age: null,
        valuationYear: null,
        rate: null
      }
    });
  };

  addFormHandler = ({ age, rate, valuationYear, ...val }) => {
    //to remove the previous instances of
    notification.destroy();

    let { isEdit, dwarfValuationUuid } = this.state;
    this.setState({ btnLoad: true });
    if (!isEdit) {
      fetchApi("/dwarf_valuation_rates", "post", {
        age: parseFloat(age),
        rate: parseFloat(rate),
        valuationYear: parseFloat(moment(valuationYear).format("YYYY")),
        ...val
      }).then(res => {
        if (res) {
          if (res.violations) {
            notification.error({
              message: "Failed",
              description: res.violations[0].message
            });
            //this.setState({ clearFields: false });
          } else {
            notification.success({
              message: "Success",
              description: "Successfully added Dwarf Valuation Chart"
            });
            this.clearFields();
            this.fetchDwarfValuationRate();
          }
        } else {
          notification.error({
            message: "Failed",
            description: "Failed to add Dwarf Valuation Chart"
          });
        }
        this.setState({ btnLoad: false, addPopulateData: null });
      });
    } else {
      fetchApi(`/dwarf_valuation_rates/${dwarfValuationUuid}`, "put", {
        age: parseFloat(age),
        rate: parseFloat(rate),
        valuationYear: parseFloat(moment(valuationYear).format("YYYY")),
        ...val
      }).then(res => {
        if (res) {
          notification.success({
            message: "Success",
            description: "Successfully updated the Dwarf Valuation Chart"
          });
          this.fetchDwarfValuationRate();
          this.addModalToggle()
        } else {
          notification.error({
            message: "Failed",
            description: "Failed to update the Dwarf Valuation Chart"
          });
        }
        this.setState({ btnLoad: false }
          //, () => this.addModalToggle()
        );
      });
    }
  };

  onEditClickHandler = item => {
    this.setState(
      {
        isEdit: true
      },
      () => {
        this.setState({
          dwarfValuationUuid: item.uuid,
          addModal: true,
          editData: {
            age: item.age,
            valuationYear: moment("01-01-" + item.valuationYear),
            rate: item.rate
          }
        });
      }
    );
  };

  onPanelChange = value => {
    if (value) {
      this.setState({
        isopen: false,
        addPopulateData: {
          valuationYear: value
        }
      });
    } else {
      this.setState({
        isopen: false,
        addPopulateData: {
          valuationYear: null
        }
      });
    }
  };

  handleOpenChange = status => {
    if (status) {
      this.setState({ isopen: true });
    } else {
      this.setState({ isopen: false });
    }
  };

  render() {
    let {
      addModal,
      data,
      btnLoad,
      editData,
      pagination,
      tableLoader,
      isEdit,
      isopen,
      addPopulateData
    } = this.state;
    const columns = [
      {
        title: "Age/Year ",
        dataIndex: "age"
      },
      {
        title: "Rate for (Year)",
        dataIndex: "valuationYear"
      },
      {
        title: "Rate/Rs",
        dataIndex: "rate"
      },
      {
        title: "Actions",
        className: "action",
        render: it => (
          <Tooltip placement="topRight" title={"Edit"}>
            <Icon onClick={() => this.onEditClickHandler(it)} type="edit" />
          </Tooltip>
        )
      }
    ],
      formItems = [
        {
          name: "age",
          type: "number",
          placeholder: "Age/Year"
        },
        {
          name: "valuationYear",
          type: "year",
          //onValuationYearChange: value => this.onValuationYearChange(value),
          onPanelChange: value => this.onPanelChange(value),
          onOpenChange: this.handleOpenChange,
          open: isopen,
          placeholder: "Rate for (Year)"
        },
        {
          name: "rate",
          type: "number",
          placeholder: "Rate/Rs"
        }
      ];

    // const _formItems = isEdit
    //   ? [
    //       {
    //         name: "code",
    //         type: "alphanumeric",
    //         placeholder: "Dwarf Tree Type",
    //         disabled: true
    //       }
    //     ].concat(formItems)
    //   : [
    //       {
    //         name: "code",
    //         type: "alphanumeric",
    //         placeholder: "Dwarf Tree Type"
    //       }
    //     ].concat(formItems);

    const buttonList =
      data.length > 0
        ? [
          {
            label: "Add",
            icon: "folder-add",
            onClickHandler: this.addModalToggle
          },
          {
            label: "Print",
            icon: "diff",
            reference: this.componentRef
          }
        ]
        : [
          {
            label: "Add",
            icon: "folder-add",
            onClickHandler: this.addModalToggle
          }
        ];
    return (
      <LocationPageBuilder
        title="Dwarf Valuation Chart"
        buttonList={buttonList}
        addModal={addModal}
        addPopulateData={addPopulateData}
        addModalToggle={this.addModalToggle}
        addFormHandler={this.addFormHandler}
        editData={editData ? editData : null}
        btnLoad={btnLoad}
        formItems={formItems}
        isEdit={isEdit}
      >
        <div ref={this.componentRef}>
          {/* <h1>
            Dwarf Tree Type Master - List &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            Farm : {getCookie(COOKIE.FARM)}{" "}
          </h1> */}
          <Spin spinning={tableLoader} size="large">
            <Table
              ref="table"
              columns={columns}
              dataSource={data}
              pagination={!pagination ? pagination : { pageSize: 15 }}
            //loading={tableLoader}
            />
          </Spin>
        </div>
      </LocationPageBuilder>
    );
  }
}

export default ValuationChart;
