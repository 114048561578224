import React from "react";
import { Table, Col, Statistic, Row, Descriptions } from "antd";
import ScollBarView from "react-double-scrollbar";
import moment from "moment";

const layout = {
  xs: {
    span: 6
  },
  sm: {
    span: 6
  },
  lg: {
    span: 4
  },
  md: {
    span: 4
  }
};

let columns = [
  {
    title: "Tree No.",
    dataIndex: "tree_no_full"
    //render: it => (it ? it : "-")
  },
  {
    title: "Date Planted",
    dataIndex: "dt_planted",
    render: it => moment(it).format("DD/MM/YYYY")
    //render: it => (it ? it : "-")
  },
  {
    title: "Date Died",
    dataIndex: "dt_died",
    render: it => moment(it).format("DD/MM/YYYY")
    //render: it => (it ? it : "-")
  },
  {
    title: "Reason for Deletion",
    dataIndex: "delete_reason",
    render: it => it? it:"-"
  },
  {
    title: "Age",
    children: [
      {
        title: "Years",
        dataIndex: "years",
        key: "years"
      },
      {
        title: "Months",
        dataIndex: "months",
        key: "months"
      }
    ]
  }
  // {
  //   title: "Age In Months",
  //   dataIndex: "months"
  //   //render: it => (it ? it : "-")
  // },
  // {
  //   title: "Age In Years",
  //   dataIndex: "years"
  //   //render: it => (it ? it : "-")
  // }
];

export default ({ dataSource }) =>
  dataSource.map(({ block_no_full, trees, block_total }, j) => (
    <div>
      <br></br>
      <Statistic title="Block No." value={block_no_full} />
      <br></br>
      <Table
        //className="block-wise-summary-dwarf-table"
        //key={j}
        className="bordered-table-head"
        bordered
        size="small"
        columns={columns}
        dataSource={trees}
        pagination={false}
      />
      <br />
      <Descriptions bordered>
        <Descriptions.Item label="Block Total Trees" span={3}>
          {block_total}
        </Descriptions.Item>
      </Descriptions>
      <br />
      {dataSource.length > j + 1 ? <div className="pagebreak" /> : null}
    </div>
  ));
