import React, { Component, Fragment } from "react";
import ReactDOM from "react-dom";

import {
  Table,
  Icon,
  message,
  notification,
  Popconfirm,
  Tooltip,
  Spin,
  Modal,
  Row, Col, Button
} from "antd";

//Component import
import LocationPageBuilder from "../../MasterFiles/PageBuilder";
import Empty from "../../../components/Custom/Empty";

//Utility
import { sortFunction } from "../../../../utility/sorter";
import { cloneDeep, filter } from "lodash";
import { fetchApi } from "../../../../services/api";
import moment from "moment";

class TallTree extends Component {
  componentRef = React.createRef();
  state = {
    blockNoList: [],
    treeTypeData:[],
    block_uuid: null,
    data: [],
    dataWODate: [],
    dynamicTableData: [],
    blockNumber: null,
    areaNoList: [],
    areaNumber: null,
    area_uuid: null,
    treeNoList: [],
    formTreeNoList: [],
    treeNumber: null,
    tree_uuid: null,
    pagination: true,
    dateOfHarvest: null,
    addModal: false,
    editData: null,
    btnLoad: false,
    isEdit: false,
    isDelete: false,
    loading: false,
    confirmedId:null,
    addPopulateData: null,
    visibleConfirm: false,
    TreeTypeCode:null,
    showModal:false,
    storageId:null,
    storageCode:null,
    recordId:null,
    recordCode:null,
    TreeTypeId:null,
    showModalAdd:false,
  };

  componentDidMount() {
    this.tallHarvests();
    this.tallHarvestswithoutDate();
    this.fetchBlockNo();
    this.fetchTreeType();
    const tableCon = ReactDOM.findDOMNode(this.refs["table"]),
      table = tableCon.querySelector("table"),
      header = table.createTHead(),
      row = header.insertRow(0),
      cell = row.insertCell(0);
    row.innerHTML = "<h1>Tall Tree Harvest</h1>";
    table.setAttribute("id", "table-to-xls");
    table.setAttribute("class", "Tall-Tree-Harvest");
  }

  fetchTreeType = ()=>{
    let {treeTypeData} = this.state;
      // fetchApi("/tree_types", "get")
       fetchApi(
        "/tree_types?type=2","get").then((response) =>{
        this.setState({treeTypeData:response});
        console.log(this.state.treeTypeData);
        return treeTypeData;
       });
  }

  tallHarvests = () => {
    this.setState({ loading: true, dynamicTableData: [], pagination: false });
    let {
      block_uuid,
      area_uuid,
      tree_uuid,
      formTreeNoList,
      dateOfHarvest
    } = this.state,
      url = "/tall_harvests";
    if (block_uuid) url += `?block.uuid=${block_uuid}`;
    if (area_uuid) url += `&area.uuid=${area_uuid}`;
    if (tree_uuid) url += `&tree.uuid=${tree_uuid}`;

    if (block_uuid && formTreeNoList.length < 1) {
      // console.log("inside the loop");
      this.fetchFormTreeList();
    }
    if (dateOfHarvest) {
      if (block_uuid) {
        url += `&harvestDt%5Bbefore%5D=${moment(dateOfHarvest).format(
          "YYYY/MM/DD"
        )}`;
        url += `&harvestDt%5Bafter%5D=${moment(dateOfHarvest).format(
          "YYYY/MM/DD"
        )}`;
      } else {
        url += `?harvestDt%5Bbefore%5D=${moment(dateOfHarvest).format(
          "YYYY/MM/DD"
        )}`;
        url += `&harvestDt%5Bafter%5D=${moment(dateOfHarvest).format(
          "YYYY/MM/DD"
        )}`;
      }
    }
    fetchApi(url, "get")
      .then(data =>
        data
          ? this.setState(
            {
              data: data.map(({ harvestDt, ...it }) => {
                return {
                  harvestDt: moment(harvestDt).format("DD/MM/YYYY"),
                  date: harvestDt,
                  ...it
                };
              }),
              pagination: true
            },
            () => this.tableFilterHandler()
          )
          : message.error("Internal Server Error!")
      )
      .then(() => this.setState({ loading: false }));
  };


  tallHarvestswithoutDate = () => {
    this.setState({ loading: true });
    let {
      block_uuid,
      area_uuid,
      tree_uuid,
      formTreeNoList,
      treeNumber
    } = this.state,
      url = "/tall_harvests";
    if (block_uuid && area_uuid && tree_uuid) {
      if (block_uuid) url += `?block.uuid=${block_uuid}`;
      if (area_uuid) url += `&area.uuid=${area_uuid}`;
      if (tree_uuid) url += `&tree.uuid=${tree_uuid}`;
      fetchApi(url, "get")
        .then(data =>
          data
            ? this.setState(
              {
                dataWODate: data,
              }, () => {
                if (this.state.dataWODate.length == 0 && this.state.addModal == true && cloneDeep(formTreeNoList) && formTreeNoList.length > 0 && cloneDeep(formTreeNoList).filter(item => item.treeNoFull == treeNumber).length > 0 && cloneDeep(formTreeNoList).filter(item => item.treeNoFull == treeNumber)[0].stageCode == 2)
                  this.setState({
                    visibleConfirm: true,
                    addModal: false
                  })
                else
                  this.setState({
                    btnLoad: false,
                  })
              }
            )
            : message.error("Internal Server Error!")
        )
        .then(() => this.setState({ loading: false }));
    } else {
      this.setState(
        {
          btnLoad: false,
          dataWODate: [],
        }
      )
    }
  }

  fetchBlockNo = () => {
    fetchApi("/blocksuuid", "get").then(data =>
      this.setState({ blockNoList: sortFunction(data, "blockNoFull") })
    );
  };

  fetchAreaRowUuid = () => {
    var { block_uuid, treeNumber } = this.state;
    fetchApi(
      `/talls?block.uuid=${block_uuid}&treeNo=${treeNumber}`,
      "get"
    ).then(res =>
      this.setState(
        {
          area_uuid: res[0].areaUuid,
          // row_uuid: res[0].rowUuid,
          tree_uuid: res[0].uuid,
          loading: false
        }
        //,
        //() => console.log("Tall Data", res)
      )
    );
  };

  fetchFormTreeList = () => {
    let { blockNoList, blockNumber, block_uuid } = this.state;
    //`/talls?block.uuid=${block_uuid}&properties[]=uuid&properties[]=treeNoFull&stageCode[]=2&stageCode[]=3`,
    fetchApi(
      `/talls?block.uuid=${block_uuid}&stageCode[]=2&stageCode[]=3`,
      "get"
    ).then(res =>
      this.setState(
        {
          formTreeNoList: sortFunction(res, "treeNoFull"),
          treeNumber: null,
          tree_uuid: null,
          spatheNo: null
        }
        // () => this.dwarfTreeEmasculation()
      )
    );
  };

  clear = () => {
    this.setState(
      {
        //block_uuid: null,
        data: [],
        dynamicTableData: [],
        //blockNumber: null,
        area_uuid: null,
        areaNoList: [],
        areaNumber: null,
        tree_uuid: null,
        treeNoList: [],
        treeNumber: null,
        formTreeNoList: [],
        pagination: true,
        dateOfHarvest: null,
        addModal: false,
        editData: null,
        btnLoad: false,
        isEdit: false,
        isDelete: false,
        loading: false
      },
      () => {
        this.tallHarvests()
        this.tallHarvestswithoutDate()
      }
    );
  };

  addModalToggle = () => {
    message.destroy();
    let {
      loading,
      isAdded,
      dateOfHarvest,
      treeNumber,
      tree_uuid,
      blockNumber,
      block_uuid,
      areaNumber,
      area_uuid,
      isEdit,
      addModal,
      isDelete,
      dataWODate,
      formTreeNoList
    } = this.state;
    if (!isEdit && !isAdded && !isDelete && loading) {
      message.info("Loading Data Please wait");
    } else {
      if (addModal) {
        this.setState(
          {
            addModal: false,
            editData: null,
            addPopulateData: {
              blockNo: !isEdit || !isAdded || !isDelete ? blockNumber : null
            },
            blockNumber: isEdit || isAdded || isDelete ? blockNumber : null,
            block_uuid: isEdit || isAdded || isDelete ? block_uuid : null,
            areaNumber: isEdit || isAdded || isDelete ? areaNumber : null,
            area_uuid: isEdit || isAdded || isDelete ? area_uuid : null,
            treeNumber: isEdit || isAdded || isDelete ? treeNumber : null,
            tree_uuid: isEdit || isAdded || isDelete ? tree_uuid : null,
            dateOfHarvest: isEdit || isAdded || isDelete ? dateOfHarvest : null,
            isEdit: false,
            isDelete: false,
            isAdded: false
          },
          () => {
            this.setState({ isAdded: false });
          }
          //() => this.tallHarvests()
        );
      } else {
        if (!blockNumber || !dateOfHarvest) {
          message.info("Select Block and Harvest Date");
        } else {
          if (dateOfHarvest || treeNumber) {
            this.setState(
              {
                editData: Object.assign(
                  {},
                  dateOfHarvest ? { harvestDt: dateOfHarvest } : {},
                  //treeNumber ? { tree: treeNumber } : { tree: null },
                  cloneDeep(treeNumber) ? { tree: cloneDeep(treeNumber) } : {},
                  cloneDeep(this.state.editData)
                )
              },
              //() => this.setState({ addModal: !this.state.addModal })
              () => {
                this.state.addModal == true ?
                  this.setState({ addModal: false })
                  : dataWODate.length == 0 && cloneDeep(formTreeNoList) && formTreeNoList.length > 0 && cloneDeep(formTreeNoList).filter(item => item.treeNoFull == treeNumber).length > 0 && cloneDeep(formTreeNoList).filter(item => item.treeNoFull == treeNumber)[0].stageCode == 2 ?
                    this.setState({ visibleConfirm: true })
                    : this.setState({ addModal: true })
              }
            );
          }
        }
      }
    }
  };

  yesConfirm = () => {
    if (this.state.addModal == false)
      this.setState({
        addModal: true,
        visibleConfirm: false,
        btnLoad: false
      })
    else
      this.setState({
        visibleConfirm: false,
        btnLoad: false
      })
  }
  closeConfirm = () => {
    let {
      isEdit,
      isAdded,
      isDelete,
      block_uuid,
      areaNumber,
      area_uuid,
      treeNumber,
      tree_uuid,
      dateOfHarvest
    } = this.state;
    this.setState({
      visibleConfirm: false,
      btnLoad: false,
      addModal: false,
      editData: null,
      blockNumber: isEdit || isAdded || isDelete ? isDelete : null,
      block_uuid: isEdit || isAdded || isDelete ? block_uuid : null,
      areaNumber: isEdit || isAdded || isDelete ? areaNumber : null,
      area_uuid: isEdit || isAdded || isDelete ? area_uuid : null,
      treeNumber: isEdit || isAdded || isDelete ? treeNumber : null,
      tree_uuid: isEdit || isAdded || isDelete ? tree_uuid : null,
      dateOfHarvest: isEdit || isDelete ? dateOfHarvest : null,
      isEdit: false,
      isDelete: false,
      isAdded: false
    })
  }

  clearFields = () => {
    let { blockNumber,TreeTypeCode } = this.state;
    this.setState({
      areaNumber: null,
      treeNumber: null,
      dateOfHarvest: null,
      addPopulateData: {
        blockNo: blockNumber ? blockNumber : null,
        //harvestDt: null,
        tree: null,
        bunchQty: null,
        harvestedNuts: null,
        TreeTypeCode:null
      }
    });
  };

  addFormHandler = ({ tree,bunchQty,harvestedNuts,harvestDt, ...values }) => {
    notification.destroy();
    message.destroy();
    let {
      loading,
      blockNoList,
      areaNoList,
      treeNoList,
      tree_uuid,
      blockNumber,
      areaNumber,
      area_uuid,
      TreeTypeCode,
      addModal,
      confirmedId,
      TreeTypeId,
      recordId,
      storageId,
      isEdit,
      isDelete,
      editData
    } = this.state;
    if (loading) {
      message.info("Loading Data Please wait");
    } else {
      this.setState({ btnLoad: true });
      if (isDelete) {
        fetchApi(`/tall_harvests/${editData.uuid}`, "put", {
          dtDeleted: moment().format("YYYY-MM-DD")
        }).then(res => {
          if (res) {
            if (res.violations) {
              notification.error({
                message: "Failed",
                description: res.violations[0].message
              });
            } else {
              notification.success({
                message: "Success",
                description: "Successfully Deleted Tall Harvest"
              });
              this.tallHarvests();
              this.tallHarvestswithoutDate()
              this.addModalToggle()
            }
          } else {
            notification.error({
              message: "Failed",
              description: "Failed to Delete Tall Harvest"
            });
          }
          this.setState({ btnLoad: false }
            //, () => this.addModalToggle()
          );
        });
      } else if (isEdit) {
        fetchApi(`/tall_harvests/${editData.uuid}`, "put", {
          harvestedNuts: parseInt(harvestedNuts),
          bunchQty: parseInt(bunchQty),
          block: "/blocks/" + editData.blockUuid,
          area: "/areas/" + editData.areaUuid,
          tree: "/talls/" + editData.treeUuid,
          harvestDt: moment(harvestDt._d).format("YYYY-MM-DD"),
          treeTypeCode:editData.TreeTypeCode,
        }).then(res => {
          if (res) {
            if (res.violations) {
              notification.error({
                message: "Failed",
                description: res.violations[0].message
              });
            } else {
              notification.success({
                message: "Success",
                description: "Successfully Updated Tall Tree Harvest"
              });
              this.tallHarvests();
              this.tallHarvestswithoutDate()
              this.addModalToggle()
            }
          } else {
            notification.error({
              message: "Failed",
              description: "Failed to Update Tall Tree Harvest"
            });
          }
          this.setState({ btnLoad: false }
            //, () => this.addModalToggle()
          );
        });
      } else {
        fetchApi("/tall_harvests", "post", {
          harvestedNuts: parseInt(harvestedNuts),
          bunchQty: parseInt(bunchQty),
          block:
            "/blocks/" +
            filter(blockNoList, { blockNoFull: blockNumber })[0].uuid,
          area: "/areas/" + area_uuid,
          tree: "/talls/" + tree_uuid,
          treeTypeCode:TreeTypeCode,
          treeTypeId: confirmedId && confirmedId == true ? Number(recordId):confirmedId == false ?Number(storageId):TreeTypeId,
          ...values,
          harvestDt: moment(harvestDt).format("YYYY-MM-DD")
        }).then(res => {
          if (res) {
            if (res.violations) {
              notification.error({
                message: "Failed",
                description: res.violations[0].message
              });
             
            } else {
              notification.success({
                message: "Success",
                description: "Successfully added Tall Tree Harvest"
              });
             
              this.setState({ isAdded: true, dateOfHarvest: null }, () => {
                this.tallHarvests()
               this.tallHarvestswithoutDate()
              }
              );
              this.clearFields();
            }
          } else {
            notification.error({
              message: "Failed",
              description: "Failed to add Tall Tree Harvest"
            });
          }
          //this.setState({ btnLoad: false, addModalToggle: null });
          this.setState({
            btnLoad: false,
            addPopulateData: null,
            addPopulateData: {
              blockNo: blockNumber ? blockNumber : null,
            }
          });
        });
      }
    }
  };

  onBlockChangeHandler = it => {
    let { blockNoList } = this.state;
    if (it) {
      fetchApi(
        `/areasuuid?block.uuid=${
        cloneDeep(blockNoList).filter(item => item.blockNoFull == it)[0].uuid
        }`,
        "get"
      ).then(res =>
        this.setState(
          {
            blockNumber: it ? it : null,
            block_uuid: it
              ? cloneDeep(blockNoList).filter(item => item.blockNoFull == it)[0]
                .uuid
              : null,
            areaNoList: res,
            areaNumber: null,
            area_uuid: null,
            rowNoList: [],
            rowNumber: null,
            row_uuid: null,
            treeNumber: null,
            treeNoList: [],
            formTreeNoList: [],
            addPopulateData: {
              blockNo: it ? it : null
            }
          },
          () => {
            this.tallHarvests()
            this.tallHarvestswithoutDate()
          }
        )
      );
    } else {
      this.setState(
        {
          block_uuid: null,
          blockNumber: null,
          areaNoList: [],
          areaNumber: null,
          area_uuid: null,
          rowNoList: [],
          rowNumber: null,
          row_uuid: null,
          treeNumber: null,
          treeNoList: [],
          formTreeNoList: [],
          tree_uuid: null,
          addPopulateData: {
            blockNo: null
          }
        },
        () => {
          this.tallHarvests()
          this.tallHarvestswithoutDate()
        }
      );
    }
  };

  onAreaChangeHandler = it => {
    let { areaNoList, blockNumber, blockNoList } = this.state;
    //&properties[]=uuid&properties[]=treeNoFull`,
    if (it) {
      fetchApi(
        `/talls?stageCode[]=2&stageCode[]=3&&area.uuid=${
        cloneDeep(areaNoList).filter(item => item.areaNo == it)[0].uuid
        }&block.uuid=${
        cloneDeep(blockNoList).filter(
          item => item.blockNoFull == blockNumber
        )[0].uuid
        } `,
        "get"
      ).then(res => {
        this.setState(
          {
            areaNumber: it,
            area_uuid: cloneDeep(areaNoList).filter(
              item => item.areaNo == it
            )[0].uuid,
            treeNoList: sortFunction(res, "treeNoFull"),
            treeNumber: null
          },
          () => {
            this.tallHarvests()
            this.tallHarvestswithoutDate()
          }
        );
      });
    } else {
      this.setState(
        {
          areaNumber: null,
          area_uuid: null,
          treeNumber: null,
          treeNoList: [],
          tree_uuid: null
        },
        () => {
          this.tallHarvests()
          this.tallHarvestswithoutDate()
        }
      );
    }
  };

  onTreeChangeHandler = it => {
    let { treeNoList } = this.state;
    if (it) {
      this.setState(
        {
          treeNumber: it,
          tree_uuid: cloneDeep(treeNoList).filter(
            item => item.treeNoFull == it
          )[0].uuid,
          areaNumber: cloneDeep(treeNoList).filter(
            item => item.treeNoFull == it
          )[0].areaNo,
        },
        () => {
          this.tallHarvests()
          this.tallHarvestswithoutDate()
        }
      );
    } else {
      this.setState(
        {
          treeNumber: null,
          tree_uuid: null
        },
        () => {
          this.tallHarvests()
          this.tallHarvestswithoutDate()
        }
      );
    }
  };


  tallAddTreeTypeHandler =(it)=>{
    let {treeTypeData,changedtreeUid,isEdit,uuid,TreeTypeId,editData,TreeTypeCode,data,treeTypeUuid,
      isConfirm,
      storageId,
      storageCode,
      recordId,
      recordCode,
      showModalAdd,...values} = this.state;

      let previousCode = localStorage.getItem('oldValueCode');
      let previousId = localStorage.getItem('oldValueId');
      console.log(previousCode,previousId);

       this.setState({storageId:previousId,storageCode:previousCode},function(){console.log(this.state.storageId,this.state.storageCode)});
     
         if(it){
           console.log(it);
          const filtered = treeTypeData.find(el => {return (el.code === it)});
          let treeId = filtered.id;
          let treeCode = filtered.code;
          console.log(treeCode);
          this.setState({recordId:treeId},function(){console.log(this.state.recordId)});
          this.setState({recordCode:treeCode},function(){console.log(this.state.recordCode)});
          if(treeCode === previousCode){
            this.setState({showModalAdd:false});
          }else if(treeCode !== previousCode){
            this.setState({showModalAdd:true,addPopulateData:{
              TreeTypeCode:it
            }});
          }
    }

  }

  handleOk = ()=>{
    this.setState({showModalAdd:false});
  }

  handleCancelAdd = ()=>{
    let {TreeTypeId,storageId,storageCode,recordId,confirmedId,recordCode,isConfirm,editData,uuid,tree_uuid,area_uuid,row_uuid, blockNoList,
    blockNumber,showModalAdd,...values}= this.state;
    this.setState({confirmedId:true});
    this.setState({showModalAdd:false,isConfirm:true});
    this.setState({TreeTypeId:recordId});
  }

  handleCancelCloseAdd = ()=>{
    let {TreeTypeId,storageId,storageCode,showModalAdd,blockNoList,confirmedId, blockNumber,recordId,recordCode,tree_uuid,area_uuid, row_uuid, dtEmasculation,isConfirm,uuid,editData,TreeTypeCode,treeTypeData}= this.state;
    this.setState({confirmedId:false});
    this.setState({showModalAdd:false,isConfirm:false});
    this.setState({TreeTypeId:storageId,TreeTypeCode:storageCode});
    
    console.log(this.state.storageCode);
        this.setState({addPopulateData: {
          TreeTypeCode: storageCode
        },
       })
  }




  tallTreeTypeHandler = (it)=>{
    let {treeTypeData,isEdit,uuid,editData,data,treeTypeUuid,storageId,storageCode,recordId,showModal,
      recordCode,TreeTypeId,blockNo,harvestDt,treeNo,TreeTypeCode,harvestQty
      ,...values} = this.state;
      let treetypeIdentifier = localStorage.getItem('treeID');
      let treetypeCodeIdentifier = localStorage.getItem('treeCo');
        this.setState({storageId:treetypeIdentifier},function(){console.log(this.state.storageId)});
        this.setState({storageCode:treetypeCodeIdentifier},function(){console.log(this.state.storageCode)});
        console.log(treetypeIdentifier,treetypeCodeIdentifier);
         if(it){
          this.setState({
            addPopulateData: null
          })
          const filtered = treeTypeData.find(el => {return (el.code === it)});
          console.log(filtered);
          let treeId = filtered.id;
          let treeCode =filtered.code;
          let typeName = filtered.name; 
          //console.log(treeId);
         console.log(treeCode,typeName,treeId);
          console.log(typeName,treeCode);
          this.setState({recordId:treeId},function(){console.log(this.state.recordId)});
          this.setState({recordCode:treeCode},function(){console.log(this.state.recordCode)});
         //this.setState({recordId:treeId,recordCode:treeCode},function(){console.log(this.state.recordId,this.state.recordCode)});
          if(treeCode === treetypeCodeIdentifier){
            this.setState({showModal:false});
          }else if(treeCode !== treetypeCodeIdentifier){
            this.setState({showModal:true});
          }
    }
  }

  handleOk = ()=>{
    this.setState({showModal:false});
}

  handleCancel = ()=>{
    let {storageId,storageCode,recordId,recordCode,isConfirm,visible,editData,TreeTypeId,TreeTypeCode}= this.state;
    this.setState({showModal:false,isConfirm:true},function(){console.log(this.state.isConfirm)});
    this.setState({TreeTypeId:recordId,TreeTypeCode:recordCode},function(){console.log(this.state.treeTypeId)});
    fetchApi(`/tall_harvests/${editData.uuid}`,"put",{
      treeTypeId:Number(recordId),
         })
  }

  handleCancelClose = ()=>{
    let {storageId,storageCode,recordId,recordCode,isConfirm,visible,editData,TreetTypeCode,TreeTypeId,isEdit}= this.state;
    this.setState({showModal:false,isConfirm:false});
    this.setState({TreeTypeId:storageId},function(){console.log(this.state.TreeTypeId)});
        fetchApi(`/tall_harvests/${editData.uuid}`,"put",{
          treeTypeId:Number(storageId),
        })
        this.setState({addPopulateData:{
          TreeTypeCode:storageCode
        },
       })
  }

  onFormTreeChangeHandler = it => {
    let { formTreeNoList,TreeTypeCode,TreeTypeId } = this.state;
    if (it) {
      let selectedRecord = formTreeNoList.find(el => {return (el.treeNoFull === it)});
      console.log(selectedRecord);
      let selectedTreeType = selectedRecord.treeType;
      let selectedTreeId = selectedRecord.treeTypeId;
      console.log(selectedTreeType);
      console.log(selectedTreeId);

      this.setState(
        {
          btnLoad: true,
          treeNumber: it,
          TreeTypeCode:selectedTreeType,
          TreeTypeId:selectedTreeId,
          tree_uuid: cloneDeep(formTreeNoList).filter(
            item => item.treeNoFull == it
          )[0].uuid,
          areaNumber: cloneDeep(formTreeNoList).filter(
            item => item.treeNoFull == it
          )[0].areaNo,
          area_uuid: cloneDeep(formTreeNoList).filter(item => item.treeNoFull == it)[0].areaUuid,
          addPopulateData: {
            tree: it ? it : null,
            TreeTypeCode:selectedTreeType
          }
        },
        () => {
          this.tallHarvests()
          this.tallHarvestswithoutDate();
          localStorage.setItem('oldValueCode',this.state.TreeTypeCode);
          let test1 = localStorage.getItem('oldValueCode');
          localStorage.setItem('oldValueId',this.state.TreeTypeId);
          let test2 = localStorage.getItem('oldValueId');
          console.log(test1,test2);
        }
      );
    } else {
      this.setState(
        {
          treeNumber: null,
          tree_uuid: null,
          addPopulateData: {
            tree: it ? it : null
          }
        },
        () => {
          this.tallHarvests()
          this.tallHarvestswithoutDate();
        }
      );
    }
    // let { formTreeNoList } = this.state;
    // if (it) {
    //   this.setState({ loading: true });
    //   //this.setState({ treeNumber: it }, () => this.dwarfTreeEmasculation());
    //   fetchApi(
    //     `/talls/${
    //     cloneDeep(formTreeNoList).filter(item => item.treeNoFull == it)[0]
    //       .uuid
    //     }?groups[]=getNextSpatheNo`,
    //     "get"
    //   ).then(res =>
    //     this.setState(
    //       {
    //         treeNumber: it,
    //         tree_uuid: cloneDeep(formTreeNoList).filter(
    //           item => item.treeNoFull == it
    //         )[0].uuid,
    //         spatheNo: res.nextSpatheNo,
    //         areaNumber: cloneDeep(formTreeNoList).filter(
    //           item => item.treeNoFull == it
    //         )[0].areaNo
    //         //loading: false
    //       },
    //       () => this.fetchAreaRowUuid()
    //       //,
    //       // () => {
    //       //   this.setState(
    //       //     {
    //       //       addPopulateData: {
    //       //         spatheNo: cloneDeep(this.state.spatheNo)
    //       //       }
    //       //     },
    //       //     () => this.fetchAreaRowUuid()
    //       //   );
    //       // }
    //     )
    //   );
    // } else {
    //   this.setState(
    //     {
    //       treeNumber: null,
    //       tree_uuid: null,
    //       spatheNo: null,
    //       area_uuid: null,
    //       row_uuid: null
    //     }
    //     // () => {
    //     //   this.setState({
    //     //     //   addPopulateData: {
    //     //     //     spatheNo: null
    //     //     //   }

    //     //   });
    //     // }
    //   );
    // }
  };

  dateOfHarvestHandler = (date, dateString) => {
    let { blockNumber, treeNumber } = this.state;
    if (date) {
      this.setState(
        { dateOfHarvest: date },
        () => this.tallHarvests()
        //() => this.tableFilterHandler()
      );
    } else {
      this.setState(
        { dateOfHarvest: null },
        () => this.tallHarvests()
        //() => this.tableFilterHandler()
      );
    }
  };

  tableFilterHandler = () => {
    let {
      //dateOfHarvest,
      data
      // blockNumber,
      // areaNumber,
      // treeNumber
    } = this.state;
    //filterJson = {};
    // if (dateOfHarvest)
    //   filterJson.harvestDt = dateOfHarvest.format("DD/MM/YYYY");
    // if (blockNumber) filterJson.blockNo = blockNumber;
    // if (areaNumber) filterJson.areaNo = areaNumber;
    // if (treeNumber) filterJson.treeNo = parseInt(treeNumber);
    //console.log(dateOfHarvest, filterJson);
    this.setState({
      //dynamicTableData: filter(data, filterJson)
      dynamicTableData: data
    });
  };

  editHandler = ({ harvestDt, date,TreeTypeCode,TreeTypeId, treeNo, ...it }) => {

  let {treeTypeCode,treeTypeId} = this.state;
   
    this.setState(
      {
        addPopulateData:null,
        isEdit: true,
        editData: {
          harvestDt: moment(date, "YYYY-MM-DD"),
          tree: treeNo,
          TreeTypeCode,
          TreeTypeId,
          ...it
        },
      },
      () => {this.setState({ addModal: true })

      console.log(it);

    }
    );

    this.setState({TreeTypeCode:TreeTypeCode},()=>{console.log(this.state.TreeTypeCode)});
    this.setState({TreeTypeId:TreeTypeId},()=>{console.log(this.state.TreeTypeId)});

    localStorage.setItem('treeID',TreeTypeId);
    localStorage.setItem('treeCo',TreeTypeCode);
   
  };

  deleteHandler = ({ harvestDt, date, treeNo, ...it }) => {
    this.setState(
      {
        isDelete: true,
        editData: {
          harvestDt: moment(date, "YYYY-MM-DD"),
          tree:treeNo,
          ...it
        }
      },
      () => {this.setState({ addModal: true })
      console.log(treeNo);
    }
    );
  };

  render() {
    let {
      blockNoList,
      blockNumber,
      areaNoList,
      treeNoList,
      areaNumber,
      dateOfHarvest,
      dynamicTableData,
      pagination,
      addModal,
      editData,
      btnLoad,
      isEdit,
      isDelete,
      treeNumber,
      loading,
      formTreeNoList,
      data,
      dataWODate,
      addPopulateData,
      treeTypeData
    } = this.state;
    const columns = [
      {
        title: "Harvest Date",
        key: "harvestDt",
        dataIndex: "harvestDt"
      },
      {
        title: "Tree No.",
        key: "treeNo",
        dataIndex: "treeNo"
      },
      {
        title: "Tree Types",
        key: "TreeTypeCode",
        dataIndex: "TreeTypeCode",
      },
      {
        title: "No. of Bunches Harvested",
        key: "bunchQty",
        dataIndex: "bunchQty"
      },
      {
        title: "Harvest Nuts",
        key: "harvestedNuts",
        dataIndex: "harvestedNuts"
      },
      {
        title: "Actions",
        key: "actions",
        className: "action",
        render: it => (
          <Fragment>
            <Tooltip placement="top" title="Edit">
              <Icon
                className="table-action-btn"
                onClick={() => this.editHandler(it)}
                type="edit"
              />
            </Tooltip>
            <Tooltip placement="top" title="Delete">
              <Icon
                type="delete"
                className="table-action-btn"
                onClick={() => this.deleteHandler(it)}
              />
            </Tooltip>
          </Fragment>
        )
      }
    ];
    const buttonList =
      dynamicTableData.length > 0
        ? [
          blockNumber || areaNumber || treeNumber || dateOfHarvest
            ? {
              label: "Clear",
              icon: "delete",
              onClickHandler: this.clear
            }
            : "",
          {
            label: "Add",
            icon: "folder-add",
            onClickHandler: this.addModalToggle
          },
          // {
          //   label: "Excel",
          //   icon: "file-text"
          // },
          // {
          //   label: "Print",
          //   icon: "diff",
          //   reference: this.componentRef
          // },
          {
            label: this.state.pagination ? "View All" : "View Less",
            icon: "container",
            onClickHandler: () => this.setState({ pagination: !pagination })
          }
        ]
        : [
          blockNumber || areaNumber || treeNumber || dateOfHarvest
            ? {
              label: "Clear",
              icon: "delete",
              onClickHandler: this.clear
            }
            : "",
          {
            label: "Add",
            icon: "folder-add",
            onClickHandler: this.addModalToggle
          }
        ];

    const formItems =
      !isEdit && !isDelete
        ? [
          {
            name: "blockNo",
            placeholder: "Block",
            disabled: true
          },
          {
            name: "harvestDt",
            placeholder: "Harvest Date",
            type: "date",
            message: "Input the date of harvest!"
          },
          {
            name: "tree",
            placeholder: "Tree No.",
            type: "alphanumeric",
            listData: cloneDeep(formTreeNoList).map(it => it.treeNoFull),
            readonly: treeNoList.length > 0 ? false : true,
            message: "Input the tree no.!",
            disabled: this.state.btnLoad,
            changeHandler: it => this.onFormTreeChangeHandler(it)
          },
          {
            name: "TreeTypeCode",
            placeholder: "Tree Type",
            message: "Input the Tree Type!",
            required: true,
            value: this.state.TreeTypeCode,
            listData: cloneDeep(treeTypeData).map(it => it.code),
            changeHandler: it=> this.tallAddTreeTypeHandler(it)
          },
          {
            name: "bunchQty",
            placeholder: "No. of Bunches Harvested",
            type: "number",
            message: "Input the number of Bunch Quantity!"
          },
          {
            name: "harvestedNuts",
            placeholder: "Harvest Nuts",
            type: "number",
            message: "Input the number of Harvested Nuts!"
          }
        ]
        : [
          {
            name: "blockNo",
            placeholder: "Block",
            disabled: true,
            required: false
          },
          {
            name: "harvestDt",
            placeholder: "Harvest Date",
            type: "date",
            disabled: isDelete ? true : false,
            message: "Input the date of harvest!"
          },
          {
            name: "tree",
            placeholder: "Tree No.",
            //type: "alphanumeric",
            type: "text",
            disabled: true,
            required: false
          },
          {
            name: "TreeTypeCode",
            placeholder: "Tree Type",
            message: "Input the Tree Type!",
            required: true,
            value: this.state.TreeTypeCode,
            listData: cloneDeep(treeTypeData).map(it => it.code),
            changeHandler: it=> this.tallTreeTypeHandler(it)
          },
          {
            name: "bunchQty",
            placeholder: "No. of Bunches Harvested",
            type: "number",
            disabled: isDelete ? true : false,
            message: "Input the number of Bunch Quantity!"
          },
          {
            name: "harvestedNuts",
            placeholder: "Harvest Nuts",
            type: "number",
            disabled: isDelete ? true : false,
            message: "Input the number of Harvested Nuts!"
          }
        ];
    return (
      <Fragment>
        <Modal
          className="page-builder-add-modal emasculation-modal"
          //className="admin-container-modal"
          width={400}
          footer={false}
          closable={false}
          destroyOnClose={true}
          title="Confirm"
          visible={this.state.visibleConfirm}
        //onOk={()=>this.yesConfirm()}
        //onCancel={()=>this.closeConfirm()}
        >
          {cloneDeep(formTreeNoList) && formTreeNoList.length > 0 && cloneDeep(formTreeNoList).filter(item => item.treeNoFull == treeNumber).length > 0 ? <p className="emasculation-p">{"Date Of Planting: " + moment(cloneDeep(formTreeNoList).filter(item => item.treeNoFull == treeNumber)[0].dtPlanted).format("DD/MM/YYYY")}</p> : null}
          {cloneDeep(formTreeNoList) && formTreeNoList.length > 0 && cloneDeep(formTreeNoList).filter(item => item.treeNoFull == treeNumber).length > 0 && cloneDeep(formTreeNoList).filter(item => item.treeNoFull == treeNumber)[0].stageCode == 2 ? <p>Plant will be transferred to Tree</p> : null}
          <p>Do you want to Proceed further?</p>
          <Row type="flex" justify="end" gutter={5}>
            <Col>
              <Button
                onClick={() => this.closeConfirm()} //loading={btnLoad}
              >
                CANCEL
                  </Button>
            </Col>
            <Col>
              <Button
                onClick={() => this.yesConfirm()}
              >
                OK
                  </Button>
            </Col>
          </Row>
        </Modal>
        <LocationPageBuilder
          title="Tall Tree Harvest"
          buttonList={buttonList}
          dropDownList={[
            {
              label: "Block",
              listItems: blockNoList.map(it => it.blockNoFull),
              value: blockNumber ? blockNumber : undefined,
              changeHandler: it => this.onBlockChangeHandler(it)
            },
            {
              label: "Area",
              listItems: cloneDeep(areaNoList).map(it => it.areaNo),
              disabled: blockNumber ? false : true,
              value: areaNumber ? areaNumber : undefined,
              changeHandler: it => this.onAreaChangeHandler(it)
            },
            {
              label: "Tree No.",
              listItems: cloneDeep(treeNoList).map(it => it.treeNoFull),
              disabled: areaNumber ? false : true,
              value: treeNumber ? treeNumber : undefined,
              changeHandler: it => this.onTreeChangeHandler(it)
            },
            {
              label: "Harvest Date",
              type: "date",
              //disabled: treeNumber ? false : true,
              value: dateOfHarvest ? dateOfHarvest : undefined,
              changeHandler: this.dateOfHarvestHandler
            }
          ]}
          addModal={addModal}
          addModalToggle={this.addModalToggle}
          addFormHandler={this.addFormHandler}
          addPopulateData={addPopulateData}
          editData={editData}
          btnLoad={btnLoad}
          isEdit={isEdit}
          isDelete={isDelete}
          formItems={formItems}
        // formItems={[
        //   {
        //     name: "blockNo",
        //     placeholder: "Block",
        //     //listData: cloneDeep(blockNoList).map(it => it.blockNo),
        //     disabled: true
        //   },
        //   {
        //     name: "harvestDt",
        //     placeholder: "Harvest Date",
        //     type: "date",
        //     disabled: isDelete ? true : false,
        //     message: "Input the date of harvest!"
        //   },
        //   {
        //     name: "tree",
        //     placeholder: "Tree No.",
        //     type: "alphanumeric",
        //     listData: cloneDeep(formTreeNoList).map(it => it.treeNoFull),
        //     disabled: isEdit ? true : false,
        //     readonly: treeNoList.length > 0 ? false : true,
        //     message: "Input the tree no.!",
        //     changeHandler: it => this.onFormTreeChangeHandler(it)
        //   },
        //   {
        //     name: "bunchQty",
        //     placeholder: "No. of Bunches Harvested",
        //     type: "number",
        //     disabled: isDelete ? true : false,
        //     message: "Input the number of Bunch Quantity!"
        //   },
        //   {
        //     name: "harvestedNuts",
        //     placeholder: "Harvest Nuts",
        //     type: "number",
        //     disabled: isDelete ? true : false,
        //     message: "Input the number of Harvested Nuts!"
        //   }
        // ]}
        >
          <div ref={this.componentRef}>
            <Spin spinning={loading} size="large">
              <Table
                key="tablekey"
                ref="table"
                rowKey="uuid"
                columns={columns}
                dataSource={dynamicTableData}
                pagination={!pagination ? pagination : { pageSize: 15 }}
                //loading={loading}
                locale={{
                  emptyText: <Empty />
                }}
              />
            </Spin>
          </div>
        </LocationPageBuilder>
        <Modal 
        title="Confirm"
        visible={this.state.showModal}
        className="confirmModal"
        onCancel={this.handleCancel}
        footer={[
          <Button key="submit" onClick={this.handleCancel}>
            Yes
          </Button>,
          <Button key="back" onClick={this.handleCancelClose}>
            No
          </Button>
        ]}
        >
          <div className="confirmationBody">Are you sure you want to change the Tree Type?</div>
        </Modal>

        <Modal 
        title="Confirm"
        visible={this.state.showModalAdd}
        className="confirmModal"
        onCancel={this.handleCancelAdd}
        footer={[
          <Button key="submit" onClick={this.handleCancelAdd}>
            Yes
          </Button>,
          <Button key="back" onClick={this.handleCancelCloseAdd}>
            No
          </Button>
        ]}
        >
          <div className="confirmationBody">Are you sure you want to change the Tree Type?</div>
        </Modal>
      </Fragment>
    );
  }
}

export default TallTree;
