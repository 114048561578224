import React, { Component, Fragment } from "react";
import ReactDOM from "react-dom";

import { Table, message, Spin, Statistic, Col, Descriptions } from "antd";

//Component import
import ReportLayout from "../../../../pageLayouts/Report";
import { fetchApi, fetchExcelApi } from "../../../../../services/api";
import MultiTable from "../MultiTableDataEntryStatus";
import Empty from "../../../../components/Custom/Empty";

//Utility
import { COOKIE, getCookie } from "../../../../../services/cookie";
import { sortFunction } from "../../../../../utility/sorter";
import { mapKeys, isEmpty, cloneDeep } from "lodash";
import moment from "moment";

import { parseData } from "../../parserFunctionsTransfer";

//mock
//import mock from "./mocData";
const layout = {
  xs: {
    span: 6
  },
  sm: {
    span: 6
  },
  lg: {
    span: 4
  },
  md: {
    span: 4
  }
};

class PitsAndGapsTransfer extends Component {
  componentRef = React.createRef();
  state = {
    data: [],
    loading: false,
    farmTotal: [],
    blockNoList: [],
    block_uuid: null,

    areaNoList: [],
    areaNumber: null,
    area_uuid: null,

    to_date: null,
    from_date: null,

    stage: null,
    stage_code: null,

    type: null,
    type_code: null
  };

  componentDidMount() {
    //this.fetchDataEntryTall();
    this.fetchBlockNo();
    //this.fetchReport();
  }

  fetchTransferList = () => {
    this.setState({ loading: true });
    let {
      block_uuid,
      area_uuid,
      to_date,
      from_date,
      stage_code,
      type_code
    } = this.state;
    let url = `/pit-gap-transfer-list?fromDate=${from_date}&toDate=${to_date}`;
    if (block_uuid) url += `&block.uuid=${block_uuid}`;
    if (area_uuid) url += `&area.uuid=${area_uuid}`;
    if (stage_code) url += `&stage_code=${stage_code}`;
    if (type_code) url += `&type=${type_code}`;

    if (to_date && from_date) {
      fetchApi(url, "get").then(res => {
        if (res)
          this.setState({
            data: res.data ? sortFunction(res.data, "block_no") : []
          });
        else message.error("Internal Server Error!");
        this.setState({ loading: false });
      });
    } else {
      this.setState({ loading: false });
    }
  };

  fetchBlockNo = () =>
    fetchApi("/blocksuuid", "get").then(data =>
      this.setState({ blockNoList: sortFunction(data, "blockNoFull") })
    );

  onBlockChangeHandler = it => {
    let { blockNoList } = this.state;
    if (it) {
      fetchApi(
        `/areasuuid?block.uuid=${
        cloneDeep(blockNoList).filter(item => item.blockNoFull == it)[0].uuid
        }`,
        "get"
      ).then(res => this.setState({ areaNoList: res }));
    }
    this.setState(
      {
        blockNumber: it ? it : null,
        block_uuid: it
          ? cloneDeep(blockNoList).filter(item => item.blockNoFull == it)[0]
            .uuid
          : null,
        areaNumber: null,
        area_uuid: null,
        areaNoList: []
      },
      () => this.fetchTransferList()
    );
  };

  onAreaChangeHandler = it => {
    let { areaNoList, blockNoList, blockNumber } = this.state;
    this.setState(
      {
        areaNumber: it ? it : null,
        area_uuid: it
          ? cloneDeep(areaNoList).filter(item => item.areaNo == it)[0].uuid
          : null
      },
      () => this.fetchTransferList()
    );
  };

  // fromDateHandler = date => {
  //   if (date) {
  //     this.setState(
  //       {
  //         from_date: date.format("YYYY-MM-DD")
  //       },
  //       () => this.fetchTransferList()
  //     );
  //   } else {
  //     this.setState({
  //       from_date: null,
  //       blockNumber: null,
  //       block_uuid: null,

  //       data: []
  //     });
  //   }
  // };

  // toDateHandler = date => {
  //   if (date) {
  //     this.setState(
  //       {
  //         to_date: date.format("YYYY-MM-DD")
  //       },
  //       () => this.fetchTransferList()
  //     );
  //   } else {
  //     this.setState({
  //       to_date: null,
  //       blockNumber: null,
  //       block_uuid: null,
  //       data: [],
  //       farm_count_auto: null,
  //       farm_count_manual: null
  //     });
  //   }
  // };

  onRangeChangeHandler = date => {
    if (date.length > 0) {
      this.setState(
        {
          from_date: date[0].format("YYYY-MM-DD"),
          to_date: date[1].format("YYYY-MM-DD")
        },
        () => this.fetchTransferList()
      );
    } else {
      this.setState(
        {
          from_date: null,
          to_date: null,
          blockNumber: null,
          block_uuid: null,
          data: [],
          farm_count_auto: null,
          farm_count_manual: null
        },
        () => this.fetchTransferList()
      );
    }
  };

  onTreeTypeChangeHandler = it => {
    var type, type_code;
    if (it) {
      if (it === "Dwarf") {
        type_code = 1;
      } else {
        type_code = 2;
      }
      type = it;
      this.setState({ type: type, type_code: type_code }, () =>
        this.fetchTransferList()
      );
    } else {
      this.setState({ type: null, type_code: null }, () =>
        this.fetchTransferList()
      );
    }
  };

  onStageChangeHandler = it => {
    var stage, stage_code;
    if (it) {
      if (it === "Seedling") {
        stage_code = 1;
      } else if (it === "Plant") {
        stage_code = 2;
      } else {
        stage_code = 3;
      }
      stage = it;
      this.setState({ stage: stage, stage_code: stage_code }, () =>
        this.fetchTransferList()
      );
    } else {
      this.setState({ stage: null, stage_code: null }, () =>
        this.fetchTransferList()
      );
    }
  };

  generateTables = (tableData, columns) => {
    var { data, from_date, to_date } = this.state;
    console.log("tableData", tableData.length);
    if (data.length == 0) {
      return <Empty description={"No Data Found"} />;
    } else
      return (
        <div>
          <h3>
            Pits/Gaps - Transfer
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Farm
    : {getCookie(COOKIE.FARM).charAt(0).toUpperCase() + getCookie(COOKIE.FARM).slice(1)}{" "} </h3>
          <h3>Transfer Period : {" " + moment(from_date).format("DD/MM/YYYY")} - {moment(to_date).format("DD/MM/YYYY")}</h3>
          {tableData.map(({ areas, block_no, total }, index) => (
            <div key={index}>
              {areas &&
                areas.map(({ total, area_no, trees }, j) => (
                  <div key={j} className={index == 0 && j == 0 ? "dwarf-list-report" : "pagebreak dwarf-list-report"}>
                    {
                      <div>
                        <Col key={1} {...layout}>
                          <Statistic title="Block No." value={block_no} />
                        </Col>
                        <Col key={2} {...layout}>
                          <Statistic title="Area No." value={area_no} />
                        </Col>
                        <br></br>
                        <br></br>
                        <br></br>
                        <Table
                          //className="block-wise-summary-dwarf-table"
                          //key={j}
                          bordered
                          className="bordered-table-head"
                          columns={columns}
                          dataSource={parseData(trees)}
                          size="small"
                          pagination={false}
                        />
                        <br></br>
                      </div>
                    }
                    <Descriptions bordered>
                      <Descriptions.Item label="Area Wise Total" span={3}>
                        {total}
                      </Descriptions.Item>
                      <Descriptions.Item span={6}></Descriptions.Item>
                    </Descriptions>
                    <br />
                  </div>
                ))}
              <Descriptions bordered>
                <Descriptions.Item label="Block Wise Total" span={3}>
                  {total}
                </Descriptions.Item>
              </Descriptions>
              <br></br>
              <br></br>
            </div>
          ))}
        </div>
      );
  };

  generateExcel = () => {
    let {
      block_uuid,
      area_uuid,
      to_date,
      from_date,
      stage_code,
      type_code
    } = this.state;
    let url = `/pit-gap-transfer-list?fromDate=${from_date}&toDate=${to_date}`;
    if (block_uuid) url += `&block.uuid=${block_uuid}`;
    if (area_uuid) url += `&area.uuid=${area_uuid}`;
    if (stage_code) url += `&stage_code=${stage_code}`;
    if (type_code) url += `&type=${type_code}`;

    if (to_date && from_date) {
      fetchExcelApi(url, "get").then();
    }
  };

  render() {
    let {
      data,
      loading,
      farmTotal,
      blockNoList,
      blockNumber,
      areaNoList,
      areaNumber,
      stage,
      type,
      to_date,
      from_date
    } = this.state;

    const columns = [
      {
        title: "Pit/Gap No.",
        dataIndex: "pit_gap_no_full"
      },
      {
        title: "Tree Type",
        dataIndex: "type"
      },
      {
        title: "Type",
        dataIndex: "code"
      },
      {
        title: "Stage",
        dataIndex: "stage_code"
      },
      {
        title: "Transfer Date",
        dataIndex: "dt_planted",
        render: it => (it ? moment(it).format("DD/MM/YYYY") : "-")
      }
    ];
    const buttonList =
      data.length > 0
        ? [
          {
            label: "Excel",
            icon: "file-text",
            onClickHandler: () => this.generateExcel()
          },
          {
            label: "Print",
            icon: "diff",
            reference: this.componentRef
          }
        ]
        : null;
    let JSX = data ? this.generateTables(data, columns) : null;
    return (
      <ReportLayout
        title="Pits/Gaps - Transfer"
        buttonList={buttonList}
        dropDownList={[
          // {
          //   label: "From Date",
          //   type: "date",
          //   hasInitial: false,
          //   changeHandler: this.fromDateHandler
          // },
          // {
          //   label: "To Date",
          //   type: "date",
          //   hasInitial: false,
          //   changeHandler: this.toDateHandler
          // },
          {
            label: "Block",
            listItems: cloneDeep(blockNoList).map(it => it.blockNoFull),
            disabled: to_date && from_date ? false : true,
            changeHandler: it => this.onBlockChangeHandler(it),
            className: "width-type-pits-gaps"
          },
          {
            label: "Area",
            listItems: cloneDeep(areaNoList).map(it => it.areaNo),
            disabled: blockNumber ? false : true,
            value: areaNumber ? areaNumber : undefined,
            changeHandler: it => this.onAreaChangeHandler(it),
            className: "width-type-pits-gaps"
          },
          {
            label: "Tree Type",
            listItems: ["Dwarf", "Tall"],
            // disabled: areaNumber ? false : true,
            value: type ? type : undefined,
            changeHandler: it => this.onTreeTypeChangeHandler(it),
            className: "width-type-pits-gaps"
          },
          {
            label: "Stages",
            listItems: ["Seedling", "Plant", "Tree"],
            // disabled: areaNumber ? false : true,
            value: stage ? stage : undefined,
            changeHandler: it => this.onStageChangeHandler(it),
            className: "width-type-pits-gaps"
          },
          {
            label: "Transfer Period:",
            type: "range",
            //mode: ["date", "date"],
            format: "DD/MM/YYYY",
            changeHandler: (date, string) => this.onRangeChangeHandler(date)
          }
        ]}
      >
        <div ref={this.componentRef}>
          <Spin spinning={loading} size="large" active paragraph={{ rows: 15 }}>
            {data.length > 0 ? (
              <div>{JSX}</div>
            ) : from_date && to_date ? (
              <Empty description="No Data Found" />
            ) : (
                  <Empty
                    description={"Select Transfer From and Transfer To date"}
                  />
                )}
          </Spin>
        </div>
      </ReportLayout>
    );
  }
}

export default PitsAndGapsTransfer;
