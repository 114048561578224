import React, { Component, Fragment } from "react";
import ReactDOM from "react-dom";

import {
  Table,
  message,
  Skeleton,
  Statistic,
  Spin,
  Col,
  Descriptions,
  Button,
  Icon,
  Row
} from "antd";

//Component import
import ReportLayout from "../../../../pageLayouts/Report";
import { fetchApi, fetchExcelApi } from "../../../../../services/api";
import MultiTable from "../MultiTable";
import Empty from "../../../../components/Custom/Empty";

//Utility
import { sortFunction } from "../../../../../utility/sorter";
import { cloneDeep, filter } from "lodash";
import { parseData } from "../parseData";

class DwarfTreeHarvestsList extends Component {
  componentRef = React.createRef();
  state = {
    data: null,
    loading: false,
    //farmTotal: [],

    blockNoList: [],
    block_uuid: null,
    block_no: null,
    areaNumber: null,
    area_uuid: null,
    areaNoList: [],
    treeNumber: null,
    tree_uuid: null,
    treeNoList: [],

    fromDate: null,
    toDate: null
  };

  componentDidMount() {
    this.fetchBlockNo();
    this.fetchDwarfHarvestsList();
  }

  fetchDwarfHarvestsList = () => {
    this.setState({ loading: true });
    let { fromDate, toDate, block_uuid, area_uuid, tree_uuid } = this.state;

    if (fromDate && toDate) {
      let url = `/dwarf-harvests-list?fromDate=${fromDate}&toDate=${toDate}`;
      if (block_uuid) url += `&block.uuid=${block_uuid}`;
      if (area_uuid) url += `&area.uuid=${area_uuid}`;
      if (tree_uuid) url += `&tree.uuid=${tree_uuid}`;
      fetchApi(url, "get").then(res => {
        if (res) {
          parseData(res);
          this.setState(
            {
              //data: res
              data: parseData(res)
            }
            // ,
            // () => console.log("The Data is", this.state.data)
          );
        } else message.error("Internal Server Error!");
        this.setState({ loading: false });
      });
    } else {
      this.setState({ loading: false, data: null });
    }
  };

  fetchBlockNo = () =>
    fetchApi("/blocksuuid", "get").then(data =>
      this.setState({ blockNoList: sortFunction(data, "blockNoFull") })
    );

  onBlockChangeHandler = it => {
    let { blockNoList, harvest_dt } = this.state;
    if (it) {
      fetchApi(
        `/areasuuid?block.uuid=${
          cloneDeep(blockNoList).filter(item => item.blockNoFull == it)[0].uuid
        }`,
        "get"
      ).then(res => this.setState({ areaNoList: res }));
    }
    this.setState(
      {
        blockNumber: it ? it : null,
        block_uuid: it
          ? cloneDeep(blockNoList).filter(item => item.blockNoFull == it)[0]
              .uuid
          : null,
        areaNumber: null,
        area_uuid: null,
        areaNoList: []
      },
      () => this.fetchDwarfHarvestsList()
    );
  };

  onAreaChangeHandler = it => {
    let { areaNoList, blockNoList, blockNumber } = this.state;
    if (it) {
      fetchApi(
        `/dwarves?area.uuid=${
          cloneDeep(areaNoList).filter(item => item.areaNo == it)[0].uuid
        }&block.uuid=${
          cloneDeep(blockNoList).filter(
            item => item.blockNoFull == blockNumber
          )[0].uuid
        }&properties[]=uuid&properties[]=treeNoFull`,
        "get"
      ).then(res =>
        this.setState(
          {
            areaNumber: it,
            area_uuid: cloneDeep(areaNoList).filter(
              item => item.areaNo == it
            )[0].uuid,
            treeNoList: sortFunction(res, "treeNoFull"),
            treeNumber: null,
            tree_uuid: null
          },
          () => this.fetchDwarfHarvestsList()
        )
      );
    } else {
      this.setState(
        {
          areaNumber: null,
          area_uuid: null,
          treeNoList: [],
          treeNumber: null,
          tree_uuid: null
        },
        () => this.fetchDwarfHarvestsList()
      );
    }
  };

  onTreeChangeHandler = it => {
    if (it) {
      var { data, treeNoList } = this.state;
      this.setState(
        {
          treeNumber: it,
          tree_uuid: cloneDeep(treeNoList).filter(
            item => item.treeNoFull == it
          )[0].uuid
        },
        () => this.fetchDwarfHarvestsList()
      );
    } else {
      this.setState(
        {
          treeNumber: null,
          tree_uuid: null
        },
        () => this.fetchDwarfHarvestsList()
      );
    }
  };

  onRangeChangeHandler = date => {
    if (date.length > 0) {
      this.setState(
        {
          fromDate: date[0].format("YYYY-MM-DD"),
          toDate: date[1].format("YYYY-MM-DD")
        },
        () => this.fetchDwarfHarvestsList()
      );
    } else {
      this.setState(
        {
          fromDate: null,
          toDate: null
        },
        () => this.fetchDwarfHarvestsList()
      );
    }
  };

  render() {
    let {
      data,
      blockNoList,
      blockNumber,
      loading,
      areaNoList,
      areaNumber,
      treeNoList,
      treeNumber,
      toDate,
      fromDate
    } = this.state;
    {
      const buttonList =
        data && data.blocks
          ? [
              {
                label: "Print",
                icon: "diff",
                reference: this.componentRef
              }
            ]
          : null;
      const columns = [
        {
          title: "Tree No.",
          dataIndex: "tree_no_full"
        },
        {
          title: "Nuts Qty.",
          dataIndex: "harvest_qty",
          render: it => (it ? it : "0")
        }
      ];
      const dropDownList = [
        {
          label: "Block",
          listItems: blockNoList.map(it => it.blockNoFull),
          value: blockNumber ? blockNumber : undefined,
          changeHandler: it => this.onBlockChangeHandler(it)
        },
        {
          label: "Area",
          listItems: cloneDeep(areaNoList).map(it => it.areaNo),
          disabled: blockNumber ? false : true,
          value: areaNumber ? areaNumber : undefined,
          changeHandler: it => this.onAreaChangeHandler(it)
        },
        {
          label: "Tree No.",
          listItems: cloneDeep(treeNoList).map(it => it.treeNoFull),
          disabled: areaNumber ? false : true,
          value: treeNumber ? treeNumber : undefined,
          changeHandler: it => this.onTreeChangeHandler(it)
        },
        {
          label: "Harvest Date:",
          type: "range",
          //mode: ["date", "date"],
          format: "DD/MM/YYYY",

          //mode: ["DD/MM/YYYY", "DD/MM/YY"],
          changeHandler: (date, string) => this.onRangeChangeHandler(date)
        }
      ];
      const JSX =
        data && data.blocks ? (
          <MultiTable tableData={data} columns={columns} fromDate={fromDate} toDate={toDate} pgheading={"Dwarf Tree Harvest - List"} />
        ) : (
          <Empty description={"No Data Found"} />
        );
      return (
        <ReportLayout
          title="Dwarf Tree Harvest - List"
          buttonList={buttonList}
          dropDownList={dropDownList}
        >
          <div ref={this.componentRef}>
            <Spin spinning={loading} size="large">
              {data && data.blocks ? (
                <div>{JSX}</div>
              ) : toDate && fromDate ? (
                <Empty description="No Data Found" />
              ) : (
                <Empty description={"Select Harvest Date"} />
              )}
            </Spin>
          </div>
        </ReportLayout>
      );
    }
  }
}

export default DwarfTreeHarvestsList;
