import React from 'react'

//Style imports
import './index.less'

export default ()=> {
    return (
        <div className='header-container'>
            <span className='header-title'>
            Deejay Groups
            </span>
            <p className='header-sub-title'>
            Coconut Data Management System
            </p>
        </div>
    )
}
