import React, { Component, Fragment } from "react";
import ReactDOM from "react-dom";

import { Table, message, Spin, Statistic, Row, Col } from "antd";

//Component import
import ReportLayout from "../../../pageLayouts/Report";
import { fetchApi, fetchExcelApi } from "../../../../services/api";
import ScollBarView from "react-double-scrollbar";
import Empty from "../../../components/Custom/Empty";

//Utility
import { mapKeys, isEmpty, cloneDeep } from "lodash";
import { sortFunction } from "../../../../utility/sorter";
import { parserFunction } from "./parserFunctions";
import moment from "moment";
import columns from "./columns";

class MonthwiseFarmPerformanceReportPeriodGroup extends Component {
  componentRef = React.createRef();
  state = {
    data: [],
    coloums: [],
    loading: false,
    blockNoList: [],
    blockNumber: null,
    block_uuid: null,
    areaNoList: [],
    areaNumber: null,
    area_uuid: null,
    DwarfType: null,
    DwarfTypeList: [],
    DwarfType_uuid: null,
    isopen: false,
    typeOfGroup: null,
    type: "Blockwise",
    period: [],
    areaVisible: false,
    dwarfTypeVisible: false,
    groupType: 0
  };

  componentDidMount() {
    this.fetchBlockNo();
    this.fetchDwarfType();
  }

  fetchFarmPerformanceReportPeriodGroup = () => {
    let {
      block_uuid,
      area_uuid,
      DwarfType_uuid,
      groupType,
      period
    } = this.state;
    if (period.length > 0) {
      if (groupType != 0 && !block_uuid) {
        this.setState({ data: [] });
      } else {
        this.setState({ loading: true });
        fetchApi(
          `/regular-reports/monthwise-farm-performance-activities?from_date=${period[0]}&to_date=${period[1]}&block.uuid=${block_uuid}&group_type=${groupType}&area.uuid=${area_uuid}&tree_type.uuid=${DwarfType_uuid}`,
          "get"
        ).then(res => {
          if (res) {
            console.log("Result", res);
            this.setState({
              data: parserFunction(res)
            });
          } else message.error("Internal Server Error!");
          this.setState({ loading: false });
        });
      }
    } else {
      this.setState({
        data: []
      });
    }
  };

  fetchBlockNo = () =>
    fetchApi("/blocksuuid", "get").then(data =>
      this.setState({ blockNoList: sortFunction(data, "blockNoFull") })
    );
  fetchDwarfType = () =>
    fetchApi("/tree_types?type=1", "get").then(data =>
      this.setState({ DwarfTypeList: data })
    );

  //year functions
  handleOpenChange = status => {
    if (status) {
      this.setState({ isopen: true });
    } else {
      this.setState({ isopen: false });
    }
  };

  onDropDownChangeHandler = (type, item) => {
    let { blockNoList, areaNoList, DwarfTypeList } = this.state;
    switch (type) {
      case "block":
        if (item) {
          fetchApi(
            `/areasuuid?block.uuid=${
              cloneDeep(blockNoList).filter(it => it.blockNoFull == item)[0]
                .uuid
            }`,
            "get"
          ).then(res => this.setState({ areaNoList: res }));
          this.setState(
            {
              blockNumber: item ? item : null,
              block_uuid: item
                ? cloneDeep(this.state.blockNoList).filter(
                    each => each.blockNoFull === item
                  )[0].uuid
                : null
            },
            () => this.fetchFarmPerformanceReportPeriodGroup()
          );
        } else {
          this.setState(
            {
              blockNumber: null,
              block_uuid: null,
              areaNoList: [],
              areaNumber: null,
              area_uuid: null
            },
            () => this.fetchFarmPerformanceReportPeriodGroup()
          );
        }
        break;

      case "typeOfGroup":
        if (item) {
          this.setState({ typeOfGroup: item }, () => {
            if (this.state.typeOfGroup == "AREAWISE") {
              this.setState(
                {
                  type: "Areawise",
                  areaVisible: true,
                  dwarfTypeVisible: false,
                  groupType: 1,
                  DwarfType: null,
                  DwarfType_uuid: null
                },
                () => this.fetchFarmPerformanceReportPeriodGroup()
              );
            } else if (this.state.typeOfGroup == "TYPEWISE") {
              this.setState(
                {
                  type: "Typewise",
                  areaVisible: false,
                  dwarfTypeVisible: true,
                  groupType: 2,
                  areaNumber: null,
                  area_uuid: null
                },
                () => this.fetchFarmPerformanceReportPeriodGroup()
              );
            } else {
              this.setState(
                {
                  type: "Blockwise",
                  areaVisible: false,
                  dwarfTypeVisible: false,
                  groupType: 0,
                  areaNumber: null,
                  area_uuid: null,
                  DwarfType: null,
                  DwarfType_uuid: null
                },
                () => this.fetchFarmPerformanceReportPeriodGroup()
              );
            }
          });
        } else {
          this.setState(
            {
              blockNumber: null,
              block_uuid: null,
              areaNoList: [],
              areaNumber: null,
              area_uuid: null
            },
            () => this.fetchFarmPerformanceReportPeriodGroup()
          );
        }
        break;

      case "area":
        this.setState(
          {
            areaNumber: item ? item : null,
            area_uuid: item
              ? cloneDeep(areaNoList).filter(it => it.areaNo == item)[0].uuid
              : null
          },
          () => this.fetchFarmPerformanceReportPeriodGroup()
        );
        break;

      case "dwarfType":
        this.setState(
          {
            DwarfType: item ? item : null,
            DwarfType_uuid: item
              ? cloneDeep(DwarfTypeList).filter(it => it.code == item)[0].uuid
              : null
          },
          () => this.fetchFarmPerformanceReportPeriodGroup()
        );
        break;

      default:
        if (item.length > 0) {
          this.setState(
            {
              value: item,
              period: [
                moment(item[0]).format("YYYY-MM"),
                moment(item[1]).format("YYYY-MM")
              ],
              isopen: false
            },
            () => this.fetchFarmPerformanceReportPeriodGroup()
          );
        } else {
          this.setState(
            {
              value: null,
              period: [],
              isopen: false,
              data: []
            },
            () => this.fetchFarmPerformanceReportPeriodGroup()
          );
        }
    }
  };

  generateExcel = () => {
    let {
      block_uuid,
      area_uuid,
      DwarfType_uuid,
      groupType,
      period
    } = this.state;
    console.log("DwarfType_uuid",DwarfType_uuid);
    if (period.length > 0) {
      fetchExcelApi(
        `/regular-reports/monthwise-farm-performance-activities?from_date=${period[0]}&to_date=${period[1]}&block.uuid=${block_uuid}&group_type=${groupType}&area.uuid=${area_uuid}&tree_type.uuid=${DwarfType_uuid}`,
        "get"
      ).then();
    }
  };

  render() {
    let {
      data,
      loading,
      value,
      isopen,
      blockNoList,
      areaNoList,
      DwarfTypeList,
      areaVisible,
      dwarfTypeVisible,
      areaNumber,
      blockNumber,
      block_uuid,
      groupType,
      DwarfType,
      period,
      type
    } = this.state;
    let JSX =
      data.length > 0 ? (
        <Fragment>
          {/* <h3>BLOCKWISE WEEKLY EMASCULATION SUMMARY FOR        {data.month}</h3>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Statistic title="BLOCK NO." value={data.blocks[0].block_no} />
                                </Col>
                            </Row> */}
          <h3>
            {type +
              "/Monthwise Farm Performance Activities Report for the Period of " +
              moment(period[0]).format("MMM YYYY") +
              " - " +
              moment(period[1]).format("MMM YYYY")}
          </h3>

          <ScollBarView>
            <Table
              key="tree_code"
              //className="regular-reports-tables bordered-table-head"
              className="table-align-left-all regular-reports-tables bordered-table-head"
              bordered
              columns={columns}
              dataSource={data}
              bordered
              size="small"
              pagination={false}
            />
          </ScollBarView>
        </Fragment>
      ) : !block_uuid && groupType != 0 ? (
        <Empty description="Select Block No. And Period" />
      ) : period.length > 0 ? (
        <Empty description="No Data Found" />
      ) : (
        <Empty description={"Select Period"} />
      );

    const buttonList =
      data.length > 0
        ? [
            {
              label: "Excel",
              icon: "file-text",
              onClickHandler: () => this.generateExcel()
            },
            {
              label: "Print",
              icon: "diff",
              toolTip: "Suggestion: Print in Landscape",
              reference: this.componentRef
            }
          ]
        : null;

    return (
      <ReportLayout
        buttonList={buttonList}
        title="Monthwise Farm Performance Activities Report - for a Selected Period & Selected Group"
        // title={
        //   data && data.length > 0
        //     ? type +
        //       "/Monthwise Farm Performance Activities Report - for a Selected Period & Selected Group for the Period of " +
        //       moment(period[0]).format("MMM YYYY") +
        //       " - " +
        //       moment(period[1]).format("MMM YYYY")
        //     : "Monthwise Farm Performance Activities Report - for a Selected Period & Selected Group"
        // }
        dropDownList={[
          {
            label: "Block",
            listItems: cloneDeep(blockNoList).map(it => it.blockNoFull),
            value: blockNumber ? blockNumber : undefined,
            changeHandler: it => this.onDropDownChangeHandler("block", it)
          },
          {
            label: "Type of Group",
            listItems: ["BLOCKWISE", "AREAWISE", "TYPEWISE"],
            hasInitial: true,
            changeHandler: it => this.onDropDownChangeHandler("typeOfGroup", it)
          },
          {
            label: "Area",
            listItems: cloneDeep(areaNoList).map(it => it.areaNo),
            disabled: blockNumber ? false : true,
            value: areaNumber ? areaNumber : undefined,
            visible: areaVisible,
            changeHandler: it => this.onDropDownChangeHandler("area", it)
          },
          {
            label: "Dwarf Type",
            listItems: cloneDeep(DwarfTypeList).map(it => it.code),
            visible: dwarfTypeVisible,
            value: DwarfType ? DwarfType : undefined,
            changeHandler: it => this.onDropDownChangeHandler("dwarfType", it)
          },
          {
            label: "Period",
            mode: ["month", "month"],
            value: value,
            type: "range",
            format: "MM/YYYY",
            open: isopen,
            showTime: true,
            onOk: date => this.onDropDownChangeHandler("period", date),
            onOpenChange: this.handleOpenChange,
            onPanelChange: val => this.setState({ value: val }),
            changeHandler: () => this.onDropDownChangeHandler("period", [])
          }
        ]}
      >
        <div ref={this.componentRef}>
          <Spin
            spinning={loading}
            size="large"
            wrapperClassName="regular-reports-container"
          >
            {JSX}
          </Spin>
        </div>
      </ReportLayout>
    );
  }
}

export default MonthwiseFarmPerformanceReportPeriodGroup;
