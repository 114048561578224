
export const parserFunction = arr =>
  arr.map(({
    block_no,
    areas,
    ...rest
  }) => {

    let areasTemp = []

    if (areas)
      areas.forEach(({ area_no, weeks, trees, spathes, buttons }) => {
        let temp = { area_no, trees, spathes, buttons }
        if (weeks)
          Object.assign(temp, handelWeeks(weeks))
        areasTemp.push(temp)
      })

    let temp = {
      //area_no: block_no ? 'TOTAL' : 'FARM',
      area_no: block_no ? 'TOTAL' : 'TOTAL',
      ...handelWeeks(rest.weeks),
      buttons: rest.buttons,
      spathes: rest.spathes,
      trees: rest.trees
    }
    areasTemp.push(temp)

    return {
      block_no,
      areas: areasTemp
    }
  })



const handelWeeks = (arr) => {
  let result = {}
  arr.forEach(({ week, ...it }) => {
    if (week === 'week 1') {
      result.weekOneSpathes = it.spathes
      result.weekOneButtons = it.buttons
    }
    if (week === 'week 2') {
      result.weekTwoSpathes = it.spathes
      result.weekTwoButtons = it.buttons
    }
    if (week === 'week 3') {
      result.weekThreeSpathes = it.spathes
      result.weekThreeButtons = it.buttons
    }
    if (week === 'week 4') {
      result.weekFourSpathes = it.spathes
      result.weekFourButtons = it.buttons
    }
  })
  return result
}





