import React from "react";
import { Table, Col, Statistic, Row } from "antd";
import { isEmpty } from "lodash";
import moment from "moment";

const layout = {
  xs: {
    span: 6
  },
  sm: {
    span: 6
  },
  lg: {
    span: 4
  },
  md: {
    span: 4
  }
};

export default ({ dataSource, columns, period }) =>
  dataSource && (
    <div className="pagebreak">
      <h3>
        TALL TREE POPULATION SPREAD TYPEWISE BASED ON MONTHLY AVERAGE HARVESTED
        NUTS FOR THE PERIOD {moment(period[0]).format("MMMM YYYY")}
        {" - "}
        {moment(period[1]).format("MMMM YYYY")}
        {dataSource.month}
      </h3>
      <br />
      {dataSource.map(({ types, ...rest }, i) => (
        <div key={i}>
          <Row gutter={5}>
            {Object.keys(rest).map((it, index) => (
              <Col key={index} {...layout}>
                {rest[it] ? (
                  <Statistic
                    title={it.split("_").map(it => `${it.toUpperCase()} `)}
                    value={rest[it]}
                  />
                ) : (
                  <p>FARM TOTAL</p>
                )}
              </Col>
            ))}
          </Row>
          <br />
          <Table
            className="block-wise-summary-dwarf-table"
            columns={columns}
            dataSource={types}
            size="small"
            pagination={false}
          />
          <br />
        </div>
      ))}
    </div>
  );
