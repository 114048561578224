// import React, { Component } from 'react'

// //Componenet Import
// import { Row, Col, Button, Layout } from 'antd'
// import buttonList from './ButtonRenderer'

// import { xor } from 'lodash'

// import "./index.less"

// const { Content } = Layout

// const btnLayout = {
//     xs: 8,
//     sm: 16,
//     md: 12
// }

// class MasterPage extends Component {
//     constructor(props){
//         super(props)
//         let linkItem=null,temp=[]
//         if(buttonList){
//             buttonList.forEach(item=>{
//                 let filterItems = item.children.filter((each,index)=>{
//                     if(each.link===this.props.location.pathname){
//                         linkItem=index

//                     }

//                     return each.link.includes(`/${this.props.location.pathname.split('/')[1]}/${this.props.location.pathname.split('/')[2]}`)
//                 })
//                 if(filterItems.length>0)temp.push(...filterItems)
//             }
//             )
//         }
//         this.state={
//             activeKey: temp.length>0?temp:[],
//             selectKey:linkItem
//         }

//     }

//     onButtonClick = (children) => {
//         let { activeKey } = this.state
//         if (activeKey == children)
//             this.setState({ selectKey: null }, () => this.setState({ activeKey: [] }))
//         else
//             this.setState({ selectKey: null }, () => this.setState({ activeKey: children }))
//     }

//     renderButtonList = (buttonList) => buttonList.map(({ label, key, children }) => (
//         <Col className="gutter-row" span={5} key={key}>
//             <Button
//             className={
//                 xor(this.state.activeKey,children).length===0?'transection-file-btn transection-file-btn-main transection-file-btn-active':
//                 'transection-file-btn transection-file-btn-main'
//             }
//             onClick={() => this.onButtonClick(children)}
//             >
//                 {label}
//             </Button>
//         </Col>
//     ))

//     setContentRender = (key,link) => {
//         this.props.history.push(link)
//         this.setState({ selectKey: key })
//     }

//     renderSubButtons = (btns) => {
//         let { selectKey,activeKey } = this.state
//         return btns.map(({ label, key,link }, i) => (
//             <Col className="gutter-row" span={6} key={key}>
//                 <Button
//                 className={
//                     selectKey !== null && activeKey[selectKey]?
//                     activeKey[selectKey].key==key?'transection-file-btn transection-file-btn-active':'transection-file-btn'
//                     :'transection-file-btn'
//                 }
//                 onClick={() => this.setContentRender(i,link)}>
//                     {label}
//                 </Button>
//             </Col>
//         ))
//     }

//     render() {
//         let JSX = buttonList.length > 0 ? this.renderButtonList(buttonList) : null,
//             { activeKey, selectKey } = this.state
//         return (
//                 <div className='transection-file'>
//                     <Row gutter={btnLayout} className='transection-file-btn-container' >
//                         {
//                             JSX
//                         }
//                     </Row>
//                     <Row gutter={12} className='transection-file-sub-btn-container' >
//                         {
//                             this.renderSubButtons(activeKey)
//                         }
//                     </Row>
//                     {selectKey !== null && activeKey[selectKey] ?
//                         <Content className='master-file-content-container'>
//                             {activeKey[selectKey].render()}
//                         </Content>
//                         : null}
//                 </div>
//         )
//     }
// }

// export default MasterPage

import React, { Component } from "react";
import buttonList from "./ButtonRenderer";

import PageTabs from "../../components/PageTabs";
import "./index.less";

export default () => {
  return <PageTabs buttonList={buttonList} />;
};
