import React, { Component, Fragment } from "react";
import ReactDOM from "react-dom";

import { Descriptions, Spin, message, Col, Statistic } from "antd";

//Component import
import ReportLayout from "../../../pageLayouts/Report";
import { fetchApi, fetchExcelApi } from "../../../../services/api";
import Empty from "../../../components/Custom/Empty";

//Utility
import { sortFunction } from "../../../../utility/sorter";
import { cloneDeep, filter, mapKeys } from "lodash";
import moment from "moment";
import { COOKIE, getCookie } from "../../../../services/cookie";

class TallAgeTypeWiseList extends Component {
  componentRef = React.createRef();
  state = {
    data: [],
    loading: false,

    blockNumber: null,
    blockNoList: [],
    block_uuid: null,

    stage: null,

    TallType: null,
    TallTypeList: [],
    TallType_uuid: null,

    DateOfSummary: null
  };

  componentDidMount() {
    this.fetchBlockNo();
    this.fetchTallType();
    //this.fetchTallAgeTypeWiseList();
  }
  fetchBlockNo = () =>
    fetchApi("/blocksuuid", "get").then(data =>
      this.setState({ blockNoList: sortFunction(data, "blockNoFull") })
    );
  fetchTallType = () =>
    fetchApi("/tree_types?type=2", "get").then(data =>
      this.setState({ TallTypeList: sortFunction(data, "code") })
    );

  onBlockChangeHandler = it => {
    let { blockNoList } = this.state;
    this.setState(
      {
        blockNumber: it ? it : null,
        block_uuid: it
          ? cloneDeep(blockNoList).filter(item => item.blockNoFull == it)[0]
              .uuid
          : null
      },
      () => this.fetchTallAgeTypeWiseList()
    );
  };
  onTreeTypeChangeHandler = it => {
    this.setState(
      {
        stage:
          it == "Seedling" ? 1 : it == "Plant" ? 2 : it == "Tree" ? 3 : null
      },
      () => this.fetchTallAgeTypeWiseList()
    );
  };
  onTallTypeChangeHandler = it => {
    let { TallTypeList } = this.state;
    this.setState(
      {
        TallType: it ? it : null,
        TallType_uuid: it
          ? cloneDeep(TallTypeList).filter(item => item.code == it)[0].uuid
          : null
      },
      () => this.fetchTallAgeTypeWiseList()
    );
  };

  dateOfSummaryOnHandler = date => {
    if (date) {
      this.setState(
        {
          DateOfSummary: date.format("YYYY-MM-DD")
        },
        () => this.fetchTallAgeTypeWiseList()
      );
    } else {
      this.setState(
        {
          DateOfSummary: null
        },
        () => this.fetchTallAgeTypeWiseList()
      );
    }
  };

  fetchTallAgeTypeWiseList = () => {
    let { block_uuid, stage, TallType_uuid, DateOfSummary } = this.state;
    if (DateOfSummary) {
      this.setState({ loading: true });

      fetchApi(
        `/tree-age/block-wise-list-tall?date=${DateOfSummary}&stage_code=${
          stage ? stage : 1
        }&block.uuid=${block_uuid}&tree_type.uuid=${TallType_uuid}`,
        "get"
      ).then(data => {
        if (data) this.setState({ data: data.data });
        else message.error("Internal Server Errors");
        this.setState({ loading: false });
      });
    } else {
      this.setState({
        data: []
      });
    }
  };

  generateTables = tableData =>
  <Fragment>
  <div className="description-table-header">
  <h3>
  Age Wise / Type Wise List Of Tall Seedling/Plant/Tree Nos. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Farm
    : {getCookie(COOKIE.FARM).charAt(0).toUpperCase() + getCookie(COOKIE.FARM).slice(1)}{" "} </h3>
    </div>
    <div className="description-table-header">
      <h3>List As On {moment(this.state.DateOfSummary).format("DD/MM/YYYY")}</h3>
    </div>
    {tableData.map(({ block_no, tree_types }, index) => (
      <div key={index}>
        {tree_types && (
          <Fragment>
            <div className="description-table-header">
              <Statistic title="Block No." value={block_no} />
            </div>

            <Descriptions
              //title={"Block No. : " + block_no}
              bordered
              className={"title" + index}
            >
              {tree_types &&
                tree_types.map(({ tree_type, total_count, years }, index) => [
                  <Descriptions.Item label="Tall Type">
                    {tree_type}
                  </Descriptions.Item>,
                  <Descriptions.Item
                    span={2}
                    label={`Total No. of Tall ${
                      this.state.stage == 3
                        ? "Tree"
                        : this.state.stage == 2
                        ? "Plant"
                        : "Seedling"
                    }`}
                  >
                    {total_count}
                  </Descriptions.Item>,
                  years &&
                    years.map(({ year, total_tall_type, tree_nos }, ind) => [
                      <Descriptions.Item label="Year Of Planting">
                        {year}
                      </Descriptions.Item>,
                      <Descriptions.Item
                        span={2}
                        label={`Total No. of Tall ${
                          this.state.stage == 3
                            ? "Tree"
                            : this.state.stage == 2
                            ? "Plant"
                            : "Seedling"
                        } in ${year}`}
                      >
                        {total_tall_type}
                      </Descriptions.Item>,
                      <Descriptions.Item
                        label="Tree No."
                        span={6}
                        // className="tree-nos-wrapper p-3"
                        className="p-3"
                      >
                        {tree_nos.map(it => (
                          <Col>
                            <span style={{ margin: "0px 3px" }}>{it}</span>
                          </Col>
                        ))}
                      </Descriptions.Item>,
                      <Descriptions.Item
                        span={6}
                        className="tree-nos-blank-row"
                      />
                    ])
                ])}
            </Descriptions>
          </Fragment>
        )}
        {tableData.length > index + 1 ? (
          <div className="pagebreak">
            <br />
            <br />
          </div>
        ) : null}
      </div>
    ))}
    </Fragment>

  generateExcel = () => {
    let { block_uuid, stage, TallType_uuid, DateOfSummary } = this.state,
      today = moment().format("YYYY-MM-DD");

    fetchExcelApi(
      `/tree-age/block-wise-list-tall?date=${
        DateOfSummary ? DateOfSummary : today
      }&stage_code=${
        stage ? stage : 1
      }&block.uuid=${block_uuid}&tree_type.uuid=${TallType_uuid}`,
      "get"
    ).then();
  };

  render() {
    let {
      data,
      loading,
      blockNoList,
      TallTypeList,
      DateOfSummary
    } = this.state;
    let JSX =
      data && data.length > 0 ? (
        this.generateTables(data)
      ) : !DateOfSummary ? (
        <Empty description="Select Age Summary As On" />
      ) : (
        <Empty description="No Data Found" />
      );
    const buttonList = data && data.length > 0
      ? [
          {
            label: "Excel",
            icon: "file-text",
            onClickHandler: () => this.generateExcel()
          },
          {
            label: "Print",
            icon: "diff",
            reference: this.componentRef
          }
        ]
      : null;
    return (
      <ReportLayout
        title="Age Wise / Type Wise List Of Tall Seedling/Plant/Tree Nos."
        buttonList={buttonList}
        dropDownList={[
          {
            label: "Block",
            listItems: cloneDeep(blockNoList).map(it => it.blockNoFull),
            changeHandler: it => this.onBlockChangeHandler(it)
          },
          {
            label: "Tree Type",
            listItems: ["Seedling", "Plant", "Tree"],
            hasInitial: true,
            changeHandler: it => this.onTreeTypeChangeHandler(it)
          },
          {
            label: "Tall Type",
            listItems: cloneDeep(TallTypeList).map(it => it.code),
            changeHandler: it => this.onTallTypeChangeHandler(it)
          },
          {
            label: "Age Summary As On",
            type: "date",
            //hasInitial: true,
            changeHandler: this.dateOfSummaryOnHandler
          }
        ]}
      >
        <div ref={this.componentRef}>
          <center>
            <Spin spinning={loading} size="large">
              {JSX}
            </Spin>
          </center>
        </div>
      </ReportLayout>
    );
  }
}

export default TallAgeTypeWiseList;
