let coloums = [
  {
    children: [
      {
        title: "Type Code",
        dataIndex: "code"
      },
      {
        title: "Tree No.",
        dataIndex: "tree_no_full"
      }
    ]
  }
];

export const dwartfColoums = date => {
  let temp = [
    {
      title: `EMASCULATION IN ${date -
        1} & HARVESTED IN ${date} (Selected Year Data)`,
      children: [
        {
          title: "No. of spathes",
          dataIndex: "no_of_spathes",
          render: it => (it ? it : "-")
        },
        {
          title: "No. of Buttons",
          dataIndex: "no_of_buttons",
          render: it => (it ? it : "-")
        },
        {
          title: "120 Days Count",
          dataIndex: "count_120_days",
          render: it => (it ? it : "-")
        },
        {
          title: "Harvest Nuts",
          dataIndex: "harvested_nuts",
          render: it => (it ? it : "-")
        }
      ]
    },
    {
      title: `EMASCULATION IN ${date - 2} & HARVESTED IN ${date -
        1} (Previous Year Data)`,
      children: [
        {
          title: "No. of spathes",
          dataIndex: "prev_no_of_spathes",
          render: it => (it ? it : "-")
        },
        {
          title: "No. of Buttons",
          dataIndex: "prev_no_of_buttons",
          render: it => (it ? it : "-")
        },
        {
          title: "120 Days Count",
          dataIndex: "prev_count_120_days",
          render: it => (it ? it : "-")
        },
        {
          title: "Harvest Nuts",
          dataIndex: "prev_harvested_nuts",
          render: it => (it ? it : "-")
        }
      ]
    }
  ];

  return coloums.concat(temp);
};

export const tallColoums = date => {
  let temp = [
    {
      title: `HARVESTED IN ${date} (Selected Year Data)`,
      children: [
        {
          title: "No. of Bunches",
          dataIndex: "bunch_qty",
          render: it => (it ? it : "-")
        },
        {
          title: "No. of Nuts Harvested",
          dataIndex: "harvested_nuts",
          render: it => (it ? it : "-")
        }
      ]
    },
    {
      title: `HARVESTED IN ${date - 1} (Previous Year Data)`,
      children: [
        {
          title: "No. of Bunches",
          dataIndex: "prev_bunch_qty",
          render: it => (it ? it : "-")
        },
        {
          title: "No. of Nuts Harvested",
          dataIndex: "prev_harvested_nuts",
          render: it => (it ? it : "-")
        }
      ]
    }
  ];

  return coloums.concat(temp);
};

// export const tallColoums = (date) => {
//     let temp = []
//     for (let i = 0; i < 2; i++) {
//         temp.push({
//             title: `HARVEST IN ${date + i} (Selected Year Data)`,
//             children: [
//                 {
//                     title: 'No. of Bunches',
//                     dataIndex: 'bunches_' + i,
//                     render: it => (it ? it : '-')
//                 },
//                 {
//                     title: 'No. of Nuts Harvested',
//                     dataIndex: 'harvested_nuts_' + i,
//                     render: it => (it ? it : '-')
//                 }
//             ]
//         }

//         )
//     }
//     return coloums.concat(temp)
// }
