import React from "react";
import { Table, Col, Statistic, Row } from "antd";
import { isEmpty } from "lodash";

const layout = {
  xs: {
    span: 6
  },
  sm: {
    span: 6
  },
  lg: {
    span: 4
  },
  md: {
    span: 4
  }
};

export default ({ dataSource: data, columns, month }) =>
  data && (

    <div className="pagebreak">
      {data.block_no ? (
        <h3>Weekly Spathe Emasculation Data Summary for {" " + month}</h3>
      ) : null}
      {data.block_no ? (
        < br />
      ) : null}

      {data.map(({ areas, ...rest }, i) => (
        <div key={i} className="printblock">
          <Row gutter={5}>
            {Object.keys(rest).map((it, index) => (
              <Col key={index} {...layout}>
                {rest[it] ? (
                  // <Statistic
                  //   title={it.split("_").map(it => `${it.toUpperCase()} `)}
                  //   value={rest[it]}
                  // />
                  <Statistic title="Block No." value={rest.block_no} />
                ) : (
                    <h3>Farm Total</h3>
                  )}
              </Col>
            ))}
          </Row>
          <Table
            //className="block-wise-summary-dwarf-table"
            className="bordered-table-head"
            bordered
            columns={columns}
            dataSource={areas}
            size="small"
            pagination={false}
          />
          <br />
          <br />
          {
            //data.length > i + 1 ? <div className="pagebreak" /> : null
          }
        </div>
      ))}
    </div>
  );
