import React, { Component } from "react";
import ReactDOM from "react-dom";
import { Table, message, notification, Icon, Tooltip, Spin } from "antd";

//Component import
import LocationPageBuilder from "../PageBuilder";
import Empty from "../../../components/Custom/Empty";

//Utility
import { sortFunction } from "../../../../utility/sorter";
import { COOKIE, getCookie } from "../../../../services/cookie";
import { fetchApi, fetchExcelApi } from "../../../../services/api";
import { cloneDeep, filter } from "lodash";

class TreeTypes extends Component {
  componentRef = React.createRef();
  state = {
    addModal: false,
    data: [],
    btnLoad: false,
    editData: null,
    isEdit: false,
    tableLoader: false,
    pagination: true,
    tallTreeTypeUuid: null,
    addPopulateData: null
  };

  componentDidMount() {
    this.setState({ tableLoader: true });
    this.fetchTreeTypes();
    const tableCon = ReactDOM.findDOMNode(this.refs["table"]),
      table = tableCon.querySelector("table"),
      header = table.createTHead(),
      row = header.insertRow(0);
    //cell = row.insertCell(0);
    row.innerHTML = `<h1>  Tall Tree Type Master - List - List &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Farm
    : ${getCookie(COOKIE.FARM)}${" "} </h1>`;
    table.setAttribute("id", "table-to-xls");
    table.setAttribute("class", "Tall-Tree-Types");
  }

  fetchTreeTypes = () =>
    fetchApi("/tree_types?type=2", "get").then(data =>
      this.setState({
        tableLoader: false,
        //data: sortFunction(data, "code") 
        data: data
      })
    );

  addModalToggle = () => {
    let { addModal } = this.state;
    if (addModal) {
      this.setState(
        {
          addModal: false,
          editData: null,
          isEdit: false,
          dwarfTreeTypeUuid: null,
          addPopulateData: null
        }
        //() => this.fetchTreeTypes()
      );
    } else {
      this.setState({ addModal: !this.state.addModal });
    }
  };

  clearFields = () => {
    this.setState({
      addPopulateData: {
        code: null,
        name: null
      }
    });
  };

  addFormHandler = val => {
    //to remove the previous instances of
    notification.destroy();

    let { isEdit, tallTreeTypeUuid } = this.state;
    this.setState({ btnLoad: true });
    if (!isEdit) {
      fetchApi("/tree_types", "post", {
        type: 2,
        ...val
      }).then(res => {
        if (res) {
          if (res.violations) {
            notification.error({
              message: "Failed",
              description: res.violations[0].message
            });
          } else {
            notification.success({
              message: "Success",
              description: "Successfully added Tall Tree Type"
            });
            this.fetchTreeTypes();
            this.clearFields();
          }
        } else {
          notification.error({
            message: "Failed",
            description: "Failed to add Tall Tree Type"
          });
        }
        this.setState({ btnLoad: false, addPopulateData: null });
      });
    } else {
      fetchApi(`/tree_types/${tallTreeTypeUuid}`, "put", {
        type: 2,
        ...val
      }).then(res => {
        if (res) {
          notification.success({
            message: "Success",
            description: "Successfully updated the Tall Tree Type"
          });
          this.fetchTreeTypes();
          this.addModalToggle()
        } else {
          notification.error({
            message: "Failed",
            description: "Failed to update Tall Tree Type"
          });
        }
        this.setState({ btnLoad: false }
          //, () => this.addModalToggle()
        );
      });
    }
  };

  onEditClickHandler = item => {
    this.setState(
      {
        isEdit: true
      },
      () => {
        this.setState({
          tallTreeTypeUuid: item.uuid,
          addModal: true,
          editData: {
            code: item.code,
            name: item.name
          }
        });
      }
    );
  };

  generateExcel = () => {
    fetchExcelApi("/tree_types?type=2", "get").then();
  };

  render() {
    let {
      addModal,
      data,
      btnLoad,
      editData,
      pagination,
      tableLoader,
      isEdit,
      addPopulateData
    } = this.state;
    const columns = [
      {
        title: "Tall Tree Type",
        dataIndex: "code"
      },
      {
        title: "Type Name",
        dataIndex: "name"
      },
      {
        title: "Actions",
        className: "action",
        render: it => (
          <Tooltip placement="topRight" title={"Edit"}>
            <Icon onClick={() => this.onEditClickHandler(it)} type="edit" />
          </Tooltip>
        )
      }
    ],
      formItems = [
        {
          name: "name",
          type: "alphanumeric",
          placeholder: "Type Name"
        }
      ];

    const _formItems = isEdit
      ? [
        {
          name: "code",
          placeholder: "Tall Tree Type",
          disabled: true
        }
      ].concat(formItems)
      : [
        {
          name: "code",
          placeholder: "Tall Tree Type",
          type: "alphanumeric"
        }
      ].concat(formItems);
    const buttonList =
      data.length > 0
        ? [
          {
            label: "Add",
            icon: "folder-add",
            onClickHandler: this.addModalToggle
          },
          {
            label: "Excel",
            icon: "file-text",
            onClickHandler: () => this.generateExcel()
          },
          {
            label: "Print",
            icon: "diff",
            reference: this.componentRef
          }
        ]
        : [
          {
            label: "Add",
            icon: "folder-add",
            onClickHandler: this.addModalToggle
          }
        ];
    return (
      <LocationPageBuilder
        title="Tall Tree Type Master"
        buttonList={buttonList}
        addModal={addModal}
        addModalToggle={this.addModalToggle}
        addFormHandler={this.addFormHandler}
        addPopulateData={addPopulateData}
        editData={editData ? editData : null}
        btnLoad={btnLoad}
        formItems={_formItems}
        isEdit={isEdit}
      >
        <div ref={this.componentRef}>
          {/* <h1>
            Tall Tree Type Master - List - List
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Farm : {getCookie(COOKIE.FARM)}{" "}
          </h1> */}
          <Spin spinning={tableLoader} size="large">
            <Table
              ref="table"
              columns={columns}
              dataSource={data}
              pagination={!pagination ? pagination : { pageSize: 15 }}
              //loading={tableLoader}
              locale={{
                emptyText: <Empty />
              }}
            />
          </Spin>
        </div>
      </LocationPageBuilder>
    );
  }
}

export default TreeTypes;
