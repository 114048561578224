import React from "react";

const renderContent = it =>
  it
    ? it
    : it == 0
    ? it
    : {
        props: {
          colSpan: 0
        }
      };

const renderSpecialFields = (it, rowField) =>
  it
    ? it.tree_code || it.area_code
      ? {
          props: {
            colSpan: 0
          }
        }
      : it[rowField]
      ? it[rowField]
      : 0
    : null;
// const renderSpathesTreepop = it => {
//   console.log("It is ....", it);
//   return 0;
// };

const columns = [
  {
    className: "reports-right-border",
    children: [
      {
        title: "Year",
        className: it => `rerer${it}`,
        render: it =>
          it.tree_code
            ? {
                children: it.tree_code,
                props: {
                  colSpan: 26
                }
              }
            : it.area_code
            ? {
                children: it.area_code,
                props: {
                  colSpan: 26
                }
              }
            : it.year
            ? it.year
            : {
                children: it.total,
                props: {
                  colSpan: 2
                }
              }
      },
      {
        title: "Month",
        dataIndex: "month",
        render: renderContent
      },
      {
        title: "No. of Trees",
        dataIndex: "no_of_tree",
        render: renderContent
      },
      {
        title: "No. of Plants",
        dataIndex: "no_of_plants",
        render: renderContent
      },
      {
        title: "Total Population",
        className: "reports-right-border",
        dataIndex: "total_population",
        render: renderContent
      }
    ]
  },
  {
    title: "EMASCULATION DATA",
    className: "reports-right-border",
    children: [
      {
        title: "Trees Emasc",
        //dataIndex: "trees_emasc",
        render: it => renderSpecialFields(it, "trees_emasc")
      },
      {
        title: "P/M Spathes",
        //dataIndex: "pm_spathe",
        render: it => renderSpecialFields(it, "pm_spathe")
      },
      {
        title: "Good Spathes",
        //dataIndex: "good_spathes",
        render: it => renderSpecialFields(it, "good_spathes")
      },
      {
        title: "Spathes/ TreeEm",
        //dataIndex: "spathes_treesemasc",
        render: it => renderSpecialFields(it, "spathes_treesemasc")
      },
      {
        title: "Spathes/ TotalPP",
        //dataIndex: "spathes_treepop",
        render: it => renderSpecialFields(it, "spathes_treepop")
      },
      {
        title: "Total Buttons",
        // dataIndex: "buttons_pol",
        render: it => renderSpecialFields(it, "buttons_pol")
      },
      {
        title: "Button/ Spathe",
        className: "reports-right-border",
        dataIndex: "button_spathes",
        render: renderContent
      }
    ]
  },
  {
    title: "120 DAYS NUTS COUNT",
    className: "reports-right-border",
    children: [
      {
        title: "Original Spathes",
        //dataIndex: "original_spathes",
        render: it => renderSpecialFields(it, "original_spathes")
      },
      {
        title: "Original Buttons",
        //dataIndex: "original_buttons",
        render: it => renderSpecialFields(it, "original_buttons")
      },
      {
        title: "120 Days Count",
        //dataIndex: "nuts",
        render: it => renderSpecialFields(it, "nuts")
      },
      {
        title: "Nuts/ Spathe",
        dataIndex: "nuts_spathe",
        render: renderContent
      },
      {
        title: "120 Days Setting %",
        dataIndex: "days_setting",
        className: "reports-right-border",
        render: renderContent
      }
    ]
  },
  {
    title: "HARVEST DATA ANALYSIS",
    className: "reports-right-border",
    children: [
      {
        title: "Prev. Year Total Trees",
        dataIndex: "prev_year_total_trees",
        render: renderContent
      },
      {
        title: "Prev. Year Buttons",
        //dataIndex: "prev_year_buttons",
        render: it => renderSpecialFields(it, "prev_year_buttons")
      },
      {
        title: "120 days Count",
        //dataIndex: "nuts_counts",
        render: it => renderSpecialFields(it, "nuts_counts")
      },
      {
        title: "Harvested Nuts",
        dataIndex: "harvested_nuts",
        render: renderContent
      },
      {
        title: "Trees Harvested",
        dataIndex: "trees_harvested",
        render: renderContent
      },
      {
        title: "Nuts/ Harv. Tree",
        dataIndex: "nuts_by_trees",
        render: renderContent
      },
      {
        title: "Nuts/ Tot. Trees",
        dataIndex: "nuts_by_tot_trees",
        render: renderContent
      },
      {
        title: "% of Yield to",
        className: "reports-right-border",
        children: [
          {
            title: "Buttons",
            dataIndex: "percent_yield_buttons",
            render: renderContent
          },
          {
            title: "120 days",
            dataIndex: "percent_yield_days_count",
            className: "reports-right-border",
            render: renderContent
          }
        ]
      }
    ]
  }
];

export default columns;
