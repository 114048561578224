import React, { Component } from "react";
import { Modal } from "antd";

import PropTypes from "prop-types";

//Component Import
import AdvanceHeader from "../../components/AdvanceHeader";
import AddForm from "./addForm";
import AddForm2 from "./AddForm2";

import "./index.less";

export default ({
  title = null,
  buttonList = [],
  dropDownList = [],
  addModalToggle = () => {},
  addModal = false,

  addFormModal = false,
  handleOk = () => {},
  handleCancel = () => {},
  formItems = [],
  children,
  addFormHandler = () => {},
  btnLoad = false,
  editData = null,
  addPopulateData = null,
  isEdit = false,
  isDelete = false,
  isTransfer = false,
  addData = null,
  value = null
}) => {
  //console.log("Data", addData);
  return (
    <div className="page-builder-container">
      {/* {console.log(addFormModal)} */}
      <AdvanceHeader
        title={title}
        buttonList={buttonList}
        dropDownList={dropDownList}
      />
      <div className="page-builder-table">
        {children}
        <table id="table-to-xls" />
      </div>
      {addFormModal ? (
        <Modal
          //title="Dwarf Tree 120 Days Count - Add"
          title={`${title} - ${"Add"}`}
          visible={addFormModal}
          width={900}
          centered
          footer={false}
          closable={false}
          //className="customModal-form-2"
          destroyOnClose
          //onOk={handleOk}
          //confirmLoading={confirmLoading}
          //onCancel={handleCancel}
        >
          <AddForm2
            addData={addData}
            onOk={handleOk}
            onCancel={handleCancel}
          ></AddForm2>
        </Modal>
      ) : (
        <Modal
          visible={addModal}
          width={400}
          centered
          footer={false}
          closable={false}
          destroyOnClose
          className="page-builder-add-modal"
          title={`${title} - ${
            isEdit
              ? "Edit"
              : isDelete
              ? "Delete"
              : isTransfer
              ? "Transfer"
              : "Add"
          }`}
        >
          <AddForm
            onCancel={addModalToggle}
            onAdd={addFormHandler}
            formItems={formItems}
            btnLoad={btnLoad}
            editData={editData}
            addPopulateData={addPopulateData}
            isEdit={isEdit}
            isDelete={isDelete}
            isTransfer={isTransfer}
          />
        </Modal>
      )}
    </div>
  );
};
