import React, { Component } from "react";
import { Table, message, Spin, Row, Col, Statistic } from "antd";

//Component import
import ReportLayout from "../../../pageLayouts/Report";
import { fetchApi, fetchExcelApi } from "../../../../services/api";
import Empty from "../../../components/Custom/Empty";
//Utility
import { sortFunction } from "../../../../utility/sorter";
import { parseData } from "./parseData";
import { mapKeys, cloneDeep, filter } from "lodash";
import { COOKIE, getCookie } from "../../../../services/cookie";

class RowWiseChart extends Component {
  componentRef = React.createRef();
  state = {
    data: [],
    dynamicData: [],
    loading: false,

    blockNumber: null,
    blockNoList: [],
    block_uuid: null
  };

  componentDidMount() {
    this.setState({ loading: true });
    this.fetchRowWiseChart();
    this.fetchBlockNo();
  }

  fetchRowWiseChart = () =>
    fetchApi("/tree-age/row-wise-chart", "get").then(res => {
      if (res)
        this.setState({
          data: Object.keys(res).length === 0 && res.constructor === Object? [] : res, 
          dynamicData: parseData(Object.keys(res).length === 0 && res.constructor === Object? [] : res) 
        });
      else message.error("Internal Server Error!");
      this.setState({ loading: false });
    });

  fetchBlockNo = () =>
    fetchApi("/blocksuuid", "get").then(data =>
      this.setState({ blockNoList: sortFunction(data, "blockNoFull") })
    );

  generateTables = tableData =>
    tableData.map(({ block_no, rows }, index) => (
      <div key={index}>
        <div className="description-table-header">
          <Statistic title="Block No." value={block_no} />
        </div>
        <Table
          //className="row-wise-chart-table"
          pagination={false}
          columns={rows.map(({ row_no }, index) => ({
            title: `Row ${row_no}`,
            dataIndex: row_no,
            render: it => it.trees.map(each => <div>{each}</div>),
            className: "row-wise-chart-table-item"
          }))}
          dataSource={[mapKeys(rows, o => o.row_no)]}
        />
        {tableData.length > index + 1 ? (
          <div className="pagebreak">
            <br />
            <br />
          </div>
        ) : null}
      </div>
    ));

  onBlockChangeHandler = it => {
    let { data, blockNoList } = this.state;

    if (it) {
      this.setState({
        blockNumber: it,
        block_uuid: cloneDeep(blockNoList).filter(each => each.blockNoFull === it)[0].uuid,
        dynamicData: parseData(
          cloneDeep(data).filter(item => String(item.block_no) === String(it))
        )
      });
    } else {
      this.setState({
        blockNumber: null,
        block_uuid: null,
        dynamicData: parseData(data)
      });
    }
  };

  generateExcel = () => {
    let { block_uuid } = this.state;
    fetchExcelApi(
      `/tree-age/row-wise-chart?block_uuid=${block_uuid}&type=excel`,
      "get"
    ).then();
  };

  render() {
    let { dynamicData, loading, blockNoList } = this.state;
    let JSX =
      dynamicData.length > 0 ? (
        this.generateTables(dynamicData)
      ) : (
        <Empty description="No Data Found" />
      );
    const legends =
      dynamicData.length > 0 ? (
        <div>
          <h3>
            Row Wise Chart All Types Tree/Plant/Seedling/Pit/Gap &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Farm
            : {getCookie(COOKIE.FARM).charAt(0).toUpperCase() + getCookie(COOKIE.FARM).slice(1)}{" "} </h3>
            <br/>
          <div className="legends">
            <Row className="legend-rows1" gutter={16}>
              <Col className="gutter-row" span={6}>
                <div className="gutter-box">DS - Dwarf Seedling</div>
              </Col>
              <Col className="gutter-row" span={6}>
                <div className="gutter-box">DP - Dwarf Plant</div>
              </Col>
              <Col className="gutter-row" span={6}>
                <div className="gutter-box">DT - Dwarf Tree</div>
              </Col>
              <Col className="gutter-row" span={6}>
                <div className="gutter-box">P - Pits</div>
              </Col>
            </Row>
            <Row className="legend-rows2" gutter={16}>
              <Col className="gutter-row" span={6}>
                <div className="gutter-box">TS - Tall Seedling</div>
              </Col>
              <Col className="gutter-row" span={6}>
                <div className="gutter-box">TP - Tall Plant</div>
              </Col>
              <Col className="gutter-row" span={6}>
                <div className="gutter-box">TT - Tall Tree</div>
              </Col>
              <Col className="gutter-row" span={6}>
                <div className="gutter-box">G - Gaps</div>
              </Col>
            </Row>
          </div>
        </div>
      ) : null;
    const buttonList =
      dynamicData.length > 0
        ? [
            {
              label: "Excel",
              icon: "file-text",
              onClickHandler: () => this.generateExcel()
            },
            {
              label: "Print",
              icon: "diff",
              reference: this.componentRef
            }
          ]
        : null;
    return (
      <ReportLayout
        title="Row Wise Chart All Types Tree/Plant/Seedling/Pit/Gap"
        buttonList={buttonList}
        dropDownList={[
          {
            label: "Block",
            listItems: cloneDeep(blockNoList).map(it => it.blockNoFull),
            changeHandler: it => this.onBlockChangeHandler(it)
          }
        ]}
      >
        <div ref={this.componentRef}>
          <Spin spinning={loading} size="large">
            {legends}
            <br />
            <center>{JSX}</center>
          </Spin>
        </div>
      </ReportLayout>
    );
  }
}

export default RowWiseChart;
