import React, { Component, Fragment } from "react";

//Component
import { Table, message, Spin, Statistic } from "antd";
import ReportLayout from "../../../pageLayouts/Report";
import Empty from "../../../components/Custom/Empty";
import ScollBarView from "react-double-scrollbar";
import { parseData } from "./parseData";

//Utility
import { sortFunction } from "../../../../utility/sorter";
import { fetchApi, fetchExcelApi } from "../../../../services/api";
import { cloneDeep } from "lodash";
import moment from "moment";
import { COOKIE, getCookie } from "../../../../services/cookie";

//import dummyData from "./Deletion.json";

class PerformanceComparisonReport extends Component {
  componentRef = React.createRef();
  state = {
    data: [],
    coloums: [],
    loading: false,

    blockNoList: [],
    blockNumber: null,
    block_uuid: null,

    isopen: false,
    title: "Valuation Report",

    fromYear: null,
    toYear: null,
    isopen: false,
    value: null
  };

  componentDidMount() {
    this.fetchBlockNo();
  }

  fetchValuationReport = () => {
    let { block_uuid, fromYear, toYear } = this.state;
    if (fromYear) {
      let url = `/regular-reports/valuation-of-financial-year?fromYear=${fromYear}&toYear=${toYear}`;
      if (block_uuid) url += `&block.uuid=${block_uuid}`;
      this.setState({ loading: true });
      fetchApi(url, "get").then(res => {
        if (res) {
          this.setState({
            data: res.blocks ? parseData(res.blocks) : []
          });
        } else {
          message.error("Internal Server Error!");
          this.setState({
            data: []
          });
        }
        this.setState({ loading: false });
      });
    }
  };

  fetchBlockNo = () =>
    fetchApi("/blocksuuid", "get").then(data =>
      this.setState({ blockNoList: sortFunction(data, "blockNoFull") })
    );

  onBlockChangeHandler = it => {
    let { blockNoList } = this.state;
    this.setState(
      {
        blockNumber: it ? it : null,
        block_uuid: it
          ? cloneDeep(blockNoList).filter(item => item.blockNoFull == it)[0]
            .uuid
          : null
      },
      () => this.fetchValuationReport()
    );
  };

  onDateChangeHandler = it => {
    it
      ? this.setState(
        {
          value: it,
          fromYear: it.format("YYYY"),
          toYear: moment(it.format("YYYY"))
            .add(1, "y")
            .format("YYYY"),
          isopen: false,
          data: []
        },
        () => this.fetchValuationReport()
      )
      : this.setState({
        value: null,
        fromYear: null,
        toYear: null,
        isopen: false,
        data: []
      });
  };

  //year functions
  handleOpenChange = status => {
    if (status) {
      this.setState({ isopen: true });
    } else {
      this.setState({ isopen: false });
    }
  };

  clearValue = () => {
    this.setState(
      {
        value: null,
        fromYear: null,
        toYear: null
      },
      () => this.fetchValuationReport()
    );
  };

  generateExcel = () => {
    let { block_uuid, fromYear, toYear } = this.state;
    let url = `/regular-reports/valuation-of-financial-year?fromYear=${fromYear}&toYear=${toYear}`;
    if (block_uuid) url += `&block.uuid=${block_uuid}`;
    fetchExcelApi(url, "get").then();
  };

  render() {
    let {
      data,
      blockNoList,
      blockNumber,
      loading,
      fromYear,
      toYear,
      isopen,
      value
    } = this.state;

    const columns = [
      {
        title: "Tree No.",
        //dataIndex: "tree_no_full",
        key: "tree_no_full",
        render: it =>
          it.block_total
            ? {
              children: it.block_total,
              props: {
                colSpan: 5
              }
            }
            : it.farm_total
              ? {
                children: it.farm_total,
                props: {
                  colSpan: 5
                }
              }
              : it.blank_row
                ? {
                  children: it.blank_row,
                  props: {
                    colSpan: 19
                  }
                }
                : it.tree_no_full
      },
      {
        title: "Plantation Date CDMS",
        //dataIndex: "dt_planted",
        key: "dt_planted",
        //render: it => (it ? moment(it).format("DD/MM/YYYY") : "NA")
        render: it =>
          it.block_total
            ? {
              props: {
                colSpan: 0
              }
            }
            : it.farm_total
              ? {
                props: {
                  colSpan: 0
                }
              }
              : it.blank_row
                ? {
                  props: {
                    colSpan: 0
                  }
                }
                : (it.dt_planted ? moment(it.dt_planted).format("DD/MM/YYYY") : "NA")
      },
      {
        title: "Tree Type FY " + fromYear + "-" + toYear,
        //dataIndex: "tree_type_fy",
        key: "tree_type_fy",
        render: it =>
          it.block_total
            ? {
              props: {
                colSpan: 0
              }
            }
            : it.farm_total
              ? {
                props: {
                  colSpan: 0
                }
              }
              : it.blank_row
                ? {
                  props: {
                    colSpan: 0
                  }
                }
                : it.tree_type_fy == 1
                  ? "Seedling"
                  : it.tree_type_fy == 2
                    ? "Plant"
                    : it.tree_type_fy == 3
                      ? "Tree"
                      : "-'"
      },
      {
        title: "Tree Type FY " + fromYear + "-" + toYear + " Age Based",
        //dataIndex: "tree_type_fy_age_based",
        key: "tree_type_fy_age_based",
        render: it =>
          it.block_total
            ? {
              props: {
                colSpan: 0
              }
            }
            : it.farm_total
              ? {
                props: {
                  colSpan: 0
                }
              }
              : it.blank_row
                ? {
                  props: {
                    colSpan: 0
                  }
                }
                : it.tree_type_fy_age_based
        // it.tree_type_fy_age_based == 1
        //   ? "Seedling"
        //   : it.tree_type_fy_age_based == 2
        //   ? "Plant"
        //   : it.tree_type_fy_age_based == 3
        //   ? "Tree"
        //   : "-'"
      },
      {
        title: "Deletion Date",
        //dataIndex: "dt_died",
        key: "dt_died",
        //render: it => (it ? moment(it).format("DD/MM/YYYY") : "NA")
        render: it =>
          it.block_total
            ? {
              props: {
                colSpan: 0
              }
            }
            : it.farm_total
              ? {
                props: {
                  colSpan: 0
                }
              }
              : it.blank_row
                ? {
                  props: {
                    colSpan: 0
                  }
                }
                : (it.dt_died ? moment(it.dt_died).format("DD/MM/YYYY") : "NA")
      },
      {
        title: "Original Cost on 01-04-" + fromYear,
        //dataIndex: "original_cost",
        key: "original_cost",
        //render: it => (it ? it : "0")
        render: it =>
          it.blank_row
            ? {
              props: {
                colSpan: 0
              }
            }
            : it.original_cost ? it.original_cost : "0"
      },
      {
        title: "Additions FY " + fromYear + "-" + toYear,
        //dataIndex: "additions_fy",
        key: "additions_fy",
        //render: it => (it ? it : "0")
        render: it =>
          it.blank_row
            ? {
              props: {
                colSpan: 0
              }
            }
            : it.additions_fy ? it.additions_fy : "0"
      },
      {
        title: "Deletion FY " + fromYear + "-" + toYear,
        //dataIndex: "deletion_on_fy",
        key: "deletion_on_fy",
        //render: it => (it ? it : "0")
        render: it =>
          it.blank_row
            ? {
              props: {
                colSpan: 0
              }
            }
            : it.deletion_on_fy ? it.deletion_on_fy : "0"
      },
      {
        title: "Cost as on 31-03-" + toYear,
        //dataIndex: "cost_as_on",
        key: "cost_as_on",
        //render: it => (it ? it : "0")
        render: it =>
          it.blank_row
            ? {
              props: {
                colSpan: 0
              }
            }
            : it.cost_as_on ? it.cost_as_on : "0"
      },
      {
        title: "Opening Dep. Reserve ",
        //dataIndex: "opening_dep_res",
        key: "opening_dep_res",
        //render: it => (it ? it : "0")
        render: it =>
          it.blank_row
            ? {
              props: {
                colSpan: 0
              }
            }
            : it.opening_dep_res ? it.opening_dep_res : "0"
      },
      {
        title: "Opening WDV 01-04-" + fromYear,
        //dataIndex: "opening_wdv",
        key: "opening_wdv",
        //render: it => (it ? it : "0")
        render: it =>
          it.blank_row
            ? {
              props: {
                colSpan: 0
              }
            }
            : it.opening_wdv ? it.opening_wdv : "0"
      },
      {
        title: "Useful life Expired (Years)",
        //dataIndex: "useful_life_expired_year",
        key: "useful_life_expired_year",
        //render: it => (it ? it : "0")
        render: it =>
          it.blank_row
            ? {
              props: {
                colSpan: 0
              }
            }
            : it.useful_life_expired_year ? it.useful_life_expired_year : "0"
      },
      {
        title: "Useful life Expired (Months) ",
        //dataIndex: "useful_life_expired_month",
        key: "useful_life_expired_month",
        //render: it => (it ? it : "0")
        render: it =>
          it.blank_row
            ? {
              props: {
                colSpan: 0
              }
            }
            : it.useful_life_expired_month ? it.useful_life_expired_month : "0"
      },
      {
        title: "Total Useful Life",
        //dataIndex: "total_useful_life",
        key: "total_useful_life",
        //render: it => (it ? it : "0")
        render: it =>
          it.blank_row
            ? {
              props: {
                colSpan: 0
              }
            }
            : it.total_useful_life ? it.total_useful_life : "0"
      },
      {
        title: "Remaining Useful Life",
        //dataIndex: "remaining_useful_life",
        key: "remaining_useful_life",
        //render: it => (it ? it : "0")
        render: it =>
          it.blank_row
            ? {
              props: {
                colSpan: 0
              }
            }
            : it.remaining_useful_life ? it.remaining_useful_life : "0"
      },
      {
        title: "Dep FY " + fromYear + "-" + toYear,
        //dataIndex: "dep_fy",
        key: "dep_fy",
        //render: it => (it ? it : "0")
        render: it =>
          it.blank_row
            ? {
              props: {
                colSpan: 0
              }
            }
            : it.dep_fy ? it.dep_fy : "0"
      },
      {
        title: "Dep Reversal on Deletion",
        //dataIndex: "dep_rev_on_del",
        key: "dep_rev_on_del",
        //render: it => (it ? it : "0")
        render: it =>
          it.blank_row
            ? {
              props: {
                colSpan: 0
              }
            }
            : it.dep_rev_on_del ? it.dep_rev_on_del : "0"
      },
      {
        title: "Acc. Dep as on 31-03-" + toYear,
        //dataIndex: "acc_dep_as_on",
        key: "acc_dep_as_on",
        //render: it => (it ? it : "0")
        render: it =>
          it.blank_row
            ? {
              props: {
                colSpan: 0
              }
            }
            : it.acc_dep_as_on ? it.acc_dep_as_on : "0"
      },
      {
        title: "Closing WDV as on 31-03-" + toYear,
        //dataIndex: "closing_wdv_as_on",
        key: "closing_wdv_as_on",
        //render: it => (it ? it : "0")
        render: it =>
          it.blank_row
            ? {
              props: {
                colSpan: 0
              }
            }
            : it.closing_wdv_as_on ? it.closing_wdv_as_on : "0"
      }
    ];

    const JSX =
      data && data.length > 0 ? (
        data.map(({ block_no_full, trees }, index) => (
          <div className={index == 0 ? "" : "pagebreak"} key={index}>
            {
              <Fragment>
                <ScollBarView>
                  <div className="multi-table-header">
                    <Statistic title="Block No." value={block_no_full} />
                  </div>
                  <Table
                    //className="bordered-table-head"
                    bordered
                    rowKey={trees.tree_no_full}
                    columns={columns}
                    dataSource={trees}
                    bordered
                    size="medium"
                    pagination={false}
                  />
                </ScollBarView>
                <br />
              </Fragment>
            }

            <br />
          </div>
        ))
      ) : fromYear ? (
        <Empty />
      ) : (
            <Empty description="Select Financial From Year" />
          );

    const buttonList =
      data && data.length > 0
        ? [
          {
            label: "Excel",
            icon: "file-text",
            onClickHandler: () => this.generateExcel()
          },
          {
            label: "Print",
            icon: "diff",
            reference: this.componentRef
          }
        ]
        : null;

    return (
      <ReportLayout
        title={"Valuation Report"}
        buttonList={buttonList}
        dropDownList={[
          {
            label: "Block",
            listItems: cloneDeep(blockNoList).map(it => it.blockNoFull),
            //value: blockNumber ? blockNumber : undefined,
            changeHandler: it => this.onBlockChangeHandler(it)
          },
          // {
          //   label: "From Year",
          //   type: "year",
          //   changeHandler: it => this.onDateChangeHandler(it)
          // }
          {
            label: "From Year",
            type: "date",
            mode: "year",
            value: value != null ? value : undefined,
            open: isopen,
            format: "YYYY",
            onOpenChange: this.handleOpenChange,
            onPanelChange: val => this.onDateChangeHandler(val),
            changeHandler: this.clearValue
          }
        ]}
      >
        <div ref={this.componentRef}>
          <Spin spinning={loading} size="large">
            {data && data.length > 0 ? (
              <Fragment>
                <h3>
                  {"Valuation Report for Financial year from April " +
                    moment(fromYear).format("YYYY") +
                    " to March " +
                    moment(toYear).format("YYYY")}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Farm :{" "}
                  {getCookie(COOKIE.FARM)
                    .charAt(0)
                    .toUpperCase() + getCookie(COOKIE.FARM).slice(1)}{" "}
                </h3>
                {JSX}
              </Fragment>
            ) : fromYear ? (
              <Empty />
            ) : (
                  <Empty description="Select Financial From Year" />
                )}
          </Spin>
        </div>
      </ReportLayout>
    );
  }
}

export default PerformanceComparisonReport;
