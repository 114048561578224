import React from "react";
import { Table, Statistic, Col } from "antd";

const layout = {
  xs: {
    span: 6
  },
  sm: {
    span: 6
  },
  lg: {
    span: 3
  },
  md: {
    span: 3
  }
};

const alignleft = {
  marginRight: "auto"
};

export default ({ columns = [], tableData = [] }) => {
  return tableData.map(({ blockNo, areaNo, value }, index) => (
    <div className={index != 0 ? "pagebreak" : ""}>
      <div className="multi-table-header" style={alignleft}>
        <Statistic title="Block No." value={blockNo} />
        <Statistic title="Area No." value={areaNo} />
      </div>
      <hr />
      <Table
        rowKey="uuid"
        className="multi-table input-formats-tables"
        columns={columns}
        dataSource={value}
        pagination={false}
      />
    </div>
  ));
};
