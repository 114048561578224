import React from 'react'

const columns = [
    {
        title: "TYPE CODE",
        dataIndex: "code",
        render: it => (it ? it : 'TOTAL')
    },
    {
        title: "NIL NUTS",
        dataIndex: "null_nuts",
    },
    {
        title: "1-2",
        dataIndex: "1-2",
        render: it => (it ? it : 0)
    },
    {
        title: "3-4",
        dataIndex: "3-4",
        render: it => (it ? it : 0)
    },
    {
        title: "5-6",
        dataIndex: "5-6",
        render: it => (it ? it : 0)
    },
    {
        title: "7-8",
        dataIndex: "7-8",
        render: it => (it ? it : 0)
    },
    {
        title: "9-10",
        dataIndex: "9-10",
        render: it => (it ? it : 0)
    },
    {
        title: "11-12",
        dataIndex: "11-12",
        render: it => (it ? it : 0)
    },
    {
        title: "13-14",
        dataIndex: "13-14",
        render: it => (it ? it : 0)
    },
    {
        title: ">=15",
        dataIndex: ">=15",
        render: it => (it ? it : 0)
    },
    {
        title: "Total",
        dataIndex: "total",
        render: it => (it ? it : 0)
    }
];

export default columns;