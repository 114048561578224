/**
 * @name sortFunction
 * @description takes array to be sorted and the target
 * @param {Array} arr
 * @param {String} target
 */
export const sortFunction = (arr, target) =>
  arr.sort((a, b) => {
    if (typeof a[target] == "string") {
      // console.log("The Target", a[target]);
      return (
        parseInt(a[target]) - parseInt(b[target]) ||
        //a[target].length - b[target].length ||
        a[target].localeCompare(b[target])
      );
    } else {
      // console.log("The Target", a[target], " Not A string");
      return parseInt(a[target]) - parseInt(b[target]);
    }
  });

export const sortFunctionString = (arr, target) =>
  arr.sort((a, b) => {
    if (typeof a[target] == "string") {
      console.log("string");
      // console.log("The Target", a[target]);
      return a[target] - b[target] || a[target].localeCompare(b[target]);
    } else {
      return a[target] - b[target];
    }
  });
/*export const sortFunction2 = (arr, target, target2) =>
  arr.sort(
    (a, b) => {
      if (typeof a[target] == "string") {
        return (
          parseInt(a[target]) - parseInt(b[target]) ||
          a[target].length - b[target].length ||
          a[target].localeCompare(b[target])
        );
      } else {
        return parseInt(a[target]) - parseInt(b[target]);
      }
    },
    arr.sort((a, b) => {
      if (typeof a[target2] == "string") {
        return (
          parseInt(a[target2]) - parseInt(b[target2]) ||
          a[target2].length - b[target2].length ||
          a[target2].localeCompare(b[target2])
        );
      } else {
        return parseInt(a[target2]) - parseInt(b[target2]);
      }
    })
  );*/

export const sortFunction2 = (arr, target) =>
  target.forEach(element => {
    arr.sort((a, b) => {
      if (typeof a[element] == "string") {
        return (
          parseInt(a[element]) - parseInt(b[element]) ||
          a[element].length - b[element].length ||
          a[element].localeCompare(b[element])
        );
      } else {
        return parseInt(a[element]) - parseInt(b[element]);
      }
    });
  });

export const sortFunctionDesc = (arr, target) =>
  arr.sort((b, a) => {
    if (typeof a[target] == "string") {
      return (
        parseInt(a[target]) - parseInt(b[target]) ||
        a[target].length - b[target].length ||
        a[target].localeCompare(b[target])
      );
    } else {
      return parseInt(a[target]) - parseInt(b[target]);
    }
  });
