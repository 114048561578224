import { chunk } from "lodash";

export const parseData = data => {
  let parsedData = [];
  data.forEach(item => {
    if (item.rows) {
      if (item.rows.length <= 10) {
        parsedData.push(item);
      } else {
        let tempChunk = chunk(item.rows, 10);
        tempChunk.forEach(it => {
          if (it.length > 0) {
            parsedData.push({
              block_no: item.block_no,
              rows: it
            });
          }
        });
      }
    }
  });
  return parsedData;
};
