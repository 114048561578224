import React, { Component, Fragment } from "react";
import propTypes from "prop-types";
import { withRouter } from "react-router-dom";

//Componenet Import
import { Row, Col, Button, Layout, Tabs } from "antd";

import "./index.less";

const { Content } = Layout,
  { TabPane } = Tabs;

const CurrentTab = ({ active, render, a, b }) => {
  if (!active) return null;
  return render();
};

class PageTabs extends Component {
  constructor(props) {
    super(props);
    let value = this.props.buttonList.filter(item =>
      item.link.includes(this.props.location.pathname)
    )[0];

    this.state = {
      activeKey: value.key || 1,
      selectedChildren: value.children || [],
      selectedChildKey: value.children
        ? value.children.filter(item =>
            item.link.includes(this.props.location.pathname)
          )[0].key
        : 1
    };
  }
  tabChangeHandler = it => {
    this.setState({
      activeKey: it,
      selectedChildKey: 1,
      selectedChildren: this.props.buttonList[it - 1].children || []
    });
    this.props.history.push(
      this.props.buttonList[parseInt(it) - 1].children
        ? this.props.buttonList[parseInt(it) - 1].link[0]
        : this.props.buttonList[parseInt(it) - 1].link
    );
  };

  getTotalLength(buttonList) {
    var length = 0;
    buttonList.map(({ label, ...val }) => (length = length + label.length));
    return length;
  }

  renderButtonList = buttonList =>
    buttonList.map(({ label, key, link, render, children }) => (
      <TabPane tab={label} key={key}>
        {!children ? (
          <Content className="page-tabs-content">
            <CurrentTab active={this.state.activeKey == key} render={render} />
          </Content>
        ) : (
          <Tabs
            onChange={this.subTabChangeHandler}
            defaultActiveKey={this.state.selectedChildKey.toString()}
            className={
              this.getTotalLength(this.state.selectedChildren) > 110
                ? ""
                : this.getTotalLength(buttonList) > 120
                ? "tab-pane-padding"
                : ""
            }
          >
            {this.state.selectedChildren.map(item => (
              <TabPane tab={item.label} key={item.key}>
                <Content className="page-tabs-content">
                  <CurrentTab
                    active={this.state.selectedChildKey == item.key}
                    render={item.render}
                  />
                </Content>
              </TabPane>
            ))}
          </Tabs>
        )}
      </TabPane>
    ));

  subTabChangeHandler = it => {
    this.setState({ selectedChildKey: parseInt(it) });
    this.props.history.push(this.state.selectedChildren[parseInt(it) - 1].link);
  };

  render() {
    let JSX =
      this.props.buttonList.length > 0
        ? this.renderButtonList(this.props.buttonList)
        : null;

    return (
      <div className="page-tabs-container">
        <Tabs
          onChange={this.tabChangeHandler}
          defaultActiveKey={this.state.activeKey.toString()}
          className="whiteButtons"
        >
          {JSX}
        </Tabs>
      </div>
    );
  }
}

PageTabs.defaultProps = {
  buttonList: []
};

PageTabs.propTypes = {
  buttonList: propTypes.array
};

export default withRouter(PageTabs);
