import React, { Component } from 'react';
import DocumentTitle from 'react-document-title';

import { Layout } from 'antd';

import SideBar from '../../components/SideBar';
import Header from '../../components/Header';

import './index.less';

const { Content } = Layout;

class DashboardLayout extends Component {


    render() {
        let {
            title
        } = this.props;
        return (
            <DocumentTitle title={title}>
                <Layout>
                    <Layout className="dashboard-core-container">
                        <SideBar location={this.props.location} />
                        <Layout>
                            <Header />
                            <Content className="core-data-container" style={{ padding: '0px 30px', minHeight: 280, marginBottom: '0' }}>
                                {React.cloneElement(this.props.children, { ...this.props })}
                            </Content>
                        </Layout>
                    </Layout>
                </Layout>
            </DocumentTitle>
        );
    }
}

export default DashboardLayout;