import React, { Component, Fragment } from "react";
import ReactDOM from "react-dom";
import Empty from "../../../components/Custom/Empty";
import { Descriptions, Spin, message, Col, Statistic } from "antd";

//Component import
import ReportLayout from "../../../pageLayouts/Report";
import { fetchApi, fetchExcelApi } from "../../../../services/api";

//Utility
import { sortFunction } from "../../../../utility/sorter";
import { cloneDeep, filter, mapKeys } from "lodash";
import moment from "moment";
import { COOKIE, getCookie } from "../../../../services/cookie";

class DwarfAgeTypeWiseList extends Component {
  componentRef = React.createRef();
  state = {
    data: [],
    dynamicData: [],
    loading: false,

    blockNumber: null,
    blockNoList: [],
    block_uuid: null,

    stage: null,

    DwarfType: null,
    DwarfTypeList: [],
    DwarfType_uuid: null,

    DateOfSummary: null
  };

  componentDidMount() {
    this.fetchBlockNo();
    this.fetchDwarfType();
    //this.fetchDwarfAgeTypeWiseList();
  }
  fetchBlockNo = () =>
    fetchApi("/blocksuuid", "get").then(data =>
      this.setState({ blockNoList: sortFunction(data, "blockNoFull") })
    );
  fetchDwarfType = () =>
    fetchApi("/tree_types?type=1", "get").then(data =>
      this.setState({ DwarfTypeList: data })
    );

  onBlockChangeHandler = it => {
    let { blockNoList } = this.state;
    this.setState(
      {
        blockNumber: it ? it : null,
        block_uuid: it
          ? cloneDeep(blockNoList).filter(item => item.blockNoFull == it)[0]
            .uuid
          : null
      },
      () => this.fetchDwarfAgeTypeWiseList()
    );
  };
  onTreeTypeChangeHandler = it => {
    this.setState(
      {
        stage:
          it == "Seedling" ? 1 : it == "Plant" ? 2 : it == "Tree" ? 3 : null
      },
      () => this.fetchDwarfAgeTypeWiseList()
    );
  };
  onDwarfTypeChangeHandler = it => {
    let { DwarfTypeList } = this.state;
    this.setState(
      {
        DwarfType: it ? it : null,
        DwarfType_uuid: it
          ? cloneDeep(DwarfTypeList).filter(item => item.code == it)[0].uuid
          : null
      },
      () => this.fetchDwarfAgeTypeWiseList()
    );
  };
  dateOfSummaryOnHandler = date => {
    if (date) {
      this.setState(
        {
          DateOfSummary: date.format("YYYY-MM-DD")
        },
        () => this.fetchDwarfAgeTypeWiseList()
      );
    } else {
      this.setState(
        {
          DateOfSummary: null
        },
        () => this.fetchDwarfAgeTypeWiseList()
      );
    }
  };

  fetchDwarfAgeTypeWiseList = () => {
    let { block_uuid, stage, DwarfType_uuid, DateOfSummary } = this.state;
    if (DateOfSummary) {
      this.setState({ loading: true });
      //console.log(block_uuid, stage, DwarfType_uuid, DateOfSummary);
      fetchApi(
        `/tree-age/block-wise-list-dwarf?date=${DateOfSummary}&stage_code=${
        stage ? stage : 1
        }&block.uuid=${block_uuid}&tree_type.uuid=${DwarfType_uuid}`,
        "get"
      ).then(data => {
        if (data) this.setState({ data, dynamicData: data.data });
        else message.error("Internal Server Errors");
        this.setState({ loading: false });
      });
    } else {
      this.setState({ data: [], dynamicData: [] });
    }
  };

  generateTables = tableData =>
    tableData.map(({ block_no, tree_types }, index) => (
      <div key={index} className={index == 0 ? "dwarf-list-report" : "pagebreak dwarf-list-report"}>
        {tree_types ? (
          <Fragment>
            <div className="description-table-header">
              <Statistic title="Block No." value={block_no} />
              <br />
            </div>
            <Descriptions
              //title={"Block No. : " + block_no}
              bordered
              className={"title" + index}
            >
              {tree_types &&
                tree_types.map(({ tree_type, total_count, years }, index) => [
                  <Descriptions.Item label="Dwarf Type">
                    {tree_type}
                  </Descriptions.Item>,
                  <Descriptions.Item
                    span={2}
                    label={`Total No. of Dwarf ${
                      this.state.stage == 3
                        ? "Tree"
                        : this.state.stage == 2
                          ? "Plant"
                          : "Seedling"
                      }`}
                  >
                    {total_count}
                  </Descriptions.Item>,
                  years &&
                  years.map(({ year, total_dwarf_type, tree_nos }, ind) => [
                    <Descriptions.Item label="Year Of Planting">
                      {year}
                    </Descriptions.Item>,
                    <Descriptions.Item
                      span={2}
                      label={`Total No. of Dwarf ${
                        this.state.stage == 3
                          ? "Tree"
                          : this.state.stage == 2
                            ? "Plant"
                            : "Seedling"
                        } in ${year}`}
                    >
                      {total_dwarf_type}
                    </Descriptions.Item>,
                    <Descriptions.Item
                      className="p-3"
                      label="Tree No."
                      span={6}
                    >
                      {tree_nos.map(it => (
                        <Col>
                          <span style={{ margin: "0px 10px" }}>{it}</span>
                        </Col>
                      ))}
                    </Descriptions.Item>,
                    <Descriptions.Item
                      span={6}
                      className="tree-nos-blank-row"
                    />
                    /*console.log(index),
                  tree_types.length > index + 1 ? (
                    <Descriptions.Item
                      span={6}
                      className="tree-nos-blank-row"
                    />
                  ) : null*/
                  ])
                ])}
            </Descriptions>
            <br /><br />
          </Fragment>
        ) : (
            <Empty description={"No Data Found"} />
          )}
      </div>
    ));

  generateExcel = () => {
    let { block_uuid, stage, DwarfType_uuid, DateOfSummary } = this.state,
      today = moment().format("YYYY-MM-DD");

    fetchExcelApi(
      `/tree-age/block-wise-list-dwarf?date=${
      DateOfSummary ? DateOfSummary : today
      }&stage_code=${
      stage ? stage : 1
      }&block.uuid=${block_uuid}&tree_type.uuid=${DwarfType_uuid}`,
      "get"
    ).then();
  };

  render() {
    let {
      dynamicData,
      loading,
      blockNoList,
      DwarfTypeList,
      DateOfSummary
    } = this.state;
    console.log(dynamicData);

    let JSX =
      dynamicData && dynamicData.length > 0 ? (
        <Fragment>
          <div className="description-table-header">
            <h3>
              Type Wise / Age Wise List Of Dwarf Seedling/Plant/Tree Nos. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Farm
              : {getCookie(COOKIE.FARM).charAt(0).toUpperCase() + getCookie(COOKIE.FARM).slice(1)}{" "} </h3>
          </div>
          <div className="description-table-header">
            <h3>List As On {moment(DateOfSummary).format("DD/MM/YYYY")}</h3>
          </div>
          {this.generateTables(dynamicData)}
        </Fragment>
      ) : !DateOfSummary ? (
        <Empty description="Select Age Summary As On" />
      ) : (
            <Empty description="No Data Found" />
          );
    const buttonList =
      dynamicData && dynamicData.length > 0
        ? [
          {
            label: "Excel",
            icon: "file-text",
            onClickHandler: () => this.generateExcel()
          },
          {
            label: "Print",
            icon: "diff",
            reference: this.componentRef
          }
        ]
        : null;
    return (
      <ReportLayout
        title="Type Wise / Age Wise List Of Dwarf Seedling/Plant/Tree Nos."
        buttonList={buttonList}
        dropDownList={[
          {
            label: "Block",
            listItems: cloneDeep(blockNoList).map(it => it.blockNoFull),
            changeHandler: it => this.onBlockChangeHandler(it)
          },
          {
            label: "Tree Type",
            listItems: ["Seedling", "Plant", "Tree"],
            hasInitial: true,
            changeHandler: it => this.onTreeTypeChangeHandler(it)
          },
          {
            label: "Dwarf Type",
            listItems: cloneDeep(DwarfTypeList).map(it => it.code),
            changeHandler: it => this.onDwarfTypeChangeHandler(it)
          },
          {
            label: "Age Summary As On",
            type: "date",
            changeHandler: this.dateOfSummaryOnHandler
          }
        ]}
      >
        <div ref={this.componentRef}>
          <center>
            <Spin spinning={loading} size="large">
              {JSX}
            </Spin>
          </center>
        </div>
      </ReportLayout>
    );
  }
}

export default DwarfAgeTypeWiseList;
