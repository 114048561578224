import React, { Component, Fragment } from "react";
import propTypes, { number } from "prop-types";

import {
  PageHeader,
  Row,
  Col,
  Select,
  Button,
  Icon,
  DatePicker,
  Input,
  message,
  InputNumber,
  Tooltip
} from "antd";

import ReactToPrint from "react-to-print";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

import moment from "moment";
import { uniqBy } from "lodash";

import "./index.less";

const { Option } = Select,
  { MonthPicker, RangePicker } = DatePicker;

const dropdownLayout = {
  xs: {
    span: 6
  },
  sm: {
    span: 6
  },
  lg: {
    span: 3
  },
  md: {
    span: 3
  }
};

const rangePickerLayout = {
  xs: {
    span: 21
  },
  sm: {
    span: 6
  },
  lg: {
    span: 8
  },
  md: {
    span: 3
  }
};

/**
 * @example
 *
 * 
        <AdvanceHeader
        title='Block Number Master'
        dropDownList={[
            {
                label:"Block",
                listItems:[1,2,3,4,5,6,7,8,9,10],               //Mocked for now will dynamic later based on api call
                changeHandler:(it)=>console.log(it)
            },
        ]}
        buttonList={[
            {
                label:"Add",
                icon:'folder-add',
                onClickHandler:this.addModalToggle
            },
            {
                label:"Excel",
                icon:"file-text",
                onClickHandler:()=>console.log("Excel")
            },
            {
                label:"Print",
                icon:"diff",
                onClickHandler:()=>console.log("Print")
            }
        ]}
        />
 */

class AdvanceHeader extends Component {
  state = {
    documentTitle: null
  };

  //Title
  generateTitle = title => (
    <PageHeader title={title} className="advance-header-page-header" />
  );

  handleDisabledDate = dt => dt > moment().valueOf();
  handleDisabledMonth = dt => dt > moment("MM/YYYY").valueOf();
  handleDisabledYear = dt =>
    dt >
    moment()
      .add("years", 1)
      .valueOf();
  //DropDown
  generateDown = items =>
    items.map(
      (
        {
          label,
          listItems,
          changeHandler,
          disabled = false,
          type = null,
          hasInitial = false,
          visible = true,
          //mode = null,
          // mode = 'date',
          //format = null,
          className,
          ...rest
        },
        i
      ) => {
        switch (type) {
          case "date":
            return (
              <Col
                key={i}
                {...rangePickerLayout}
                className="advance-header-dropdown-item"
              >
                {rest.tooltip ? (
                  <Tooltip placement="top" title={rest.tooltip}>
                    <DatePicker
                      getCalendarContainer={trigger => trigger.parentNode}
                      disabledDate={this.handleDisabledDate}
                      format="DD/MM/YYYY"
                      //mode={mode ? mode : "Date"}
                      placeholder={label}
                      onChange={changeHandler}
                      defaultValue={hasInitial ? moment() : undefined}
                      allowClear={hasInitial ? false : true}
                      {...rest}
                    />
                  </Tooltip>
                ) : (
                  <DatePicker
                    getCalendarContainer={trigger => trigger.parentNode}
                    disabledDate={this.handleDisabledDate}
                    format="DD/MM/YYYY"
                    //mode={mode ? mode : "Date"}
                    placeholder={label}
                    onChange={changeHandler}
                    defaultValue={hasInitial ? moment() : undefined}
                    allowClear={hasInitial ? false : true}
                    {...rest}
                  />
                )}
              </Col>
            );

          case "month":
            return (
              <Col
                key={i}
                {...dropdownLayout}
                className="advance-header-dropdown-item"
              >
                <MonthPicker
                  getCalendarContainer={trigger => trigger.parentNode}
                  disabledDate={this.handleDisabledDate}
                  format="MM/YYYY"
                  placeholder={label}
                  onChange={changeHandler}
                  defaultValue={hasInitial ? moment() : undefined}
                  allowClear={hasInitial ? false : true}
                  {...rest}
                />
              </Col>
            );

          case "range":
            return (
              <Col
                key={i}
                {...rangePickerLayout}
                //className="advance-header-dropdown-range-label"
                className={`advance-header-dropdown-range-label ${
                  className ? className : ""
                }`}
                style={{ display: "flex", alignItems: "center" }}
              >
                <div style={{ width: "inherit" }}>{label}</div>
                <RangePicker
                  className="advance-header-dropdown-range from-to-date"
                  getCalendarContainer={trigger => trigger.parentNode}
                  onChange={changeHandler}
                  disabledDate={
                    rest.mode && rest.mode[0] == "year"
                      ? this.handleDisabledYear
                      : this.handleDisabledDate
                  }
                  allowClear={hasInitial ? false : true}
                  //mode={mode ? mode : ["Date", "Date"]}
                  //format={format}
                  {...rest}
                />
              </Col>
            );

          case "number":
            return (
              <Col
                key={i}
                {...dropdownLayout}
                className={`advance-header-dropdown-item ${
                  className ? className : ""
                }`}
              >
                <InputNumber
                  placeholder={label}
                  className="advance-header-dropdown-number"
                  min={1}
                  onBlur={e => changeHandler(e.target.value)}
                  onPressEnter={e => changeHandler(e.target.value)}
                  allowClear
                />
              </Col>
            );

          default:
            return (
              <Col
                key={i}
                {...dropdownLayout}
                className={`advance-header-dropdown-item ${
                  visible == false ? "advance-header-dropdown-item-hidden" : ""
                }${className ? className : ""}`}
              >
                <Select
                  showSearch
                  getPopupContainer={trigger => trigger.parentNode}
                  defaultValue={
                    hasInitial
                      ? listItems
                        ? listItems[0]
                        : undefined
                      : undefined
                  }
                  {...rest}
                  placeholder={label}
                  onChange={changeHandler}
                  disabled={disabled}
                  className="advance-header-dropdown-item-content"
                  allowClear={hasInitial ? false : true}
                >
                  {uniqBy(listItems).map(it => (
                    <Option value={it} key={it}>
                      {it}
                    </Option>
                  ))}
                </Select>
              </Col>
            );
        }
      }
    );

  //Buttons
  generateButtons = btns =>
    btns
      ? btns.map(
          ({ label, onClickHandler, icon, reference = null, toolTip }, i) =>
            reference ? (
              <ReactToPrint
                key={i}
                onBeforePrint={() => {
                  //message.loading("Generating Print....", 0);
                  this.setState(
                    { documentTitle: document.title },
                    () => (document.title = "Coconut Data Management System")
                  );
                }}
                onAfterPrint={() => {
                  //message.destroy();
                  document.title = this.state.documentTitle;
                }}
                trigger={() => (
                  <a href="#">
                    {toolTip ? (
                      <Tooltip placement="topRight" title={toolTip}>
                        <Button className="advance-header-btn-item" key={i}>
                          {icon ? <Icon type={icon} theme="filled" /> : null}
                          {label}
                        </Button>
                      </Tooltip>
                    ) : (
                      <Button className="advance-header-btn-item" key={i}>
                        {icon ? <Icon type={icon} theme="filled" /> : null}
                        {label}
                      </Button>
                    )}
                  </a>
                )}
                content={() => reference.current}
              />
            ) : (
              label && (
                <Button
                  className="advance-header-btn-item"
                  key={i}
                  onClick={onClickHandler}
                >
                  {icon ? <Icon type={icon} theme="filled" /> : null}
                  {label}
                </Button>
              )
            )
        )
      : null;

  render() {
    let { title, dropDownList, buttonList } = this.props;
    return (
      <div className="advance-header-container">
        {this.generateTitle(title)}
        <Row className="advance-header-dropdown">
          {this.generateDown(dropDownList)}
          <div className="clearfix"></div>
          <Col className="advance-header-btn">
            {this.generateButtons(buttonList)}
          </Col>
        </Row>
      </div>
    );
  }
}

AdvanceHeader.defaultProps = {
  title: null,
  dropDownList: [],
  buttonList: []
};

AdvanceHeader.propTypes = {
  title: propTypes.string,
  dropDownList: propTypes.array,
  buttonList: propTypes.array
};

export default AdvanceHeader;
