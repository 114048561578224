import React from 'react';
import './index.less';
import { Input, Tooltip } from 'antd';

class NumericInput extends React.Component {
    static getDerivedStateFromProps(nextProps, prevSate) {
        if ("value" in nextProps) {
            return Object.assign(
                {},
                {
                    value: nextProps.value || null
                },
                nextProps.value != null
                    ? {
                        fieldActive: nextProps.value != null ? true : false
                    }
                    : {}
            );
        }
        return null;
    }

    constructor(props) {
        super(props);
        this.state = {
            value: props.value || null,
            test: "",
            fieldActive: false
        };
    }
    triggerChange = changedValue => {
        const { onChange } = this.props;
        if (onChange) {
            onChange(changedValue);
        }
    };

    //Styling handler
    activateField = () => {
        this.setState({
            fieldActive: true
        });
    };
    // to deactivate input only if it's empty
    disableField = e => {
        if (e.target.value == "" || !e.target.value) {
            this.setState({
                fieldActive: false
            });
        }
    };


    onChange = e => {
        const { value } = e.target;
        const reg = /^-?(0|[1-9][0-9]*)(\.[0-9]*)?$/;
        if ((!isNaN(value) && reg.test(value)) || value === '' || value === '-') {
            this.props.onChange(value);
        }
    };

    render() {
        const { label, required = false, value, ...rest } = this.props;
        const { state } = this;

        return (
            <div className="custom-input">
                <label
                    className={
                        this.state.fieldActive
                            ? required
                                ? "field-active custom-input-label require-field-active"
                                : "field-active custom-input-label"
                            : required
                                ? "custom-input-label require-field-active"
                                : "custom-input-label"
                    }
                >
                    {label}
                </label>
                <Input
                    {...this.props}
                    onChange={this.onChange}
                    onFocus={this.activateField}
                    onBlur={this.disableField}
                />
            </div>
        );
    }
}

export default NumericInput;