import React, { Component, Fragment } from "react";
import ReactDOM from "react-dom";

import { Table, message, Spin, Statistic } from "antd";

//Component import
import ReportLayout from "../../../../pageLayouts/Report";
import { fetchApi, fetchExcelApi } from "../../../../../services/api";
//import MultiTable from "../MultiTableDataEntryStatus";
import MultiTable from "./Multitable";
import Empty from "../../../../components/Custom/Empty";

//Utility
import { sortFunction } from "../../../../../utility/sorter";
import { mapKeys, isEmpty, cloneDeep } from "lodash";
import moment from "moment";
import { formatDate } from "../../../../../utility/date";
import { parseData } from "./parseData";

class DataEntryStatusReport120Days extends Component {
  componentRef = React.createRef();
  state = {
    data: null,
    loading: false,
    blockNoList: [],
    block_uuid: null,
    DateOfPeriod: null,
    blockNumber: null
  };

  componentDidMount() {
    this.fetchBlockNo();
  }

  fetchDataEntryCount120Days = () => {
    let { block_uuid, DateOfPeriod, blockNumber } = this.state;
    if (DateOfPeriod) {
      this.setState({ loading: true });
      fetchApi(
        `/regular-reports/data-entry-count-120-days?month=${DateOfPeriod}&block.uuid=${block_uuid}`,
        "get"
      ).then(res => {
        if (res)
          this.setState(
            {
              // data: cloneDeep(this.state.DateOfPeriod)
              //   ? res.blocks
              //     ? res
              //     : null
              //   : null
              data: parseData(res)
            },
            () => console.log("clone deep", this.state.data)
          );
        else message.error("Internal Server Error!");
        this.setState({ loading: false });
      });
    }
  };

  fetchBlockNo = () =>
    fetchApi("/blocksuuid", "get").then(data =>
      this.setState({ blockNoList: sortFunction(data, "blockNoFull") })
    );

  onBlockChangeHandler = it => {
    /*if (!this.state.DateOfPeriod) {
      message.info("Select Period");
      this.setState({
        blockNumber: null,
        block_uuid: null
      });
    } else {*/
    this.setState(
      {
        blockNumber: it ? it : null,
        block_uuid: it
          ? cloneDeep(this.state.blockNoList).filter(
              each => each.blockNoFull === it
            )[0].uuid
          : null
      },
      () => this.fetchDataEntryCount120Days()
    );
  };

  dateOfPeriodHandler = date => {
    if (date) {
      //console.log("date", moment(date).format("YYYY-MM"));
      this.setState(
        {
          DateOfPeriod: date.format("YYYY-MM")
        },
        () => this.fetchDataEntryCount120Days()
      );
    } else {
      this.setState({
        DateOfPeriod: null,
        blockNumber: null,
        block_uuid: null,
        data: null
      });
    }
  };

  generateExcel = () => {
    let { block_uuid, DateOfPeriod, blockNumber } = this.state;
    fetchExcelApi(
      `/regular-reports/data-entry-count-120-days?month=${DateOfPeriod}&block.uuid=${block_uuid}`,
      "get"
    ).then();
  };

  render() {
    let { data, loading, DateOfPeriod, blockNoList, blockNumber } = this.state;
    const columns = [
      {
        title: "120 Days Count Entry",
        //dataIndex: "count_enrty"
        render: it =>
          it.count_enrty
            ? it.count_enrty.length > 10
              ? {
                  children: it.count_enrty,
                  props: {
                    colSpan: 6
                  }
                }
              : it.count_enrty
            : {
                children: it.total,
                props: {
                  colSpan: 1
                }
              }
      },
      {
        title: "Total No. of Control spathes",
        //dataIndex: "control_spathes"
        render: it =>
          it.count_enrty
            ? it.count_enrty.length > 10
              ? {
                  props: {
                    colSpan: 0
                  }
                }
              : it.control_spathes
            : it.control_spathes
      },
      {
        title: "Total No. Of Entered spathes",
        //dataIndex: "total_spathes"
        render: it =>
          it.count_enrty
            ? it.count_enrty.length > 10
              ? {
                  props: {
                    colSpan: 0
                  }
                }
              : it.total_spathes
            : it.total_spathes
      },
      {
        title: "Date Of Starting",
        //dataIndex: "start_date",
        //render: it => (it ? formatDate(it) : "-")
        render: it =>
          it.count_enrty
            ? it.count_enrty.length > 10
              ? {
                  props: {
                    colSpan: 0
                  }
                }
              : it.count_enrty == "Total"
              ? {
                  props: {
                    colSpan: 1
                  }
                }
              : it.start_date
              ? formatDate(it.start_date)
              : "-"
            : it.start_date
            ? formatDate(it.start_date)
            : "-"
      },
      {
        title: "Date Of Ending",
        //dataIndex: "end_date",
        //render: it => (it ? formatDate(it) : "-")
        render: it =>
          it.count_enrty
            ? it.count_enrty.length > 10
              ? {
                  props: {
                    colSpan: 0
                  }
                }
              : it.count_enrty == "Total"
              ? {
                  props: {
                    colSpan: 1
                  }
                }
              : it.start_date
              ? formatDate(it.end_date)
              : "-"
            : it.start_date
            ? formatDate(it.end_date)
            : "-"
      },
      {
        title: "Last Update Date",
        //dataIndex: "last_update_date",
        //render: it => (it ? formatDate(it) : "-")
        render: it =>
          it.count_enrty
            ? it.count_enrty.length > 10
              ? {
                  props: {
                    colSpan: 0
                  }
                }
              : it.count_enrty == "Total"
              ? {
                  props: {
                    colSpan: 1
                  }
                }
              : it.last_update_date
              ? formatDate(it.last_update_date)
              : "-"
            : it.last_update_date
            ? formatDate(it.last_update_date)
            : "-"
      }
    ];
    let JSX =
      data && data.blocks ? (
        <MultiTable columns={columns} dataSource={data} type={"120 Days"} />
      ) : DateOfPeriod ? (
        <Empty description="No Data Found" />
      ) : (
        <Empty description={"Select Period"} />
      );
    const buttonList =
      data && data.blocks
        ? [
            {
              label: "Excel",
              icon: "file-text",
              onClickHandler: () => this.generateExcel()
            },
            {
              label: "Print",
              icon: "diff",
              reference: this.componentRef
            }
          ]
        : null;
    return (
      <ReportLayout
        title="Data Entry Status Report 120 Days"
        buttonList={buttonList}
        dropDownList={[
          {
            label: "Block",
            listItems: cloneDeep(blockNoList).map(it => it.blockNoFull),
            value: blockNumber ? blockNumber : undefined,
            changeHandler: it => this.onBlockChangeHandler(it)
          },
          {
            label: "Period",
            hasInitial: false,
            type: "month",
            //value: DateOfPeriod ? DateOfPeriod : undefined,
            changeHandler: this.dateOfPeriodHandler
          }
        ]}
      >
        <div ref={this.componentRef}>
          <Spin spinning={loading} size="large">
            {JSX}
          </Spin>
        </div>
      </ReportLayout>
    );
  }
}

export default DataEntryStatusReport120Days;
