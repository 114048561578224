import { sortFunction } from "../../../../utility/sorter";
//Dwarf
export const parserFunctionDwarf = arr =>
  arr.map(({ block_no, areas }) => ({
    block_no,
    areas: areas.map(({ area_no, trees }) => ({
      area_no,
      tableData:
        trees &&
        trees.map(
          ({
            tree_no_full,
            code,
            years,
            no_of_buttons,
            no_of_spathes,
            count_120_days,
            harvested_nuts,
            prev_year,
            prev_no_of_buttons,
            prev_no_of_spathes,
            prev_count_120_days,
            prev_harvested_nuts
          }) => {
            let temp = {
              tree_no_full,
              code,
              no_of_buttons,
              no_of_spathes,
              count_120_days,
              harvested_nuts,
              prev_no_of_buttons,
              prev_no_of_spathes,
              prev_count_120_days,
              prev_harvested_nuts
            };
            // years && years.forEach(
            //   (
            //     { no_of_buttons, no_of_spathes, count_120_days, harvested_nuts },
            //     i
            //   ) => {
            //     temp[`no_of_buttons_${i}`] = no_of_buttons;
            //     temp[`no_of_spathes_${i}`] = no_of_spathes;
            //     temp[`count_120_days_${i}`] = count_120_days;
            //     temp[`harvested_nuts_${i}`] = harvested_nuts;
            //   }
            // );
            return temp;
          }
        )
    }))
  }));

//Tall
export const parserFunctionTall = arr =>
  arr &&
  arr.map(({ block_no, areas }) => ({
    block_no,
    areas: sortFunction(areas, "area_no"),
    areas:
      areas &&
      areas.map(({ area_no, trees }) => ({
        area_no,
        tableData:
          trees &&
          trees.map(
            ({
              tree_no_full,
              code,
              years,
              bunch_qty,
              harvested_nuts,
              prev_year,
              prev_bunch_qty,
              prev_harvested_nuts
            }) => {
              let temp = {
                tree_no_full,
                code,
                bunch_qty,
                harvested_nuts,
                prev_bunch_qty,
                prev_harvested_nuts
              };
              // years.forEach(({ bunches, harvested_nuts }, i) => {
              //   temp[`bunches_${i}`] = bunches
              //   temp[`harvested_nuts_${i}`] = harvested_nuts
              // }
              // )
              return temp;
            }
          )
      }))
  }));
