import React from "react";
//Utility
import { formatDate } from "../../../../utility/date";

const columns = [
  {
    title: "Tree No.",
    dataIndex: "tree_no_full",
    key: "tree_no_full"
  },
  {
    title: "Date of Planting",
    dataIndex: "dt_planted",
    key: "dt_planted",
    render: it => (it ? formatDate(it) : "-")
  },
  {
    title: "Opening Age",
    dataIndex: "opening_age",
    key: "opening_age"
    //render: it => (it ? formatDate(it) : "-")
  },
  {
    title: "Opening rate",
    dataIndex: "opening_rate",
    key: "opening_rate"
    //render: it => (it ? formatDate(it) : "-")
  }
];

export default columns;
