import React, { Component } from "react";

import { DatePicker } from "antd";

import "../Input/index.less";
import moment from "moment";

class CustomDatePicker extends React.Component {
  static getDerivedStateFromProps(nextProps, prevSate) {
    if ("value" in nextProps) {
      return Object.assign(
        {},
        {
          value: nextProps.value || null
        },
        {
          fieldActive: nextProps.value ? true : false
        }
      );
    }
    return null;
  }

  constructor(props) {
    super(props);
    this.state = {
      value: props.value || null,
      test: "",
      fieldActive: false
    };
  }

  triggerChange = changedValue => {
    const { onChange } = this.props;
    if (onChange) {
      onChange(changedValue);
    }
  };

  //Styling handler
  activateField = () => {
    this.setState({
      fieldActive: true
    });
  };

  // to deactivate input only if it's empty
  disableField = val => {
    if (val) {
      this.setState({
        fieldActive: false
      });
    }
  };
  disabledDatedate = date => {
    // Can not select days before today and today
    console.log("current", date);
    var d = new Date();
    console.log("d", d);
    return d && d < moment().endOf("day");
    //return dt => dt < moment().valueOf();
    //return current && current < moment().endOf("day");
  };

  render() {
    const {
      label,
      selectItems = [],
      required = false,
      value,
      ...rest
    } = this.props;
    const { state } = this;
    //console.log("date value", state.value);
    return (
      <div className="custom-input">
        <label
          className={
            this.state.fieldActive
              ? required
                ? "field-active custom-input-label require-field-active"
                : "field-active custom-input-label"
              : required
              ? "custom-input-label require-field-active"
              : "custom-input-label"
          }
        >
          {label}
        </label>
        <DatePicker
          placeholder=""
          value={state.value}
          onFocus={this.activateField}
          onBlur={this.disableField}
          //disabledDate={disabledDate(state.value)}
          allowClear
          {...rest}
        />
      </div>
    );
  }
}

export default CustomDatePicker;
