import { mapKeys, groupBy } from "lodash";
import { sortFunction } from "../../../../../utility/sorter";

/**
 * @name parseData
 * @description parses the array supplied for rendering in the table
 * @param {Array} arr
 */

export const parseData = arr => {
  arr.blocks &&
    arr.blocks.map(
      (
        { total_trees, total_good_spathes, total_premature_spathes, data },
        index
      ) => {
        var total = {
          dt_emasculation: "Total No. of trees in the Block : " + total_trees
        };
        data.unshift(total);
        data.map(
          (it, i) => {
            //   //if First Row
            //   if (i == 0) {
            //     it["dt_emasculation"] = arr.month;
            //     it["good_spathes"] = total_good_spathes;
            //     var obj = {};
            //     obj["dt_emasculation"] = "Total";
            //     obj["good_spathes"] = total_good_spathes;
            //     obj["premature_spathes"] = total_premature_spathes;
            //     obj["start_date"] = null;
            //     obj["end_date"] = null;
            //     obj["last_update_date"] = null;
            //     //if First and Last Row
            //     if (i == data.length - 1) {
            //       data.push(obj);
            //     }
            //   } //if Last Row
            //   else
            if (i == data.length - 1) {
              var obj = {};
              obj["dt_emasculation"] = "Total";
              obj["good_spathes"] = total_good_spathes;
              obj["premature_spathes"] = total_premature_spathes;
              obj["start_date"] = null;
              obj["end_date"] = null;
              obj["last_update_date"] = null;
              data.push(obj);
            }
          }
          //var tree_total = { total_trees: total_trees };
        );
      }
    );
  console.log("array", arr);
  return arr;
};
