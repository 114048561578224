import React, { Component } from "react";

//Component
import { message, Spin } from "antd";
import ReportLayout from "../../pageLayouts/Report";
import MultiTable from "./MultiTable";
import FarmTotal from "./FarmTotal";
import Empty from "../../components/Custom/Empty";

//Utility
import { fetchApi, fetchExcelApi } from "../../../services/api";
import { cloneDeep } from "lodash";
import moment from "moment";
import { sortFunction } from "../../../utility/sorter";
import { COOKIE, getCookie } from "../../../services/cookie";

class DwarfTreePlantWithNumberOfSpatheEmasculated extends Component {
  componentRef = React.createRef();
  state = {
    data: [],
    dynamicData: [],
    loading: false,
    blockNoList: [],
    blockNumber: null,
    treeTypeList: [],
    treeType: null,
    emasculationFrom: null,
    emasculationTo: null,
    noOfSpathes: null,
    farm_total: []
  };

  componentDidMount() {
    this.fetchBlockNo();
    this.fetchTreeType();
  }

  fetchDTPWithNumerOfSpatheEmasculation = () => {
    let {
      blockNumber,
      treeType,
      noOfSpathes,
      emasculationFrom,
      emasculationTo
    } = this.state;
    this.setState({data: []})
    if (noOfSpathes && emasculationFrom && emasculationTo) {
      let url = `/query-module/number-of-spathes-emasculated?fromDate=${emasculationFrom}&toDate=${emasculationTo}&noOfSpathes=${noOfSpathes}`;
      if (blockNumber) url += `&block.uuid=${blockNumber}`;
      if (treeType) url += `&tree_type.uuid=${treeType}`;
      this.setState({ loading: true });
      fetchApi(url, "get").then(res => {
        if (res) {
          let tmp = res.blocks;
          res.blocks =
            tmp &&
            tmp.map(({ list, ...rest }) => ({
              list: list.map(
                ({ dt_planted, dt_last_emasculation, ...rest }) => ({
                  dt_planted: moment(dt_planted).format("DD/MM/YYYY"),
                  dt_last_emasculation: moment(dt_last_emasculation).format(
                    "DD/MM/YYYY"
                  ),
                  ...rest
                })
              ),
              ...rest
            }));
          let { blocks, farm_total } = res;
          this.setState({
            data: blocks ? blocks : [],
            farm_total: farm_total ? farm_total : []
          });
        } else message.error("Internal Server Error!");
        this.setState({ loading: false });
      });
    }
  };

  fetchBlockNo = () =>
    fetchApi("/blocksuuid", "get").then(data =>
      this.setState({ blockNoList: sortFunction(data, "blockNoFull") })
    );

  fetchTreeType = () =>
    fetchApi("/tree_types?type=1", "get").then(data =>
      this.setState({ treeTypeList: data })
    );

  onDropDownChangeHandler = (type, item) => {
    switch (type) {
      case "block":
        this.setState(
          {
            blockNumber: item
              ? cloneDeep(this.state.blockNoList).filter(
                  each => each.blockNoFull === item
                )[0].uuid
              : null
          },
          () => this.fetchDTPWithNumerOfSpatheEmasculation()
        );
        break;
      case "tree":
        this.setState(
          {
            treeType: item
              ? cloneDeep(this.state.treeTypeList).filter(
                  each => each.code === item
                )[0].uuid
              : null
          },
          () => this.fetchDTPWithNumerOfSpatheEmasculation()
        );
        break;

      case "no-of-spathes":
        this.setState({ noOfSpathes: item ? item : null }, () =>
          this.fetchDTPWithNumerOfSpatheEmasculation()
        );
        break;

      default:
        this.setState(
          {
            emasculationFrom:
              item.length > 0 ? item[0].format("YYYY-MM-DD") : null,
            emasculationTo:
              item.length > 0 ? item[1].format("YYYY-MM-DD") : null
          },
          () => this.fetchDTPWithNumerOfSpatheEmasculation()
        );
    }
  };

  generateTables = tableData =>
  <div>
  <h3>Dwarf Trees/Plants with Spathe  {" <= "+this.state.noOfSpathes} 
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Farm
  : {getCookie(COOKIE.FARM).charAt(0).toUpperCase() + getCookie(COOKIE.FARM).slice(1)}{" "} </h3>
  <h3>Spathes Emasculated from {moment(this.state.emasculationFrom).format("DD/MM/YYYY")} to {moment(this.state.emasculationTo).format("DD/MM/YYYY")}</h3>
  {tableData.map(({ block_no, list, summary }, index) => (
      <MultiTable
      pagebreak={index==0 ? "" : "pagebreak"}
        key={index}
        blockNo={block_no}
        list={sortFunction(list,"tree_no_full") }
        summary={summary}
        listColumns={[
          {
            title: "Plant/Tree No.",
            dataIndex: "tree_no_full",
            key: "tree_no_full"
          },
          {
            title: "Date Of Planting",
            dataIndex: "dt_planted",
            key: "dt_planted"
          },
          {
            title: "Dwarf Type",
            dataIndex: "code",
            key: "code"
          },
          {
            title: "No. Of Spathes",
            dataIndex: "no_of_spathes",
            key: "no_of_spathes"
          },
          {
            title: "No.Of Buttons",
            dataIndex: "no_of_buttons",
            key: "no_of_buttons"
          },
          {
            title: "Last Date Of Emasculation",
            dataIndex: "dt_last_emasculation",
            key: "dt_last_emasculation"
          }
        ]}
        summaryColumns={[
          {
            title: "Dwarf Type",
            dataIndex: "code",
            render: it => (it ? it : <b>Block Total</b>)
          },
          { title: "No. Of Trees/Plants", dataIndex: "no_of_trees" }
        ]}
      />
    ))}
    </div>;

  generateFarmTotal = data =>
    !this.state.blockNumber ? (
      <FarmTotal
        data={data}
        columns={[
          {
            title: "Dwarf Type",
            dataIndex: "code",
            render: it => (it ? it : <b>Farm Total</b>)
          },
          { title: "No. Of Plants", dataIndex: "no_of_trees" }
        ]}
      />
    ) : null;

  generateExcel = () => {
    let {
      blockNumber,
      treeType,
      noOfSpathes,
      emasculationFrom,
      emasculationTo
    } = this.state;
    if (noOfSpathes && emasculationFrom && emasculationTo) {
      let url = `/query-module/number-of-spathes-emasculated?fromDate=${emasculationFrom}&toDate=${emasculationTo}&noOfSpathes=${noOfSpathes}`;
      if (blockNumber) url += `&block.uuid=${blockNumber}`;
      if (treeType) url += `&tree_type.uuid=${treeType}`;
      fetchExcelApi(url, "get").then();
    }
  };

  render() {
    let {
      data,
      treeTypeList,
      blockNoList,
      farm_total,
      loading,
      noOfSpathes,
      emasculationFrom,
      emasculationTo
    } = this.state;
    let JSX =
        data.length > 0 ? (
          this.generateTables(data)
        ) : emasculationTo && emasculationFrom && noOfSpathes ? (
          <Empty />
        ) : (
          <Empty
            description={"Select No. of Spathes and Spathes Emasculated date"}
          />
        ),
      farmTotalJSX =
        data.length > 0 && emasculationTo && emasculationFrom && noOfSpathes
          ? this.generateFarmTotal(farm_total)
          : null;
    const buttonList =
      data.length > 0 && emasculationTo && emasculationFrom && noOfSpathes
        ? [
            {
              label: "Excel",
              icon: "file-text",
              onClickHandler: () => this.generateExcel()
            },
            {
              label: "Print",
              icon: "diff",
              reference: this.componentRef
            }
          ]
        : null;

    return (
      <ReportLayout
        title="Dwarf Trees/Plants with No. of Spathe Emasculated"
        buttonList={buttonList}
        dropDownList={[
          {
            label: "Block",
            listItems: cloneDeep(blockNoList).map(it => it.blockNoFull),
            changeHandler: it => this.onDropDownChangeHandler("block", it)
          },
          {
            label: "Dwarf Type",
            listItems: cloneDeep(treeTypeList).map(it => it.code),
            changeHandler: it => this.onDropDownChangeHandler("tree", it)
          },
          {
            label: "Spathe <=",
            type: "number",
            changeHandler: it =>
              this.onDropDownChangeHandler("no-of-spathes", it)
          },
          {
            label: "Spathes emasculated:",
            type: "range",
            //mode: ["date", "date"],
            format: "DD/MM/YYYY",

            changeHandler: (date, string) =>
              this.onDropDownChangeHandler("emasDate", date)
          }
        ]}
      >
        <div ref={this.componentRef}>
          <Spin spinning={loading} size="large">
            {JSX}
            {farmTotalJSX}
          </Spin>
        </div>
      </ReportLayout>
    );
  }
}

export default DwarfTreePlantWithNumberOfSpatheEmasculated;
