export const parserFunction = arr => {
  let resultArray = [];

  Object.keys(arr).map((it, i) => {
    if (parseInt(arr[it].length) !== 11) {
      if (arr[it].length != undefined) {
        arr[it].map(({ stage, types }) => {
          resultArray.push({
            group_type:
              stage === "seedlings"
                ? `${it.toUpperCase()} SEEDLING`
                : stage === "plants"
                ? `${it.toUpperCase()} PLANTS`
                : stage === "trees"
                ? `${it.toUpperCase()} TREES`
                : null
          });
          types && types.length>0 ?
            types.forEach(({ code, ...rest }, ind) => {
              Object.keys(rest).map((item, index) => {
                resultArray.push({
                  group_type:
                    item === "opening_balance"
                      ? code === "Total" || code === "total"
                        ? `TOTAL ${it.toUpperCase()} ${stage.toUpperCase()}`
                        : code
                      : null,
                  description:
                    item === "opening_balance"
                      ? "OP. BALANCE"
                      : item === "added"
                      ? "Add:Seedling Planted/Transfer In"
                      : item === "removed"
                      ? "Less:Removal/Transfer Out"
                      : item === "cl_balance"
                      ? "CL. BALANCE"
                      : null,
                  ...rest[item]
                });
              });
            })
          : resultArray.push([])
        });
      } else {
        let pitgap = arr[it];
        Object.keys(pitgap).map((it, index) => {
          if (it !== "code") {
            resultArray.push({
              group_type:
                it === "opening_balance"
                  ? (pitgap["code"] === "Pits")
                    ? "PITS"
                    : "GAPS"
                  : null,
              description:
                it === "opening_balance"
                  ? "OP. BALANCE"
                  : it === "added"
                  ? "Add:Additions/Transfer In"
                  : it === "removed"
                  ? "Less: Seedlings planted/Transfer Out"
                  : it === "cl_balance"
                  ? "CL. BALANCE"
                  : null,
              ...pitgap[it]
            });
          }
        });
      }
    }
  });
  return resultArray;
};
