import React, { Component, Fragment } from "react";
import ReactDOM from "react-dom";

import { Table, message, Spin, Statistic } from "antd";

//Component import
import ReportLayout from "../../../pageLayouts/Report";
import { fetchApi, fetchExcelApi } from "../../../../services/api";
import MultiTable from "../MultiTable";
import Empty from "../../../components/Custom/Empty";

//Utility
import { sortFunction } from "../../../../utility/sorter";
import { mapKeys, isEmpty, cloneDeep } from "lodash";
import { parseData, parserFunction } from "./parserFunctions";
import moment from "moment";
import { COOKIE, getCookie } from "../../../../services/cookie";

const columns = [
  {
    title: "Tall Type",
    className: "reports-right-border",
    dataIndex: "type"
  },
  {
    title: "Seedling",
    className: "reports-right-border",
    children: [
    {
      title: "0-24",
      dataIndex: "zeroTwentyFour",
      render: it => (it ? it : 0)
    },
    {
      title: "25-48",
      dataIndex: "twentyFiveFourtyEight",
      render: it => (it ? it : 0)
    },
    {
      title: "49-60",
      dataIndex: "fourtyNineSixty",
      render: it => (it ? it : 0)
    },
    {
      title: "Total",
      dataIndex: "zeroSixty",
      className: "reports-right-border",
      render: it => (it ? it : 0)
    },
  ]},
  {
    title: "Plant",
    className: "reports-right-border",
    children: [
    {
      title: "61-72",
      dataIndex: "sixtyOneSeventyTwo",
      className: "reports-right-border",
      render: it => (it ? it : 0)
    },
  ]},
  {
    title: "Tree",
    className: "reports-right-border",
    children: [
    {
      title: "73-96",
      dataIndex: "seventyThreeNinetySixty",
      render: it => (it ? it : 0)
    },
    {
      title: "97-144",
      dataIndex: "ninetySevenOneFourtyFour",
      render: it => (it ? it : 0)
    },
    {
      title: "145-192",
      dataIndex: "oneFourtyFiveOneNinetyTwo",
      render: it => (it ? it : 0)
    },
    {
      title: "193-240",
      dataIndex: "OneNinetyThreeTwoFourty",
      render: it => (it ? it : 0)
    },
    {
      title: "241-288",
      dataIndex: "TwoFourtyOneTwoEightyEight",
      render: it => (it ? it : 0)
    },
    {
      title: ">288",
      dataIndex: "greaterTwoEightyEight",
      render: it => (it ? it : 0)
    },
    {
      title: "Total",
      dataIndex: "seventyThreeTwoEightyEight",
      className: "reports-right-border",
      render: it => (it ? it : 0)
    },
  ]},
  {
    title: "Tall Total",
    dataIndex: "zeroTwoEightyEight",
    render: it => (it ? it : 0)
  }
];

class TallAgeTypeWiseSummary extends Component {
  componentRef = React.createRef();
  state = {
    data: [],
    loading: false,
    farmTotal: [],
    blockNoList: [],
    block_uuid: null,
    DateOfSummary: null
  };

  componentDidMount() {
    //this.fetchTallSummary();
    this.fetchBlockNo();
  }

  fetchTallSummary = () => {
    this.setState({ loading: true });
    let { block_uuid, DateOfSummary } = this.state;
    //  today = moment().format("YYYY-MM-DD");
    //&block.uuid=${block_uuid}
    if (DateOfSummary) {
      fetchApi(
        `/tree-age/tall-summary?date=${DateOfSummary}&block.uuid=${block_uuid}`,
        "get"
      ).then(res => {
        if (res) {
          if (res.blocks)
            this.setState({
              data: parseData(cloneDeep(res.blocks)),
              farmTotal: res.farm_total
                ? parserFunction(
                    cloneDeep(res.farm_total.types),
                    cloneDeep(res.farm_total.totals)
                  )
                : []
            });
          else
            this.setState({
              data: null,
              farmTotal: []
            });
        } else message.error("Internal Server Error!");
        this.setState({ loading: false });
      });
    } else {
      this.setState({
        loading: false,
        data: []
      });
    }
  };

  fetchBlockNo = () =>
    fetchApi("/blocksuuid", "get").then(data =>
      this.setState({ blockNoList: sortFunction(data, "blockNoFull") })
    );

  generateTables = tableData =>
  <Fragment>
    <div className="description-table-header">
    <h3>
    Age Wise/Type Wise Summary Tall Tree/Plant/Seedling &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Farm
      : {getCookie(COOKIE.FARM).charAt(0).toUpperCase() + getCookie(COOKIE.FARM).slice(1)}{" "} </h3>
      </div>
      <div className="description-table-header">
      <h3>Summary As On {moment(this.state.DateOfSummary).format("DD/MM/YYYY")}</h3>
      </div>
      {tableData.map(({ block_no, summaryData }, index) => (
        <div className="printblock">
          <div className="description-table-header">
            <Statistic title={"Block No."} value={block_no} />
          </div>
          <br />
          <Table
            className="age-type-wise-summary-tall-table tree-agewise-summary-tables"
            pagination={false}
            dataSource={summaryData}
            columns={columns}
          />
          <br/><br/>
        </div>
      ))}
    </Fragment>

  onBlockChangeHandler = it =>
    this.setState(
      {
        block_uuid: it
          ? cloneDeep(this.state.blockNoList).filter(
              each => each.blockNoFull === it
            )[0].uuid
          : null
      },
      () => this.fetchTallSummary()
    );

  dateOfSummaryOnHandler = date => {
    if (date) {
      this.setState(
        {
          DateOfSummary: date.format("YYYY-MM-DD")
        },
        () => this.fetchTallSummary()
      );
    } else {
      this.setState(
        {
          DateOfSummary: null
        },
        () => this.fetchTallSummary()
      );
    }
  };

  generateExcel = () => {
    let { block_uuid, DateOfSummary } = this.state;
    //today = moment().format("YYYY-MM-DD");

    fetchExcelApi(
      `/tree-age/tall-summary?date=${DateOfSummary}&block.uuid=${block_uuid}`,
      "get"
    ).then();
  };

  render() {
    let { data, loading, farmTotal, blockNoList } = this.state;

    let JSX = data ? (
      this.generateTables(data)
    ) : (
      <Empty description={"No Data Found"} />
    );
    // data.length > 0 ? this.generateTables(data) : null;
    // console.log(farmTotal);
    const buttonList =
      data && data.length > 0
        ? [
            {
              label: "Excel",
              icon: "file-text",
              onClickHandler: () => this.generateExcel()
            },
            {
              label: "Print",
              icon: "diff",
              reference: this.componentRef
            }
          ]
        : null;
    return (
      <ReportLayout
        title="Age Wise/Type Wise Summary Tall Tree/Plant/Seedling"
        buttonList={buttonList}
        dropDownList={[
          {
            label: "Block",
            listItems: cloneDeep(blockNoList).map(it => it.blockNoFull),
            changeHandler: it => this.onBlockChangeHandler(it)
          },
          {
            label: "Age Summary As On",
            type: "date",
            //hasInitial: true,
            changeHandler: this.dateOfSummaryOnHandler
          }
        ]}
      >
        <div ref={this.componentRef}>
            <Spin spinning={loading} size="large">
              {data ? (
                data.length > 0 ? (
                  <div>
                    {JSX}
                    {farmTotal.length > 0 ? (
                      <Fragment>
                        <br />
                        <div className="printblock">
                          <div className="description-table-header">
                            <p className="block-wise-summary-farm-title" >Farm Total</p>
                          </div>
                          <Table
                            className="age-type-wise-summary-tall-table tree-agewise-summary-tables"
                            columns={columns}
                            pagination={false}
                            dataSource={farmTotal}
                          />
                        </div>
                      </Fragment>
                    ) : null}
                  </div>
                ) : (
                  <Empty description="Select Age Summary As On"></Empty>
                )
              ) : (
                <Empty></Empty>
              )}
            </Spin>
        </div>
      </ReportLayout>
    );
  }
}

export default TallAgeTypeWiseSummary;
