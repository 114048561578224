import React, { Component, Fragment } from "react";
import ReactDOM from "react-dom";
import { Table, message, notification, Icon, Tooltip, Spin } from "antd";

//Component import
import LocationPageBuilder from "../PageBuilder";
import Empty from "../../../components/Custom/Empty";

//Utility
import { sortFunctionDesc, sortFunction } from "../../../../utility/sorter";
import moment from "moment";
import { COOKIE, getCookie } from "../../../../services/cookie";
import { fetchApi, fetchExcelApi } from "../../../../services/api";
import { cloneDeep, filter } from "lodash";

class ValuationAmount extends Component {
  componentRef = React.createRef();
  state = {
    addModal: false,
    data: [],
    btnLoad: false,
    editData: null,
    isEdit: false,
    isDelete: false,
    tableLoader: false,
    pagination: true,
    isopen: false,
    clearFields: true,
    addPopulateData: null
  };

  componentDidMount() {
    this.setState({ tableLoader: true });
    this.fetchValuationAmount();
    const tableCon = ReactDOM.findDOMNode(this.refs["table"]),
      table = tableCon.querySelector("table"),
      header = table.createTHead(),
      row = header.insertRow(0);
    //cell = row.insertCell(0);
    row.innerHTML = `<h1>Valuation Amount &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Farm
    : ${getCookie(COOKIE.FARM)}${" "} </h1>`;
    table.setAttribute("id", "table-to-xls");
    table.setAttribute("class", "Dwarf-Tree-Types");
  }

  fetchValuationAmount = () =>
    fetchApi("/addition_charts", "get").then(data =>
      this.setState({
        tableLoader: false,
        data: sortFunction(data, "fromYear")
      })
    );

  addModalToggle = () => {
    let { addModal } = this.state;
    if (addModal) {
      this.setState(
        {
          addModal: false,
          editData: null,
          isEdit: false,
          isDelete: false,
          valuationUuid: null,
          addPopulateData: null
        }
        //() => this.fetchTreeTypes()
      );
    } else {
      this.setState({ addModal: !this.state.addModal });
    }
  };

  clearFields = () => {
    this.setState({
      addPopulateData: {
        fromYear: null,
        toYear: null,
        amount: null
      }
    });
  };

  addFormHandler = ({ fromYear, toYear, amount, ...val }) => {
    //to remove the previous instances of
    notification.destroy();

    let { isEdit, isDelete, valuationUuid } = this.state;
    this.setState({ btnLoad: true });
    if (!isEdit && !isDelete) {
      fetchApi("/addition_charts", "post", {
        amount: parseFloat(amount),
        fromYear: parseInt(moment(fromYear).format("YYYY")),
        toYear: parseInt(moment(toYear).format("YYYY")),
        ...val
      }).then(res => {
        if (res) {
          if (res.violations) {
            notification.error({
              message: "Failed",
              description: res.violations[0].message
            });
          } else {
            notification.success({
              message: "Success",
              description: "Successfully added Valuation Amount"
            });
            this.clearFields();
            this.fetchValuationAmount();
          }
        } else {
          notification.error({
            message: "Failed",
            description: "Failed to add Valuation Amount"
          });
        }
        this.setState({ btnLoad: false, addPopulateData: null });
      });
    } else if (isDelete) {
      fetchApi(`/addition_charts/${valuationUuid}`, "put", {
        isDeleted: true,
        dtUpdated: moment().format("YYYY-MM-DD")
      }).then(res => {
        if (res) {
          if (res.violations) {
            notification.error({
              message: "Failed",
              description: res.violations[0].message
            });
          } else {
            notification.success({
              message: "Success",
              description: "Successfully Deleted Valuation Amount"
            });
            this.fetchValuationAmount();
            this.addModalToggle()
          }
        } else {
          notification.error({
            message: "Failed",
            description: "Failed to Delete Valuation Amount"
          });
        }
        this.setState({ btnLoad: false }
          //, () => this.addModalToggle()
        );
      });
    } else {
      fetchApi(`/addition_charts/${valuationUuid}`, "put", {
        amount: parseFloat(amount),
        fromYear: parseInt(moment(fromYear).format("YYYY")),
        toYear: parseInt(moment(toYear).format("YYYY")),
        dtUpdated: moment().format("YYYY-MM-DD"),
        ...val
      }).then(res => {
        if (res) {
          if (res.violations) {
            notification.error({
              message: "Failed",
              description: res.violations[0].message
            });
          } else {
            notification.success({
              message: "Success",
              description: "Successfully Updated Valuation Amount"
            });
            this.fetchValuationAmount();
            this.addModalToggle();
          }
        } else {
          notification.error({
            message: "Failed",
            description: "Failed to Update Valuation Amount"
          });
        }
        this.setState({ btnLoad: false }
          //, () => this.addModalToggle()
        );
      });
    }
  };

  onActionClickHandler = (item, action) => {
    this.setState(
      {
        isEdit: action == "edit" ? true : false,
        isDelete: action == "delete" ? true : false
      },
      () => {
        this.setState({
          valuationUuid: item.uuid,
          addModal: true,
          editData: {
            fromYear: moment("01-01-" + item.fromYear),
            toYear: moment("01-01-" + item.toYear),
            amount: item.amount
          }
        });
      }
    );
  };

  onPanelChange = value => {
    if (value) {
      this.setState({
        isopen: false,
        addPopulateData: {
          fromYear: value,
          toYear: moment(value.format("YYYY")).add(1, "y")
        }
      });
    } else {
      this.setState({
        isopen: false,
        addPopulateData: {
          fromYear: null,
          toYear: null
        }
      });
    }
  };

  handleOpenChange = status => {
    if (status) {
      this.setState({ isopen: true });
    } else {
      this.setState({ isopen: false });
    }
  };

  render() {
    let {
      addModal,
      data,
      btnLoad,
      editData,
      pagination,
      tableLoader,
      isEdit,
      isDelete,
      isopen,
      addPopulateData
    } = this.state;
    const columns = [
      {
        title: "From Year",
        dataIndex: "fromYear",
        render: it => "April / " + it
      },
      {
        title: "To Year",
        dataIndex: "toYear",
        render: it => "March / " + it
      },
      {
        title: "Amount/Rs",
        dataIndex: "amount"
      },
      {
        title: "Actions",
        className: "action",
        render: it => (
          <Fragment>
            {console.log("testtesttest", moment(moment().format("YYYY/MM/DD")).diff(moment(it.toYear + "/03/31"), "months"))}
            {moment(moment().format("YYYY/MM/DD")).diff(moment(it.fromYear + "/04/01"), "months") <= 24
              //&& moment(moment().format("YYYY/MM/DD")).diff(moment(it.fromYear + "/04/01"), "months") >= 0
              && moment(moment().format("YYYY/MM/DD")).diff(moment(it.toYear + "/03/31"), "months") >= -24
              //&& moment(moment().format("YYYY/MM/DD")).diff(moment(it.toYear + "/03/31"), "months") <= 0
              ?
              <Fragment>
                <Tooltip placement="topRight" title={"Edit"}>
                  <Icon
                    type="edit"
                    className="table-action-btn"
                    onClick={() => this.onActionClickHandler(it, "edit")}
                  />
                </Tooltip>
                <Tooltip placement="topRight" title={"Delete"}>
                  <Icon
                    type="delete"
                    className="table-action-btn"
                    onClick={() => this.onActionClickHandler(it, "delete")}
                  />
                </Tooltip></Fragment> : null}
          </Fragment>
        )
      }
    ],
      formItems = isDelete
        ? [
          {
            name: "fromYear",
            type: "year",
            placeholder: "From Year",
            disabled: true
          },
          {
            name: "toYear",
            type: "year",
            placeholder: "To Year",
            disabled: true
          },
          {
            name: "amount",
            type: "number",
            placeholder: "Amount/Rs",
            disabled: true
          }
        ]
        : [
          {
            name: "fromYear",
            type: "year",
            onPanelChange: value => this.onPanelChange(value),
            onOpenChange: this.handleOpenChange,
            open: isopen,
            placeholder: "From Year"
          },
          {
            name: "toYear",
            type: "year",
            placeholder: "To Year",
            disabled: true
          },
          {
            name: "amount",
            type: "number",
            placeholder: "Amount/Rs"
          }
        ];

    const buttonList =
      data.length > 0
        ? [
          {
            label: "Add",
            icon: "folder-add",
            onClickHandler: this.addModalToggle
          },
          {
            label: "Print",
            icon: "diff",
            reference: this.componentRef
          }
        ]
        : [
          {
            label: "Add",
            icon: "folder-add",
            onClickHandler: this.addModalToggle
          }
        ];
    return (
      <LocationPageBuilder
        title="Valuation Amount"
        buttonList={buttonList}
        addModal={addModal}
        addPopulateData={addPopulateData}
        addModalToggle={this.addModalToggle}
        addFormHandler={this.addFormHandler}
        editData={editData ? editData : null}
        btnLoad={btnLoad}
        formItems={formItems}
        isEdit={isEdit}
        isDelete={isDelete}
      >
        <div ref={this.componentRef}>
          {/* <h1>
            Dwarf Tree Type Master - List &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            Farm : {getCookie(COOKIE.FARM)}{" "}
          </h1> */}
          <Spin spinning={tableLoader} size="large">
            <Table
              ref="table"
              columns={columns}
              dataSource={data}
              pagination={!pagination ? pagination : { pageSize: 15 }}
              //loading={tableLoader}
              locale={{
                emptyText: <Empty />
              }}
            />
          </Spin>
        </div>
      </LocationPageBuilder>
    );
  }
}

export default ValuationAmount;
