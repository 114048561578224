//Dwarf
export const parserFunction = arr =>
  arr.map(({ block_no, trees }) => ({
    block_no,
    tableData: trees&&trees.map(({ tree_no_full, code, months }) => {
      let temp = { tree_no_full, code };
      months.forEach(
        (
          {
            no_of_buttons,
            no_of_spathes,
            count_120_days,
            harvested_nuts,
            count_120_percent,
            harvest_percent
          },
          i
        ) => {
          temp[`no_of_buttons_${i}`] = no_of_buttons;
          temp[`no_of_spathes_${i}`] = no_of_spathes;
          temp[`count_120_days_${i}`] = count_120_days;
          temp[`harvested_nuts_${i}`] = harvested_nuts;
          temp[`count_120_percent_${i}`] = count_120_percent;
          temp[`harvest_percent_${i}`] = harvest_percent;
        }
      );
      return temp;
    })
  }));
