import React, { Component, Fragment } from "react";
import ReactDOM from "react-dom";

import {
  Table,
  message,
  Skeleton,
  Statistic,
  Descriptions,
  Col,
  Spin
} from "antd";

//Component import
import ReportLayout from "../../../../pageLayouts/Report";
import { fetchApi, fetchExcelApi } from "../../../../../services/api";
import MultiTable from "../MultiTableDataEntryStatus";
import Empty from "../../../../components/Custom/Empty";

//Utility
import { COOKIE, getCookie } from "../../../../../services/cookie";
import { sortFunction } from "../../../../../utility/sorter";
import { mapKeys, isEmpty, cloneDeep } from "lodash";
import moment from "moment";

//mock
//import mock from "./mocData";
import { parseData } from "../../parserFunctionsTransfer";

const layout = {
  xs: {
    span: 6
  },
  sm: {
    span: 6
  },
  lg: {
    span: 4
  },
  md: {
    span: 4
  }
};

class DwarfSeedlingTransfer extends Component {
  componentRef = React.createRef();
  state = {
    data: [],
    loading: false,
    farmTotal: [],

    blockNoList: [],
    block_uuid: null,
    block_no: null,

    areaNoList: [],
    areaNumber: null,
    area_uuid: null,

    trans_mode: null,
    trans_mode_list: ["manual", "auto"],
    trans_code: 0,

    code: null,
    codeList: [],
    tree_type_uuid2: null,

    to_date: null,
    from_date: null,
    farm_count_auto: null,
    farm_count_manual: null
  };

  componentDidMount() {
    //this.fetchDataEntryTall();
    this.fetchBlockNo();
    this.fetchCodeList();
  }

  fetchTransferList = () => {
    var { to_date, from_date } = this.state;
    this.setState({ loading: true });
    let { block_uuid, area_uuid, trans_code, tree_type_uuid2 } = this.state;
    //today = moment().format("YYYY-MM");

    if (to_date && from_date) {
      let url = `/api/dwarves/get-dwarf-transfer-list?from_dt=${from_date}&to_dt=${to_date}&trans_mode=${trans_code}&stage_code=2`;
      if (block_uuid) url += `&block.uuid=${block_uuid}`;
      if (area_uuid) url += `&area.uuid=${area_uuid}`;
      if (tree_type_uuid2) url += `&tree_type.uuid=${tree_type_uuid2}`;

      fetchApi(url, "get").then(res => {
        if (res)
          this.setState(
            {
              data: res.data ? sortFunction(res.data, "block_no") : [],
              farm_count_auto: res.farm_count_auto,
              farm_count_manual: res.farm_count_manual,
              loading: false
            },
            () => console.log("Data is", res)
          );
        else message.error("Internal Server Error!");
        this.setState({ loading: false });
      });
    } else {
      this.setState({
        loading: false
      });
    }
  };

  fetchBlockNo = () =>
    fetchApi("/blocksuuid", "get").then(data =>
      this.setState({ blockNoList: sortFunction(data, "blockNoFull") })
    );

  fetchCodeList = () => {
    fetchApi(`/tree_types?type=1`, "get").then(res =>
      this.setState({ codeList: res }, () =>
        console.log("CodeList", this.state.codeList)
      )
    );
  };

  onBlockChangeHandler = it => {
    let { blockNoList } = this.state;
    if (it) {
      fetchApi(
        `/areasuuid?block.uuid=${
        cloneDeep(blockNoList).filter(item => item.blockNoFull == it)[0].uuid
        }`,
        "get"
      ).then(res => this.setState({ areaNoList: res }));
    }
    this.setState(
      {
        blockNumber: it ? it : null,
        block_uuid: it
          ? cloneDeep(blockNoList).filter(item => item.blockNoFull == it)[0]
            .uuid
          : null,
        areaNumber: null,
        area_uuid: null,
        areaNoList: []
        // code: null,
        // codeList: [],
        // tree_type_uuid2: null
      },
      () => this.fetchTransferList()
    );
  };

  onAreaChangeHandler = it => {
    let { areaNoList, blockNoList, blockNumber } = this.state;

    this.setState(
      {
        areaNumber: it ? it : null,
        area_uuid: it
          ? cloneDeep(areaNoList).filter(item => item.areaNo == it)[0].uuid
          : null
        // code: null,
        // codeList: [],
        // tree_type_uuid2: null
      },
      () => this.fetchTransferList()
    );
  };

  onCodeChangeHandler = it => {
    //console.log("It is", it);
    let { codeList } = this.state;
    this.setState(
      {
        code: it ? it : null,
        tree_type_uuid2: it
          ? cloneDeep(codeList).filter(item => item.code == it)[0].uuid
          : null
      },
      () => this.fetchTransferList()
    );
  };

  onTransferModeChange = it => {
    console.log("It is", it);
    if (it) {
      this.setState(
        { trans_mode: it, trans_code: it === "manual" ? 1 : 2 },
        () => this.fetchTransferList()
      );
    } else {
      this.setState({ trans_mode: null, trans_code: 0 }, () =>
        this.fetchTransferList()
      );
    }
  };

  // fromDateHandler = date => {
  //   if (date) {
  //     this.setState(
  //       {
  //         from_date: date.format("YYYY-MM-DD")
  //       },
  //       () => this.fetchTransferList()
  //     );
  //   } else {
  //     this.setState({
  //       from_date: null,
  //       blockNumber: null,
  //       block_uuid: null,
  //       // TreeType: null,
  //       farm_count_auto: null,
  //       farm_count_manual: null,
  //       data: []
  //     });
  //   }
  // };

  // toDateHandler = date => {
  //   if (date) {
  //     this.setState(
  //       {
  //         to_date: date.format("YYYY-MM-DD")
  //       },
  //       () => this.fetchTransferList()
  //     );
  //   } else {
  //     this.setState({
  //       to_date: null,
  //       blockNumber: null,
  //       block_uuid: null,
  //       // TreeType: null,
  //       data: [],
  //       farm_count_auto: null,
  //       farm_count_manual: null
  //     });
  //   }
  // };

  onRangeChangeHandler = date => {
    if (date.length > 0) {
      this.setState(
        {
          from_date: date[0].format("YYYY-MM-DD"),
          to_date: date[1].format("YYYY-MM-DD")
        },
        () => this.fetchTransferList()
      );
    } else {
      this.setState(
        {
          from_date: null,
          to_date: null,
          blockNumber: null,
          block_uuid: null,
          data: [],
          farm_count_auto: null,
          farm_count_manual: null
        },
        () => this.fetchTransferList()
      );
    }
  };

  generateTables = (tableData, columns) => {
    var { trans_code, farm_count_auto, farm_count_manual, from_date, to_date, data } = this.state;
    if (data === "No Data Found") {
      return <Empty description={"No Data Found"} />;
    } else
      return (
        <div>
          <h3>
            Dwarf Seedling Master - Transfer to Dwarf Plant &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          Farm : {getCookie(COOKIE.FARM).charAt(0).toUpperCase() + getCookie(COOKIE.FARM).slice(1)}{" "} </h3>
          <h3>Transfer Period :{" " + moment(from_date).format("DD/MM/YYYY")} - {moment(to_date).format("DD/MM/YYYY")}</h3>
          {tableData.map(
            (
              { areas, block_no, block_count_auto, block_count_manual },
              index
            ) => (
                <div key={index}>
                  {areas &&
                    areas.map(
                      (
                        { area_count_auto, area_count_manual, area_no, trees },
                        j
                      ) => (
                          <div key={j}
                            className={index == 0 && j == 0 ? "dwarf-list-report" : "pagebreak dwarf-list-report"}
                          >
                            {
                              <div>
                                <Col key={1} {...layout}>
                                  <Statistic title="Block No." value={block_no} />
                                </Col>
                                <Col key={2} {...layout}>
                                  <Statistic title="Area No." value={area_no} />
                                </Col>
                                <br></br>
                                <br></br>
                                <br></br>
                                <Table
                                  //className="block-wise-summary-dwarf-table"
                                  //key={j}
                                  className="bordered-table-head"
                                  columns={columns}
                                  dataSource={parseData(trees)}
                                  size="small"
                                  pagination={false}
                                />
                                <br></br>
                              </div>
                            }
                            {(area_count_manual || area_count_auto) && (
                              <div>
                                <Descriptions bordered>
                                  {trans_code === 1 || trans_code === 0 ? (
                                    <Descriptions.Item
                                      label="Area Wise Total"
                                      span={3}
                                    >
                                      {area_count_manual}
                                    </Descriptions.Item>
                                  ) : null}
                                  {/* {trans_code === 2 || trans_code === 0 ? (
                            <Descriptions.Item
                              label="Area Wise Total for Auto"
                              span={3}
                            >
                              {area_count_auto}
                            </Descriptions.Item>
                          ) : null} */}

                                  <Descriptions.Item span={6}></Descriptions.Item>
                                </Descriptions>
                              </div>
                            )}
                            {j == areas.length - 1 ? (
                              <br />
                            ) : (
                                <Fragment><br /><br /></Fragment>
                              )}
                          </div>
                        )
                    )}
                  {(block_count_manual || block_count_auto) && (
                    <div>
                      <Descriptions bordered>
                        {trans_code === 1 || trans_code === 0 ? (
                          <Descriptions.Item
                            label="Block Wise Total"
                            span={3}
                          >
                            {block_count_manual}
                          </Descriptions.Item>
                        ) : null}
                        {/* {trans_code === 2 || trans_code === 0 ? (
                    <Descriptions.Item
                      label="Block Wise Total for Auto"
                      span={3}
                    >
                      {block_count_auto}
                    </Descriptions.Item>
                  ) : null} */}
                      </Descriptions>
                    </div>
                  )}

                  {index == tableData.length - 1 ? (
                    <br />
                  ) : (
                      <Fragment><br /><br /></Fragment>
                    )}
                </div>
              )
          )}
          {(farm_count_manual || farm_count_auto) && (
            <Descriptions bordered>
              <Descriptions.Item label="Farm Total" span={3}>
                {farm_count_manual}
              </Descriptions.Item>

              {/* <Descriptions.Item label="Farm Count for Auto" span={3}>
              {farm_count_auto}
            </Descriptions.Item> */}
            </Descriptions>
          )}
        </div>
      );
  };
  generateExcel = () => {
    var { to_date, from_date } = this.state;

    let { block_uuid, area_uuid, trans_code, tree_type_uuid2 } = this.state;
    //today = moment().format("YYYY-MM");

    if (to_date && from_date) {
      let url = `/api/dwarves/get-dwarf-transfer-list?from_dt=${from_date}&to_dt=${to_date}&trans_mode=${trans_code}&stage_code=2`;
      if (block_uuid) url += `&block.uuid=${block_uuid}`;
      if (area_uuid) url += `&area.uuid=${area_uuid}`;
      if (tree_type_uuid2) url += `&tree_type.uuid=${tree_type_uuid2}`;

      fetchExcelApi(url, "get").then();
    }
  };

  render() {
    let {
      data,
      loading,
      farmTotal,
      blockNoList,
      blockNumber,
      to_date,
      from_date,
      areaNoList,
      areaNumber,
      trans_mode_list,
      trans_mode,
      codeList,
      code
    } = this.state;
    const buttonList =
      data.length > 0
        ? [
          {
            label: "Excel",
            icon: "file-text",
            onClickHandler: () => this.generateExcel()
          },
          {
            label: "Print",
            icon: "diff",
            reference: this.componentRef
          }
        ]
        : null;
    const columns = [
      {
        title: " Tree No.",
        dataIndex: "tree_no_full"
      },
      {
        title: "Tree Type",
        dataIndex: "code"
      },
      {
        title: "Date of Planting",
        dataIndex: "dt_planted",
        render: it => (it ? moment(it).format("DD/MM/YYYY") : null)
      },
      {
        title: "Transfer Date",
        dataIndex: "dt_stage2",
        render: it => (it ? moment(it).format("DD/MM/YYYY") : null)
      }
      // {
      //   title: "Transfer mode",
      //   dataIndex: "transfer_mode"
      // }
    ];

    let JSX = data.length > 0 ? this.generateTables(data, columns) : null;

    return (
      <ReportLayout
        title="Dwarf Seedling Master - Transfer to Dwarf Plant "
        buttonList={buttonList}
        dropDownList={[
          // {
          //   label: "From Date",
          //   type: "date",
          //   hasInitial: false,
          //   changeHandler: this.fromDateHandler
          // },
          // {
          //   label: "To Date",
          //   type: "date",
          //   hasInitial: false,
          //   changeHandler: this.toDateHandler
          // },
          {
            label: "Block",
            listItems: cloneDeep(blockNoList).map(it => it.blockNoFull),
            disabled: to_date && from_date ? false : true,
            changeHandler: it => this.onBlockChangeHandler(it)
          },
          {
            label: "Area",
            listItems: cloneDeep(areaNoList).map(it => it.areaNo),
            disabled: blockNumber ? false : true,
            value: areaNumber ? areaNumber : undefined,
            changeHandler: it => this.onAreaChangeHandler(it)
          },
          {
            label: "Type",
            listItems: cloneDeep(codeList).map(it => it.code),
            // disabled: areaNumber ? false : true,
            value: code ? code : undefined,
            changeHandler: it => this.onCodeChangeHandler(it)
          },
          {
            label: "Transfer Period:",
            type: "range",
            //mode: ["date", "date"],
            format: "DD/MM/YYYY",
            changeHandler: (date, string) => this.onRangeChangeHandler(date)
          }
          // {
          //   label: "Transfer Mode",
          //   listItems: trans_mode_list,
          //   disabled: to_date && from_date ? false : true,
          //   value: trans_mode ? trans_mode : undefined,
          //   changeHandler: it => this.onTransferModeChange(it)
          // }
        ]}
      >
        <div ref={this.componentRef}>
          <Spin spinning={loading} size="large">
            {data.length > 0 ? (
              <div>{JSX}</div>
            ) : to_date && from_date ? (
              <Empty description="No Data Found" />
            ) : (
                  // <MultiTable columns={columns} dataSource={data} />
                  <Empty
                    description={"Select Transfer From and Transfer To date"}
                  />
                )}
          </Spin>
        </div>
      </ReportLayout>
    );
  }
}

export default DwarfSeedlingTransfer;
