import React, { Component, Fragment } from "react";
import ReactDOM from "react-dom";

import { Table, message, Spin, Statistic, Descriptions, Col } from "antd";

//Component import
import ReportLayout from "../../../../pageLayouts/Report";
import { fetchApi, fetchExcelApi } from "../../../../../services/api";
import MultiTable from "../MultiTableDataEntryStatus";
import Empty from "../../../../components/Custom/Empty";

//Utility
import { COOKIE, getCookie } from "../../../../../services/cookie";
import { sortFunction } from "../../../../../utility/sorter";
import { mapKeys, isEmpty, cloneDeep } from "lodash";
import moment from "moment";
import { formatDate } from "../../../../../utility/date";

const layout = {
  xs: {
    span: 6
  },
  sm: {
    span: 6
  },
  lg: {
    span: 4
  },
  md: {
    span: 4
  }
};

class PitsAndGapsList extends Component {
  componentRef = React.createRef();
  state = {
    data: [],
    loading: false,
    blockNoList: [],
    block_uuid: null,
    blockNumber: null,

    areaNoList: [],
    areaNumber: null,
    area_uuid: null,

    total_gap: null,
    total_pit: null
  };

  componentDidMount() {
    this.fetchBlockNo();
    this.fetchPitsGaps();
  }

  fetchPitsGaps = () => {
    this.setState({ loading: true });

    let { area_uuid, block_uuid } = this.state,
      url = "/pit-gap-list";
    if (block_uuid) url += `?block.uuid=${block_uuid}`;
    if (area_uuid) url += `&area.uuid=${area_uuid}`;

    fetchApi(url, "get").then(res => {
      if (res)
        this.setState({
          data: res.data ? sortFunction(res.data, "block_no") : [],
          total_gap: res.total_gap ? res.total_gap : null,
          total_pit: res.total_pit ? res.total_pit : null
        });
      else message.error("Internal Server Error!");
      this.setState({ loading: false });
    });
  };

  fetchBlockNo = () =>
    fetchApi("/blocksuuid", "get").then(data =>
      this.setState({ blockNoList: sortFunction(data, "blockNoFull") })
    );

  onBlockChangeHandler = it => {
    let { blockNoList } = this.state;
    if (it) {
      fetchApi(
        `/areasuuid?block.uuid=${
        cloneDeep(blockNoList).filter(item => item.blockNoFull == it)[0].uuid
        }`,
        "get"
      ).then(res => this.setState({ areaNoList: res }));
    }
    this.setState(
      {
        blockNumber: it ? it : null,
        block_uuid: it
          ? cloneDeep(blockNoList).filter(item => item.blockNoFull == it)[0]
            .uuid
          : null,
        areaNumber: null,
        area_uuid: null,
        areaNoList: []
      },
      () => this.fetchPitsGaps()
    );
  };

  onAreaChangeHandler = it => {
    let { areaNoList, blockNoList, blockNumber } = this.state;

    this.setState(
      {
        areaNumber: it ? it : null,
        area_uuid: it
          ? cloneDeep(areaNoList).filter(item => item.areaNo == it)[0].uuid
          : null
      },
      () => this.fetchPitsGaps()
    );
  };

  generateTables = tableData => (
    <div>
      <h3>
        Pits/Gaps - List &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Farm
    : {getCookie(COOKIE.FARM).charAt(0).toUpperCase() + getCookie(COOKIE.FARM).slice(1)}{" "} </h3>
      {tableData.map(({ areas, block_no, total_gap, total_pit }, index) => (
        <div key={index}>
          {areas.map(({ area_no, total_gap, total_pit, trees }, j) => (
            <div key={j} className={index == 0 && j == 0 ? "pits-gaps-list" : "pagebreak pits-gaps-list"}>
              <Col key={"" + index + "A"} {...layout}>
                <Statistic title="Block No." value={block_no} />
              </Col>
              <Col key={"" + index + "B"} {...layout}>
                <Statistic title="Area No." value={area_no} />
              </Col>
              <Descriptions bordered className="p-3">
                <Descriptions.Item label="Pit/Gap" span={3}>
                  {trees &&
                    trees.map((it, i) => (
                      <Col key={"" + index + i} {...layout}>
                        <span key={i}>{it}</span>
                      </Col>
                    ))}
                </Descriptions.Item>

              </Descriptions>
              <br />
              <Descriptions bordered>
                <Descriptions.Item label={"Area Total Pits"} span={3}>
                  {total_pit}
                </Descriptions.Item>
                <Descriptions.Item label={"Area Total Gaps"} span={3}>
                  {total_gap}
                </Descriptions.Item>

              </Descriptions>
              {j == areas.length - 1 ? (
                <br />
              ) : (
                  <Fragment><br /><br /></Fragment>
                )}
            </div>
          ))}
          {(total_pit || total_gap) && (
            <Descriptions bordered>
              <Descriptions.Item label={"Block Total Pits"} span={3}>
                {total_pit}
              </Descriptions.Item>
              <Descriptions.Item label={"Block Total Gaps"} span={3}>
                {total_gap}
              </Descriptions.Item>
            </Descriptions>
          )}
          {index == tableData.length - 1 ? (
            <br />
          ) : (
              <Fragment><br /><br /></Fragment>
            )}
        </div>
      ))}
      {(this.state.total_pit || this.state.total_gap) && (
        <Descriptions bordered>
          <Descriptions.Item label={"Farm Total Pits"} span={3}>
            {this.state.total_pit}
          </Descriptions.Item>
          <Descriptions.Item label={"Farm Total Gaps"} span={3}>
            {this.state.total_gap}
          </Descriptions.Item>
        </Descriptions>
      )}
      <br></br>
      <br></br>
    </div>
  );

  generateExcel = () => {
    let { area_uuid, block_uuid } = this.state,
      url = "/pit-gap-list";
    if (block_uuid) url += `?block.uuid=${block_uuid}`;
    if (area_uuid) url += `&area.uuid=${area_uuid}`;

    fetchExcelApi(url, "get").then();
  };

  render() {
    let {
      data,
      loading,
      blockNoList,
      blockNumber,
      areaNumber,
      areaNoList
    } = this.state;
    let JSX =
      data.length > 0 ? (
        this.generateTables(data)
      ) : (
          <Empty description="No Data Found" />
        );
    const buttonList = data.length
      ? [
        {
          label: "Excel",
          icon: "file-text",
          onClickHandler: () => this.generateExcel()
        },
        {
          label: "Print",
          icon: "diff",
          reference: this.componentRef
        }
      ]
      : null;
    return (
      <ReportLayout
        title="Pits/Gaps - List"
        buttonList={buttonList}
        dropDownList={[
          {
            label: "Block",
            listItems: cloneDeep(blockNoList).map(it => it.blockNoFull),
            value: blockNumber ? blockNumber : undefined,
            changeHandler: it => this.onBlockChangeHandler(it)
          },
          {
            label: "Area",
            listItems: cloneDeep(areaNoList).map(it => it.areaNo),
            disabled: blockNumber ? false : true,
            value: areaNumber ? areaNumber : undefined,
            changeHandler: it => this.onAreaChangeHandler(it)
          }
        ]}
      >
        <div ref={this.componentRef}>
          <Spin spinning={loading} size="large" active paragraph={{ rows: 15 }}>
            {data ? (
              // <MultiTable columns={columns} dataSource={data} />
              <div>{JSX}</div>
            ) : (
                <Empty description={"Input Period"} />
              )}
          </Spin>
        </div>
      </ReportLayout>
    );
  }
}

export default PitsAndGapsList;
