import React, { Component, Fragment } from "react";
import ReactDOM from "react-dom";

import { Table, message, Spin, Statistic } from "antd";

//Component import
import ReportLayout from "../../../pageLayouts/Report";
import { fetchApi, fetchExcelApi } from "../../../../services/api";
import MultiTable from "./MultiTableWeeklySpathe";
import Empty from "../../../components/Custom/Empty";

//Utility
import { sortFunction } from "../../../../utility/sorter";
import { mapKeys, isEmpty, cloneDeep } from "lodash";
import moment from "moment";
import columns from "./columns";

class DwarfTreesPopulationMonthlyAverageHarvestedNuts extends Component {
  componentRef = React.createRef();

  state = {
    data: [],
    farm_total: null,
    coloums: [],
    loading: false,
    blockNoList: [],
    blockNumber: null,
    block_uuid: null,
    DwarfType: null,
    DwarfTypeList: [],
    DwarfType_uuid: null,
    isopen: false,
    formType: 1,
    period: []
  };

  componentDidMount() {
    this.fetchBlockNo();
    this.fetchDwarfType();
  }

  fetchDwarfTreesPopulation = () => {
    let { block_uuid, DwarfType_uuid, formType, period } = this.state;
    if (period.length > 0) {
      this.setState({ loading: true });
      fetchApi(
        `/regular-reports/dwarf-population?fromMonth=${period[0]}&toMonth=${period[1]}&block.uuid=${block_uuid}&formType=${formType}&type.uuid=${DwarfType_uuid}`,
        "get"
      ).then(res => {
        if (res)
          this.setState({
            data: res.blocks,
            farm_total: res.farm_total ? res.farm_total : null
          });
        else message.error("Internal Server Error!");
        this.setState({ loading: false });
      });
    } else {
      this.setState({
        data: [],
        farm_total: null
      });
    }
  };

  fetchBlockNo = () =>
    fetchApi("/blocksuuid", "get").then(data =>
      this.setState({ blockNoList: sortFunction(data, "blockNoFull") })
    );
  fetchDwarfType = () =>
    fetchApi("/tree_types?type=1", "get").then(data =>
      this.setState({ DwarfTypeList: data })
    );

  //year functions
  handleOpenChange = status => {
    if (status) {
      this.setState({ isopen: true });
    } else {
      this.setState({ isopen: false });
    }
  };

  onDropDownChangeHandler = (type, item) => {
    let { DwarfTypeList } = this.state;
    switch (type) {
      case "block":
        if (item) {
          this.setState(
            {
              blockNumber: item ? item : null,
              block_uuid: item
                ? cloneDeep(this.state.blockNoList).filter(
                    each => each.blockNoFull === item
                  )[0].uuid
                : null
            },
            () => this.fetchDwarfTreesPopulation()
          );
        } else {
          this.setState(
            {
              blockNumber: null,
              block_uuid: null
            },
            () => this.fetchDwarfTreesPopulation()
          );
        }
        break;

      case "formType":
        if (item) {
          if (item == "% of Trees") {
            this.setState(
              {
                formType: 2
              },
              () => this.fetchDwarfTreesPopulation()
            );
          } else {
            this.setState(
              {
                formType: 1
              },
              () => this.fetchDwarfTreesPopulation()
            );
          }
        }
        break;

      case "dwarfType":
        if (item) {
          this.setState(
            {
              DwarfType: item ? item : null,
              DwarfType_uuid: item
                ? cloneDeep(DwarfTypeList).filter(it => it.code == item)[0].uuid
                : null
            },
            () => this.fetchDwarfTreesPopulation()
          );
        } else {
          this.setState(
            {
              DwarfType: null,
              DwarfType_uuid: null
            },
            () => this.fetchDwarfTreesPopulation()
          );
        }
        break;

      default:
        if (item.length > 0) {
          this.setState(
            {
              value: item,
              period: [
                moment(item[0]).format("YYYY-MM"),
                moment(item[1]).format("YYYY-MM")
              ],
              isopen: false
            },
            () => this.fetchDwarfTreesPopulation()
          );
        } else {
          this.setState(
            {
              value: null,
              period: [],
              isopen: false,
              data: []
            },
            () => this.fetchDwarfTreesPopulation()
          );
        }
    }
  };

  generateExcel = () => {
    let { block_uuid, DwarfType_uuid, formType, period } = this.state;
    if (period.length > 0) {
      fetchExcelApi(
        `/regular-reports/dwarf-population?fromMonth=${period[0]}&toMonth=${period[1]}&block.uuid=${block_uuid}&formType=${formType}&type.uuid=${DwarfType_uuid}`,
        "get"
      ).then();
    }
  };

  render() {
    let {
      data,
      farm_total,
      loading,
      value,
      isopen,
      blockNoList,
      DwarfTypeList,
      blockNumber,
      DwarfType,
      period
    } = this.state;
    let JSX =
      data && data.length > 0 ? (
        <Fragment>
          <MultiTable
            columns={columns}
            dataSource={data}
            period={this.state.period}
          />
          <br />

          {farm_total && (
            <Fragment>
              <p>FARM TOTAL</p>{" "}
              <Table columns={columns} dataSource={farm_total} />
            </Fragment>
          )}
        </Fragment>
      ) : period ? (
        <Empty description="No Data Found" />
      ) : (
        <Empty description={"Select Period"} />
      );
    const buttonList =
      data && data.length > 0
        ? [
            {
              label: "Excel",
              icon: "file-text",
              onClickHandler: () => this.generateExcel()
            },
            {
              label: "Print",
              icon: "diff",
              reference: this.componentRef
            }
          ]
        : null;
    return (
      <ReportLayout
        title="Dwarf Trees population spread Typewise based on Monthly Average Harvested Nuts"
        buttonList={buttonList}
        dropDownList={[
          {
            label: "Block",
            listItems: cloneDeep(blockNoList).map(it => it.blockNoFull),
            value: blockNumber ? blockNumber : undefined,
            changeHandler: it => this.onDropDownChangeHandler("block", it)
          },
          {
            label: "Form Type",
            listItems: ["No. of Trees", "% of Trees"],
            hasInitial: true,
            changeHandler: it => this.onDropDownChangeHandler("formType", it)
          },
          {
            label: "Dwarf Type",
            listItems: cloneDeep(DwarfTypeList).map(it => it.code),
            value: DwarfType ? DwarfType : undefined,
            changeHandler: it => this.onDropDownChangeHandler("dwarfType", it)
          },
          {
            label: "Period",
            mode: ["month", "month"],
            value: value,
            type: "range",
            format: "MM/YYYY",
            open: isopen,
            showTime: true,
            onOk: date => this.onDropDownChangeHandler("period", date),
            onOpenChange: this.handleOpenChange,
            onPanelChange: val => this.setState({ value: val }),
            changeHandler: () => this.onDropDownChangeHandler("period", [])
          }
        ]}
      >
        <div ref={this.componentRef}>
          <Spin spinning={loading} size="large">
            {JSX}
          </Spin>
        </div>
      </ReportLayout>
    );
  }
}

export default DwarfTreesPopulationMonthlyAverageHarvestedNuts;
