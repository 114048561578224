import React from "react";
import { Table, Col, Statistic, Row } from "antd";
import ScollBarView from "react-double-scrollbar";
import { COOKIE, getCookie } from "../../../../services/cookie";

const layout = {
  xs: {
    span: 6
  },
  sm: {
    span: 6
  },
  lg: {
    span: 4
  },
  md: {
    span: 4
  }
};
function JSX(tableData, columns) {
  //Data Divide Part
  let rowLen = tableData.length;
  let colLen = Object.keys(tableData[0]).length;
  let start = 2;
  let end = 2;
  var finalData = [];

  while (end < colLen) {
    start = end;
    if (start + 12 <= colLen) end = start + 12;
    else end = start + 6;
    var data = [];

    for (var i = 0; i < rowLen; i++) {
      var temp = {};
      temp[Object.keys(tableData[i])[0]] = Object.values(tableData[i])[0];
      temp[Object.keys(tableData[i])[1]] = Object.values(tableData[i])[1];
      var k = start;
      while (k < end) {
        temp[Object.keys(tableData[i])[k]] = Object.values(tableData[i])[k];
        k++;
      }
      data.push(temp);
    }
    finalData.push(data);
  }

  //Divide Columns
  let colrowLen = columns.length;
  let colstart = 1;
  let colend = 1;
  let noOfTtables = Math.ceil((colrowLen - 1) / 2);
  var colfinalData = [];
  for (var i = 0; i < noOfTtables; i++) {
    colstart = colend;
    if (colstart + 2 <= colrowLen) colend = colstart + 2;
    else colend = colstart + 1;
    var temp = [];
    temp.push(Object.values(columns)[0]);
    var k = colstart;
    var coldata = [];
    while (k < colend) {
      temp.push(Object.values(columns)[k]);
      k++;
    }
    colfinalData.push(temp);
  }

  //Display Table
  var tables = [];
  for (i = 0; i < finalData.length; i++) {
    tables.push(
      <div>
        <Table
          //className="block-wise-summary-dwarf-table"
          //className="bordered-table-head printblock"
          bordered
          columns={colfinalData[i]}
          dataSource={finalData[i]}
          size="middle"
          pagination={false}
        />
        <br />
        <br />
      </div>
    );
  }
  return tables;
}
export default ({ dataSource, columns, title }) =>
  <div>
  <h3>{title} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Farm
    : {getCookie(COOKIE.FARM).charAt(0).toUpperCase() + getCookie(COOKIE.FARM).slice(1)}{" "} </h3>
  {dataSource.map(({ tableData, ...rest }, index) => (
    <div key={index}>
      <br />
      <Row gutter={5}>
        {/* {Object.keys(rest).map((it, index) => (
          <Col key={index} {...layout}>
            {
              <Statistic
                title={it.split("_").map(it => `${it.toUpperCase()} `)}
                value={rest[it]}
              />
            }
          </Col>
        ))} */}
        <Col key={rest.block_no} {...layout}>
          <Statistic title={"Block No."} value={rest.block_no} />
          </Col>
      </Row>
      <br />

      {JSX(tableData, columns)
      // <Table
      //   //className="block-wise-summary-dwarf-table"
      //   className="bordered-table-head"
      //   bordered
      //   columns={columns}
      //   dataSource={tableData}
      //   size="small"
      //   pagination={false}
      // />
      }
    </div>

  ))}</div>;
