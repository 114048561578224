import React from "react";
//Utility
import { formatDate } from "../../../../utility/date";

const columns = [
  // {
  //   title: "Block No.",
  //   dataIndex: "block_no_full",
  //   key: "block_no_full"
  // },
  // {
  //   title: "Tree Type",
  //   dataIndex: "treeType",
  //   key: "treeType"
  // },
  // {
  //   title: "Category",
  //   dataIndex: "stage_code",
  //   key: "stage_code"
  // },
  {
    title: "Tree No.",
    dataIndex: "tree_no_full",
    key: "tree_no_full"
  },
  {
    title: "Category",
    dataIndex: "stage_code",
    key: "stage_code",
    render: it => (it == 1 ? "Seedling" : it == 2 ? "Plant" : "Tree")
  },
  {
    title: "Date of Planting",
    dataIndex: "dt_planted",
    key: "dt_planted",
    render: it => (it ? formatDate(it) : "-")
  },

  {
    title: "Opening Age",
    dataIndex: "opening_age",
    key: "opening_age"
    //render: it => (it ? formatDate(it) : "-")
  },
  {
    title: "Opening rate",
    dataIndex: "opening_rate",
    key: "opening_rate"
    //render: it => (it ? formatDate(it) : "-")
  },
  {
    title: "Closing Age",
    dataIndex: "closing_age",
    key: "closing_age"
    //render: it => (it ? formatDate(it) : "-")
  },
  {
    title: "Closing rate",
    dataIndex: "closing_rate",
    key: "closing_rate"
    //render: it => (it ? formatDate(it) : "-")
  },
  {
    title: "Depreciation",
    dataIndex: "depriciation",
    key: "depriciation"
    //render: it => (it ? formatDate(it) : "-")
  }
];

export default columns;
