import React, { Component, Fragment } from "react";
import ReactDOM from "react-dom";

import { Table, message, Spin, Statistic, Col, Row, Descriptions } from "antd";

//Component import
import ReportLayout from "../../../pageLayouts/Report";
import { fetchApi, fetchExcelApi } from "../../../../services/api";
import Empty from "../../../components/Custom/Empty";

//Utility
import { mapKeys, isEmpty, cloneDeep } from "lodash";
import { sortFunction } from "../../../../utility/sorter";
//import { parserFunction } from "./parserFunctions";
import moment from "moment";
import columns from "./columns";
import { ftruncate } from "fs";

const layout = {
  xs: {
    span: 6
  },
  sm: {
    span: 6
  },
  lg: {
    span: 4
  },
  md: {
    span: 4
  }
};

class SeedlingWiseDwarfTallTypeReport extends Component {
  componentRef = React.createRef();
  state = {
    data: [],
    loading: false,
    blockNoList: [],
    block_uuid: null,
    type: 1,
    treetype: "Dwarf",
    plantedDateFrom: null,
    plantedDateTo: null
  };

  componentDidMount() {
    this.fetchBlockNo();
  }
  fetchSeedlingWiseDwarfTallTypeReport = () => {
    let { block_uuid, type, plantedDateFrom, plantedDateTo } = this.state;
    if (type && plantedDateFrom && plantedDateTo) {
      this.setState({ loading: true });
      let url = `/regular-reports/seedlings-list?type=${type}&fromDate=${plantedDateFrom}&toDate=${plantedDateTo}`;
      if (block_uuid) url += `&block.uuid=${block_uuid}`;
      fetchApi(url, "get").then(res => {
        if (res) {
          this.setState({
            data: res.blocks,
            farm_total: res.farm_total
          });
        } else message.error("Internal Server Error!");
        this.setState({ loading: false });
      });
    }
  };

  fetchBlockNo = () =>
    fetchApi("/blocksuuid", "get").then(data =>
      this.setState({ blockNoList: sortFunction(data, "blockNoFull") })
    );

  onBlockChangeHandler = it => {
    let { blockNoList } = this.state;
    this.setState(
      {
        blockNumber: it ? it : null,
        block_uuid: it
          ? cloneDeep(blockNoList).filter(each => each.blockNoFull === it)[0]
              .uuid
          : null
      },
      () => this.fetchSeedlingWiseDwarfTallTypeReport()
    );
  };

  onPeriodChangeHandler = it => {
    this.setState(
      {
        plantedDateFrom: it.length > 0 ? it[0].format("YYYY-MM-DD") : null,
        plantedDateTo: it.length > 0 ? it[1].format("YYYY-MM-DD") : null
      },
      () => this.fetchSeedlingWiseDwarfTallTypeReport()
    );
  };

  onTreeTypeChangeHandler = it => {
    this.setState(
      {
        type: it == "Dwarf" ? 1 : it == "Tall" ? 2 : null,
        treetype: it
      },
      () => this.fetchSeedlingWiseDwarfTallTypeReport()
    );
  };

  generateExcel = () => {
    let { block_uuid, type, plantedDateFrom, plantedDateTo } = this.state;

    if (type && plantedDateFrom && plantedDateTo) {
      let url = `/regular-reports/seedlings-list?type=${type}&fromDate=${plantedDateFrom}&toDate=${plantedDateTo}`;
      if (block_uuid) url += `&block.uuid=${block_uuid}`;
      fetchExcelApi(url, "get").then();
    }
  };

  render() {
    let {
      data,
      loading,
      blockNoList,
      blockNumber,
      type,
      plantedDateFrom,
      plantedDateTo,
      treetype,
      farm_total
    } = this.state;
    const buttonList =
      data && data.length > 0
        ? [
            {
              label: "Excel",
              icon: "file-text",
              onClickHandler: () => this.generateExcel()
            },
            {
              label: "Print",
              icon: "diff",
              reference: this.componentRef
            }
          ]
        : null;
    const JSX =
      data && data.length > 0 ? (
        data.map(({ block_no_full, trees, block_total }, index) => (
          <div className={index == 0 ? "" : "pagebreak"} key={index}>
            {trees && (
              <Fragment>
                <div
                //className="printblock"
                >
                  <Row gutter={5}>
                    <Col key={index} {...layout}>
                      <div
                      //className="multi-table-header"
                      >
                        <Statistic title="Block No." value={block_no_full} />
                      </div>
                    </Col>
                  </Row>
                  <Table
                    className="bordered-table-head"
                    bordered
                    columns={columns}
                    dataSource={trees}
                    bordered
                    size="small"
                    pagination={false}
                    key={index}
                  />
                  <br />
                   <Descriptions bordered>
                      <Descriptions.Item label="Block Total Trees" span={3}>
                        {block_total}
                      </Descriptions.Item>
                    </Descriptions>
                </div>
              </Fragment>
            )}
            <br />
            <br />
          </div>
        )) 
      ) : type && plantedDateFrom && plantedDateTo ? (
        <Empty />
      ) : (
        <Empty description="Select Period" />
      );

    return (
      <ReportLayout
        //title="Seedling Wise Dwarf/Tall Type Report"
        title="New Addition Dwarf/Tall Type Report"
        buttonList={buttonList}
        dropDownList={[
          {
            label: "Block",
            listItems: cloneDeep(blockNoList).map(it => it.blockNoFull),
            value: blockNumber ? blockNumber : undefined,
            changeHandler: it => this.onBlockChangeHandler(it)
          },
          {
            label: "Tree Type",
            listItems: ["Dwarf", "Tall"],
            hasInitial: true,
            changeHandler: it => this.onTreeTypeChangeHandler(it)
          },
          {
            label: "Period:",
            type: "range",
            //mode: ["date", "date"],
            format: "DD/MM/YYYY",
            changeHandler: it => this.onPeriodChangeHandler(it)
          }
        ]}
      >
        <div ref={this.componentRef}>
          <Spin spinning={loading} size="large">
            {data && data.length > 0 ? (
              <Fragment>
                <h3>
                  New Addition {treetype} Type Report for the Period of{" "}
                  {moment(plantedDateFrom).format("DD MMM YYYY") + " "}to{" "}
                  {moment(plantedDateTo).format("DD MMM YYYY")}{" "}
                </h3>
                {JSX}
                {
                  farm_total?
                    <Descriptions bordered>
                      <Descriptions.Item label="Farm Total Trees" span={3}>
                        {farm_total}
                      </Descriptions.Item>
                    </Descriptions>
                    :null
                }
              </Fragment>
            ) : type && plantedDateFrom && plantedDateTo ? (
              <Empty />
            ) : (
              <Empty description="Select Period" />
            )}
          </Spin>
        </div>
      </ReportLayout>
    );
  }
}
export default SeedlingWiseDwarfTallTypeReport;
