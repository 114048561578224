import React, { Component, Fragment } from "react";
import { message, Spin, Empty } from "antd";
//Component import
import ReportLayout from "../../../pageLayouts/Report";
import { fetchApi, fetchExcelApi } from "../../../../services/api";
import MultiTable from "../MultiTable3";
//Utility
import { sortFunction } from "../../../../utility/sorter";
import { cloneDeep } from "lodash";
import { parserFunction } from "./parserFunctions";
import { COOKIE, getCookie } from "../../../../services/cookie";

const columns = [
  {
    title: "Type",
    dataIndex: "type"
  },
  {
    title: "Seedling",
    dataIndex: "seedling",
    render: it => (it ? it : 0)
  },
  {
    title: "Plant",
    dataIndex: "plant",
    render: it => (it ? it : 0)
  },
  {
    title: "Tree",
    dataIndex: "tree",
    render: it => (it ? it : 0)
  }
];

class BlockWiseSummary extends Component {
  componentRef = React.createRef();
  state = {
    data: [],
    loading: false,
    farmTotal: [],
    blockNoList: [],
    block_uuid: null
  };

  componentDidMount() {
    this.fetchBlockWiseSummary();
    this.fetchBlockNo();
  }

  fetchBlockWiseSummary = () => {
    this.setState({ loading: true });
    let { block_uuid } = this.state;
    let url = "/tree-age/block-wise-summary";
    if (block_uuid) url += `?block.uuid=${block_uuid}`;
    fetchApi(url, "get").then(res => {
      if (res)
        this.setState({
          data: parserFunction(cloneDeep(res.blocks && res.blocks.length>0 ? res.blocks : [] )),
          farmTotal: res.farm_total
            ? parserFunction(cloneDeep([res.farm_total]), 1)
            : []
        });
      else message.error("Internal Server Error!");
      this.setState({ loading: false });
    });
  };

  fetchBlockNo = () =>
    fetchApi("/blocksuuid", "get").then(data =>
      this.setState({ blockNoList: sortFunction(data, "blockNoFull") })
    );

  generateTables = tableData =>
    tableData.map(({ block_no, dwarfs, talls, pitGap }, index) =>
      //, block_total
      pitGap ? (
        <MultiTable
          pagebreak={parseInt(index) === 0 ? "" : "pagebreak"}
          lastIndex={tableData.length}
          key={index}
          dwarfs={dwarfs}
          talls={talls}
          pitGap={pitGap}
          //block_total={block_total}
          columns={columns}
          headerItems={[{ title: "Block No.", value: block_no }]}
        />
      ) : null
    );

  onBlockChangeHandler = it =>
    this.setState(
      {
        block_uuid: it
          ? cloneDeep(this.state.blockNoList).filter(
              each => each.blockNoFull === it
            )[0].uuid
          : null
      },
      () => this.fetchBlockWiseSummary()
    );

  generateExcel = () => {
    let { block_uuid } = this.state,
      url = "/tree-age/block-wise-summary";

    if (block_uuid) url += `?block.uuid=${block_uuid}`;

    fetchExcelApi(url, "get").then();
  };

  render() {
    let { data, loading, farmTotal, blockNoList } = this.state;
    let JSX =
      data && data.length > 0 ? (
        <Fragment>
          <h3>
            Block Wise Summary All Types Tree/Plant/Seedling/Pit/Gap
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Farm :{" "}
            {getCookie(COOKIE.FARM)
              .charAt(0)
              .toUpperCase() + getCookie(COOKIE.FARM).slice(1)}{" "}
          </h3>
          {this.generateTables(data)}
        </Fragment>
      ) : (
        <Empty description="No Data Found" />
      );

    return (
      <ReportLayout
        title="Block Wise Summary All Types Tree/Plant/Seedling/Pit/Gap"
        buttonList={
          data && data.length > 0 ? [
          {
            label: "Excel",
            icon: "file-text",
            onClickHandler: () => this.generateExcel()
          },
          {
            label: "Print",
            icon: "diff",
            //toolTip: "Suggestion: Print in Landscape",
            reference: this.componentRef
          }
        ] : null
      }
        dropDownList={[
          {
            label: "Block",
            listItems: cloneDeep(blockNoList).map(it => it.blockNoFull),
            changeHandler: it => this.onBlockChangeHandler(it)
          }
        ]}
      >
        <div ref={this.componentRef}>
          <Spin spinning={loading} size="large">
            {JSX}
            {farmTotal.map(({ dwarfs, talls, pitGap }, index) => (
              dwarfs || talls|| pitGap ?
                <MultiTable
                  pagebreak="pagebreak"
                  key={index}
                  dwarfs={dwarfs}
                  talls={talls}
                  pitGap={pitGap}
                  columns={columns}
                  headerItems={[{ title: "Farm total", value: null }]}
                />
              : null
            ))}
          </Spin>
        </div>
      </ReportLayout>
    );
  }
}

export default BlockWiseSummary;
