//import { sortFunction } from "../../../utility/sorter";
import { months } from "moment";

export const parseData = arr => {
  // let resultArray = [];

  // var Areas = [];
  var Area = [];
  var Block = [];
  //var treeData = [];
  var Data = [];
  var months = [];
  var Years = [];
  var Nuts = [];
  //Months
  var MM = [
    "Jan",
    "Feb",
    "March",
    "April",
    "May",
    "June",
    "July",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  ];
  console.log("Arraa", arr);

  arr &&
    arr.map(({ block_no, trees }, index) => {
      trees &&
        trees.map(
          ({ area_no, code, dt_planted, tree_no, row_no, years }, j) => {
            years &&
              years.map(({ nuts, year }, k) => {
                if (nuts) {
                  Nuts.push({
                    january: nuts[0].harqty,
                    february: nuts[1].harqty,
                    march: nuts[2].harqty,
                    april: nuts[3].harqty,
                    may: nuts[4].harqty,
                    june: nuts[5].harqty,
                    july: nuts[6].harqty,
                    august: nuts[7].harqty,
                    september: nuts[8].harqty,
                    october: nuts[9].harqty,
                    november: nuts[10].harqty,
                    december: nuts[11].harqty,
                    total: nuts[12].total,
                    year: year
                  });
                }

                Years = [];
              });
            Data.push({
              treeIndex: j,
              block_no: block_no,
              tree_no: tree_no,
              area_no: area_no,
              code: code,
              dt_planted: dt_planted,
              row_no: row_no,
              Nuts: Nuts
            });
            Nuts = [];
          }
        );
    });

  console.log("Data", Data);
  return Data;
};
