import React from "react";
import { Table, Col, Statistic, Row } from "antd";
import ScollBarView from "react-double-scrollbar";
import moment from "moment";

const layout = {
  xs: {
    span: 6
  },
  sm: {
    span: 6
  },
  lg: {
    span: 4
  },
  md: {
    span: 4
  }
};

let columns = [
  {
    title: "Tree No.",
    dataIndex: "tree_no_full"
    //render: it => (it ? it : "-")
  },
  {
    title: "Date Planted",
    dataIndex: "dt_planted",
    render: it => moment(it).format("DD/MM/YYYY")
    //render: it => (it ? it : "-")
  },
  // {
  //   title: "Date Died",
  //   dataIndex: "dt_died",
  //   render: it => moment(it).format("DD/MM/YYYY")
  //   //render: it => (it ? it : "-")
  // },
  {
    title: "Age In Months",
    dataIndex: "months"
    //render: it => (it ? it : "-")
  },
  {
    title: "Age In Years",
    dataIndex: "years"
    //render: it => (it ? it : "-")
  },
  {
    title: "Evaluation Rate",
    dataIndex: "evaluation_rate"
    //render: it => (it ? it : "-")
  },
  {
    title: "Depreciation Amount",
    dataIndex: "depreciation_amount"
    //render: it => (it ? it : "-")
  }
];

export default ({ dataSource }) =>
  dataSource.map(({ block_no, trees }, j) => (
    <div>
      <br></br>
      <Statistic title="BLOCK NO" value={block_no} />
      <br></br>
      <Table
        //className="block-wise-summary-dwarf-table"
        //key={j}
        size="small"
        columns={columns}
        dataSource={trees}
        pagination={false}
      />
    </div>
  ));
