import { sortFunction } from "../../../utility/sorter";

export const parseData = arr => {
  // let resultArray = [];

  // var Areas = [];
  var Area = [];
  var Block = [];

  arr.map(({ block_no, areas, block_codes, block_total }, i) => {
    Area = [];
    console.log("Areasss", areas);
    areas &&
      areas.map(({ area_count, area_no, tree_nos, area_codes }, j) => {
        // area_codes.map(({},k)=>{

        // })

        Area.push({
          area_count: area_count,
          block_no: block_no,
          area_no: area_no,
          tree_nos: tree_nos,
          area_codes: area_codes
        });
      });
    // if (block_total > 0) {
    //   Block.push({
    //     block_no: block_no,
    //     Area: Area,
    //     block_codes: block_codes,
    //     block_total: block_total
    //   });
    // }
    Block.push({
      block_no: block_no,
      Area: Area,
      block_codes: block_codes ? block_codes : null,
      block_total: block_total ? block_total : null
    });
  });

  console.log("hiii", Block);

  return sortFunction(Block, "block_no");
};
