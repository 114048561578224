import React from "react";

import OneTwentyDaysCount from "./OneTwentyDaysCount";
import DwarfTreeHarvestData from "./DwarfTreeHarvestData";
import TallTreeHarvestData from "./TallTreeHarvestData";
import SeedlingGrowthDataForDwarfTrees from "./SeedlingGrowthDataForDwarfTrees";
import SeedlingGrowthDataForTallTrees from "./SeedlingGrowthDataForTallTrees";


export default [
  {
    label: "120 Days Count",
    key: "1",
    link: "/input-formats/120-days-count",
    render: () => <OneTwentyDaysCount />
  },
  {
    label: "Dwarf Tree Harvest Data",
    key: "2",
    link: "/input-formats/dwarf-tree-harvest-data",
    render: () => <DwarfTreeHarvestData />
  },
  {
    label: "Tall Tree Harvest Data",
    key: "3",
    link: "/input-formats/tall-tree-harvest-data",
    render: () => <TallTreeHarvestData />
  },
  {
    label: "Seedling Growth Data For Dwarf Trees",
    key: "4",
    link: "/input-formats/seedling-growth-data-for-dwarf-trees",
    render: () => <SeedlingGrowthDataForDwarfTrees />
  },
  {
    label: "Seedling Growth Data For Tall Trees",
    key: "5",
    link: "/input-formats/seedling-growth-data-for-tall-trees",
    render: () => <SeedlingGrowthDataForTallTrees/>
  }
];
