import React from "react";
// import MonthlyFarmProductionActivitiesReportAllBlocks from "./MonthlyFarmProductionActivitiesReportAllBlocks"
// import MonthwiseFarmProductionActivitiesReportPeriodGroup from './MonthwiseFarmProductionActivitiesReportPeriodGroup'
// import WeeklySpatheEmasculationDataSummaryAllBlocks from './WeeklySpatheEmasculationDataSummaryAllBlocks'
// import DwarfTreesPopulationMonthlyAverageHarvestedNuts from './DwarfTreesPopulationMonthlyAverageHarvestedNuts'
// import TallTreesPopulationMonthlyAverageHarvestedNuts from './TallTreesPopulationMonthlyAverageHarvestedNuts'
// import DataEntryStatusReport120Days from './DataEntryStatusReportHO/DataEntryStatusReport120Days'
// import DataEntryStatusReportEmasculation from './DataEntryStatusReportHO/DataEntryStatusReportEmasculation'
// import DataEntryStatusReportGrowthTallDwarf from './DataEntryStatusReportHO/DataEntryStatusReportGrowthTallDwarf'
// import DataEntryStatusReportHarvestTallDwarf from './DataEntryStatusReportHO/DataEntryStatusReportHarvestTallDwarf'
// import DataEntryStatusReportTallMalePollenCollection from './DataEntryStatusReportHO/DataEntryStatusReportTallMalePollenCollection'
// import TreeWisePerformanceComparison3yearsPeriod from './TreeWisePerformanceComparison3yearsPeriod'
// import MonthlyMovementStatementSeedlingsPlantsTreesPitsGaps from './MonthlyMovementStatementSeedlingsPlantsTreesPitsGaps'
// import MonthwiseFarmPerformanceReportPeriodGroup from './MonthwiseFarmPerformanceReportPeriodGroup'

import DwarfPlantAreaList from "./DwarfPlant/DwarfPlantAreaList";
import DwarfPlantTypeList from "./DwarfPlant/DwarfPlantTypeList";
import DwarfPlantTransfer from "./DwarfPlant/DwarfPlantTransfer";
import DwarfPlantView from "./DwarfPlant/DwarfPlantView";

import DwarfSeedlingAreaList from "./DwarfSeedling/DwarfSeedlingAreaList";
import DwarfSeedlingTransfer from "./DwarfSeedling/DwarfSeedlingTransfer";
import DwarfSeedlingTypeList from "./DwarfSeedling/DwarfSeedlingTypeList";

import DwarfTreeAreaList from "./DwarfTree/DwarfTreeAreaList";
import DwarfTreeTypeList from "./DwarfTree/DwarfTreeTypeList";
import DwarfTreeView from "./DwarfTree/DwarfTreeView";

import DwarfTreeResearchAreaList from "./DwarfTreeResearch/DwarfTreeResearchAreaList";
import DwarfTreeResearchCodeList from "./DwarfTreeResearch/DwarfTreeResearchCodeList";

import PitsAndGapsList from "./PitsAndGaps/PitsAndGapsList";
import PitsAndGapsTransfer from "./PitsAndGaps/PitsAndGapsTransfer";

import TallPlantTypeList from "./TallPlant/TallPlantTypeList";
import TallPlantAreaList from "./TallPlant/TallPlantAreaList";
import TallPlantTransfer from "./TallPlant/TallPlantTransfer";

import TallSeedlingTypeList from "./TallSeedling/TallSeedlingTypeList";
import TallSeedlingAreaList from "./TallSeedling/TallSeedlingAreaList";
import TallSeedlingTransfer from "./TallSeedling/TallSeedlingTransfer";

import TallTreeTypeList from "./TallTree/TallTreeTypeList";
import TallTreeAreaList from "./TallTree/TallTreeAreaList";
import TallTreeView from "./TallTree/TallTreeView";

import TallTreeResearchAreaList from "./TallTreeResearch/TallTreeResearchAreaList";
import TallTreeResearchCodeList from "./TallTreeResearch/TallTreeResearchCodeList";

import DwarfTreeEmasculationList from "./DwarfTreeEmasculationList";
import DwarfTrees120DaysCountList from "./DwarfTrees120DaysCountList";

import TallTreeHarvestList from "./HarvestList/TallTreeHarvestsList";
import DwarfTreeHarvestList from "./HarvestList/DwarfTreeHarvestsList";

export default [
  {
    label: "Pits / Gaps",
    key: "1",
    render: null,
    link: [
      "/other-reports/pits-and-gaps/pits-and-gaps-list",
      "/other-reports/pits-and-gaps/pits-and-gaps-transfer"
    ],
    children: [
      {
        label: "List",
        key: "1",
        link: "/other-reports/pits-and-gaps/pits-and-gaps-list",
        render: () => <PitsAndGapsList />
      },
      {
        label: "Transfer",
        key: "2",
        link: "/other-reports/pits-and-gaps/pits-and-gaps-transfer",
        render: () => <PitsAndGapsTransfer />
      }
    ]
  },
  {
    label: "Dwarf Seedling",
    key: "2",
    render: null,
    link: [
      "/other-reports/dwarf-seedling/dwarf-seedling-type-list",
      "/other-reports/dwarf-seedling/dwarf-seedling-area-list",
      "/other-reports/dwarf-seedling/dwarf-seedling-transfer"
    ],
    children: [
      {
        label: "Type List",
        key: "1",
        link: "/other-reports/dwarf-seedling/dwarf-seedling-type-list",
        render: () => <DwarfSeedlingTypeList />
      },
      {
        label: "Area List",
        key: "2",
        link: "/other-reports/dwarf-seedling/dwarf-seedling-area-list",
        render: () => <DwarfSeedlingAreaList />
      },
      {
        label: "Transfer",
        key: "3",
        link: "/other-reports/dwarf-seedling/dwarf-seedling-transfer",
        render: () => <DwarfSeedlingTransfer />
      }
    ]
  },
  {
    label: "Dwarf Plant",
    key: "3",
    render: null,
    link: [
      "/other-reports/dwarf-plant/dwarf-plant-type-list",
      "/other-reports/dwarf-plant/dwarf-plant-area-list",
      "/other-reports/dwarf-plant/dwarf-plant-transfer",
      "/other-reports/dwarf-plant/dwarf-plant-view"
    ],
    children: [
      {
        label: "Type List",
        key: "1",
        link: "/other-reports/dwarf-plant/dwarf-plant-type-list",
        render: () => <DwarfPlantTypeList />
      },
      {
        label: "Area List",
        key: "2",
        link: "/other-reports/dwarf-plant/dwarf-plant-area-list",
        render: () => <DwarfPlantAreaList />
      },
      {
        label: "Transfer",
        key: "3",
        link: "/other-reports/dwarf-plant/dwarf-plant-transfer",
        render: () => <DwarfPlantTransfer />
      },
      {
        label: "View",
        key: "4",
        link: "/other-reports/dwarf-plant/dwarf-plant-view",
        render: () => <DwarfPlantView />
      }
    ]
  },
  {
    label: "Dwarf Tree",
    key: "4",
    render: null,
    link: [
      "/other-reports/dwarf-tree/dwarf-tree-type-list",
      "/other-reports/dwarf-tree/dwarf-tree-area-list",
      "/other-reports/dwarf-tree/dwarf-tree-view"
    ],
    children: [
      {
        label: "Type List",
        key: "1",
        link: "/other-reports/dwarf-tree/dwarf-tree-type-list",
        render: () => <DwarfTreeTypeList />
      },
      {
        label: "Area List",
        key: "2",
        link: "/other-reports/dwarf-tree/dwarf-tree-area-list",
        render: () => <DwarfTreeAreaList />
      },
      {
        label: "View",
        key: "3",
        link: "/other-reports/dwarf-tree/dwarf-tree-view",
        render: () => <DwarfTreeView />
      }
    ]
  },
  {
    label: "Tall Seedling",
    key: "5",
    render: null,
    link: [
      "/other-reports/tall-seedling/tall-seedling-type-list",
      "/other-reports/tall-seedling/tall-seedling-area-list",
      "/other-reports/tall-seedling/tall-seedling-transfer"
    ],
    children: [
      {
        label: "Type List",
        key: "1",
        link: "/other-reports/tall-seedling/tall-seedling-type-list",
        render: () => <TallSeedlingTypeList />
      },
      {
        label: "Area List",
        key: "2",
        link: "/other-reports/tall-seedling/tall-seedling-area-list",
        render: () => <TallSeedlingAreaList />
      },
      {
        label: "Transfer",
        key: "3",
        link: "/other-reports/tall-seedling/tall-seedling-transfer",
        render: () => <TallSeedlingTransfer />
      }
    ]
  },
  {
    label: "Tall Plant",
    key: "6",
    render: null,
    link: [
      "/other-reports/tall-plant/tall-plant-type-list",
      "/other-reports/tall-plant/tall-plant-area-list",
      "/other-reports/tall-plant/tall-plant-transfer"
    ],
    children: [
      {
        label: "Type List",
        key: "1",
        link: "/other-reports/tall-plant/tall-plant-type-list",
        render: () => <TallPlantTypeList />
      },
      {
        label: "Area List",
        key: "2",
        link: "/other-reports/tall-plant/tall-plant-area-list",
        render: () => <TallPlantAreaList />
      },
      {
        label: "Transfer",
        key: "3",
        link: "/other-reports/tall-plant/tall-plant-transfer",
        render: () => <TallPlantTransfer />
      }
    ]
  },
  {
    label: "Tall Tree",
    key: "7",
    render: null,
    link: [
      "/other-reports/tall-tree/tall-tree-type-list",
      "/other-reports/tall-tree/tall-tree-view",
      "/other-reports/tall-tree/tall-tree-area-list"
    ],
    children: [
      {
        label: "Type List",
        key: "1",
        link: "/other-reports/tall-tree/tall-tree-type-list",
        render: () => <TallTreeTypeList />
      },
      {
        label: "Area List",
        key: "2",
        link: "/other-reports/tall-tree/tall-tree-area-list",
        render: () => <TallTreeAreaList />
      },
      {
        label: "View",
        key: "3",
        link: "/other-reports/tall-tree/tall-tree-view",
        render: () => <TallTreeView />
      }
    ]
  },
  {
    label: "Dwarf Tree Research",
    key: "8",
    render: null,
    link: [
      "/other-reports/dwarf-tree-research/dwarf-tree-research-code-list",
      "/other-reports/dwarf-tree-research/dwarf-tree-research-area-list"
    ],
    children: [
      {
        label: "Code List",
        key: "1",
        link:
          "/other-reports/dwarf-tree-research/dwarf-tree-research-code-list",
        render: () => <DwarfTreeResearchCodeList />
      },
      {
        label: "Area List",
        key: "2",
        link:
          "/other-reports/dwarf-tree-research/dwarf-tree-research-area-list",
        render: () => <DwarfTreeResearchAreaList />
      }
    ]
  },
  {
    label: "Tall Tree Research",
    key: "9",
    render: null,
    link: [
      "/other-reports/tall-tree-research/tall-tree-research-code-list",
      "/other-reports/tall-tree-research/tall-tree-research-area-list"
    ],
    children: [
      {
        label: "Code List",
        key: "1",
        link: "/other-reports/tall-tree-research/tall-tree-research-code-list",
        render: () => <TallTreeResearchCodeList />
      },
      {
        label: "Area List",
        key: "2",
        link: "/other-reports/tall-tree-research/tall-tree-research-area-list",
        render: () => <TallTreeResearchAreaList />
      }
    ]
  },
  {
    label: "Dwarf Tree Emasculation List",
    key: "10",
    link: "/other-reports/dwarf-tree-emasculation-list",
    render: () => <DwarfTreeEmasculationList />
  },
  {
    label: "Dwarf Tree 120 Days Count List",
    key: "11",
    link: "/other-reports/dwarf-trees-120-days-count-list",
    render: () => <DwarfTrees120DaysCountList />
  },
  {
    label: "Harvest List",
    key: "12",
    render: null,
    link: [
      "/other-reports/harvests-list/dwarf-tree-harvest-list",
      "/other-reports/harvests-list/tall-tree-harvest-list"
    ],
    children: [
      {
        label: "Dwarf Tree Harvest List",
        key: "1",
        link: "/other-reports/harvests-list/dwarf-tree-harvest-list",
        render: () => <DwarfTreeHarvestList />
      },
      {
        label: "Tall Tree Harvest List",
        key: "2",
        link: "/other-reports/harvests-list/tall-tree-harvest-list",
        render: () => <TallTreeHarvestList />
      }
    ]
  }
];
