import React, { Component } from "react";

//Component
import { message, Spin } from "antd";
import ReportLayout from "../../../pageLayouts/Report";
import MultiTable from "./MultiTable";
import Empty from "../../../components/Custom/Empty";

//Utility
import { sortFunction } from "../../../../utility/sorter";
import { fetchApi } from "../../../../services/api";
import { cloneDeep } from "lodash";
import moment from "moment";

import dummyData from "./Deletion.json";

class PerformanceComparisonReport extends Component {
  componentRef = React.createRef();
  state = {
    data: [],
    coloums: [],
    loading: false,

    blockNoList: [],
    blockNumber: null,
    block_uuid: null,

    areaNumber: null,
    area_uuid: null,
    areaNoList: [],

    isopen: false,
    period: [],
    periodVS: null,
    formType: null,
    title: "Age Of Flowering",

    to_date: null,
    from_date: null,
    treeType: null
  };

  componentDidMount() {
    this.fetchBlockNo();
    this.fetchDeletionReport();
  }

  fetchDeletionReport = () => {
    // let { block_uuid, area_uuid } = this.state,
    //   url = `/special-reports/age-of-flowering-dwarf-trees`;
    // if (block_uuid) url += `?block.uuid=${block_uuid}`;
    // if (area_uuid) url += `&area.uuid=${area_uuid}`;
    //this.setState({ loading: true });
    // fetchApi(url, "get").then(res => {
    //   if (res) {
    //     this.setState({
    //       data: res.blocks
    //       //title: res.title
    //     });
    //   } else {
    //     message.error("Internal Server Error!");
    //     this.setState({
    //       data: null
    //     });
    //   }
    //   this.setState({ loading: false });
    // });
    this.setState({ data: dummyData.blocks });
  };

  fetchBlockNo = () =>
    fetchApi("/blocksuuid", "get").then(data =>
      this.setState({ blockNoList: sortFunction(data, "blockNoFull") })
    );

  onBlockChangeHandler = it => {
    let { blockNoList } = this.state;

    if (it) {
      fetchApi(
        `/areasuuid?block.uuid=${
          cloneDeep(blockNoList).filter(item => item.blockNoFull == it)[0].uuid
        }`,
        "get"
      ).then(res => this.setState({ areaNoList: res }));
    }

    this.setState(
      {
        blockNumber: it ? it : null,
        block_uuid: it
          ? cloneDeep(blockNoList).filter(item => item.blockNoFull == it)[0]
              .uuid
          : null,
        areaNumber: null,
        area_uuid: null
        //areaNoList: null
      },
      () => this.fetchDeletionReport()
    );
  };

  // onAreaChangeHandler = it => {
  //   let { areaNoList } = this.state;
  //   this.setState(
  //     {
  //       areaNumber: it ? it : null,
  //       area_uuid: it
  //         ? cloneDeep(areaNoList).filter(item => item.areaNo == it)[0].uuid
  //         : null
  //     },
  //     () => this.fetchDeletionReport()
  //   );
  // };

  //generateTables = tableData => <MultiTable dataSource={tableData} />;

  fromDateHandler = date => {
    if (date) {
      this.setState(
        {
          from_date: date.format("YYYY-MM-DD")
        },
        () => this.fetchDeletionReport()
      );
    } else {
      this.setState(
        {
          from_date: null
        },
        () => this.fetchDeletionReport()
      );
    }
  };

  toDateHandler = date => {
    if (date) {
      this.setState(
        {
          to_date: date.format("YYYY-MM-DD")
        },
        () => this.fetchDeletionReport()
      );
    } else {
      this.setState(
        {
          to_date: null
        },
        () => this.fetchDeletionReport()
      );
    }
  };

  onTreeTypeChangeHandler = it => {
    if (it) {
      if (it == "Dwarf") {
        this.setState({ treeType: "Dwarf" }, () => this.fetchDeletionReport());
      } else {
        this.setState({ treeType: "Tall" }, () => this.fetchDeletionReport());
      }
    } else {
      this.setState({ treeType: null }, () => this.fetchDeletionReport());
    }
  };

  render() {
    let {
      data,
      blockNoList,
      blockNumber,
      loading,
      areaNoList,
      areaNumber,
      treeType
    } = this.state;

    let JSX = <MultiTable dataSource={data} />;

    const buttonList = data
      ? [
          {
            label: "Excel",
            icon: "file-text"
          },
          {
            label: "Print",
            icon: "diff",
            reference: this.componentRef
          }
        ]
      : null;

    return (
      <ReportLayout
        title={"Dwarf Tree Evaluation Report"}
        buttonList={buttonList}
        dropDownList={[
          {
            label: "Block",
            listItems: cloneDeep(blockNoList).map(it => it.blockNoFull),
            value: blockNumber ? blockNumber : undefined,
            changeHandler: it => this.onBlockChangeHandler(it)
          },
          // {
          //   label: "Area",
          //   listItems: cloneDeep(areaNoList).map(it => it.areaNo),
          //   disabled: blockNumber ? false : true,
          //   value: areaNumber ? areaNumber : undefined,
          //   changeHandler: it => this.onAreaChangeHandler(it)
          // }
          {
            label: "From",
            type: "date",
            hasInitial: false,
            changeHandler: this.fromDateHandler
          },
          {
            label: "To",
            type: "date",
            hasInitial: false,
            changeHandler: this.toDateHandler
          }

          // {
          //   label: "Tree Type",
          //   listItems: ["Dwarf", "Tall"],
          //   //disabled: blockNumber ? false : true,
          //   value: treeType ? treeType : undefined,
          //   changeHandler: it => this.onTreeTypeChangeHandler(it)
          // }
        ]}
      >
        <div ref={this.componentRef}>
          <Spin spinning={loading} size="large">
            {data ? (
              data.length > 0 ? (
                <div> {JSX}</div>
              ) : (
                <Empty description={"Select a Block No."}></Empty>
              )
            ) : (
              <Empty></Empty>
            )}
          </Spin>
        </div>
      </ReportLayout>
    );
  }
}

export default PerformanceComparisonReport;
