import React, { Component, Fragment } from "react";
import ReactDOM from "react-dom";
import { Table, message, notification, Icon, Tooltip, Spin } from "antd";

//Component import
import LocationPageBuilder from "../PageBuilder";
import Empty from "../../../components/Custom/Empty";

//Utility
import { sortFunction } from "../../../../utility/sorter";
import { COOKIE, getCookie } from "../../../../services/cookie";
import { fetchApi } from "../../../../services/api";
import { cloneDeep, filter } from "lodash";

class PitsGaps extends Component {
  componentRef = React.createRef();
  state = {
    addModal: false,
    data: [],
    dynamicTableData: [],
    btnLoad: false,
    pagination: true,
    tableLoader: false,

    blockNumber: null,
    blockNoList: [],
    block_uuid: null,

    areaNumber: null,
    areaNoList: [],
    area_uuid: null,
    formareaNumber: null,
    formarea_uuid: null,

    rowNumber: null,
    rowNoList: [],
    row_uuid: null,

    pitsGapNumber: null,
    pitsGapsNoList: [],
    pitsGaps_uuid: null,

    editData: null,
    isEdit: false,
    isDelete: false,
    isTransfer: false,

    blockUuid: null,
    areaUuid: null,
    rowUuid: null,

    pitGapUuid: null,
    pitGapNum: null,
    pitGapSuffix: null,

    treeTypeCodeList: [],
    treeTypeCode: null,
    treeType_uuid: null,
    growthCode: null,

    addPopulateData: null,
    isAdded: false
  };

  componentDidMount() {
    this.setState({ tableLoader: true });
    this.fetchPitsGaps();
    this.fetchBlockNo();
    const tableCon = ReactDOM.findDOMNode(this.refs["table"]),
      table = tableCon.querySelector("table"),
      header = table.createTHead(),
      row = header.insertRow(0);
    //cell = row.insertCell(0);
    row.innerHTML = `<h1>Pits/Gaps - List &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Farm
    : ${getCookie(COOKIE.FARM)}${" "} </h1>`;
    table.setAttribute("id", "table-to-xls");
    table.setAttribute("class", "Pits-Gaps");
  }

  fetchBlockNo = () => {
    fetchApi("/blocksuuid", "get").then(data =>
      this.setState({ blockNoList: sortFunction(data, "blockNoFull") })
    );
  };

  fetchPitsGaps = (reload = true) => {
    if (reload) {
      this.setState({
        tableLoader: true,
        dynamicTableData: [],
        pagination: false
      });
      let { row_uuid, formarea_uuid, block_uuid } = this.state,
        url = "/pit_gaps";
      if (block_uuid) url += `?block.uuid=${block_uuid}`;
      if (formarea_uuid) url += `&area.uuid=${formarea_uuid}`;
      if (row_uuid) url += `&row.uuid=${row_uuid}`;

      fetchApi(url, "get").then(data => {
        this.setState(
          {
            tableLoader: false,
            data,
            dynamicTableData: sortFunction(data, "pitGapNoFull"),
            pagination: true
          },
          () => this.tableFilterHandler()
        );
      });
    }
  };

  addModalToggle = () => {
    let {
      blockNumber,
      block_uuid,
      areaNumber,
      rowNumber,
      addModal,
      isEdit,
      area_uuid,
      row_uuid,
      pitsGapNumber,
      pitsGaps_uuid,
      isAdded,
      isDelete,
      isTransfer
    } = this.state;
    if (addModal) {
      this.setState(
        {
          addModal: false,
          editData: null,

          isDelete: false,
          treeTypeCodeList: [],

          blockNumber:
            isEdit || isAdded || isDelete || isTransfer ? blockNumber : null,
          block_uuid:
            isEdit || isAdded || isDelete || isTransfer ? block_uuid : null,
          areaNumber:
            isEdit || isAdded || isDelete || isTransfer ? areaNumber : null,
          area_uuid:
            isEdit || isAdded || isDelete || isTransfer ? area_uuid : null,
          rowNumber: isEdit || isDelete || isTransfer ? rowNumber : null,
          row_uuid: isEdit || isDelete || isTransfer ? row_uuid : null,
          pitsGapNumber:
            isEdit || isDelete || isTransfer ? pitsGapNumber : null,
          pitsGaps_uuid:
            isEdit || isDelete || isTransfer ? pitsGaps_uuid : null,
          addPopulateData: null,
          // addPopulateData: isAdded
          //   ? {
          //       blockNo: blockNumber,
          //       areaNo: areaNumber
          //     }
          //   : null,
          isEdit: false,
          isAdded: false,
          isTransfer: false
        }
        //() => this.fetchPitsGaps()
      );
    } else {
      if (!blockNumber || !areaNumber) {
        message.info("Select Block No. and Area No.");
      } //else if (!areaNumber) {
      //   message.info("Please Select an Area Number");
      // } else if (!rowNumber) {
      //   message.info("Please Select a Row Number");
      //}
      else {
        // this.setState({
        //   addModal: blockNumber
        //     ? areaNumber
        //       ? rowNumber
        //         ? !this.state.addModal
        //         : false
        //       : false
        //     : false
        // });
        this.setState({
          addModal: blockNumber ? !this.state.addModal : false
        });
      }
    }
  };

  clearFields = () => {
    this.setState({
      areaNumber: null,
      area_uuid: null,
      rowNumber: null,
      row_uuid: null,
      pitsGapNumber: null,
      pitsGaps_uuid: null,

      addPopulateData: {
        //   //areaNo: null,
        rowNo: null,
        pitGapNo: null,
        isPit: null,
        tagNumber: null
      }
    });
  };

  addFormHandler = ({
    pitGapNo,
    pitGapNoSuffix,
    isPit,
    dtTransfered,
    variety,
    stage,
    treeType,
    dtRemoved,
    tagNumber,
    ...val
  }) => {
    //to remove the previous instances of
    notification.destroy();

    let {
      block_uuid,
      formarea_uuid,
      row_uuid,
      isEdit,
      blockUuid,
      areaUuid,
      rowUuid,
      pitGapUuid,
      pitGapNum,
      pitGapSuffix,
      isTransfer,
      isDelete,
      treeType_uuid,
      growthCode
    } = this.state;
    this.setState({ btnLoad: true });
    if (isEdit) {
      fetchApi(`/pit_gaps/${pitGapUuid}`, "put", {
        pitGapNo: parseInt(pitGapNum),
        pitGapSuffix:
          pitGapSuffix == "--All--" || pitGapSuffix == "" ? null : pitGapSuffix,
        isPit: isPit === "Pit" ? true : false,
        block: "/blocks/" + blockUuid,
        area: "/areas/" + areaUuid,
        row: "/rows/" + rowUuid,
        isDelete: true,
        ...val
      }).then(res => {
        if (res) {
          if (res.violations) {
            notification.error({
              message: "Failed",
              description: res.violations[0].message
            });
          } else {
            notification.success({
              message: "Success",
              description: "Successfully updated Pit or Gap"
            });
            this.fetchPitsGaps();
            this.addModalToggle()
          }
          this.setState(
            {
              btnLoad: false
            }
            //,
            //() => this.addModalToggle()
          );
        } else {
          notification.error({
            message: "Failed",
            description: "Failed to updated Pit or Gap"
          });
        }
      });
    } else if (isTransfer) {
      //Once pit and gap is transferred create Dwarf or Tall Seedling
      let treeVariety = variety === "Dwarf Variety" ? "dwarves" : "talls",
        transfertree = variety === "Dwarf Variety" ? "Dwarf" : "Tall",
        treeStage =
          stage === "Seedling"
            ? 1
            : stage === "Plant"
              ? 2
              : stage === "Tree"
                ? 3
                : null;
      fetchApi(`/pit_gaps/${pitGapUuid}`, "put", {
        dtRemoved: dtTransfered.format("YYYY-MM-DD")
      }).then(res => {
        if (res) {
          if (res.violations) {
            notification.error({
              message: "Failed",
              description: res.violations[0].message
            });
          } else {
            fetchApi(`/${treeVariety}`, "post", {
              treeNo: parseInt(pitGapNum),
              suffix:
                pitGapSuffix == "--All--" || pitGapSuffix == ""
                  ? null
                  : pitGapSuffix,
              dtPlanted: dtTransfered.format("YYYY-MM-DD"),
              treeType: "/tree_types/" + treeType_uuid,
              tagNumber: tagNumber? tagNumber.toUpperCase(): "",
              stageCode: treeStage,
              growthCode: growthCode,
              block: "/blocks/" + blockUuid,
              area: "/areas/" + areaUuid,
              row: "/rows/" + rowUuid,
              ...val
            }).then(res => {
              if (res) {
                if (res.violations) {
                  notification.error({
                    message: "Failed",
                    description: res.violations[0].message
                  });
                  fetchApi(`/pit_gaps/${pitGapUuid}`, "put", {
                    dtRemoved: null
                  })
                } else {
                  notification.success({
                    message: "Success",
                    description:
                      "Successfully transferred Pit/Gap No. to " +
                      transfertree +
                      " " +
                      stage
                  });
                  this.fetchPitsGaps();
                  this.addModalToggle()
                }
              } else {
                notification.error({
                  message: "Failed",
                  description: "Failed to updated Pit or Gap"
                });
              }
            });
          }
          this.setState(
            {
              btnLoad: false
            }
            //,
            //() => this.addModalToggle()
          );
        } else {
          notification.error({
            message: "Failed",
            description:
              "Failed to transfer Pit/Gap No. to " + transfertree + " " + stage
          });
        }
      });

      //   let treeVariety = variety == "Dwarf Variety" ? "dwarves" : "talls",
      //     treeStage =
      //       stage == "Seedling"
      //         ? 1
      //         : stage == "Plant"
      //         ? 2
      //         : stage == "Tree"
      //         ? 3
      //         : null;

      //   fetchApi(`/${treeVariety}`, "post", {
      //     treeNo: parseInt(pitGapNum),
      //     suffix:
      //       pitGapSuffix == "--All--" || pitGapSuffix == "" ? null : pitGapSuffix,
      //     dtPlanted: dtTransfered.format("YYYY-MM-DD"),
      //     treeType: "/tree_types/" + treeType_uuid,
      //     stageCode: treeStage,
      //     growthCode: growthCode,
      //     block: "/blocks/" + blockUuid,
      //     area: "/areas/" + areaUuid,
      //     row: "/rows/" + rowUuid,
      //     ...val
      //   }).then(res => {
      //     if (res) {
      //       fetchApi(`/pit_gaps/${pitGapUuid}`, "put", {
      //         dtRemoved: dtTransfered.format("YYYY-MM-DD")
      //       }).then(res => {
      //         if (res) {
      //           notification.success({
      //             message: "Success",
      //             description:
      //               "Successfully transferred pits/gap to " +
      //               transfertree +
      //               " " +
      //               stage
      //           });
      //           this.fetchPitsGaps();
      //         } else {
      //           notification.error({
      //             message: "Failed",
      //             description: "Failed to updated Pit or Gap"
      //           });
      //         }
      //       });
      //     } else {
      //       notification.error({
      //         message: "Failed",
      //         description:
      //           "Failed to transferr pits/gap to " + transfertree + " " + stage
      //       });
      //     }
      //     this.setState(
      //       {
      //         btnLoad: false
      //       },
      //       () => this.addModalToggle()
      //     );
      //   });
    } else if (isDelete) {
      fetchApi(`/pit_gaps/${pitGapUuid}`, "put", {
        dtRemoved: dtRemoved.format("YYYY-MM-DD"),
        isDelete: true
      }).then(res => {
        if (res) {
          if (res.violations) {
            notification.error({
              message: "Failed",
              description: res.violations[0].message
            });
          } else {
            notification.success({
              message: "Success",
              description: "Successfully deleted the Pit or Gap"
            });
            this.fetchPitsGaps();
            this.addModalToggle()
          }
          this.setState(
            {
              btnLoad: false
            }
            //,
            //() => this.addModalToggle()
          );
        } else {
          notification.error({
            message: "Failed",
            description: "Failed to delete the Pit or Gap"
          });
        }
      });
    } else {
      fetchApi("/pit_gaps", "post", {
        pitGapNo: parseInt(pitGapNo),
        pitGapSuffix: pitGapNoSuffix == "--All--" ? null : pitGapNoSuffix,
        isPit: isPit === "Pit" ? true : false,
        block: "/blocks/" + block_uuid,
        area: "/areas/" + formarea_uuid,
        row: "/rows/" + row_uuid,
        ...val
      }).then(res => {
        if (res) {
          if (res.violations) {
            notification.error({
              message: "Failed",
              description: res.violations[0].message
            });
          } else {
            notification.success({
              message: "Success",
              description: "Successfully added Pit or Gap"
            });
            this.clearFields();
            this.setState({ isAdded: true });
            this.fetchPitsGaps();
          }
        } else {
          notification.error({
            message: "Failed",
            description: "Failed to add Pit or Gap"
          });
        }
        this.setState({
          btnLoad: false,
          addPopulateData: null
        });
      });
    }
  };

  onBlockChangeHandler = it => {
    let { blockNoList } = this.state;

    if (it) {
      fetchApi(
        `/areasuuid?block.uuid=${
        cloneDeep(blockNoList).filter(item => item.blockNoFull == it)[0].uuid
        }`,
        "get"
      ).then(res => this.setState({ areaNoList: res }));
      this.setState(
        {
          blockNumber: it ? it : null,
          block_uuid: it
            ? cloneDeep(blockNoList).filter(item => item.blockNoFull == it)[0]
              .uuid
            : null,
          areaNumber: null,
          area_uuid: null,
          formareaNumber: null,
          formarea_uuid: null,
          areaNoList: [],
          rowNumber: null,
          row_uuid: null,
          rowNoList: [],
          pitsGapNumber: null,
          pitsGapsNoList: [],
          addPopulateData: {
            blockNo: it ? it : null
          }
        },
        () => this.fetchPitsGaps()
      );
    } else {
      this.setState(
        {
          blockNumber: null,
          block_uuid: null,
          areaNumber: null,
          area_uuid: null,
          formareaNumber: null,
          formarea_uuid: null,
          areaNoList: [],
          rowNumber: null,
          row_uuid: null,
          rowNoList: [],
          pitsGapNumber: null,
          pitsGapsNoList: [],
          addPopulateData: {
            //blockNo: it ? it : null
          }
        },
        () => this.fetchPitsGaps()
      );
    }
  };

  onAreaChangeHandler = (it, reload = true) => {
    let { areaNoList, blockNoList, blockNumber } = this.state;

    if (it) {
      fetchApi(
        `/rowsuuid?area.uuid=${
        cloneDeep(areaNoList).filter(item => item.areaNo == it)[0].uuid
        }&block.uuid=${
        cloneDeep(blockNoList).filter(
          item => item.blockNoFull == blockNumber
        )[0].uuid
        }`,
        "get"
      ).then(res => {
        this.setState({ rowNoList: sortFunction(res, "rowNoFull") });
      });
      this.setState(
        {
          areaNumber: it ? it : null,
          area_uuid: it
            ? cloneDeep(areaNoList).filter(item => item.areaNo == it)[0].uuid
            : null,
          formareaNumber: it ? it : null,
          formarea_uuid: it
            ? cloneDeep(areaNoList).filter(item => item.areaNo == it)[0].uuid
            : null,
          rowNumber: null,
          row_uuid: null,
          rowNoList: [],
          pitsGapNumber: null,
          pitsGapsNoList: [],
          addPopulateData: {
            blockNo: blockNumber ? blockNumber : null,
            areaNo: it ? it : null
            //rowNo: null
          }
        },
        () => this.fetchPitsGaps(reload)
      );
    } else {
      this.setState(
        {
          areaNumber: null,
          area_uuid: null,
          formareaNumber: null,
          formarea_uuid: null,
          rowNumber: null,
          row_uuid: null,
          rowNoList: [],
          pitsGapNumber: null,
          pitsGapsNoList: [],
          addPopulateData: {
            blockNo: blockNumber ? blockNumber : null
          }
        },
        () => this.fetchPitsGaps(reload)
      );
    }
  };

  onRowChangeHandler = (it, reload = true) => {
    let {
      rowNoList,
      areaNoList,
      blockNoList,
      blockNumber,
      formareaNumber
    } = this.state;

    if (it) {
      fetchApi(
        `/pitgapsuuids?row.uuid=${
        cloneDeep(rowNoList).filter(item => item.rowNoFull == it)[0].uuid
        }&area.uuid=${
        cloneDeep(areaNoList).filter(item => item.areaNo == formareaNumber)[0]
          .uuid
        }&block.uuid=${
        cloneDeep(blockNoList).filter(
          item => item.blockNoFull == blockNumber
        )[0].uuid
        }`,
        "get"
      ).then(res => {
        this.setState({ pitsGapsNoList: sortFunction(res, "pitGapNoFull") });
      });
      this.setState(
        {
          rowNumber: it ? it : null,
          row_uuid: it
            ? cloneDeep(rowNoList).filter(item => item.rowNoFull == it)[0].uuid
            : null,
          pitsGapNumber: null,
          pitsGapsNoList: [],
          addPopulateData: {
            blockNo: blockNumber ? blockNumber : null,
            areaNo: formareaNumber ? formareaNumber : null,
            rowNo: it ? it : null
          }
        },
        () => this.fetchPitsGaps(reload)
      );
    } else {
      this.setState(
        {
          rowNumber: null,
          row_uuid: null,
          pitsGapNumber: null,
          pitsGapsNoList: [],
          addPopulateData: {
            blockNo: blockNumber ? blockNumber : null,
            areaNo: formareaNumber ? formareaNumber : null
            //rowNo: it ? it : null
          }
        },
        () => this.fetchPitsGaps(reload)
      );
    }
  };

  onPitGapChangeHandler = it => {
    let { pitsGapsNoList } = this.state;

    this.setState(
      {
        pitsGapNumber: it ? it : null,
        pitsGaps_uuid: it
          ? cloneDeep(pitsGapsNoList).filter(item => item.pitGapNoFull == it)[0]
            .uuid
          : null
      },
      () => this.fetchPitsGaps()
    );
  };

  tableFilterHandler = () => {
    let { pitsGapNumber, data } = this.state,
      filterJson = {};

    if (pitsGapNumber) filterJson.pitGapNoFull = pitsGapNumber;
    this.setState({
      dynamicTableData: filter(data, filterJson)
    });
  };

  onActionClickHandler = (item, action) => {
    console.log("items", item);
    this.setState(
      {
        isEdit: action === "edit" ? true : false,
        isDelete: action === "delete" ? true : false,
        isTransfer: action === "transfer" ? true : false
      },
      () => {
        this.setState({
          blockUuid: item.blockUuid,
          areaUuid: item.areaUuid,
          rowUuid: item.rowUuid,
          pitGapUuid: item.uuid,
          pitGapNum: item.pitGapNo,
          pitGapSuffix: item.pitGapSuffix,
          growthCode: item.growthCode + 1,
          addModal: true,
          editData: {
            blockNo: item.blockNo,
            areaNo: item.areaNo,
            rowNo: item.rowNo,
            pitGapNoFull: item.pitGapNoFull,
            isPit: item.isPit ? "Pit" : "Gap"
          }
        });
      }
    );
  };

  fetchTreeTypeCode = type =>
    fetchApi(
      `/tree_types?type=${type}&properties[]=uuid&properties[]=code`,
      "get"
    ).then(data => this.setState({ treeTypeCodeList: data }));

  onTreeVarietyChangeHandler = item => {
    // this.setState(
    //   {
    //     treeTypeCode: null,
    //     treeTypeCodeList: []
    //   },
    //   () => {
    //     if (item) {
    //       this.fetchTreeTypeCode(item == "Dwarf Variety" ? 1 : 2);

    //     }
    //   }
    // );
    //let { treeTypeCode } = this.state;
    if (item) {
      this.setState(
        {
          treeTypeCode: null,
          treeType_uuid: null,
          treeTypeCodeList: [],
          addPopulateData: {
            treeType: null
          }
        },
        () => this.fetchTreeTypeCode(item === "Dwarf Variety" ? 1 : 2)
      );
      //this.fetchTreeTypeCode(item == "Dwarf Variety" ? 1 : 2);
    } else {
      this.setState(
        {
          treeTypeCode: "Hello",
          treeType_uuid: null,
          treeTypeCodeList: [],
          addPopulateData: {
            treeType: null
          }
        },
        () => this.tableFilterHandler()
      );
    }
  };

  onTreeTypeChangeHandler = it => {
    let { treeTypeCodeList } = this.state;
    this.setState(
      {
        treeTypeCode: it ? it : null,
        treeType_uuid: it
          ? cloneDeep(treeTypeCodeList).filter(item => item.code == it)[0].uuid
          : null,
        addPopulateData: {
          treeType: it ? it : null
        }
      },
      () => this.tableFilterHandler()
    );
  };

  render() {
    let {
      addModal,
      dynamicTableData,
      blockNoList,
      blockNumber,
      areaNoList,
      areaNumber,
      rowNoList,
      rowNumber,
      pitsGapsNoList,
      pitsGapNumber,
      editData,
      pagination,
      tableLoader,
      btnLoad,
      isEdit,
      isDelete,
      isTransfer,
      treeTypeCodeList,
      addPopulateData
    } = this.state;
    const columns = [
      {
        title: "Pit/Gap No.",
        dataIndex: "pitGapNoFull"
      },
      {
        title: "Pit or Gap",
        dataIndex: "isPit",
        render: isPit => (isPit ? "Pit" : "Gap")
      },
      {
        title: "Block No.",
        dataIndex: "blockNo"
      },
      {
        title: "Area No.",
        dataIndex: "areaNo"
      },
      {
        title: "Row No.",
        dataIndex: "rowNo"
      },
      {
        title: "Action",
        className: "action",
        render: it => (
          <Fragment>
            <Tooltip placement="topRight" title={"Edit"}>
              <Icon
                type="edit"
                className="table-action-btn"
                onClick={() => this.onActionClickHandler(it, "edit")}
              />
            </Tooltip>
            <Tooltip placement="topRight" title={"Delete"}>
              <Icon
                type="delete"
                className="table-action-btn"
                onClick={() => this.onActionClickHandler(it, "delete")}
              />
            </Tooltip>
            <Tooltip placement="topRight" title={"Transfer"}>
              <Icon
                type="to-top"
                className="table-action-btn"
                onClick={() => this.onActionClickHandler(it, "transfer")}
              />
            </Tooltip>
          </Fragment>
        )
      }
    ],
      formItems = [
        {
          name: "blockNo",
          placeholder: "Block No.",
          disabled: true
        },
        {
          name: "areaNo",
          placeholder: "Area No.",
          type: "number",
          disabled: true
        },
        {
          name: "rowNo",
          placeholder: "Row No.",
          disabled: true
        },
        {
          name: "pitGapNoFull",
          placeholder: "Pit/Gap No.",
          disabled: true
        }
      ];

    const _formItems = isEdit
      ? formItems.concat([
        {
          name: "isPit",
          placeholder: "Pit or Gap",
          listData: ["Pit", "Gap"]
        }
      ])
      : isDelete
        ? formItems.concat([
          {
            name: "isPit",
            placeholder: "Pit or Gap",
            disabled: true
          },
          {
            name: "dtRemoved",
            placeholder: "Pit/Gap removal date",
            type: "date"
          }
        ])
        : isTransfer
          ? formItems.concat([
            {
              name: "variety",
              placeholder: "Dwarf/Tall Variety",
              listData: ["Dwarf Variety", "Tall Variety"],
              changeHandler: it => this.onTreeVarietyChangeHandler(it)
            },
            {
              name: "treeType",
              placeholder: "Tree Type",
              disabled: treeTypeCodeList.length > 0 ? false : true,

              listData: cloneDeep(treeTypeCodeList).map(it => it.code),
              changeHandler: it => this.onTreeTypeChangeHandler(it)
            },
            {
              name: "stage",
              placeholder: "Stage",
              listData: ["Seedling", "Plant", "Tree"]
            },
            {
              name: "tagNumber",
              placeholder: "Tag No.",
              type: "tagNum",
            },
            {
              name: "dtTransfered",
              placeholder: "Date Of Transfer",
              type: "date"
            }
          ])
          : [
            {
              name: "blockNo",
              placeholder: "Block No.",
              disabled: true
            },
            {
              name: "areaNo",
              placeholder: "Area No.",
              type: "number",
              required: true,
              //listData: cloneDeep(areaNoList).map(it => it.areaNo),
              //changeHandler: it => this.onAreaChangeHandler(it, false),
              disabled: true
            },
            {
              name: "rowNo",
              placeholder: "Row No.",
              type: "number",
              //disabled: areaNumber ? false : true,
              required: true,
              listData: cloneDeep(rowNoList).map(it => it.rowNoFull),
              //disabled: rowNoList.length > 0 ? false : true,
              readonly: rowNoList.length > 0 ? false : true,
              changeHandler: it => this.onRowChangeHandler(it, false)
            },
            {
              name: "pitGapNo",
              placeholder: "Pit/Gap No.",
              type: "number",
              postfix: true
            },
            {
              name: "isPit",
              placeholder: "Pit or Gap",
              listData: ["Pit", "Gap"]
            }
          ];

    const buttonList =
      dynamicTableData.length > 0
        ? [
          {
            label: "Add",
            icon: "folder-add",
            onClickHandler: this.addModalToggle
          },
          /*{
            label: "Excel",
            icon: "file-text"
          },*/
          /*{
            label: "Print",
            icon: "diff",
            reference: this.componentRef
          },*/
          {
            label: this.state.pagination ? "View All" : "View Less",
            icon: "container",
            onClickHandler: () => this.setState({ pagination: !pagination })
          }
        ]
        : [
          {
            label: "Add",
            icon: "folder-add",
            onClickHandler: this.addModalToggle
          }
        ];

    return (
      <LocationPageBuilder
        title="Pits/Gaps"
        buttonList={buttonList}
        dropDownList={[
          {
            label: "Block",
            listItems: cloneDeep(blockNoList).map(it => it.blockNoFull),
            value: blockNumber ? blockNumber : undefined,
            changeHandler: it => this.onBlockChangeHandler(it)
          },
          {
            label: "Area",
            listItems: cloneDeep(areaNoList).map(it => it.areaNo),
            disabled: blockNumber ? false : true,
            value: areaNumber ? areaNumber : undefined,
            changeHandler: it => this.onAreaChangeHandler(it)
          },
          {
            label: "Row",
            listItems: cloneDeep(rowNoList).map(it => it.rowNoFull),
            disabled: areaNumber ? false : true,
            value: rowNumber ? rowNumber : undefined,
            changeHandler: it => this.onRowChangeHandler(it)
          },
          {
            label: "Pit/Gap",
            listItems: cloneDeep(pitsGapsNoList).map(it => it.pitGapNoFull),
            disabled: rowNumber ? false : true,
            value: pitsGapNumber ? pitsGapNumber : undefined,
            changeHandler: it => this.onPitGapChangeHandler(it)
          }
        ]}
        addModal={addModal}
        addModalToggle={this.addModalToggle}
        addFormHandler={this.addFormHandler}
        editData={editData ? editData : null}
        btnLoad={btnLoad}
        formItems={_formItems}
        isEdit={isEdit}
        addPopulateData={addPopulateData}
        isDelete={isDelete}
        isTransfer={isTransfer}
      >
        <div ref={this.componentRef}>
          {/* <h1>
            Pits/Gap - List &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Farm :{" "}
            {getCookie(COOKIE.FARM)}{" "}
          </h1> */}
          <Spin spinning={tableLoader} size="large">
            <Table
              ref="table"
              columns={columns}
              dataSource={dynamicTableData}
              pagination={!pagination ? pagination : { pageSize: 15 }}
              //loading={tableLoader}
              locale={{
                emptyText: <Empty />
              }}
            />
          </Spin>
        </div>
      </LocationPageBuilder>
    );
  }
}

export default PitsGaps;
