import React, { Component } from "react";

//Component
import { message, Spin } from "antd";
import ReportLayout from "../../pageLayouts/Report";
import MultiTable from "./MultiTable";
import FarmTotal from "./FarmTotal";
import Empty from "../../components/Custom/Empty";

//Utility
import { fetchApi, fetchExcelApi } from "../../../services/api";
import { cloneDeep } from "lodash";
import moment from "moment";
import { sortFunction } from "../../../utility/sorter";
import { COOKIE, getCookie } from "../../../services/cookie";

class DwarfTallTreeWithHarvestedNuts extends Component {
  componentRef = React.createRef();
  state = {
    data: [],
    dynamicData: [],
    loading: false,
    blockNoList: [],
    blockNumber: null,
    treeTypeList: [],
    treeType: null,
    treeCode: null,
    harvestedNutsFrom: null,
    harvestedNutsTo: null,
    farm_total: [],
    type: null,
    equality: "<=",
    numberOfNuts: null
  };

  componentDidMount() {
    this.fetchBlockNo();
  }

  fetchDwarfTallTreeWithHarvestedNuts = () => {
    let {
      blockNumber,
      treeType,
      harvestedNutsFrom,
      type,
      equality,
      numberOfNuts,
      harvestedNutsTo
    } = this.state;
    this.setState({ data: [] })
    if (
      harvestedNutsFrom &&
      harvestedNutsTo &&
      type &&
      equality &&
      numberOfNuts
    ) {
      let url = "/query-module/harvested-nuts";
      url += `?type=${type === "Dwarf" ? 1 : 2}`;
      url += `&fromDate=${harvestedNutsFrom}&toDate=${harvestedNutsTo}`;
      url += `&isGreater=${
        equality === ">=" ? "true" : "false"
        }&nuts=${numberOfNuts}`;

      if (blockNumber) url += `&block.uuid=${blockNumber}`;
      if (treeType) url += `&tree_type.uuid=${treeType}`;
      this.setState({ loading: true });
      console.log(url);

      fetchApi(url, "get").then(res => {
        if (res) {
          let { blocks, farm_total } = res;
          this.setState({
            data: blocks ? blocks : [],
            farm_total: farm_total ? farm_total : []
          });
        } else message.error("Internal Server Error!");
        this.setState({ loading: false });
      });
    } else
      this.setState({
        data: [],
        farm_total: []
      });
  };

  fetchBlockNo = () =>
    fetchApi("/blocksuuid", "get").then(data =>
      this.setState({ blockNoList: sortFunction(data, "blockNoFull") })
    );

  fetchType = it =>
    it
      ? fetchApi(`/tree_types?type=${it === "Dwarf" ? 1 : 2}`, "get").then(
        data =>
          this.setState(
            {
              treeTypeList: sortFunction(data, "code"),
              treeType: null,
              treeCode: null,
              type: it
            },
            () => this.fetchDwarfTallTreeWithHarvestedNuts()
          )
      )
      : this.setState(
        { treeTypeList: [], treeType: null, treeCode: null, type: null },
        () => this.fetchDwarfTallTreeWithHarvestedNuts()
      );

  onDropDownChangeHandler = (type, item) => {
    switch (type) {
      case "block":
        this.setState(
          {
            blockNumber: item
              ? cloneDeep(this.state.blockNoList).filter(
                each => each.blockNoFull === item
              )[0].uuid
              : null
          },
          () => this.fetchDwarfTallTreeWithHarvestedNuts()
        );
        break;
      case "tree":
        this.setState(
          {
            treeType: item
              ? cloneDeep(this.state.treeTypeList).filter(
                each => each.code === item
              )[0].uuid
              : null,
            treeCode: item
          },
          () => this.fetchDwarfTallTreeWithHarvestedNuts()
        );
        break;
      case "equality":
        this.setState(
          {
            equality: item ? item : null
          },
          () => this.fetchDwarfTallTreeWithHarvestedNuts()
        );
        break;
      case "numberOfNuts":
        this.setState(
          {
            numberOfNuts: item ? item : null
          },
          () => this.fetchDwarfTallTreeWithHarvestedNuts()
        );
        break;
      default:
        this.setState(
          {
            harvestedNutsFrom:
              item.length > 0 ? item[0].format("YYYY-MM-DD") : null,
            harvestedNutsTo:
              item.length > 0 ? item[1].format("YYYY-MM-DD") : null
          },
          () => this.fetchDwarfTallTreeWithHarvestedNuts()
        );
    }
  };

  generateTables = tableData =>
    <div>
      <h3>{this.state.type + " "} Trees With Harvested Nuts {" " + this.state.equality + " " + this.state.numberOfNuts}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Farm
  : {getCookie(COOKIE.FARM).charAt(0).toUpperCase() + getCookie(COOKIE.FARM).slice(1)}{" "} </h3>
      <h3>Harvested Nuts from {moment(this.state.harvestedNutsFrom).format("DD/MM/YYYY")} to {moment(this.state.harvestedNutsTo).format("DD/MM/YYYY")}</h3>
      {tableData.map(({ block_no, list, summary }, index) => (
        <MultiTable
          key={index}
          blockNo={block_no}
          list={sortFunction(list, "tree_no_full")}
          summary={summary}
          listColumns={
            this.state.type == "Dwarf"
              ? [
                {
                  title: "Dwarf Tree No.",
                  dataIndex: "tree_no_full",
                  key: "tree_no_full"
                },
                {
                  title: "Date of Planting",
                  dataIndex: "dt_planted",
                  key: "dt_planted",
                  render: it => (it ? moment(it).format("DD/MM/YYYY") : "-")
                },
                {
                  title: this.state.type + " Type",
                  dataIndex: "code",
                  key: "code"
                },
                {
                  title: "No. of Spathes",
                  dataIndex: "no_of_spathes",
                  key: "no_of_spathes"
                },
                {
                  title: "No. of Buttons",
                  dataIndex: "no_of_buttons",
                  key: "no_of_buttons"
                },
                {
                  title: "120 Days Nuts Count",
                  dataIndex: "count_120_days_nuts",
                  key: "count_120_days_nuts"
                },
                {
                  title: "No. of Nuts Harvested",
                  dataIndex: "harvested_nuts",
                  key: "harvested_nuts"
                },
                {
                  title: "Nuts/Spathes",
                  dataIndex: "nuts_spathe",
                  key: "nuts_spathe"
                },
                {
                  title: "Last Date of Harvest",
                  dataIndex: "harvest_dt",
                  key: "harvest_dt",
                  render: it => (it ? moment(it).format("DD/MM/YYYY") : "-")
                }
              ]
              : [
                {
                  title: "Tall Tree No.",
                  dataIndex: "tree_no_full",
                  key: "tree_no_full"
                },
                {
                  title: "Date of Planting",
                  dataIndex: "dt_planted",
                  key: "dt_planted",
                  render: it => (it ? moment(it).format("DD/MM/YYYY") : "-")
                },
                {
                  title: "Tall Type",
                  dataIndex: "code",
                  key: "code"
                },
                {
                  title: "No. of Bunches",
                  dataIndex: "no_of_bunches",
                  key: "no_of_bunches"
                },
                {
                  title: "No. of Nuts Harvested",
                  dataIndex: "harvested_nuts",
                  key: "harvested_nuts"
                },
                {
                  title: "Nuts/Bunches",
                  dataIndex: "nuts_bunches",
                  key: "nuts_bunches"
                },
                {
                  title: "Last Date of Harvest",
                  dataIndex: "harvest_dt",
                  key: "harvest_dt",
                  render: it => (it ? moment(it).format("DD/MM/YYYY") : "-")
                }
              ]
          }
          summaryColumns={[
            {
              title: "Type",
              dataIndex: "code",
              render: it => (it ? it : <b>Block Total</b>)
            },
            { title: "No. Of Trees", dataIndex: "no_of_trees" }
          ]}
        />
      ))}
    </div>

  generateFarmTotal = data => (
    <FarmTotal
      data={data}
      columns={[
        {
          title: "Type",
          dataIndex: "code",
          render: it => (it ? it : <b>Farm Total</b>)
        },
        { title: "No. Of Plants", dataIndex: "no_of_trees" }
      ]}
    />
  );

  generateExcel = () => {
    let {
      blockNumber,
      treeType,
      harvestedNutsFrom,
      type,
      equality,
      numberOfNuts,
      harvestedNutsTo
    } = this.state;
    if (
      harvestedNutsFrom &&
      harvestedNutsTo &&
      type &&
      equality &&
      numberOfNuts
    ) {
      let url = "/query-module/harvested-nuts";
      url += `?type=${type === "Dwarf" ? 1 : 2}`;
      url += `&fromDate=${harvestedNutsFrom}&toDate=${harvestedNutsTo}`;
      url += `&isGreater=${
        equality === ">=" ? "true" : "false"
        }&nuts=${numberOfNuts}`;

      if (blockNumber) url += `&block.uuid=${blockNumber}`;
      if (treeType) url += `&tree_type.uuid=${treeType}`;
      fetchExcelApi(url, "get").then();
    }
  };

  render() {
    let {
      data,
      treeTypeList,
      treeCode,
      blockNoList,
      farm_total,
      loading,
      harvestedNutsFrom,
      harvestedNutsTo,
      type,
      equality,
      numberOfNuts
    } = this.state;
    let JSX =
      data.length > 0 ? (
        this.generateTables(data)
      ) : harvestedNutsFrom &&
        harvestedNutsTo &&
        type &&
        equality &&
        numberOfNuts ? (
            <Empty />
          ) : (
            <Empty
              description={
                "Select the Tree Type and Greater/Less than,Count and Period of Harvested Nuts"
              }
            />
          ),
      farmTotalJSX =
        data.length > 0 && farm_total.length > 0 ? this.generateFarmTotal(farm_total) : null;
    const buttonList =
      data.length > 0
        ? [
          {
            label: "Excel",
            icon: "file-text",
            onClickHandler: () => this.generateExcel()
          },
          {
            label: "Print",
            icon: "diff",
            reference: this.componentRef
          }
        ]
        : null;
    return (
      <ReportLayout
        title="Dwarf/Tall Trees With Harvested Nuts"
        buttonList={buttonList}
        dropDownList={[
          {
            label: "Block",
            listItems: cloneDeep(blockNoList).map(it => it.blockNoFull),
            changeHandler: it => this.onDropDownChangeHandler("block", it),
            className: "width-bock-no"
          },
          {
            label: "Tree",
            listItems: ["Dwarf", "Tall"],
            changeHandler: this.fetchType,
            className: "width-tree-type"
          },
          {
            label: "Type",
            disabled: !treeTypeList.length > 0,
            listItems: cloneDeep(treeTypeList).map(it => it.code),
            value: treeCode ? treeCode : undefined,
            changeHandler: it => this.onDropDownChangeHandler("tree", it),
            className: "width-type"
          },
          {
            label: "<=",
            listItems: ["<=", ">="],
            changeHandler: it => this.onDropDownChangeHandler("equality", it),
            className: "width-less-then"
          },
          {
            label: "Number of Nuts",
            type: "number",
            changeHandler: it =>
              this.onDropDownChangeHandler("numberOfNuts", it),
            className: "width-number-of-nuts",
            onPressEnter: it => this.onDropDownChangeHandler("numberOfNuts", it)
          },
          {
            label: "Harvested Nuts Period:",
            type: "range",
            //mode: ["date", "date"],
            format: "DD/MM/YYYY",
            changeHandler: (date, string) =>
              this.onDropDownChangeHandler("harvestDate", date)
          }
        ]}
      >
        <div ref={this.componentRef}>
          <Spin spinning={loading} size="large">
            {JSX}
            {farmTotalJSX}
          </Spin>
        </div>
      </ReportLayout>
    );
  }
}

export default DwarfTallTreeWithHarvestedNuts;
