import React from "react";

const renderContent = it =>
  it
    ? it
    : it == 0
    ? it
    : {
        props: {
          colSpan: 0
        }
      };

const columns = [
  {
    className: "reports-right-border",
    children: [
      {
        title: "Year",
        className: it => `rerer${it}`,
        render: it =>
          it.tree_code
            ? {
                children: it.tree_code,
                props: {
                  colSpan: 24
                }
              }
            : it.area_code
            ? {
                children: it.area_code,
                props: {
                  colSpan: 24
                }
              }
            : it.year
            ? it.year
            : {
                children: it.total,
                props: {
                  colSpan: 2
                }
              }
      },
      {
        title: "Month",
        dataIndex: "month",
        render: renderContent
      },
      {
        title: "No. of Trees",
        dataIndex: "no_of_tree",
        render: renderContent
      },
      {
        title: "No. of Plants",
        dataIndex: "no_of_plants",
        render: renderContent
      },
      {
        title: "Total Population",
        dataIndex: "total_population",
        className: "reports-right-border",
        render: renderContent
      }
    ]
  },
  {
    title: "EMASCULATION DATA",
    className: "reports-right-border",
    children: [
      {
        title: "Trees Emasc",
        dataIndex: "trees_emasc",
        render: renderContent
      },
      {
        title: "P/M Spathes",
        dataIndex: "pm_spathe",
        render: renderContent
      },
      {
        title: "Good Spathes",
        dataIndex: "good_spathes",
        render: renderContent
      },
      {
        title: "Spathes/ TreeEm",
        dataIndex: "spathes_treesemasc",
        render: renderContent
      },
      {
        title: "Spathes/ TotalPP",
        dataIndex: "spathes_treepop",
        render: renderContent
      },
      {
        title: "Total Buttons",
        dataIndex: "buttons_pol",
        render: renderContent
      },
      {
        title: "Button/ Spathe",
        dataIndex: "button_spathes",
        className: "reports-right-border",
        render: renderContent
      }
    ]
  },
  {
    title: "120 DAYS NUTS COUNT",
    className: "reports-right-border",
    children: [
      {
        title: "120 Days Count",
        dataIndex: "nuts",
        render: renderContent
      },
      {
        title: "Nuts/ Spathe",
        dataIndex: "nuts_spathe",
        render: renderContent
      },
      {
        title: "120 Days Setting %",
        dataIndex: "days_setting",
        className: "reports-right-border",
        render: renderContent
      }
    ]
  },
  {
    title: "HARVEST DATA ANALYSIS",
    className: "reports-right-border",
    children: [
      {
        title: "Harvested Nuts",
        dataIndex: "harvested_nuts",
        render: renderContent
      },
      {
        title: "Trees Harvested",
        dataIndex: "trees_harvested",
        render: renderContent
      },
      {
        title: "Nuts/ Harv. Tree",
        dataIndex: "nuts_treesharv",
        render: renderContent
      },
      {
        title: "Nuts/ Tot. Trees",
        dataIndex: "nuts_totalpop",
        render: renderContent
      },
      {
        title: "% of Yield to",
        className: "reports-right-border",
        children: [
          {
            title: "Buttons",
            dataIndex: "percent_yield_buttons",
            render: renderContent
          },
          {
            title: "120 days",
            dataIndex: "percent_yield_days_count",
            className: "reports-right-border",
            render: renderContent
          }
        ]
      }
    ]
  }
];

export default columns;
